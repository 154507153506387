import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Layer, Source, Popup } from "react-map-gl";
import { CircleLayer } from "mapbox-gl";
import { RefContext } from "../../RefContext";
import { PointContext } from "../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ActivePopupContext } from "../../ActivePopupContext";
import { adjustHexBrightness } from "../../features/utils";
import "./point-layer.scss";

const PointLayer = ({
    global,
    id,
    action,
    url,
    sourceLayer,
    circleColor,
    opacity,
    callValue,
    reportRoute
}: any) => {

    const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
    const { setCurrentPoint } = useContext<any>(PointContext);
    const { currentRef } = useContext(RefContext);

      // @ts-ignore
    const ref = currentRef?.current;
    const [features, setFeatures] = useState<any>()
    const lngLat = action?.lngLat;
    const navigate = useNavigate();

    const handleRadius = (input: number) => {
        if (input <= 3) {
            return 5;
        } else if (input >= 4 && input <= 7) {
            return 5;
        } else if (input >= 8 && input <= 9) {
            return 7;
        } else if (input >= 9) {
            return 9;
        }
      }

    const CircleVectorLayer: CircleLayer = {
        id: `${id}-layer`,
        type: "circle",
        "source-layer": `${sourceLayer}`,
        paint: {
            "circle-radius": handleRadius(global.viewport.zoom),
            "circle-opacity": opacity,
            "circle-color": circleColor,
        },
    };

    const goToReport = useCallback((lat: any, lng: any, input: string) => {
        setCurrentPoint({ lat, lng, id: input })
        setTimeout(() => navigate(reportRoute), 1000);
      }, []);

      const getFeaturesAtPoint = useCallback(async (map: any, event: any) => {
        const res = await map.queryRenderedFeatures(event.point)?.[0];
        if (res?.properties) {
            setFeatures(res?.properties)
        }
      }, [ref]);
    
      // Cleanup effect for the map click event
      useEffect(() => {
        if (ref) {
          const handleClick = (e: any) => getFeaturesAtPoint(ref, e);
          ref.on('click', handleClick);
          return () => ref.off('click', handleClick);
        }
      }, [ref, getFeaturesAtPoint]);

    const memoizedPopup = useMemo(() => {
        if (!features || !lngLat) return null;    
      
    
        const handleClose = () => {
          setFeatures(null);
          setActivePopup({ id: null, content: null });
        };
    
        return (
          <Popup
            longitude={lngLat.lng}
            latitude={lngLat.lat}
            onClose={handleClose}
            className={'circledata-popup'}
          >
            <FontAwesomeIcon
              className="close-btn"
              icon={faXmark}
              onClick={handleClose}
            />
            {features && Object.entries(features).map(([key, value]: [string, any]) => (
                <div key={key}>
                    {key}<strong>:</strong> {value}
                </div>
            ))}
            <a className="report-anchor" onClick={() => goToReport(lngLat.lat, lngLat.lng, features[callValue])}>Go to report</a>
          </Popup>
        );
      }, [features, lngLat]);

    useEffect(() => {
        if (memoizedPopup && activePopup.id !== id) {
          setActivePopup({
            id,
            content: memoizedPopup
          });
        }
      }, [memoizedPopup, activePopup, setActivePopup, id]);

  return (
    <Source id={`${id}-layer`} type="vector" url={url}>
        <Layer {...CircleVectorLayer} />
        {activePopup && activePopup.id === id && activePopup.content}
    </Source>
  )
}

export default PointLayer
