import LayerDataReport from "./features/reports/LayerDataReport/LayerDataReport";

const ReportsRoutes = {
  USGSWUCReport: '/USGSWUCReport', 
  Default: '/',
  ClimatePrecipitationCalculator: '/ClimatePrecipitationCalculator',
  Risk: '/Risk',
  WaterMonitoring: '/WaterMonitoring!',
  Dashboard: '/Dashboard',
  Chat: '/Chat',
  ContactAdmin: '/ContactAdmin',
  AAV4CoastalFloodRisk: '/AAV4CoastalFloodRisk',
  AAV4WaterStress: '/AqueductWaterRiskAtlasV4',
  AGWaterTrueQIForecast: '/AGWaterTrueQIForecast',
  AlgaeBloomPrediction: 'https://trueelements.shinyapps.io/TrueElements/',
  AqueductWaterRiskAtlas: '/AqueductWaterRiskAtlas',
  AustralianBoreData: '/AusBoreData',
  CarbonSequestration: 'https://carbonsequestrationhosting.s3.us-west-2.amazonaws.com/index.html',
  CarbonSequestrationModeler: 'http://3.22.0.171:8084/',
  ChemicalDataReporting: '/ChemicalDataReporting',
  CVI: '/ClimateVulnerabilityIndex',
  DailyLakeMeadLevelsReport: '/DailyLakeMeadLevelsReport',  
  DischargeMonitoring: '/DischargeMonitoring',
  DrinkingWaterTrueQIReport: '/DrinkingWaterTrueQIReport',
  EPAMajorPermitHolders: '/EPAMajorPermitHolders',
  FacilityRegistrySystem: '/FacilityRegistrySystem',
  GBIF: '/GBIF',
  GemstatRaw: '/GemstatRaw',
  GFSHuc12Forecast: '/GFSHuc12Forecast',
  GoogleMapsDemo: '/GoogleMapsDemo',
  GroundwaterDetermination: '/GroundwaterDetermination',
  GroundwaterLevels: '/GroundwaterLevels',
  GroundwaterMexico: '/GroundwaterMexico',
  HistoricalFireData: '/HistoricalFireData',
  IndoHUCs: '/IndoHUCs',
  IndoWatersheds: '/IndoWatersheds',
  LiveWaterSensors: '/LiveWaterSensors',
  MenuGemstatRaw: '/MenuGemstatRaw',
  NationalStormWaterQualityDatabase2020: '/NationalStormWaterQualityDatabase2020',
  NationalStormWaterQualityDatabase: '/NationalStormWaterQualityDatabase2020',
  NationalStreamQualityAccountingNetwork: '/NationalStreamQualityAccountingNetwork',
  PermitDischargeReport: '/PermitDischargeReport',
  PfasDrinkingWater: '/PfasDrinkingWater',
  PfasEnvironmental: '/PfasEnvironmental',
  PfasProduction: '/PfasProduction',
  ProtectedAreasWorldDatabase: '/ProtectedAreasWorldDatabase',
  RainfallForecast: '/RainfallForecast',
  RiskScreening: '/RiskScreening',
  StormWaterTrueQIForecast: '/StormWaterTrueQIForecast',
  SurfaceWaterFlowRate: '/SurfaceWaterFlowRate',
  SurfacewaterMexico: '/SurfacewaterMexico',
  Top100Polluters: '/Top100Polluters',
  ToxicsReleaseInventoryReport: '/ToxicsReleaseInventory',
  ViolationData: '/ViolationData',
  WaterConflict: '/WaterConflict',
  WhatsInMyDrinkingWater: '/WhatsInMyDrinkingWater',
  WhatsInMyWatershed: '/WhatsInMyWatershed',
  WIImpairedWatersList: '/WIImpairedWatersList',
  WildfireBoundaries: '/WildfireBoundaries',
  WaterImpairmentsSteams: "/WaterImpairementsStreams",
  WaterImpairmentsLakes: "/WaterImpairementsLakes",
  WaterImpairmentsPoints: "/WaterImpairementsPoints",
  WaterImpairmentsReport: "/WaterImpairmentsReport",
  USGSHuc12: "/USGSHuc12",
  CEJST: "/CEJST",
  PAWD: "/PAWD",
  CWAReport:'/CWAReport',
  FHBPReport: '/FHBPReport',
  FBFIBReport: '/FBFIBReport',
  OWQPReport: '/OWQPReport',
  NSGReport: '/NSGReport',
  FIBReport: '/FIBReport',
  MECReport: '/MECReport',
  WiseReport: '/WiseReport',
  HydrobasinsReport: '/HydrobasinsReport',
  HydrobasinsCombinedReport: '/HydrobasinsCombinedReport',
  OSUWellsReport: '/OSUWellsReport'
};

export default ReportsRoutes;
