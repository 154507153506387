import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from 'react';
import { Button, OverlayTrigger, Tab, Tabs, Dropdown, Row, Col, Card, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Tooltip as TooltipRB, Form, Modal, Popover, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { jsPDF, ImageOptions } from "jspdf";
import agent from '../../../api/agent';
import LoadingDataAnimation from '../../../components/LoadingDataAnimation';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fullscreenIcon from '../../../assets/fullscreen-icon.svg';
import trueQ from '../../../assets/trueQ.svg';

import * as d3Format from 'd3-format';
import { DateTime } from 'luxon';
import localForage from "localforage";
import { formatRowValue } from '../../utils';

import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';

import ReportsConfigs from '../../../ReportsConfigs';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTableExpandedPopup from '../shared/ReportTableExpandedPopup';
import { HeaderProps } from 'react-table';
import { RefContext } from '../../../RefContext';
import { v4 as uuidv4 } from 'uuid';
import { mapLayersOff } from '../../utils';
import { parseQueryFilters, exportToCsv, exportReportLink } from '../shared/report-data';
import SearchInput from '../../../layout/SearchInput';
import { putReport, setLocalReport, getCountryFromStorage } from '../../utils';
import { Location, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { QueryParamProvider,
  useQueryParam, StringParam, NumberParam,
  BooleanParam} from 'use-query-params';
import { message_hucrestriction, message_nodatalocation, message_erroroccured } from '../../../Constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExport, faFloppyDisk, faFilePdf, faGear, faCancel, faRectangleXmark, faRotate, faTrashCan, faList, faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons';
import searchLocation from "../../../assets/searchLocation.png";


import reportsAGTQIcon from '../../../assets/reports/reports_AGTF.png';
import reportsCDRIcon from '../../../assets/reports/reports_CDR.png';
import reportsCPCIcon from '../../../assets/reports/reports_CPC.png';
import reportsDashIcon from '../../../assets/reports/reports_DASH.png';
import reportsDMLIcon from '../../../assets/reports/reports_DML.png';
import reportsDMRIcon from '../../../assets/reports/reports_DMR.png';
import reportsEPAIcon from '../../../assets/reports/reports_EPA.png';
import reportsFRSIcon from '../../../assets/reports/reports_FRS.png';
import reportsGEMIcon from '../../../assets/reports/reports_GEM.png';
import reportsGFSIcon from '../../../assets/reports/reports_GFS.png'; 
import reportsGLWIcon from '../../../assets/reports/reports_GLW.png'; 
import reportsGWLIcon from '../../../assets/reports/reports_GWL.png'; 
import reportsLWSQIcon from '../../../assets/reports/reports_LWS.png';
import reportsNSQAIcon from '../../../assets/reports/reports_NSQA.png';
import reportsNSQDIcon from '../../../assets/reports/reports_NSQD.png';
import reportsPDRIcon from '../../../assets/reports/reports_PDR.png';
import reportsPFASdrIcon from '../../../assets/reports/reports_PFASdr.png';
import reportsPFASeIcon from '../../../assets/reports/reports_PFASe.png';
import reportsPFASprIcon from '../../../assets/reports/reports_PFASpr.png';
import reportsRiskIcon from '../../../assets/reports/reports_Risk.png';
import reportsRiskScrIcon from '../../../assets/reports/reports_RiskScr.png';
import reportsRiverineIcon from '../../../assets/reports/reports_Riverine.png';
import reportsSWFIcon from '../../../assets/reports/reports_SWF.png';
import reportsSwFRIcon from '../../../assets/reports/reports_SWFR.png';
import reportsTRIIcon from '../../../assets/reports/reports_TRI.png';
import reportsUCMRIcon from '../../../assets/reports/reports_UCMR.png';
import reportsWIWIcon from '../../../assets/reports/reports_WIW.png';
import reportsWMRIcon from '../../../assets/reports/reports_WMR.png';
import reports303DIcon from '../../../assets/reports/reports_303D.png';




import './styles.scss';
import { resolve } from 'dns';


export default function Dashboard(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;
  const navigate = useNavigate();
  const selectedHUC8Value = appContext.selectedHUC8;
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);


  const [tableData, setTableData] = useState<any[]>([]);
  const [savedData, setSavedData] = useState<any[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<string>('');
  const [removeLink, setRemoveLink] = useState<string>('');
  const [removeDashboard, setRemoveDashboard] = useState<string>('');
  const [navigateLink, setNavigateLink] = useState<string>('');
  const [exportLink, setExportLink] = useState<string>('');
  const [currentLink, setCurrentLink] = useState<string>('');
  const [addReportLinks, setAddReportLinks] = useState<any[]>([]);

  const [savedDashboards, setSavedDashboards] = useState<any[]>([]);
  const [dashboards, setDashboards] = useState<any[]>([]);

  const [savedThumbnails, setSavedThumbnails] = useState<any[]>([]);

  const [isDisplayGrid, setIsDisplayGrid] = useState(true);
  const [tabActiveKey, setTabActiveKey] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMode, setDeleteMode] = useState<string>('');
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [changeMode, setChangeMode] = useState<string>('');

  const [useremail, setUseremail] = useState<string>('');
  const [qsr, setqsr] = useQueryParam('r', BooleanParam); 
  const [qsdash, setqsdash] = useQueryParam('dash', StringParam);
  const [qsdt, setqsdt] = useQueryParam('dt', StringParam);
  const [qsdr, setqsdr] = useQueryParam('dr', BooleanParam);

  const [changedValue, setChangedValue] = useState<string>('');

  const [group, setGroup] = useState<number>(1);
  const [globalgr, setGlobalgr] = useState<number>(1);
  const [useremailMain, setUseremailMain] = useState<string>('');
  const [userdash, setuserdash] = useState<any[]>([]);
  const [userdashlinks, setuserdashlinks] = useState<any[]>([]);
  const [usertemp, setusertemp] = useState<any[]>([]);
  const [usertemplinks, setusertemplinks] = useState<any[]>([]);
  const [userwork, setuserwork] = useState<any[]>([]);
  const [userworklinks, setuserworklinks] = useState<any[]>([]);
  const [grouptemp, setgrouptemp] = useState<any[]>([]);
  const [grouptemplinks, setgrouptemplinks] = useState<any[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<string>('');
  const [currentWorkflow, setCurrentWorkflow] = useState<string>('');
  const groupemail = "groupglobal@te.com";

  const [workflowids, setworkflowids] = useState<any[]>([]);

  let rslinks = [] as any;
  const currentTitle = useRef(null)
  const currentDescription = useRef(null);

  useEffect(() => {
    const ue_ls = localStorage.getItem('useremail');
    if(ue_ls && ue_ls.length>4){
      setUseremail(ue_ls)   
      //setUseremail(groupemail)
    }
  }, [global.userInfo]); 

  useEffect(() => {
    if(!global.dashboardProjects || (global.dashboardProjects && global.dashboardProjects.length < 1)){
      getDashboardsDB();
      getDashboardsDBTemplate(true);
      getDashboardsDBWorkflow();
    } else {
      setuserdash(global.dashboardProjects);
      getDashboardsDBTemplate(true);
      getDashboardsDBWorkflow();
    }
  }, [useremail]); 

  useEffect(() => {
    global.setUserLogged(true);
  }, [selectedHUC8Value]);

  useEffect(() => {
    if(qsdr){   
      setTimeout(() => {
        setqsdr(false);
      }, 1111)
      let searchParse = queryString.parse(window.location.search);
       
      if(qsdt){
        console.log(window.location.search)
          let searchParse = queryString.parse(window.location.search);
          if(searchParse["city"] && searchParse["huc"]){
            getTemplate(qsdt, searchParse["city"], searchParse["huc"]);
          }         
      }
    }
  }, [qsdr]);

  useEffect(() => {
    if(appContext.selectedHUC8 && currentDashboard){
     let currdash = dashboards.find((o: any) => +o.dashboard_id === +currentDashboard);
     if(currdash && currdash.title){
       let searchParse = queryString.parse(window.location.search);
       setChangeMode(`Change all links for this dashboard '${currdash.title}', to the current \r\nlocation: ${searchParse["city"]},    \r\nHUC8:${searchParse["huc"]}`);      
     }
    }
  }, [appContext.selectedHUC8]);

  const dashboardFromTemplate = (gData:any, gLData:any, templateid: string, city?: any, huc?: any) => {
    const ue_ls = localStorage.getItem('useremail');
    let currentemail = useremail || ue_ls

    if(!currentemail||currentemail.length<5){
        return;
    }
                
    let newCity = city;
    let newHUC = huc; 

    if(!city || !huc){
        let searchParse = queryString.parse(window.location.search);
        if(searchParse["city"] && searchParse["huc"]){
           newCity = searchParse["city"] || 'Upper Colorado Region';
           newHUC = searchParse["huc"] || '14010001'; 
        }      
    }


    if(newCity && newHUC){
      let tData = [...tableData];
      let tdData = tData.filter((o: any) => +o.parent === +currentDashboard);
      for (let dataItem of gLData) {
        let thisLink = JSON.parse(dataItem.report_data);
       
        dataItem.pathname = thisLink.pathname;
        if(thisLink.pathname){
          thisLink.reportname = thisLink.pathname.slice(1);
        } else {
          thisLink.reportname = thisLink.pathname;                    
        }              
        let linkParse = queryString.parse(dataItem.search);    
        linkParse["city"] = newCity;
        linkParse["huc"] = newHUC;
        dataItem.currentCity = newCity;
        dataItem.currentLocation = newCity;
        dataItem.huc = newHUC; 

        let searchParse = queryString.parse(thisLink.search);
        searchParse["city"] = newCity;
        searchParse["huc"] = newHUC;
        thisLink["currentCity"] = newCity;
        thisLink["currentLocation"] = newCity;
        thisLink.huc = newHUC; 


        const stringified = queryString.stringify(searchParse); //'?'+
        dataItem.search = '?'+stringified;
        dataItem.url = `${thisLink.pathname}?${stringified}`;
        dataItem._url = `${thisLink.pathname}?${stringified}`;
     
        let reportLink_ = {parent: dataItem.parent, timestamp : dataItem.timestamp, currentCity: newCity,
           huc: newHUC, pathname: dataItem.pathname,
            search: stringified, hash: dataItem.hash, sortkey: dataItem.sortkey, workflow: dataItem.workflow};
        let payload = {REPORT_ID: dataItem.report_id, REPORT_DATA: JSON.stringify(reportLink_), SUBTITLE:""};
        dataItem.report_data = JSON.stringify(reportLink_);

      }
      
      copytoGroup('now', currentemail, templateid, [gData], [...gLData]);
    }
 }


 const getTemplate = (templateid: string, city?: any, huc?: any) => {
   console.log(city, huc)

    if(grouptemp && grouptemplinks && grouptemp.length>0){
        let gData = [...grouptemp].find((o: any) => +o.dashboard_id === +templateid);
        let gLData = [...grouptemplinks].filter((o: any) => +o.parent === +templateid);
        if(gData && gLData){
          
          dashboardFromTemplate(gData, gLData, templateid, city, huc)
        }
    } else {
     let templateEmail = 'groupglobal@te.com';
      agent.Utilities.GetDashboard(templateEmail).then((res: any) => {
     
        if(res && res.body){
          let returnData = [...res.body]
          let gData = [...returnData].find((o: any) => +o.dashboard_id === +templateid);
          if(gData){

            agent.Utilities.GetReport(templateid).then((res: any) => {     
             
              if(res && res.body && res.body.length > 0){
                const returnedData = [...res.body];
                const rLinks = [] as any;
                dashboardFromTemplate(gData, returnedData, templateid, city, huc)
           
              }
            });
                   
          } else {
            console.log('template not found')
            global.notify('Template not found');
          }
        
        }
      })      
    }
  };



  useEffect(() => {
    if(group===1){ 
      const rData = [...userdash];
      const cDashboard = [...rData].filter((o: any) => o.title === '+');
      if(cDashboard.length < 1){
        rData.push({ title: '+', description: 'add new Dashboard Project', timestamp: '1660079848127' });
      }
      
      setDashboards(rData);
      //getLinksDB('group');
    }
    if(group===2){
      if(usertemp.length>0){
        setDashboards(usertemp);
        if(!currentTemplate && usertemp.length>0){
          setCurrentTemplate(usertemp[0].dashboard_id);
        }
      } else {
        setDashboards([]);
      }
      
      if(usertemplinks.length>0){
        setSavedData(usertemplinks);
        if(currentTemplate){
          let tData = [...usertemplinks].filter((o: any) => +o.parent === +currentTemplate);
          setTableData(tData);
        } else {
          setTableData([]);
        }
      } else {
        setSavedData([]);
        setTableData([]);
      }

    }
    if(group===3){
      if(userwork.length>0){
        setDashboards(userwork);
        if(!currentWorkflow && userwork.length>0){
          setCurrentTemplate(userwork[0].dashboard_id);
        }
      } else {
        setDashboards([]);
      }
      
      if(userworklinks.length>0){
        setSavedData(userworklinks);
        if(currentWorkflow){
          let tData = [...userworklinks].filter((o: any) => +o.parent === +currentWorkflow);
          setTableData(tData);
        } else {
          setTableData([]);
        }
      } else {
        setSavedData([]);
        setTableData([]);
      }
    }

   
    global.setSelectedDashboardProject('');
    setCurrentDashboard('');
  }, [group]);
  
  useEffect(() => {
    if(!global.dashboardProjects || (global.dashboardProjects && global.dashboardProjects.length < 1)) {
      getDashboardsDB();
      getDashboardsDBTemplate(true);
      getDashboardsDBWorkflow();
    } else {
      setSavedDashboards(global.dashboardProjects);
      //setCurrentDashboard(global.dashboardProjects[0]);

      const rData = [...global.dashboardProjects];
      const cDashboard = [...rData].filter((o: any) => o.title === '+');
      if(cDashboard.length < 1){
        rData.push({ title: '+', description: 'add new Dashboard Project', timestamp: '1660079848127' });
      }
  
      if(group===3){
        getDashboardsDBWorkflow();
      }
      if(group===2){
        getDashboardsDBTemplate(true);
      } else {
        setDashboards(rData); 
        setuserdash(rData);

        if(group===1 && !currentDashboard){
            if(global.selectedDashboardProject){
              const cDashboard = [...rData].filter((o: any) => +o.dashboard_id === +global.selectedDashboardProject);
              if(cDashboard.length > 0){
                global.setSelectedDashboardProject(cDashboard[0].dashboard_id);
                setCurrentDashboard(cDashboard[0].dashboard_id);
              } else {
                global.setSelectedDashboardProject(rData[0].dashboard_id);
                setCurrentDashboard(rData[0].dashboard_id); 
              }
            } else {
              global.setSelectedDashboardProject(rData[0].dashboard_id);
              setCurrentDashboard(rData[0].dashboard_id); 
            }
          }
      }
    }
    const dg = localStorage.getItem('displaygrid');
    if(dg==='0'){
      setIsDisplayGrid(false);
    }
  }, []);

  useEffect(() => {
    getLinksDB('dashboards');
  }, [dashboards]);

  useEffect(() => {
    if (currentDashboard) {
      updateTableData(savedData);
    } else if (!currentDashboard && global.selectedDashboardProject){
      setCurrentDashboard(""+global.selectedDashboardProject);
    } else {
     
      setTableData([]);
    }
    setqsdash(currentDashboard)
  }, [currentDashboard]);


  useEffect(() => {
    const wfi = localStorage.getItem('workflowids');
    if(wfi&&JSON.parse(wfi)){
      const wfids = JSON.parse(wfi);
      setworkflowids(wfids);
    }
  }, []);

  useEffect(() => {
    if(workflowids && workflowids.length>0){
      localStorage.setItem('workflowids', JSON.stringify(workflowids));
    }
  }, [workflowids]);

  useEffect(() => {  
    if (navigateLink) {
      navigateReportLink(navigateLink);
      setNavigateLink("");
    }
  }, [navigateLink]);

  useEffect(() => {
    if (exportLink) {
      exportReportLink(exportLink, savedData);
      setExportLink("");
    }
  }, [exportLink]);

  useEffect(() => {
    if (removeLink) {
      removeReportLink(removeLink);
      setRemoveLink("");
    }
  }, [removeLink]);

  useEffect(() => {
    if (removeDashboard) {
      removeDashboardLink(removeDashboard);
      setRemoveDashboard("");
    }
  }, [removeDashboard]);


  const getDashboardsDB = () => {
  
    const ue_ls = localStorage.getItem('useremail');
    let currentemail = useremail || ue_ls

    if(!currentemail||currentemail.length<5){
        return;
    }
    agent.Utilities.GetDashboard(currentemail).then((res: any) => {
     
      if(res && res.body){
        let returnData = [...res.body]
         
        if(returnData.length < 1){
          const firstProject = { TITLE: 'Project', DESCRIPTION: 'new Project', EMAIL: currentemail };
          agent.Utilities.CreateDashboard(firstProject).then((res: any) => {
            if(res && res.body){
              const newDashboardId = res.body.dashboard_id;
              returnData.push({ title: 'Project', description: 'new Project', email: currentemail, dashboard_id: newDashboardId, timestamp: newDashboardId })
              setSavedDashboards(returnData);
              global.setDashboardProjects(returnData);
              global.setSelectedDashboardProject(newDashboardId);
              //global.setSelectedDashboardProject(returnData[0]);
              setCurrentDashboard(returnData[0]);
         
              const rData = [...returnData];
              rData.push({ title: '+', description: 'add new Dashboard Project', timestamp: '1660079848127' });
              if(group===1){
                setDashboards(rData);
              }             
              setuserdash(returnData);
            }
          }) 
        } else {
          for (let dataItem of returnData) {
            dataItem.timestamp = dataItem.dashboard_id;
          }    

          returnData.sort(
            (x: any, y: any) =>
              x['timestamp'] - y['timestamp']
          );

          setSavedDashboards(returnData);
          global.setDashboardProjects(returnData);
     
          if(group===1 && !currentDashboard){

            if(global.selectedDashboardProject)
            {
              const cDashboard = [...returnData].filter((o: any) => +o.dashboard_id === +global.selectedDashboardProject);
              if(cDashboard.length > 0){
                global.setSelectedDashboardProject(cDashboard[0].dashboard_id);
                setCurrentDashboard(qsdash || cDashboard[0].dashboard_id);
              } else {
                global.setSelectedDashboardProject(returnData[0].dashboard_id);
                setCurrentDashboard(qsdash || returnData[0].dashboard_id); 
              }
            } else {
              global.setSelectedDashboardProject(returnData[0].dashboard_id);
              setCurrentDashboard(qsdash || returnData[0].dashboard_id); 
            }
          }

          const rData = [...returnData];
          rData.push({ title: '+', description: 'add new Dashboard Project', timestamp: '1660079848127' });
          
          if(group===1){
            setDashboards(rData);
          }    
          setuserdash(returnData);
          //currentDash = returnData[0];     
        }
      }
    }) 
  };

  
  const getDashboardsDBTemplate = (globaltemp: boolean) => {
  
    if(!useremail){
      return;
    }
    let ue = 'template_' + useremail;
    if(globaltemp){
      ue = groupemail;
    }
  
    agent.Utilities.GetDashboard(ue).then((res: any) => {
      
      if(res && res.body){
        let returnData = [...res.body]   
        if(returnData.length > 0){
          for (let dataItem of returnData) {
            dataItem.timestamp = dataItem.dashboard_id;
          }    
          returnData.sort(
            (x: any, y: any) =>
              x['timestamp'] - y['timestamp']
          );

          //if(globaltemp){
            setgrouptemp(returnData);
          //} else {
            setusertemp(returnData);
          //}

          for (let dataItem of returnData) {
            getDashboardsLinksDBTemplate(dataItem, returnData, false); 
          }  
        }
      }
    }) 
  };

  const getDashboardsLinksDBTemplate = (dashboard: any, dashboards: any, globaltemp: boolean) => {
    let dashpr = [...dashboards];

    if(dashpr){
      for (var i = 0; i < dashpr.length; i++) {      
        if(dashpr[i]){
          const dash = dashpr[i];
       
          if(dash.dashboard_id){  
            setTimeout(() => {
            agent.Utilities.GetReport(dash.dashboard_id).then((res: any) => {      
              if(res && res.body && res.body.length > 0){
                const returnedData = res.body;
               
                  const rLinks = [] as any;
                  for (let dataItem of returnedData) {
                    if(dataItem.report_data && isJson(dataItem.report_data)){
                      const thisLink = JSON.parse(dataItem.report_data);
                      thisLink.report_id = dataItem.report_id;
                      thisLink.dashboard_id = dataItem.dashboard_id;    
                      thisLink.url = thisLink.pathname + thisLink.search
                      if(thisLink.pathname){
                        thisLink.reportname = thisLink.pathname.slice(1);
                      } else {
                        thisLink.reportname = thisLink.pathname;                    
                      }
                      thisLink.formattedname = getReportLabelExp(thisLink.reportname, thisLink.search);
                      thisLink._url = thisLink.pathname + thisLink.search
                      thisLink._timestamp = thisLink.timestamp;
                      thisLink.__timestamp = thisLink.timestamp;  
                      thisLink.___timestamp = thisLink.timestamp;
                      thisLink.____timestamp = thisLink.timestamp;  
                      if(!thisLink.currentCity && thisLink.currentLocation){
                        thisLink.currentCity = thisLink.currentLocation.replace(", United States", "");//.split(", United States").pop();
                      }                        
                      rLinks.push(thisLink)
                    }
                  }

                  rLinks.sort(
                    (x: any, y: any) =>
                      x['sortkey'] - y['sortkey'] ||
                      x['timestamp'] - y['timestamp']
                  );
      
                  for (let index = 0; index < rLinks.length; index++) {
                    if (!rLinks[index].parent){
                        rLinks[index].parent = rLinks[index].dashboard_id;
                    }
                  }

                  for (let index = 0; index < rLinks.length; index++) {
                    rLinks[index].sortkey = index + 1;
                  }
                  rLinks.sort(
                    (x: any, y: any) =>
                      x['sortkey'] - y['sortkey'] ||
                      x['timestamp'] - y['timestamp']
                  ); 

                  let returnedLinks = [...rslinks].filter((o: any) => +o.dashboard_id !== +dash.dashboard_id);
                  returnedLinks.push(...rLinks); 
                  rslinks = returnedLinks; 
                  setusertemplinks(rslinks);     
                  setgrouptemplinks(rslinks);       
              }
            }) 
          }, 100*i);
          }
        }
      }
    }
  };

  const getDashboardsDBWorkflow = () => {
/*     if(!useremail){
      return;
    }
    let ue = 'workflow_' + useremail;

    agent.Utilities.GetDashboard(ue).then((res: any) => {
  
      if(res && res.body){
        let returnData = [...res.body]   
        if(returnData.length > 0){
          for (let dataItem of returnData) {
            dataItem.timestamp = dataItem.dashboard_id;
          }    
          returnData.sort(
            (x: any, y: any) =>
              x['timestamp'] - y['timestamp']
          );
          setuserwork(returnData);
          
          for (let dataItem of returnData) {
            getDashboardsLinksDBWorkflow(dataItem, returnData); 
          }  
        }
      }
    })  */
  };

  
  const getDashboardsLinksDBWorkflow = (dashboard: any, _dashboards: any) => {
    /* let dashpr = [..._dashboards];

    if(dashpr){
      for (var i = 0; i < dashpr.length; i++) {      
        if(dashpr[i]){
          const dash = dashpr[i];
  
          if(dash.dashboard_id){
            setTimeout(() => {
            agent.Utilities.GetReport(dash.dashboard_id).then((res: any) => {      
              if(res && res.body && res.body.length > 0){
                const returnedData = res.body;
             
                  const rLinks = [] as any;
                  for (let dataItem of returnedData) {
                    if(dataItem.report_data && isJson(dataItem.report_data)){
                      const thisLink = JSON.parse(dataItem.report_data);
                      thisLink.report_id = dataItem.report_id;
                      thisLink.dashboard_id = dataItem.dashboard_id;    
                      thisLink.url = thisLink.pathname + thisLink.search
                      if(thisLink.pathname){
                        thisLink.reportname = thisLink.pathname.slice(1);
                      } else {
                        thisLink.reportname = thisLink.pathname;                    
                      }
                      thisLink._url = thisLink.pathname + thisLink.search
                      thisLink._timestamp = thisLink.timestamp;
                      thisLink.__timestamp = thisLink.timestamp;
                      thisLink.___timestamp = thisLink.timestamp;
                      thisLink.____timestamp = thisLink.timestamp;                             
                      rLinks.push(thisLink)
                    }
                  }

                  rLinks.sort(
                    (x: any, y: any) =>
                      x['sortkey'] - y['sortkey'] ||
                      x['timestamp'] - y['timestamp']
                  );
      
                  for (let index = 0; index < rLinks.length; index++) {
                    if (!rLinks[index].parent){
                        rLinks[index].parent = rLinks[index].dashboard_id;
                    }
                  }

                  for (let index = 0; index < rLinks.length; index++) {
                    rLinks[index].sortkey = index + 1;
                  }
                  rLinks.sort(
                    (x: any, y: any) =>
                      x['sortkey'] - y['sortkey'] ||
                      x['timestamp'] - y['timestamp']
                  ); 

                  let returnedLinks = [...rslinks].filter((o: any) => +o.dashboard_id !== +dash.dashboard_id);
                  returnedLinks.push(...rLinks); 
                  rslinks = returnedLinks; 
                  setuserworklinks(rslinks);          
              }
            }) 
          }, 100*i);
          }
        }
      }
    } */
  };


  const editDashboard = (changedField: any, changedValue: any) => {
   let cDashboard = savedDashboards.filter((o: any) => +o.timestamp === +currentDashboard);
   if(group === 2){
    cDashboard = grouptemp.filter((o: any) => +o.timestamp === +currentDashboard);
   }
   
    if (cDashboard) {
      if (changedField === 'formdescription') {
        cDashboard[0].description = changedValue;
      } else if (changedField === 'formtitle')  {
        cDashboard[0].title = changedValue;
      } else {
        return;
      }
      
      if(group === 2){
        //setgrouptemp(grouptemp);
       } else {
        setSavedDashboards(savedDashboards);
        global.setDashboardProjects(savedDashboards);
        //setDashboardParent(savedDashboards);
       }

      setTabActiveKey(currentDashboard);

      const payload = {DASHBOARD_ID: cDashboard[0].dashboard_id, REPORT_ID: cDashboard[0].dashboard_id, DESCRIPTION: cDashboard[0].description, TITLE: cDashboard[0].title}
      agent.Utilities.UpdateDashboard(payload).then((res: any) => {
        console.log("UpdateDashboard", payload, res);   
      }) 
    }
  }

  const removeDashboardLink = (timestamp: any) => {
    if (timestamp) {
      let dData = dashboards.filter((o: any) => +o.timestamp !== +timestamp);
      
      if(group === 2){
        dData = grouptemp.filter((o: any) => +o.timestamp !== +timestamp);
        if(dData.length > 1){
          //global.setSelectedDashboardProject(fData[0].timestamp);
          setCurrentDashboard(dData[0].timestamp);
        } else {
          global.setSelectedDashboardProject('');
          setCurrentDashboard('');
        } 
      } else {
        let fData = savedDashboards.filter((o: any) => +o.timestamp !== +timestamp);
        setSavedDashboards(fData);
        global.setDashboardProjects(fData);
        if(fData.length > 1){
          global.setSelectedDashboardProject(fData[0].timestamp);
          setCurrentDashboard(fData[0].timestamp);
        } else {
          global.setSelectedDashboardProject('');
          setCurrentDashboard('');
        } 
      }
      setDashboards(dData);   

      agent.Utilities.DeleteDashboard(timestamp).then((res: any) => {
        console.log("DeleteDashboard", timestamp, res);
        if(group === 2){
          getDashboardsDBTemplate(true);
        } else {
          getDashboardsDB();
        }  
      })  

      const returnedLinks = [...savedData].filter((o: any) => +o.dashboard_id === +timestamp);
      for (let dataItem of returnedLinks) {          
        removeReportLink(dataItem.timestamp);
      }
    }
  };

  const isJson = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  const getLinksDB = (source: string) => {
    if(dashboards){
      for (var i = 0; i < dashboards.length; i++) {      
        if(dashboards[i]){
          const dash = dashboards[i];
          if(dash.dashboard_id){
            setTimeout(() => {
            agent.Utilities.GetReport(dash.dashboard_id).then((res: any) => {      
              if(res && res.body && res.body.length > 0){
                const returnedData = res.body;

                  const rLinks = [] as any;
                  for (let dataItem of returnedData) {
                    if(dataItem.report_data && isJson(dataItem.report_data)){
                      const thisLink = JSON.parse(dataItem.report_data);
                      thisLink.report_id = dataItem.report_id;
                      thisLink.dashboard_id = dataItem.dashboard_id;    
                      thisLink.url = thisLink.pathname + thisLink.search
                      if(thisLink.pathname){
                        thisLink.reportname = thisLink.pathname.slice(1);
                      } else {
                        thisLink.reportname = thisLink.pathname;                    
                      }
                      thisLink.formattedname = getReportLabelExp(thisLink.reportname, thisLink.search);
                      thisLink._url = thisLink.pathname + thisLink.search
                      thisLink._timestamp = thisLink.timestamp;  
                      thisLink.__timestamp = thisLink.timestamp;
                      thisLink.___timestamp = thisLink.timestamp;
                      thisLink.____timestamp = thisLink.timestamp;      
                      if(!thisLink.currentCity && thisLink.currentLocation){
                        thisLink.currentCity = thisLink.currentLocation.replace(", United States", "");//.split(", United States").pop();
                      }                       
                      rLinks.push(thisLink)
                    }
                  }

                  rLinks.sort(
                    (x: any, y: any) =>
                      x['sortkey'] - y['sortkey'] ||
                      x['report_id'] - y['report_id']
                  );
      
                  for (let index = 0; index < rLinks.length; index++) {
                    if (!rLinks[index].parent){
                        rLinks[index].parent = rLinks[index].dashboard_id;
                    }
                  }
           
                  for (let index = 0; index < rLinks.length; index++) {
                    //if(!rLinks[index].sortkey){              
                      rLinks[index].sortkey = index + 1;
                    //}
                  }
                  rLinks.sort(
                    (x: any, y: any) =>
                      x['sortkey'] - y['sortkey'] ||
                      x['report_id'] - y['report_id']
                  ); 

                  if(+currentDashboard === +dash.dashboard_id){
                    setTableData(rLinks);
                  } 
                  let returnedLinks = [...rslinks].filter((o: any) => +o.dashboard_id !== +dash.dashboard_id);
                  returnedLinks.push(...rLinks); 
                  rslinks = returnedLinks; 
                  setSavedData(rslinks);
                  setuserdashlinks(rslinks);
                  //setAddReportLinks(rLinks);
              }
            }) 
          }, 100*i);
          }
        }
      }
    }
  };

  const removeReportLink = (timestamp: any) => {
    if (timestamp) {
      let fData = savedData.filter((o: any) => +o.timestamp !== +timestamp);
      let tData = [...fData].filter((o: any) => +o.parent === +currentDashboard);
      //setDashboard(fData);
      setSavedData(fData);
      updateTableData(tData); 

      let dData = savedData.filter((o: any) => +o.timestamp === +timestamp);
      if(dData && dData.length > 0){
        const report_id = dData[0].report_id;
        if(report_id){
          agent.Utilities.DeleteReport(report_id).then((res: any) => {
            console.log("DeleteReport", report_id, res);   
          })
          localForage.removeItem(''+timestamp, function (err:any) {
            console.log("DeleteReport_removeItem", report_id, err); 
          });
        }
      }
    }
  };

  
  const getLink = (dashboard_id: any) => {
  
    if(dashboards){
      let dData = dashboards.filter((o: any) => +o.dashboard_id === +dashboard_id);

        if(dData && dData.length>0){
          const dash = dData[0];
          if(dash.dashboard_id){
            setTimeout(() => {
              agent.Utilities.GetReport(dash.dashboard_id).then((res: any) => {      
                if(res && res.body && res.body.length > 0){
                  const returnedData = res.body;

                    const rLinks = [] as any;
                    for (let dataItem of returnedData) {
                      if(dataItem.report_data && isJson(dataItem.report_data)){
                        const thisLink = JSON.parse(dataItem.report_data);
                        thisLink.report_id = dataItem.report_id;
                        thisLink.dashboard_id = dataItem.dashboard_id;    
                        thisLink.url = thisLink.pathname + thisLink.search
                        if(thisLink.pathname){
                          thisLink.reportname = thisLink.pathname.slice(1);
                        } else {
                          thisLink.reportname = thisLink.pathname;                    
                        }
                        thisLink._url = thisLink.pathname + thisLink.search
                        thisLink._timestamp = thisLink.timestamp;
                        thisLink.__timestamp = thisLink.timestamp;
                        thisLink.___timestamp = thisLink.timestamp;
                        thisLink.____timestamp = thisLink.timestamp;                             
                        rLinks.push(thisLink)
                      }  
                    }

                    rLinks.sort(
                      (x: any, y: any) =>
                        x['sortkey'] - y['sortkey'] ||
                        x['report_id'] - y['report_id']
                    );
        
                    for (let index = 0; index < rLinks.length; index++) {
                      if (!rLinks[index].parent){
                          rLinks[index].parent = rLinks[index].dashboard_id;
                      }
                    }
            
                    for (let index = 0; index < rLinks.length; index++) {
                      //if(!rLinks[index].sortkey){              
                        rLinks[index].sortkey = index + 1;
                      //}
                    }
                    rLinks.sort(
                      (x: any, y: any) =>
                        x['sortkey'] - y['sortkey'] ||
                        x['report_id'] - y['report_id']
                    ); 
  
                    let sLinks = [...savedData].filter((o: any) => +o.dashboard_id === +dash.dashboard_id);
                    if(rLinks.length !== sLinks.length && rLinks.length > 0){

                      setTableData(rLinks);
                      let returnedLinks = [...rslinks].filter((o: any) => +o.dashboard_id !== +dash.dashboard_id);
                      returnedLinks.push(...rLinks); 
                      rslinks = returnedLinks; 
  
                      if(group === 2){
                        setusertemplinks(rslinks);     
                        setgrouptemplinks(rslinks);  
                      } else {
                        setuserdashlinks(rslinks)                    
                      }
                      setSavedData(rslinks); 
                    }                     
                }
              }) 
            }, 1);
          }
        }

    }
  };

  const refresh = (event: any) => {
    getLinksDB('refresh');
  };

  const updateTableData = (sData: any) => {   

    if(group === 2){
      let tData = [...grouptemplinks].filter((o: any) => +o.parent === +currentDashboard);
      setTableData(tData);
    } else {
      let tData = [...sData].filter((o: any) => +o.parent === +currentDashboard);
      setTableData(tData); 
    }
    getLink(currentDashboard);
    //saveSavedData(sData);
  };

  const saveSavedData = (sData: any) => {
    //setDashboard(sData);
    //setDashboardParent(savedDashboards);
  };

  const setAsTemplate = (event: any) => { 
      const globaltemp = true;
      if(!useremail){
        return;
      }
      let ue = 'template_' + useremail;
      if(globaltemp){
        ue = groupemail;
      }

    const dashbid = +global.selectedDashboardProject;
    let fData = [...savedDashboards].filter((o: any) => +o.dashboard_id === +dashbid);
    let rData = [...tableData].filter((o: any) => +o.dashboard_id === +dashbid);
    if(fData && fData.length>0 && rData){
      const templateProject = { TITLE: fData[0].title, DESCRIPTION: fData[0].description, EMAIL: ue };

        agent.Utilities.CreateDashboard(templateProject).then((res: any) => {
          if(res && res.body){
            const newDashboardId = res.body.dashboard_id;
            getDashboardsDB();
            getDashboardsDBTemplate(true);
            if(rData && rData.length>0){
              for (let dataItem of rData) {
                dataItem.workflow = false;
                setTimeout(() => {
                  addLink(dataItem, newDashboardId)
                }, 11)
              }
              global.notify('Template saved: ' + fData[0].title)
              setTimeout(() => {
                getDashboardsDBTemplate(true);
              }, 1111)
            }        
          }
        }) 
      }

      function addLink(link:any, dashid: any){
        const timestamp = DateTime.local().toMillis();
        const oldTimestamp = link.timestamp;
 
        let reportLink = link;
        reportLink.parent = dashid;
        reportLink.dashboard_id = dashid;
        reportLink.timestamp = timestamp;
        reportLink._timestamp = timestamp;
        reportLink.__timestamp = timestamp;
        reportLink.___timestamp = timestamp;
        reportLink.____timestamp = timestamp;
        let payload = {DASHBOARD_ID: dashid, REPORT_DATA: JSON.stringify(reportLink), SUBTITLE: ""}; 
    
        agent.Utilities.CreateReport(payload).then((res: any) => {
          console.log("SetReport", payload, res);   
          localForage.getItem(''+oldTimestamp, function(err, value) {
            if(value){
              localForage.setItem(''+timestamp, value);
            }
          });
        })   
      }
  }

  const setAsProject = (event: any) => { 
    if(!useremail){
      return;
    }
    let ue = useremail;
    const dashbid = +global.selectedDashboardProject;
    let fData = [...grouptemp].filter((o: any) => +o.dashboard_id === +dashbid);
    let rData = [...tableData].filter((o: any) => +o.dashboard_id === +dashbid);
    copytoGroup(event, ue, dashbid, fData, rData)
  }

  const setAsWorkflow = (event: any) => { 
    if(!useremail){
      return;
    }
    let ue = 'workflow_' + useremail;
    const dashbid = +global.selectedDashboardProject;
    let fData = [...grouptemp].filter((o: any) => +o.dashboard_id === +dashbid);
    let rData = [...tableData].filter((o: any) => +o.dashboard_id === +dashbid);
    copytoGroup(event, ue, dashbid, fData, rData)
  }

  const copytoGroup = (event: any, ue:string, dashbid:any, fData:any, rData:any) => { 
    console.log(fData, rData)
    if(fData && fData.length>0 && rData){
      const templateProject = { TITLE: fData[0].title, DESCRIPTION: fData[0].description, EMAIL: ue };
        agent.Utilities.CreateDashboard(templateProject).then((res: any) => {
        
          if(res && res.body){
            const newDashboardId = res.body.dashboard_id;
            //const returnData = res.body;
    
            //setusertemp(returnData);
            getDashboardsDB();
            //getDashboardsDBTemplate(true);
            if(rData && rData.length>0){
              for (let dataItem of rData) {
                dataItem.workflow = false;
                setTimeout(() => {
                  addLink(dataItem, newDashboardId)
                }, 11)
              }
              global.notify('Template copied to Projects: ' + fData[0].title)
              if(event==='now'){
                  setCurrentDashboard(newDashboardId)
                  setqsdash(newDashboardId)

                  setTimeout(() => {
                    setCurrentDashboard(newDashboardId)
                    setqsdash(newDashboardId)
                  }, 1111)
              }
              setTimeout(() => {
                if(group===2){
                  getDashboardsDBTemplate(true);
                } else {
                  getLinksDB("copytoGroup")
                }               
              }, 1111)
            }  
          }
        })  
      }

      function addLink(link:any, dashid: any){
        const timestamp = DateTime.local().toMillis();
        const oldTimestamp = link.timestamp;

        let reportLink = link;
        reportLink.parent = dashid;
        reportLink.dashboard_id = dashid;
        reportLink.timestamp = timestamp;
        reportLink._timestamp = timestamp;
        reportLink.__timestamp = timestamp;
        reportLink.___timestamp = timestamp;
        reportLink.____timestamp = timestamp;
        let payload = {DASHBOARD_ID: dashid, REPORT_DATA: JSON.stringify(reportLink), SUBTITLE: ""}; 
    
        agent.Utilities.CreateReport(payload).then((res: any) => {
          console.log("SetReport", payload, res);   
          localForage.getItem(''+oldTimestamp, function(err, value) {
            if(value){
              localForage.setItem(''+timestamp, value);
            }
          });
        })   
      }
}

  const clickTemplateSaveHandler = (event: any) => { 
    if(group === 1){
      setAsTemplate(event);
    }
    if(group === 2){
      setAsProject(event);
    }
  }

  const clickSaveDashboardHandler = (event: any) => { 
    setChangedValue(event.target.id);
  }

  const clickChangeHandler = (event: any) => { 
    if (event && event.target) {
      let currdash = dashboards.find((o: any) => +o.dashboard_id === +currentDashboard);
      if(currdash && currdash.title){
        setShowChangeModal(true);
        let searchParse = queryString.parse(window.location.search);
        setChangeMode(`Change all links for this dashboard '${currdash.title}', to the current \r\nlocation: ${searchParse["city"]},    \r\nHUC8:${searchParse["huc"]}`);          
      }
    }      
  };

  const handleChangeConfirm = (event:any) => {   
    let searchParse = queryString.parse(window.location.search);
    if(searchParse["city"] && searchParse["huc"]){
      let newCity = searchParse["city"];
      let newHUC = searchParse["huc"]; 
      let tData = [...tableData];
      let tdData = tData.filter((o: any) => +o.parent === +currentDashboard);
      for (let dataItem of tdData) {;
        let linkParse = queryString.parse(dataItem.search);    
        linkParse["city"] = newCity;
        linkParse["huc"] = newHUC;
        dataItem.currentCity = newCity;
        dataItem.huc = newHUC; 
        const stringified = '?'+queryString.stringify(linkParse); 
        dataItem.search = stringified;
        dataItem.url = `${dataItem.pathname}?${stringified}`;
        dataItem._url = `${dataItem.pathname}?${stringified}`;
      
        let reportLink_ = {parent: dataItem.parent, timestamp : dataItem.timestamp, currentCity: newCity,
           huc: newHUC, pathname: dataItem.pathname,
            search: stringified, hash: dataItem.hash, sortkey: dataItem.sortkey, workflow: dataItem.workflow};
        let payload = {REPORT_ID: dataItem.report_id, REPORT_DATA: JSON.stringify(reportLink_), SUBTITLE:""};
   
        agent.Utilities.UpdateReport(payload).then((res: any) => {
          console.log("SetReport", payload, res);   
        })  
      }
      setuserdashlinks(tData);
      setTableData(tData);
      setSavedData(tData);
    }
    setShowChangeModal(false);
  };

  const handleChangeCancel = () => {
    setShowChangeModal(false);
  };

  const clickWorkflowHandler = (event: any) => { 
    if(group===2){
      setAsWorkflow(event);
    } else if(group===3){
    
    } else {

      global.setSelectedDashboardProjectLinks(tableData);
   
      let navigateURL = '/WorkStart';      
      let fData = savedDashboards.filter((o: any) => +o.dashboard_id === +global.selectedDashboardProject);
      if(!fData || fData.length < 1){
        console.log('Error', savedDashboards, fData, global.selectedDashboardProject);
        return;
      }
      fData[0].workflow = true;
      if(fData[0].currentStep){
        let rData = [...tableData].filter((o: any) => +o.timestamp === +fData[0].currentStep);
        if(rData && rData.length>0 && rData[0].url){
          if (appContext && appContext.updateContext) {         
            const reportConfig = ReportsConfigs.find(x => x.route == rData[0].pathname);
            let _routeData = {
              name: "Global Live Water Map",
              parentReportName: "Home",
              root: '/'
            } 
            if(reportConfig && reportConfig.parentReportName){
              _routeData = {
                name: reportConfig.name,
                parentReportName: reportConfig.parentReportName,
                root: reportConfig.route
              }
            }
        
            setLocalReport(rData[0].pathname);
            appContext.updateContext(rData[0].huc, rData[0].currentCity, _routeData, getCountryFromStorage());
            global.setSelectedHUC8(rData[0].huc)
          } 
        
          navigateURL = rData[0].url;
        } else if (fData[0].currentStep === '1111'){
          navigateURL = '/WorkStart'; 
        }
      } else {
        if(tableData && tableData.length>0 && tableData[0].url){
          fData[0].currentStep = tableData[0].timestamp;
          navigateURL = tableData[0].url;
        }
      }

      let wData = workflowids.filter((o: any) => +o.dashboard_id === +global.selectedDashboardProject);
      if(wData && wData.length<1){
        wData.push(+global.selectedDashboardProject);
        setworkflowids(workflowids);
      }

  
      setSavedDashboards(savedDashboards); 
      global.setDashboardProjects(savedDashboards);
  
      global.setSelectedDashboardProject(event.target.id);
      global.setShowWorkflow(true);
      navigate(navigateURL);
    }
      
  };

  const clickDeleteDashboardHandler = (event: any) => { 
    if (event && event.target) {
      setCurrentLink(event.target.id);
      setShowDeleteModal(true);
      setDeleteMode('dashboard');
    }
  };

  const clickDeleteHandler = (event: any) => {
    if (event && event.target) {
      setCurrentLink(event.target.id);
      setShowDeleteModal(true);
      setDeleteMode('report');
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteConfirm = (event:any) => {   
    if(deleteMode === 'dashboard'){
      setRemoveDashboard(currentLink);
      setDeleteMode('');
    } else {
      setRemoveLink(currentLink);
    }
    setShowDeleteModal(false);
    setCurrentLink("")
  };

  function NavigateItem(url: any) {
    mapLayersOff(global);

    setTimeout(() => {
      navigate(url);
    }, 555);
  }

  const clickLinkHandler = (event: any) => {
    if (event && event.target) {
      setNavigateLink(event.target.id);
    }
  };

  const navigateReportLink = (timestamp: any) => {

    if (timestamp) {
      let tData = [...tableData].filter((o: any) => +o.timestamp === +timestamp);
      if (tData.length > 0) {
        const reportConfig = ReportsConfigs.find(x => x.route == tData[0].pathname);
         let _routeData = {
          name: "Global Live Water Map",
          parentReportName: "Home",
          root: '/'
        } 
        if(reportConfig && reportConfig.parentReportName){
          _routeData = {
            name: reportConfig.name,
            parentReportName: reportConfig.parentReportName,
            root: reportConfig.route
          }
        }
        
        if (appContext && appContext.updateContext) {         
          appContext.updateContext(tData[0].huc, tData[0].currentCity, routeData, global.currentCountry);
        } 

        let nURL = tData[0]._url;
        if(nURL.indexOf('&r=0') > -1){
          nURL = nURL.replace('&r=0', '&r=1');
        } else {
          nURL = nURL + '&r=1';
        }
        //console.log(nURL)
        NavigateItem(nURL); 

      }
    }
  };

  const exportToPdf = (dashboardid: any) => {
    let fData = savedDashboards.filter((o: any) => +o.dashboard_id === +global.selectedDashboardProject);
    const steps = tableData; //current dashboard
    let reportName = 'Dashboard';
    if(fData && fData.length > 0){
      reportName = 'Dashboard_' + fData[0].title + '_';
    }
    const nowTime = DateTime.local().toFormat('_ydL_Hms_');
    let aspectRatio = .7; //reportElement.offsetHeight/reportElement.offsetWidth; 
    const doc = new jsPDF()

    for (var i = 0; i < steps.length; i++) {
        const timest = steps[i].timestamp.toString();
          localForage.getItem(timest).then(function (blob) {
            if(blob){
                const objectURL = URL.createObjectURL(blob as Blob);
                const img = new Image();
                img.src = objectURL;
                img.onload = () => {                 
                  doc.addPage('a4', 'l');
                  aspectRatio = img.height/img.width;
                  doc.addImage(objectURL, 'PNG', 0, 0, 210, (210*(aspectRatio)));
                };
            }      
        }); 
    }
    setTimeout(() => {
        doc.deletePage(1);
        doc.save(reportName + nowTime); 
    }, 1400);   
  }

  const exportToCsvBatch = (dashboardid: any) => {
    let fData = savedDashboards.filter((o: any) => +o.dashboard_id === +global.selectedDashboardProject);
    const steps = tableData; //current dashboard
    for (var i = 0; i < steps.length; i++) {
        exportReportLink(steps[i].timestamp, savedData);
    }
  }

  const handleExportReport = (reportLink: any) => {

  }

  const clickExportHandler = (event: any) => {
    if (event && event.target) {
      setExportLink(event.target.id);
    }
  };

  const clickPdfHandler = (event: any) => {
    if (event && event.target) {
      exportToPdf(event.target.id);
    }
  };

  const clickExportBatchHandler = (event: any) => {
    if (event && event.target) {
      exportToCsvBatch(event.target.id);
    }
  };

  const dashboardFormHandler = (event: any) => {
    setChangedValue(event.target.id);
    editDashboard(event.target.id, event.target.value);
  };

  const clickDisplayHandler = (event: any) => {
    if(event.target.id === 'dashboard-options-faList'){
      setIsDisplayGrid(false);
      localStorage.setItem('displaygrid', '0');
    } else {
      setIsDisplayGrid(true);  
      localStorage.setItem('displaygrid', '1');   
    }    
  };

  const updateLinkProjectTemplate = (reportLink: any, newProject: any) => {
    if (reportLink) {
      let sData = savedData.filter((o: any) => +o.timestamp === +reportLink);
      if (sData.length > 0 && sData[0].timestamp) {
/*         const timestamp = DateTime.local().toMillis();
        let reportL = {parent: newProject, dashboard_id: +newProject, timestamp : timestamp,
           currentCity: sData[0].currentCity, huc: sData[0].huc, pathname: sData[0].pathname, 
           search: sData[0].search, hash: sData[0].hash, url: sData[0].url, reportname: sData[0].reportname};
        let payload = {DASHBOARD_ID: newProject, REPORT_DATA: JSON.stringify(reportL), SUBTITLE: ""};
 */
/* 
        agent.Utilities.CreateReport(payload).then((res: any) => {
          console.log("SetReport", payload, res);   
          getLinksDB('updateLinkProject');
        })  
      
        localForage.getItem(''+reportLink, function(err, value) {
          if(value){
            localForage.setItem(''+timestamp, value);
          }
        }); */
      } 
    }
  }

  const onSelectDropdownProjectsTemplate = (event: any, e: any) => {
    updateLinkProjectTemplate(e.target.id, event);
  }

  const onSelectDropdownProjects = (event: any, e: any) => {
    updateLinkProject(e.target.id, event);
  }

  const updateLinkProject = (reportLink: any, newProject: any) => {
    if (reportLink) {
      let sData = savedData.filter((o: any) => +o.timestamp === +reportLink);
      if (sData.length > 0 && sData[0].timestamp) {
        const timestamp = DateTime.local().toMillis();
        let reportL = {parent: newProject, dashboard_id: +newProject, timestamp : timestamp,
           currentCity: sData[0].currentCity, huc: sData[0].huc, pathname: sData[0].pathname, 
           search: sData[0].search, hash: sData[0].hash, url: sData[0].url, reportname: sData[0].reportname};
        let payload = {DASHBOARD_ID: newProject, REPORT_DATA: JSON.stringify(reportL), SUBTITLE: ""};

        agent.Utilities.CreateReport(payload).then((res: any) => {
          console.log("SetReport", payload, res);   
          getLinksDB('updateLinkProject');
        })  
      
        localForage.getItem(''+reportLink, function(err, value) {
          if(value){
            localForage.setItem(''+timestamp, value);
          }
        });
      } 
    }
  }

  const tableColumns = useMemo(
    () => [
      { Header: 'Order', accessor: 'sortkey',
      },
      {
        Header: ' ',
        accessor: 'workflow',
        Cell: (props: any) => (
          <div
            className='dashboard-check'
            style={(group===1&&props.value&&props.value===true)  ? {display: 'inline-flex'} : {display : 'none'}}
          >
          </div>
        ),
      },
      { Header: 'Report', accessor: 'formattedname',
      },
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: (props: any) => (props.value) ? (<span style={{whiteSpace: 'nowrap'}}>{DateTime.fromMillis(props.value).toLocaleString(DateTime.DATETIME_SHORT)}</span>) : "",
      },
      {
        Header: 'City',
        accessor: 'currentCity',
      },
      {
        Header: 'HUC',
        accessor: 'huc',
      },

      {
        Header: 'Report link',
        accessor: '__timestamp',
        Cell: (props: any) => (
          <Button
            type='button'
            className='btn btn-default btn-circle dark-mode active'
            style={{whiteSpace: 'nowrap'}}
            onClick={clickLinkHandler}
            id={props.value}
          >
            <span style={{ pointerEvents: 'none' }} >Go to Report</span>
          </Button>
        ),
      },
      {
        Header: '',
        accessor: '____timestamp',
        Cell: (props: any) => (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderExportTooltip}
            rootClose
          >
            <Button
              type='button'
              className='ct-table-button'
              onClick={clickExportHandler}
              id={props.value}
            >
              <FontAwesomeIcon icon={faFileArrowDown} size='xs' />
            </Button>
          </OverlayTrigger>
        ),
      },
       {
        Header: '',
        accessor: '___timestamp',
        Cell: (props: any) => (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderSelectTooltip}
          rootClose
        >
          <Dropdown navbar={true} align={{ lg: 'end' }}
            onSelect={onSelectDropdownProjects}
            className="dropdown-projects-container">
            <Dropdown.Toggle className="dropdown-projects">
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {global.dashboardProjects.map((dash:any, index:any) =>
                <Dropdown.Item key={'dropdown-projects-cont__'+dash.timestamp} id={props.value} eventKey={dash.timestamp}>{dash.title}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
        ),
      },  
      {
        Header: '',
        accessor: '_timestamp',
        Cell: (props: any) => (
          <Button
            type='button'
            className='ct-table-button'
            onClick={clickDeleteHandler}
            id={props.value}
          >
            <FontAwesomeIcon icon={faTrashCan} size='xs' />
          </Button>
        ),
      }
    ],
    []
  );

  const clickRowHandler = (event: any) => {
    
  };

  const hoverRowHandler = (event: any) => {

  };

  const onSelectTab = (event: any) => {
    if (event === '1660079848127') {
      const cDashboard = [...savedDashboards];
      const newProject = { TITLE: 'Project', DESCRIPTION: '', EMAIL: useremail };
      
      agent.Utilities.CreateDashboard(newProject).then((res: any) => {
        console.log("CreateDashboard", res);   
        if(res && res.body){
          const newDashboardId = res.body.dashboard_id;
          cDashboard.push({ title: 'Project', description: '', email: useremail, dashboard_id: newDashboardId, timestamp: newDashboardId })
          setSavedDashboards(cDashboard);
          global.setDashboardProjects(cDashboard);
          //setCurrentDashboard(cDashboard[cDashboard.length-1]);
          //global.setSelectedDashboardProject(cDashboard[cDashboard.length-1]);

          const cDash = [...cDashboard];
          cDash.push({ title: '+', description: 'add new Dashboard Project', timestamp: '1660079848127' });
          setDashboards(cDash);
          global.setSelectedDashboardProject(newDashboardId);
          setCurrentDashboard(newDashboardId);
        }

        if(res && res.errorMessage){
          global.notify(message_erroroccured);
          console.log('Error: CreateDashboard ' + useremail + res.errorMessage);
          return;
        }
      }) 

    } else {
      global.setSelectedDashboardProject(event);
      setCurrentDashboard(event);     
      setTabActiveKey(event);
      //getLink(event);
    }
    global.setShowWorkflow(false);    
  };

  const renderExportTooltip = (props:any) => (
    <TooltipRB id="button-tooltip" {...props}>
      Export as CSV
    </TooltipRB>  
  );

  const renderAllExportTooltip = (props:any) => (
    <TooltipRB id="button-tooltip" {...props}>
      Export All CSV's
    </TooltipRB>
  );
  
  const renderSelectTooltip = (props:any) => (
    <TooltipRB id="button-tooltip" {...props}>
      Copy link
    </TooltipRB>
  );

  const renderPdfTooltip = (props:any) => (
    <TooltipRB id="button-tooltip" {...props}>
      Save as PDF
    </TooltipRB>
  );

  const popoverLink = (event:any) => {   
    if (event) {
      let sData = savedData.filter((o: any) => +o.timestamp === +event);
      if(sData && sData.length > 0 && sData[0].search){
        let filterArray = parseQueryFilters(sData[0].search, sData[0].pathname)
        return (
          <Popover id="dashboard-filter-popover" className='dashboard-popover'>
          <Popover.Header as="h3">Filters applied:</Popover.Header>
          <Popover.Body>
          {filterArray.map((link:any, index:number) => 
            <p key={'popover_' + index}><img src={trueQ} style={(link.filterLabel==='Scores'? {display:'inline-block'} : {display: 'none'})} />{link.filterLabel + ': ' + link.filterValue}</p>
          )}
          </Popover.Body>
        </Popover>
        );
      } else {
        return (
          <Popover id="dashboard-filter-popover" className='dashboard-popover'>
            <Popover.Header as="h3">Filters applied: none</Popover.Header>
          </Popover>
        );       
      }
    } else {
      return (
        <Popover id="dashboard-filter-popover" className='dashboard-popover'>
          <Popover.Header as="h3">Filters applied: none</Popover.Header>
        </Popover>
      );
    }    
  };

  const getReportLabel = (reportname:string, ) => {
    let reportLabel = ReportsConfigs.find(x => x.route.substr(1) == reportname)?.name;  

    if(reportLabel){
      return reportLabel;
    } else {
      return reportname;
    }
  }

  const getReportLabelExp = (reportname:string, search:string) => {
    let reportLabel = ReportsConfigs.find(x => x.route.substr(1) == reportname)?.name;  
    if (reportLabel ==='True QI Disclosure Suite' && search && search.substr(0, 6) !== '?ent=1'){
      reportLabel="Climate Precipitation Calculator";
    }

    if(reportLabel){
      return reportLabel;
    } else {
      return reportname;
    }
  }

  const buttongroupDash = (event:any) => {   
   setGroup(event);
  }

  const buttongroupGlobal = (event:any) => {   
    setGlobalgr(event);
  }

  const SelectThumbnail = (reportname: string, timestamp: any) => {
      if(timestamp){
        const sThumb = [...savedThumbnails].filter((o: any) => +o.timestamp === timestamp);
        if(sThumb.length > 0 && sThumb[0].url ){
          return sThumb[0].url;
        } else {
          localForage.getItem(''+timestamp, function(err, value) {
            if(value){
              let url = URL.createObjectURL(value as Blob);
              let image = document.getElementById('cardthumb'+timestamp) as HTMLImageElement;
              if(image){
                image.src = url;  
              }
              const newThumbs = savedThumbnails;
              newThumbs.push({timestamp: timestamp, url: url});
              setSavedThumbnails(newThumbs);
              return value;
            }
          });
        }
      }
      //console.log(reportname)
      if (reportname === 'AGWaterTrueQIForecast'){
        return reportsAGTQIcon;
      }  else if (reportname === 'ChemicalDataReporting'){
        return reportsCDRIcon;
      } else if (reportname === 'ClimatePrecipitationCalculator'){
        return reportsCPCIcon;
      } else if (reportname === 'Dashboard'){
        return reportsDashIcon;
      } else if (reportname === 'DailyLakeMeadLevelsReport'){
        return reportsDMLIcon;
      } else if (reportname === 'DischargeMonitoring'){
        return reportsDMRIcon;
      } else if (reportname === 'EPAMajorPermitHolders'){
        return reportsEPAIcon;
      } else if (reportname === 'FacilityRegistrySystem'){
        return reportsFRSIcon;
      } else if (reportname === 'MenuGemstatRaw'){
        return reportsGEMIcon;
      } else if (reportname === 'NationalLiveWaterQualityMap'){
        return reportsGLWIcon;
      } else if (reportname === 'GFSHuc12Forecast'){
        return reportsGFSIcon;
      } else if (reportname === 'GroundwaterLevels'){
        return reportsGWLIcon;
      } else if (reportname === 'LiveWaterSensors'){  //GFSHuc12Forecast
        return reportsLWSQIcon;
      } else if (reportname === 'NationalStormWaterQualityDatabase2020'){
        return reportsNSQDIcon;
      } else if (reportname === 'NationalStreamQualityAccounting'){
        return reportsNSQAIcon;
      } else if (reportname === 'PermitDischargeReport'){
        return reportsPDRIcon;
      } else if (reportname === 'PfasDrinkingWater'){
        return reportsPFASdrIcon;
      } else if (reportname === 'PfasEnvironmental'){
        return reportsPFASeIcon;
      } else if (reportname === 'PfasProduction'){
        return reportsPFASprIcon;
      } else if (reportname === 'Risk'){
        return reportsRiskIcon;
      } else if (reportname === 'RiskScreening'){
        return reportsRiskScrIcon;
      } else if (reportname === 'GroundwaterDetermination'){
        return reportsRiverineIcon;
      } else if (reportname === 'StormWaterTrueQIForecast'){
        return reportsSWFIcon;
      } else if (reportname === 'SurfaceWaterFlowRate'){
        return reportsSwFRIcon;
      } else if (reportname === 'ToxicsReleaseInventory'){
        return reportsTRIIcon;
      } else if (reportname === 'WhatsInMyWatershed'){
        return reportsWIWIcon;
      } else if (reportname === 'WhatsInMyDrinkingWater'){
        return reportsUCMRIcon;
      } else if (reportname === 'WaterMonitoring!'){
        return reportsWMRIcon;
      } else if (reportname === 'WaterImpairementsStreams'){
        return reports303DIcon;
      }  else { 
        return reportsGLWIcon;
      }  
  };

  let defaultActiveKey = (savedDashboards.length > 0) ? savedDashboards[0].timestamp : "";
  if (currentDashboard) {
    defaultActiveKey = currentDashboard;
  }

  //style={{display: 'none'}}  #0a58ca
  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <div className='CustomDashboard' id='CustomDashboard-report'>
          <ReportHeader global={global} data={tableData}
            reportID={"CustomDashboard-report"}
            fullScreenClickHandle={fullscreenhandle.enter}
            breadcrumboff={true} />

          <div className='CustomDashboard-container'>

            <div className='dashboard-container'>
            <ToggleButtonGroup type="radio" name="dashboard-options"
                 onChange={buttongroupDash}
                 defaultValue={group} className='dashboard-button-group'>
              <ToggleButton id="tbg-radio-1" className='dashboard-button-group-button' value={1}
                style={(group===1) ? {backgroundColor: '#0a58ca'} : {}}>
                Projects
              </ToggleButton>
              <ToggleButton id="tbg-radio-2"  className='dashboard-button-group-button' value={2}
                style={(group===2) ? {backgroundColor: '#0a58ca'} : {}}>
                Templates
              </ToggleButton>
              {/* <ToggleButton id="tbg-radio-3" className='dashboard-button-group-button' value={3}
                style={(group===3) ? {backgroundColor: '#0a58ca'} : {}}>
                Workflows
              </ToggleButton> */}
            </ToggleButtonGroup>

            <ToggleButtonGroup type="radio" name="dashboard-options"
                 onChange={buttongroupGlobal}  style={((group===12)) ? {display: 'inline-block', marginLeft: 30} : {display: 'none'}}
                 defaultValue={1} className='dashboard-button-group'>
              <ToggleButton id="tbg1-radio-1"  className='dashboard-button-group-button' value={1}>
                Private
              </ToggleButton>
              <ToggleButton id="tbg1-radio-2"  className='dashboard-button-group-button' value={2}>
                Global
              </ToggleButton>
            </ToggleButtonGroup>

              <div className='dashboard-options'>
                <Button
                  type='button'
                  onClick={clickDisplayHandler}
                  id={'dashboard-options-faList'}
                >
                  <FontAwesomeIcon className={(isDisplayGrid) ? '' : 'isActive'} icon={faList} size='lg' />
                </Button>
                <Button
                  type='button'
                  onClick={clickDisplayHandler}
                  id={'dashboard-options-faTableCellsLarge'}
                >
                  <FontAwesomeIcon className={(isDisplayGrid) ? 'isActive' : ''} icon={faTableCellsLarge} size='lg' />
                </Button>
              </div>
              
              <Tabs
                id="fill-tab-example"
                className="mb-3"
                onSelect={onSelectTab}
                activeKey={defaultActiveKey}
                mountOnEnter={true} unmountOnExit={true}
              >
                {dashboards.map((dash, index) =>
                  <Tab key={dash.timestamp + dash.title+index + changedValue}
                    eventKey={dash.timestamp}
                    title={(dash.title === '') ? '(untitled)' : dash.title}
                    mountOnEnter={true} unmountOnExit={true}>
                    <div className="dashboard-main">
                      <div className="dashboard-main-form-container" key={'main'+dash.timestamp + index + dash.description}>
                        <Form onBlur={dashboardFormHandler} className="dashboard-form-main">
                          <Form.Group className="mb-3 dashboard-form" controlId="formtitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control defaultValue={dash.title} />
                            
                          </Form.Group>
                          <Form.Group className="mb-3 dashboard-form" controlId="formdescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control ref={currentDescription} defaultValue={dash.description}  as="textarea" rows={3}/>
                         
                          </Form.Group>
                          <Form.Group className="mb-3 dashboard-form" controlId="formid"
                            style={((group===2)) ? {display: 'inline-block', minWidth: 50} : {display: 'none'}}>
                            <Form.Label>ID</Form.Label>
                            <Form.Control defaultValue={dash.dashboard_id} readOnly/>
                            
                          </Form.Group>
                        </Form> 
                        <Button
                              type='button'
                              className='btn btn-default btn-circle dark-mode active dashboard-button'
                              onClick={clickSaveDashboardHandler}  
                              id={'save' + dash.timestamp}>
                              <FontAwesomeIcon icon={faFloppyDisk} size='xs' className='point-events' />
                              <span style={{ marginLeft: 5, pointerEvents: 'none' }} >Save</span>
                        </Button>
                      </div>
                        <div className="dashboard-main-button">

                          
                        <div>

                            <Button
                              type='button'
                              className='btn btn-default btn-circle dark-mode active dashboard-button'
                              onClick={clickWorkflowHandler}                              
                              style={((group===12)) ? {display: 'inline-block'} : {display: 'none'}}
                              id={dash.timestamp}>                            
                              <span >Publish as Global</span>
                            </Button>
                            <Button
                              type='button'
                              className='btn btn-default btn-circle dark-mode active dashboard-button'
                              onClick={clickWorkflowHandler}                             
                              style={((group===1)) ? {display: 'inline-block'} : {display: 'none'}}
                              id={dash.timestamp}>                             
                              <span >{(group!==2) ? "Workflow" : "Save as Workflow"}</span>
                            </Button>
                            <Button
                              type='button'
                              className='btn btn-default btn-circle dark-mode active dashboard-button'
                              onClick={clickTemplateSaveHandler}                             
                              style={((group===1)||(group===2)) ? {display: 'inline-block'} : {display: 'none'}}
                              id={dash.timestamp}>                              
                              <span >{(group===1) ? "Save as Template" : "Copy to Projects"}</span>
                            </Button>
                          </div>        

                          
                          <div>
                            <Button
                              type='button'
                              className='btn btn-default btn-circle dark-mode active dashboard-button'
                              onClick={clickChangeHandler}                             
                              style={((group===1)) ? {display: 'inline-block'} : {display: 'none'}}
                              id={dash.timestamp}>                             
                              <span >{(group!==2) ? "Change Location" : "Change Location"}</span>
                            </Button>
                          <OverlayTrigger
                              placement="right"
                              rootClose
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderPdfTooltip}>
                              <Button
                                type='button'
                                className='ct-table-button'
                                onClick={clickPdfHandler}
                                id={'pdf'+dash.timestamp}>
                                <FontAwesomeIcon icon={faFilePdf} size='xs' />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              rootClose
                              overlay={renderAllExportTooltip}>
                              <Button
                                type='button'
                                className='ct-table-button'
                                onClick={clickExportBatchHandler}
                                id={'export'+dash.timestamp}>
                                <FontAwesomeIcon icon={faFileArrowDown} size='xs' />
                              </Button>
                            </OverlayTrigger>
                            <Button
                              type='button'
                              className='btn btn-default btn-circle dark-mode active dashboard-button'
                              onClick={clickDeleteDashboardHandler}
                              id={dash.timestamp}>
                              <FontAwesomeIcon icon={faTrashCan} size='xs' />
                              <span style={{ marginLeft: 12 }} >Delete</span>
                            </Button>
                          </div>
               
                        </div>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
            <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm deletion</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary"  onClick={handleDeleteCancel}>
                  Cancel
                </Button>
                <Button variant="primary" id={'confirm' + 'dashtimsestamp'} onClick={handleDeleteConfirm}>
                  Delete
                </Button>
              </Modal.Footer>  
            </Modal>      
            <Modal show={showChangeModal} onHide={handleChangeCancel} className={'dashboard-modal'}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Change</Modal.Title>
              </Modal.Header>
              <Modal.Body  style={{minHeight:300}} >
                    <p style={{}}>Update Current Location:</p>
                    <SearchInput global={global} />

                    <span key={'modalchangemode'+appContext.selectedHUC8}style={{whiteSpace: 'pre-line', marginTop: 40}}>{changeMode}</span>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary"  onClick={handleChangeCancel}>
                  Cancel
                </Button>
                <Button variant="primary" id={'confirm' + 'dashtimsestamp'} onClick={handleChangeConfirm}>
                  Change
                </Button>
              </Modal.Footer>  
            </Modal>  
              <div
                className={
                  isFullScreenActive
                    ? 'row gx-0 table-chart-row table-parent-container expand-container'
                    : 'row gx-0 table-chart-row table-parent-container'
                }
              >
                <div className='report-options' >
                  <div className='report-options-button-container'>
                    <Button onClick={refresh}>
                      <FontAwesomeIcon icon={faRotate} />
                    </Button>
                  </div>
                </div>
                <div className='dashboard-table' key={'dashboard-table' + tableData.length} style={(isDisplayGrid) ? { display: 'none' } : { display: 'block' }}>
                  <ReportTableExpandedPopup
                    data={tableData}
                    columns={tableColumns}
                    clickLoadStop={true}
                    clickRowHandler={clickRowHandler}
                    hoverRowHandler={hoverRowHandler}
                    popoverRowHandler={popoverLink}
                  />               
                </div>
  
                <div className='dashboard-grid' style={(isDisplayGrid) ? { display: 'block' } : { display: 'none' }}>
                  <Row xs={1} md={3} className="g-4 dashboard-grid-row">
                    {tableData.map((link, index) => (
                      <Col key={'dashboard-grid'+link.timestamp+group+index}>
                        <OverlayTrigger key={'dashboard-grid-pop_'+link.timestamp+group+index} 
                        rootClose
                          trigger={['hover', 'focus']} placement="auto" overlay={popoverLink(link.timestamp)}>
                        <Card className='dashboard-card'  key={'dashboard-grid-card'+link.timestamp+group+index}>
                           <Card.Body>
                            <Card.Title>{getReportLabelExp(link.reportname, link.search)}<div
                                className='dashboard-check-a'
                                style={(group===1&&link.workflow&&link.workflow===true)  ? {display: 'inline-flex'} : {display : 'none'}}
                              >
                              </div></Card.Title>
                            
                            <Card.Text>
                              <span>{link.currentCity}</span>
                              <span className='dashboard-card-span'>{(link.timestamp) ? DateTime.fromMillis(link.timestamp).toLocaleString(DateTime.DATETIME_SHORT) : ""}</span>
                            </Card.Text>
                            <ButtonToolbar>
                                <Button
                                  type='button'
                                  className='btn btn-default btn-circle dark-mode active'
                                  onClick={clickLinkHandler}
                                  id={link.timestamp}
                                >
                                  <span style={{ pointerEvents: 'none' }} >Go to saved View</span>
                                </Button>                              
                              <ButtonGroup>
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderExportTooltip}
                                  rootClose
                                >
                                  <Button
                                    type='button'
                                    className='ct-table-button'
                                    onClick={clickExportHandler}
                                    id={link.timestamp}
                                  >
                                    <FontAwesomeIcon icon={faFileArrowDown} size='xs' />
                                  </Button>
                                </OverlayTrigger>
                              </ButtonGroup>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderSelectTooltip}
                                rootClose
                              >
                              <Dropdown navbar={true} align={{ lg: 'end' }}
                                onSelect={onSelectDropdownProjects}
                                className="dropdown-projects-container">
                                <Dropdown.Toggle className="dropdown-projects">

                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{maxHeight: '20vh', overflowY: 'auto'}}>
                                  {global.dashboardProjects.map((dash:any, index:number) =>
                                    <Dropdown.Item key={'dropdown-projects' + link.timestamp+index} id={link.timestamp} eventKey={dash.timestamp}>{dash.title}</Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              </OverlayTrigger>
                              <Button
                                type='button'
                                className='ct-table-button'
                                onClick={clickDeleteHandler}
                                id={link.timestamp}
                              >
                                <FontAwesomeIcon icon={faTrashCan} size='xs' />
                              </Button>
                            </ButtonToolbar>
                          </Card.Body>
                          <Card.Img id={'cardthumb'+ link.timestamp} className='card-image' variant="top" src={SelectThumbnail(link.reportname, link.timestamp)} />
                        </Card>
                        </OverlayTrigger>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            
          </div>
        </div>
      </FullScreenComp>
    </>
  );
}