import React, { useState, useEffect, useContext, useRef, useCallback, memo } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl
} from 'react-map-gl';
import settings from '../../../settings.json';
import { PointContext } from "../../../PointContext";
import { RefContext } from '../../../RefContext';
import { Row, Col, Container } from 'react-bootstrap';
import { putViewportIntoStorage, updateLocationContext } from '../../utils';
import MapAddition from '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTable from "../shared/ReportTable";
import MapLegend from '../shared/MapLegend';
import agent from '../../../api/agent';
import { SpinnerCircular } from 'spinners-react';
import { Pagination } from 'react-bootstrap';
import { AppContext } from '../../../AppContext'
import NSGComponents from './NSGComponents'
import 'chartjs-plugin-zoom';
import './nsg-report.scss';

const NSGReport = ({ global }: any) => {
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const ref = useRef<any>();
  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext(RefContext);
  const { updateContext } = useContext(AppContext);

  const [tableData, setTableData] = useState<any>({});
  const [datasets, setDatasets] = useState<string[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(2); // Start with 2 pages
  const notificationCounter = useRef(0);

  useEffect(() => {
    if (error && notificationCounter.current === 0) {
      global.notify('No data at this location');
      notificationCounter.current++;
    }
  }, [error]);

  useEffect(() => {
    setCurrentRef?.(ref);
  }, [ref]);

  const getData = async (dataset: string, page: number = 1) => {
    if (tableData[dataset] && tableData[dataset][page]) {
      return;
    }
    global.setLoading(true);
    try {
      const { body } = await agent.Reports.NSG(id, dataset, page);
      setTableData((prevData: any) => ({
        ...prevData,
        [dataset]: {
          ...(prevData[dataset] || {}),
          [page]: body
        }
      }));

      if (body.length === 0) {
        setTotalPages(page); 
      } else {
        setTotalPages(page + 1); 
      }
    } catch (error) {
      setError(true);
    } finally {
      setError(false);
      global.setLoading(false);
    }
  };

  useEffect(() => {
    if (id && selectedDataset) {
      getData(selectedDataset, currentPage);
    }
  }, [id, selectedDataset, currentPage]);
  
  const getMetaData = async (id: string) => {
    global.setLoading(true);

    const filterNonEmptyValues = (obj: any) =>
      Object.fromEntries(
        Object.entries(obj).filter(([key, value]: any) => value.length !== 0)
    );

    try {
      const { body } = await agent.Reports.NSGMeta(id);
      
      const obj = filterNonEmptyValues(body)
      const datasetKeys = Object.keys(obj);

      if(datasetKeys.length === 0) {
        global.notify('No data at this location');
      }

      setDatasets(datasetKeys);
      if (datasetKeys.length > 0) {
        setSelectedDataset(datasetKeys[0]);
        getData(datasetKeys[0]);
      }
    } catch (error) {
      if (notificationCounter.current === 0) {
        global.notify('No data at this location');
        notificationCounter.current++;
      }
    } finally {
      global.setLoading(false);
    }
  };

  const flyToPoint = (lng: any, lat: any) => {
    if (ref.current) {
      ref.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 10,
      });
    }
  };

  useEffect(() => {
    if (currentPoint && updateContext) {
      const { lng, lat } = currentPoint;
      setTimeout(() => updateLocationContext(lat, lng, global.currentReport, "United States", updateContext), 100);
      setTimeout(() => flyToPoint(lng, lat), 100);
      if (currentPoint['field_site_id'] !== id) { 
        setId(currentPoint['field_site_id']);
        notificationCounter.current = 0; 
      }
    }
  }, [currentPoint]);

  useEffect(() => {
    if (id) {
      getMetaData(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      global.setLoading(false);
    };
  }, []);

  const renderReportTables = useCallback((data: any) => {
    const pageData = data[currentPage] || [];
    
    if (pageData.length === 0) {
      return <p className="no-data">No data</p>;
    }
  
    const handleHeaders = (input: any[]) => {
      let headers: any = [];
      input.forEach((header: any) => headers.push({ Header: header, accessor: header }));
      return headers.length > 0 ? headers : [];
    };
  
    const keys = Object.keys(pageData[0]);
    return <ReportTable data={pageData} columns={handleHeaders(keys)} />;
  }, [currentPage]);
  
  const spinnerStyle = {
    color: '#3861AD',
    overflow: 'visible',
    width: '75px',
    marginTop: '10%'
  };

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    if (!tableData[selectedDataset]?.[page] && page <= totalPages) {
      getData(selectedDataset, page);
    }
  }, [])

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container className='nsg-report-container'>
        <ReportHeader
          global={global}
          data={[]}
          reportID={"NSGReport"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="nsg-map-container w-100">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={ref}
                preserveDrawingBuffer={true}
                onClick={(e:any) => global.onMapClick(e)}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                <MapAddition 
                  global={global} 
                  mapRef={ref}
                  position={'low'}
                  zipOff={true}
                  MapSliderAdd={true}
                />
                <MapLegend global={global} legendWidth={280} />
                <FullscreenControl />
                <NavigationControl />
                <GeolocateControl />
              </Map>
            </Col>
          </Col>
        </Row>
        {
          datasets.length > 0 && (
            <Row>
              <Col className='col-12 container-bottom bottom'>
              <div className='dataset-menu-wrapper'>
                  <strong>Dataset:</strong>
                  <select
                    className="form-select"
                    value={selectedDataset}
                    onChange={(e) => {
                      const dataset = e.target.value;
                      setSelectedDataset(dataset);
                      setCurrentPage(1); 
                      getData(dataset);
                    }}
                  >
                    {datasets.map((dataset: string, index: number) => (
                      <option value={dataset} key={index}>
                        {dataset}
                      </option>
                    ))}
                  </select>
                </div>
                <Col>
                  {datasets.length > 0 && (
                    <div className="dataset-select-container">
                        {
                          tableData[selectedDataset] 
                            ? 
                          <NSGComponents
                            global={global}
                            title={selectedDataset}
                            dataset={tableData[selectedDataset]}
                            currentPage={currentPage} 
                          /> 
                            : 
                          <SpinnerCircular style={spinnerStyle}/>
                        }
                    </div>
                  )}
                </Col>
                <div className='pagination-wrapper'>
                  <Pagination className="pagination-controls">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Pagination.Prev>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                  </Pagination.Next>
                  </Pagination>
                </div>
              </Col>
            </Row>
          )
        }
      </Container>
      <ReportFooter />
    </FullScreenComp>
  );
}

export default memo(NSGReport);