import React, { useState, useContext, useEffect } from 'react';
import {
  Form,
  FormControl,
  Button,
  InputGroup,
  ListGroup,
  Container,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { AppContext } from '../AppContext';
import { Location, useLocation } from 'react-router';
import agent from '../api/agent';
import localForage from "localforage";
import settings from '../settings.json';
import ISOs from '../ISO.json';
import { message_hucrestriction, message_nodatalocation } from '../Constants';
import Routes from '../ReportsRoutes';
import stateCentroids from '../json/state_centroids.json';
import './search-input.scss'
import { parse, unparse } from 'papaparse';
import { HUC12InfoPoint } from '../types/HUC12InfoPoint';

interface SearchInputProps {
  global: any;
}

interface Feature {
  place_name: string;
  center: [number, number];
}

export default function SearchInput(props: SearchInputProps) {
  const appContext = useContext<any>(AppContext);
  const { global } = props;

  const [queryString, setQueryString] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Feature[]>([]);
  const [infoWater, setInfoWater] = useState<null | any>(null)
  const { pathname } = useLocation();
  const location: Location = useLocation();
  const hucRestrict: string[] = global.hucRestrict;
  const instanceId = Math.floor(Math.random() * 111111);
  const states = stateCentroids.find(({ countryName }: any) => global.currentCountry === countryName);

  const [recentLocation, setRecentLocation] = useState<null | any>(null)

  const [huc12InfoCatalogArray, setHuc12InfoCatalogDataArray] = useState<any[]>([]);
  const [zipCatalogArray, setZipCatalogArray] = useState<any[]>([]);

  useEffect(() => {
    const huc12InfoFileURL = '/huc12_info_update.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: HUC12InfoPoint[] | any[] = parse(responseText, {
          header: true,
        }).data;
        setHuc12InfoCatalogDataArray(data);
      });
  }, []);

  useEffect(() => {
    const huc12InfoFileURL = '/csv/ziphuc12.csv';
    fetch(huc12InfoFileURL)
      .then(response => response.text())
      .then(responseText => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        setZipCatalogArray(data);
      });
  }, []);

  useEffect(() => {
    localForage.getItem("recentLocations", function(err, value) {    
      if(value){//@ts-ignore
        global.setRecentLocations(value);
      }
    });
  }, []);

  useEffect(() => {
    if(global.recentLocations){
      localForage.setItem('recentLocations', global.recentLocations);
    }
  }, [global.recentLocations]);

  useEffect(() => {
    if(recentLocation){

      let ls_huc12 = localStorage.getItem('location_huc12')
      if(!recentLocation.huc12){
        if(ls_huc12){
          let parsedHuc12 = JSON.parse(ls_huc12);
          if(parsedHuc12 && parsedHuc12.huc12 && parsedHuc12.huc12.substr(0,8)===recentLocation.huc8){
            recentLocation.huc12 = parsedHuc12.HUC12;
            recentLocation.huc12name = parsedHuc12.NAME;  
            recentLocation.HUC12 = parsedHuc12.HUC12;
            recentLocation.NAME = parsedHuc12.NAME;  
            recentLocation.centroid_latitude = recentLocation.latitude;
            recentLocation.centroid_longitude = recentLocation.longitude;  
          }
        }
      } 

      let rL = [...global.recentLocations];
      let newLength = rL.unshift(recentLocation);
      if(newLength > 20){
        rL.pop();
      }
      global.setRecentLocations(rL)
    }
  }, [recentLocation]);


  useEffect(() => {
    if (global.cookieTrailState && global.selectedStateCoordinates) {
      handleContext(global.selectedStateCoordinates.lat, global.selectedStateCoordinates.lng, global.cookieTrailState, global.currentState);
    }
  }, [global.cookieTrailState, global.selectedStateCoordinates]);

  useEffect(() => {
    if (global.cookieTrailState) {
      handleContext(global.viewport.latitude, global.viewport.longitude, global.cookieTrailState, global.currentState);
    }
  }, [global.currentState]);

  const getISOCode = (input: string): string => {
    const countries = Object.values(ISOs);
    const isos = Object.keys(ISOs);
    const countryIndex = countries.findIndex(country => country === input);

    return isos[countryIndex];
  };

  const findState = (location: string): string | null => {
    const { stateCentroids }: any = states;
    for (let i = 0; i < stateCentroids.length; i++) {
      const { name } = stateCentroids[i];
      const regex = new RegExp(name, 'i');
      if (regex.test(location)) {
        return name;
      }
    }
    return null;
  };

  const handleContext = (selectedPlaceLat: number, selectedPlaceLng: number, selectedLocation: string, selectedState?:string): void => {
    if (global.currentCountry === 'Mexico') {
      if (pathname !== Routes.DailyLakeMeadLevelsReport) {
        agent.Utilities.LatLngToHUCMexico(selectedPlaceLat, selectedPlaceLng).then((res: any) => {
          const responseBody = res.body;
          if (responseBody === undefined) return global.notify(message_nodatalocation);
          const basinId = responseBody[0]['Basin Id'];
          if (appContext.updateContext) {
            appContext.updateContext(
              basinId,
              selectedLocation,
              global.currentReport,
              'Mexico'
            );
            setRecentLocation({location: selectedLocation, huc8: null, country: 'Mexico', basin: basinId, huc12: null, huc12name: null, latitude: selectedPlaceLat, longitude: selectedPlaceLng, state: selectedState}) 
          }
        });
      } else {
        appContext.updateContext('15010005', 'Lake Mead');
        global.notify(message_nodatalocation);
      }
    } else {
      if (global.currentCountry !== 'United States') {
        if (appContext.updateContext) {
          appContext.updateContext(
            "",
            selectedLocation,
            global.currentReport,
            global.currentCountry
          );
          setRecentLocation({location: selectedLocation, huc8: null, country: global.currentCountry||'United States',  huc12: null, huc12name: null, latitude: selectedPlaceLat, longitude: selectedPlaceLng, state: selectedState})
        }
      }
      if (pathname !== Routes.DailyLakeMeadLevelsReport) {
        if (selectedPlaceLat && selectedPlaceLng) {
          agent.Utilities.LatLngToHuc8(selectedPlaceLat, selectedPlaceLng).then((res: any) => {
            const responseBody = res.body;
            if (responseBody === undefined) return global.notify(message_nodatalocation);
            const responseHUC8 = responseBody[0].Huc8 ? responseBody[0].Huc8 : '';

            if (responseHUC8 && global.permitReports.indexOf(pathname) < 0 && hucRestrict.length > 0 && (hucRestrict.indexOf(responseHUC8) < 0)) {
              return global.notify(message_hucrestriction);
            }
            global.setSearchCenter([selectedPlaceLat, selectedPlaceLng]);
            if (appContext.updateContext) {
              appContext.updateContext(
                responseHUC8,
                selectedLocation,
                global.currentReport,
                'United States'
              );

              setRecentLocation({location: selectedLocation, huc8: responseHUC8, country: 'United States',  huc12: null, huc12name: null, latitude: selectedPlaceLat, longitude: selectedPlaceLng, state: selectedState})
            }
          });
        }
      } else {
        appContext.updateContext('15010005', 'Lake Mead');
        global.notify(message_nodatalocation);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent, input: string | null = null): Promise<void> => {
    e.preventDefault();
    setQueryString('');
    global.setResultsDataset([]); //Clear huc12 search layer
    try {
      global.setLoading(true);
      const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&country=${getISOCode(global.currentCountry)}&limit=3&access_token=${settings.maboxKey}`);

      if (data && data.features && data.features.length > 0) {
        const [lng, lat] = data.features[0].center;
        const location = data.features[0].place_name;
        const state = findState(location);

        if (state) {
          global.setCurrentState(state);
        }

        localStorage.setItem('location_mapbox', JSON.stringify(data.features[0]));

        global.setViewport({
          zoom: 12,
          latitude: lat,
          longitude: lng
        });

        handleContext(lat, lng, location, state||'');
      } else {
        global.notify('No results found.');
      }
    } catch (e) {
      console.log(e);
    }
    global.setLoading(false);
  };

  const handleWatershedSubmit = async (e: React.FormEvent, input: any | null = null): Promise<void> => {
    e.preventDefault();
    setQueryString('');

    if(input && input.centroid_latitude && input.centroid_longitude && input.huc12){
        let newLocation = input;
        newLocation.NAME = input.name;
        newLocation.HUC12 = input.huc12;
        newLocation.centroid_latitude = input.centroid_latitude;
        newLocation.centroid_longitude = input.centroid_longitude;
        newLocation.states = input.states;
    
        global.setViewport({
          zoom: 10,
          latitude: +input.centroid_latitude,
          longitude:+input.centroid_longitude
        });
        handleContext(+input.centroid_latitude, +input.centroid_longitude, `${input.name} (${input.huc12})`, input.states);
        global.setClickedHUC12(input.huc12)
        global.setGlobalHuc12(input.huc12)
        localStorage.setItem('location_huc12', JSON.stringify(newLocation)); //location for Breadcrumb
        global.setResultsDataset([newLocation]);  //huc12 search layer
    }
  };

  const handleAutoComplete = async (input: string): Promise<void> => {
    setQueryString(input);

    try {
      const { data } = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?autocomplete=true&country=${getISOCode(global.currentCountry)}&limit=3&access_token=${settings.maboxKey}`
      );

     let rowLimit = 5; 
     let dataWater = [] as any;

     //Prioritize HUC12 search results
     let nums = new Set(['1','2','3','4','5','6','7','8','9','0']);
     let hasOnlyNumbers = (str:string) =>str.split('').every( x=>nums.has(x) );
     let searchState = "";

     //Is this a zip?
     if(input && input.length === 5 && hasOnlyNumbers(input)){
         const findZip = zipCatalogArray.find((i:any) => i.zip === input)
         if(findZip && findZip.huc12){
             let hucFilter = [...huc12InfoCatalogArray].filter((o: any) => o["huc12"] === findZip.huc12);
             //let hucFilter = [...huc12InfoCatalogArray].filter((o: any) => o["huc12"].substr(0,8) === findZip.huc8);
             dataWater = [...dataWater,...hucFilter.slice(0,3)]  
         }
     }

     //Check name and huc12 number
     let nameFilter = [...huc12InfoCatalogArray].filter((o: any) => o["name"].toLowerCase().indexOf(input.toLowerCase()) > -1);
     let huc12Filter = [...huc12InfoCatalogArray].filter((o: any) => o["huc12"].indexOf(input) > -1);
     
     //Check input to city zip file
     let cityFilter = [] as any; 
     if(input && input.length>4){
       let cityZipFilter = [...zipCatalogArray].filter((o: any) => o["name"] && o["name"].length>0 && o["name"].toLowerCase().indexOf(input.toLowerCase()) > -1).map((su) => su.huc12);     
       cityFilter = [...huc12InfoCatalogArray].filter((o: any) => cityZipFilter.indexOf(o["huc12"]) > -1);
     }

     //Prioritize based on current HUC8
     let HUCarray = ["selectedHUC8", "clickedHUC8"]
     if(appContext && appContext.selectedHUC8 && appContext.selectedHUC8.length===8){
       let hFilter = [...nameFilter].filter((o: any) => ("" + o["huc12"]).substr(0,8)=== appContext.selectedHUC8);
       dataWater = [...dataWater,...hFilter]

       let sFilter = [...huc12InfoCatalogArray].filter((o: any) => ("" + o["huc12"]).substr(0,8)=== appContext.selectedHUC8);
       if(sFilter && sFilter.length > 0){
         searchState = sFilter[0].states;
       }
       //clickedHUC12 currentState globalHuc12  centroid_longitude  selectedState
     }
     if(global["clickedHUC8"] && global["clickedHUC8"].length===8){
       let hFilter = [...nameFilter].filter((o: any) => ("" + o["huc12"]).substr(0,8) === global["clickedHUC8"]);
       dataWater = [...dataWater,...hFilter]
     }

     //Prioritize based on HUC8 State
     if(searchState && (searchState.length===2||searchState.length===5)){
       let hFilter = [...nameFilter].filter((o: any) => o["states"] === searchState);
       dataWater = [...dataWater,...hFilter]
     }

     //viewport

     //home state

     //concat results
      dataWater = [...dataWater,...huc12Filter]
      dataWater = [...dataWater,...nameFilter]
      dataWater = [...dataWater,...cityFilter]

      //Unique only
      let dataSubset = dataWater.slice(0,10);  
      let dataSubsetUnique =  dataSubset.filter((obj1:any, i:any, arr:any) => 
        arr.findIndex((obj2:any) => (obj2.huc12 === obj1.huc12)) === i
      )
      //final watershed search results 
      setInfoWater(dataSubsetUnique.slice(0,rowLimit))

      let dataFilter = [...huc12Filter]
      dataFilter = [...dataFilter,...nameFilter]
  
      //huc12 map layer, if under limit
      if(dataFilter && dataFilter.length<500){
          global.setResultsDataset(dataFilter);
      } else {
          global.setResultsDataset([]);
      }
      
      //no geocode results
      if (!data && dataWater.length>0) {
        setOpen(true);
      }

      if (data) {
        setSuggestions(data.features);
        setOpen(true);
      }
    } catch (e) {
      console.log(e);
    }

    if (input === '') setOpen(false);
  };

  const renderSuggestions = (): JSX.Element | null => {
    if (((suggestions && suggestions.length > 0) || (infoWater && infoWater.length > 0)) && open) {
      return (
        <ListGroup className="suggestions-list">
          {suggestions && suggestions.length>0 && ( <ListGroup.Item key={uuid()} className={'suggestion-list-subtitle'} >
                        <span>Addresses, Places, and Zip Codes</span>
                      </ListGroup.Item>  )}       
          {suggestions.map((suggestion) => (
            <ListGroup.Item key={uuid()} action className={'suggestion-list-item'} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.place_name}
            </ListGroup.Item>
          ))}
          {infoWater && infoWater.length > 0 && (<ListGroup.Item key={uuid()}  className={'suggestion-list-subtitle'} >
                      <span>Watersheds (HUC12)</span>
                    </ListGroup.Item>)}
          {infoWater.map((item:any) => (
            <ListGroup.Item key={uuid()} action className={'suggestion-list-item'} onClick={() => handleWatershedSuggestionClick(item)}>
              {item.name} {item.states} ({item.huc12})
            </ListGroup.Item>
          ))}
        </ListGroup>
      );
    }
    return null;
  };

  const handleWatershedSuggestionClick = (suggestion: any): void => {
    // @ts-ignore
    handleWatershedSubmit(new Event('submit'), suggestion);
    setOpen(false);
  };

  const handleSuggestionClick = (suggestion: Feature): void => {
    setQueryString(suggestion.place_name);
    // @ts-ignore
    handleSubmit(new Event('submit'), suggestion.place_name);
    setOpen(false);
  };

  const handleClear = () => {
    setOpen(false);
    setSuggestions([]);
    setQueryString('');
    if(global.resultsDataset.length !== 1){
        global.setResultsDataset([])
    }
    
  };

  return (
    <Container className='search-input'>
      <Form>
        <InputGroup>
          <InputGroup.Text className='icon-wrapper'>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputGroup.Text>
          <FormControl
            type="text" 
            className='search-form-input'
            placeholder="Search Address, City, Zip Code or Watershed"
            value={queryString}
            onChange={(e) => handleAutoComplete(e.target.value)}
          />
          {queryString && (
            <Button variant="secondary" className="btn-clear" onClick={handleClear} type="button">
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          )}
        </InputGroup>
      </Form>
      {renderSuggestions()}
    </Container>
  );
}
