import { useEffect, useContext } from "react";
import OutflowLayer from "../../../components/Layers/OutflowLayer";
import NitrateAssessmentLayer from "../../../components/Layers/NitrateAssessmentLayer";
import settings from "../../../settings.json";
import settings_map from "../../../settings_map.json";
import VectorTileLayer from "../../../components/Layers/VectorTileLayer";
import WiseLayer from "../../../components/Layers/WiseLayer/WiseLayer";
import HucList from "../../../components/HucList";
import TerraclimateLayer from "../../../components/Layers/TerraclimateLayer";
import EnviromentalJustice from "../../../components/Layers/EnviromentalJustice/EnviromentalJustice";
import NOAASeaLevelRise from "../../../components/Layers/NOAASeaLevelRise";
import HUCsMexico from "../../../components/Mexico/HUCsMexico";
import { Layer, Source } from "react-map-gl";
import TileHover from "../../../components/HucList/TileHover";
import TileHover12 from "../../../components/HucList/TileHover12";
import FemaLayers from "../../../components/Layers/FemaLayers/FemaLayer";
import FemaFloodZones from "../../../components/Layers/FemaFloodZones/FemaFloodZones";
import GlobalMapWithReport from "../../../components/Layers/GlobalMapWithReport/GlobalMapWithReport";
import EpicLayer from "../../../components/Layers/EpicLayer/EpicLayer";
import RenderSeaLevelRise from "./RenderSeaLevelRise";
import HucListLine from "../../../components/HUCListLine/HUCListLine";
import CountiesLayer from "../../../components/Layers/CountiesLayer";
import { useLocation } from "react-router-dom";
import { formatDate, pollutantColor } from "../../utils";
import LineLayer from "../../../components/LineLayer";
import CircleVectorLayer from "../../../components/Layers/CircleVectorLayer";
import BIALayer from "../../../components/BIALayer";
import YellowstoneHuc12 from "../../../components/YellowstoneHuc12/YellowstoneHuc12";
import FireDataLayer from "../../../components/Layers/FireDataLayer/FireDataLayer";
import EDDIDrought from "../../../components/Layers/EDDIDrought";
import NOAAMix from "../../../components/Layers/NOAAMix/NOAAMix";
import FemaFirm from "../../../components/Layers/FemaFirm/FemaFirm";
import FHO from "../../../components/Layers/FHO";
import StreamAnomaly from "../../../components/Layers/StreamAnomaly";
import RiverGauge from "../../../components/Layers/RiverGauge";
import FloodInd from "../../../components/Layers/FloodInd";
import NWSHazard from "../../../components/Layers/NWSHazard/NWSHazard";
import WaterQualityPortal from "../../../components/Layers/WaterQualityPortal/WaterQualityPortal";
import WRFCLayer from "../../../components/Layers/WRFCLayer/WRFCLayer";
import CVILayer from "../../../components/Layers/CVILayer/CVILayer";
import persistent from "../../../components/YellowstoneHuc12/ysData/yellow_stone_huc12_persistent_contaminants.json";
import moderate from "../../../components/YellowstoneHuc12/ysData/yellow_stone_huc12_moderately_persistent_contaminants.json";
import volatile from "../../../components/YellowstoneHuc12/ysData/yellow_stone_huc12_volatile_contaminants.json";
import EastPalestine from "../../../components/EastPalestine/EastPalestine";
import epmpc from "../../../components/EastPalestine/epJSON/east_palestine_moderately_persistent_contaminants.json";
import epmpcad from "../../../components/EastPalestine/epJSON/east_palestine_moderately_persistent_contaminants_with_air_deposition.json";
import eppc from "../../../components/EastPalestine/epJSON/east_palestine_persistent_contaminants.json";
import epvc from "../../../components/EastPalestine/epJSON/east_palestine_volatile_contaminants.json";
import epvcad from "../../../components/EastPalestine/epJSON/east_palestine_volatile_contaminants_with_air_deposition.json";
import eppcad from "../../../components/EastPalestine/epJSON/east_palestine_persistent_contaminants_with_air_deposition.json";
import ExcessiveRainfallForecast from "../../../components/Layers/ExcessiveRainfallForecast/ExcessiveRainfallForecast";
import RasterLayer from "../../../components/RasterLayer/RasterLayer";
import UserLayers from "../../../components/UserLayer/UserLayers";
import WaterConflict from "../../../components/WaterConflict/WaterConflict";
import WaterImpairments from "../../../components/WaterImpairments/WaterImpairments";
import Hydrobasins from "../../../components/Hydrobasins/Hydrobasins";
import HydrobasinsCombined from "../../../components/HydrobasinsCombined/HydrobasinsCombined";
import PointLayer from "../../../components/PointLayer/PointLayer";
import {  
  adjustHexBrightness,
  adjustHSLABrightness,
  adjustRGBABrightness
} from "../../../features/utils";
import { RefContext } from '../../../RefContext'  
import OABLayer from "../../../components/OABLayer/OABLayer";
import GemstatLayer from "../../../components/Layers/GemstatLayer/GemstatLayer";
import OGWTDLayer from "../../../components/OGWTDLayer/OGWTDLayer";
import USGSPWU from "../../../components/USGSPWU/USGSPWU";
import USGSHuc12Layer from "../../../components/USGSHuc12Layer/USGSHuc12Layer";
import AppleLocationData from '../../../components/AppleLocationData/AppleLocationData'
import CEJSTlayer from '../../../components/CEJSTLayer/CEJSTLayer'
import CircleDataLayer from '../../../components/Layers/CircleLayer/CircleDataLayer'
import LabelMarker from '../../../components/LabelMarker/LabelMarker'
import HucLayer from '../../../components/HucLayer/HucLayer'
import agent from '../../../api/agent'




const RenderLayers = ({ global, mapRef = {}, beforeID = "" }: any) => {
  const { pathname } = useLocation();
  const zoom = global.viewport.zoom;
  const { currentRef } = useContext(RefContext)
  

  
  useEffect(() => {

    const moveLayersAfterStyleLoad = () => {
        // @ts-ignore
        const map = currentRef?.current?.getMap();
        if (!map) {
            return;
        }

        setTimeout(() => {
            const flattenedLayerOrder = global.layerIds.flat().reverse();

            // Iterate over each layer and move it to the correct position
            flattenedLayerOrder.forEach((layer: any, index: any) => {
                const mapLayer = map.getLayer(layer);
                if (mapLayer) {
                    try {
                        // Determine the ID of the layer that should come before the current one
                        const beforeId = flattenedLayerOrder[index + 1] || undefined;

                        console.log(`Layer found: ${layer}. Moving it before: ${beforeId}`);
                        map.moveLayer(layer, beforeId);
                        console.log(`Successfully moved layer: ${layer} before ${beforeId}`);
                    } catch (error) {
                        console.error(`Error moving layer ${layer}:`, error);
                    }
                } else {
                    console.log(`Layer not found on the map: ${layer}`);
                }
            });

            console.log(
                "Current map layers after moving:",
                map.getStyle().layers.map((layer: any) => layer.id)
            );
        }, 500); // Adjust the delay as necessary for your use case
    };

    // Execute the function after the map style has loaded
    // @ts-ignore
    if (currentRef?.current?.loaded()) {
        moveLayersAfterStyleLoad();
    } else {
        // @ts-ignore
        currentRef?.current?.once('style.load', moveLayersAfterStyleLoad);
    }
}, [global.layerIds]);


  return (
    <>
    {global.OSUWells && (
        <PointLayer
           global={global}
           id={'osu'}
           action={global.action}
           callValue={'gw_logid'}
           url={settings.tileSetURLs.OSUWells}
           sourceLayer={'osu_recorder_wells-7xql2a'}
           reportRoute={'/OSUWellsReport'}
           circleColor={`${adjustHexBrightness('#5EFDD1', global.OSUWellsBrightness)}`}
           opacity={global.OSUWellsOpacity}
        />
    )}
    {global.Hydrobasins && (
      <>
        <HydrobasinsCombined 
          id={"hbc"}
          global={global}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'hydrobasin_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsCombined}
        />
        <Hydrobasins 
          id={"hbsi"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'si_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsSiberia}
        />
        <Hydrobasins 
          id={"hbna"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'na_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsNorthAmerica}
        />
        <Hydrobasins 
          id={"hbsa"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'sa_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsSouthAmerica}
        />
        <Hydrobasins 
          id={"hbgl"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'gr_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsGreenland}
        />
        <Hydrobasins 
          id={"hbar"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'ar_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsArtic}
        />
        <Hydrobasins 
          id={"hbas"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'asia_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsAsia}
        />
        <Hydrobasins 
          id={"hbau"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'australia_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsAustralia}
        />
        <Hydrobasins 
          id={"hbeu"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'europe_combined'}
          tileSet={settings.tileSetURLs.HydrobasinsEurope}
        />
        <Hydrobasins 
          id={"hba"}
          global={global}
          HydrobasinsLevel={global.HydrobasinsLevel}
          HydrobasinsKind={global.HydrobasinsKind}
          action={global.action}
          opacity={global.HydrobasinsOpacity}
          brightness={global.HydrobasinsBrightness}
          source={'hydrobasins_africa'}
          tileSet={settings.tileSetURLs.HydrobasinsAfrica}
        />
      </>
    )}

      <UserLayers global={global} mapRef={mapRef} />

      {global.GGDE && (
        <RasterLayer
          id="GGDE"
          url={settings.tileSetURLs.GGDE}
          opacity={global.GGDEOpacity}
        />
      )}

      {global.Wise && (
        <WiseLayer
          id="wise"
          global={global}
          opacity={global.WiseOpacity}
          action={global.action}
          brightness={global.WiseBrightness}
          tileSet={settings.tileSetURLs.CountryBoundaries}
          sourceLayer={"country_boundaries"}
        />
      )}

      {global.MEC && (
        <CircleDataLayer
          id="MEC"
          global={global}
          action={global.action}
          opacity={global.MECOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.MEC}
          sourceLayer={"Manuels_E_coli_1-4mpr7n"}
          circleColor={`${adjustHexBrightness("#FF66ED", global.MECBrightness)}`}
          objString={'Name'}
          featurePoint={'field_site_name'}
          pointLabel={'index'}
          reportRoute={'/MECReport'}
          includedLayerData={true}
          circleStrokeColor={"white"}
          popupTitle={true}
          radius={9}
          zoom={zoom}
        />
      )}

      {global.MBFIBDT && (
        <CircleDataLayer
          id="MBFIBDT"
          global={global}
          action={global.action}
          opacity={global.MBFIBDTOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.MBFIBDT}
          sourceLayer={"waterkeepers2-4cg4h0"}
          circleColor={`${adjustHexBrightness("#7834FF", global.MBFIBDTBrightness)}`}
          objString={'Name'}
          featurePoint={'field_site_name'}
          pointLabel={'index'}
          reportRoute={'/FIBReport'}
          includedLayerData={true}
          circleStrokeColor={"white"}
          popupTitle={true}
          radius={9}
          zoom={zoom}
        />
      )}

      {global.NSG && (
        <CircleDataLayer
          id="NSG"
          global={global}
          action={global.action}
          opacity={global.NSGOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.NSG}
          sourceLayer={"NeonScience-59cm3c"}
          circleColor={`${adjustHexBrightness("#F93EF3", global.NSGBrightness)}`}
          objString={'Name'}
          featurePoint={'field_site_name'}
          reportRoute={'/NSGReport'}
          includedLayerData={true}
          circleStrokeColor={"white"}
          nsg={true}
          radius={9}
          zoom={zoom}
        />
      )}

      {global.FBFIB && (
        <CircleDataLayer
          id="FBFIB"
          global={global}
          action={global.action}
          opacity={global.FBFIBOpacity}
          tileSet={settings.tileSetURLs.FBFIB}
          sourceLayer={"WaterKeeperLocations_2-735ij2"}
          circleColor={`${adjustHexBrightness("#ffffff", global.FBFIBBrightness)}`}
          objString={'Name'}
          featurePoint={'Name'}
          reportRoute={'/FBFIBReport'}
          includedLayerData={true}
          circleStrokeColor={"orange"}
          radius={4}
          zoom={zoom}
        />
      )}

      {global.FHBP && (
        <CircleDataLayer
          id="FHBP"
          global={global}
          action={global.action}
          opacity={global.FHBPOpacity}
          tileSet={settings.tileSetURLs.FHBP}
          sourceLayer={"beaches-9b2gct"}
          circleColor={`${adjustHexBrightness("#14B57D", global.FHBPBrightness)}`}
          objString={'Location'}
          featurePoint={'location'}
          reportRoute={'/FHBPReport'}
          includedLayerData={false}
          radius={4}
          zoom={zoom}
        />
      )}

      {global.OWQPDemo && (
        <CircleDataLayer
          id="OWQPDemo"
          global={global}
          action={global.action}
          opacity={global.OWQPDemoOpacity}
          tileSet={settings.tileSetURLs.OWQPDemo}
          sourceLayer={"OWQP_Data-23ywuz"}
          circleColor={`${adjustHexBrightness("#04ED9F", global.OWQPDemoBrightness)}`}
          objString={'OWRD Well ID'}
          featurePoint={'OWRD Well ID'}
          reportRoute={'/OWQPReport'}
          includedLayerData={false}
          radius={4}
          zoom={zoom}
        />
      )}

      {global.CWALayer && (
        <CircleDataLayer
          id="CWA"
          global={global}
          action={global.action}
          opacity={global.CWALayerOpacity}
          tileSet={settings.tileSetURLs.CWA}
          sourceLayer={"Cleveland_Water_Alliance_Loca-dmnv36"}
          circleColor={[
            "match",
            ["get", "Type"],
            "BUOY", `${adjustHexBrightness("#FCA502", global.CWALayerBrightness)}`,
            "SHORLINE", `${adjustHexBrightness("#FF4433", global.CWALayerBrightness)}`,
            'transparent'

          ]}
          excludedIDs={['mwgib', 'mwcatawba', 'mcavon', 'mwewp', '45165']}
          reportRoute={'/CWAReport'}
          featurePoint={'Name'}
          radius={4}
          zoom={zoom}
        />
      )}

  
      {global.CEJST && (
        <CEJSTlayer
          id="CEJST"
          global={global}
          action={global.action}
          opacity={global.CEJSTOpacity}
          brightness={global.CEJSTBrightness}
          outlineColor={"green"}
          tileSet={settings.tileSetURLs.CEJST}
          sourceLayer={"CEJST"}
          zoom={zoom}
        />
      )}
      
      {global.USGSHuc12 && (
        <USGSHuc12Layer 
          id={`usgs_huc12`}
          global={global} 
          opacity={global.USGSHuc12Opacity} 
          brightness={global.USGSHuc12Brightness} 
          url={settings.tileSetURLs.USGSHuc12}
          action={global.action}
          sourceLayer={`water_usage_huc12`}
          reportRoute={"/USGSHuc12"}
          sourceLabel={'USGS Monthly Public Water Usage (By HUC12) - 2016-2020'}
          sourceLink={"https://www.usgs.gov/data/public-supply-water-use-reanalysis-2000-2020-period-huc12-month-and-year-conterminous-united"}
          tableColumns={[
            { Header: 'HUC 12', accessor: 'huc12' },
            { Header: 'Jan 2016', accessor: '1/2016' },
            { Header: 'Feb 2016', accessor: '2/2016' },
            { Header: 'Mar 2016', accessor: '3/2016' },
            { Header: 'Apr 2016', accessor: '4/2016' },
            { Header: 'May 2016', accessor: '5/2016' },
            { Header: 'Jun 2016', accessor: '6/2016' },
            { Header: 'Jul 2016', accessor: '7/2016' },
            { Header: 'Aug 2016', accessor: '8/2016' },
            { Header: 'Sep 2016', accessor: '9/2016' },
            { Header: 'Oct 2016', accessor: '10/2016' },
            { Header: 'Nov 2016', accessor: '11/2016' },
            { Header: 'Dec 2016', accessor: '12/2016' },
            { Header: 'May 2017', accessor: '5/2017' },
            { Header: 'Jun 2017', accessor: '6/2017' },
            { Header: 'Jul 2017', accessor: '7/2017' },
            { Header: 'Aug 2017', accessor: '8/2017' },
            { Header: 'Sep 2017', accessor: '9/2017' },
            { Header: 'Oct 2017', accessor: '10/2017' },
            { Header: 'Nov 2017', accessor: '11/2017' },
            { Header: 'Dec 2017', accessor: '12/2017' },
            { Header: 'Jan 2018', accessor: '1/2018' },
            { Header: 'Feb 2018', accessor: '2/2018' },
            { Header: 'Mar 2018', accessor: '3/2018' },
            { Header: 'Apr 2018', accessor: '4/2018' },
            { Header: 'May 2018', accessor: '5/2018' },
            { Header: 'Jun 2018', accessor: '6/2018' },
            { Header: 'Jul 2018', accessor: '7/2018' },
            { Header: 'Aug 2018', accessor: '8/2018' },
            { Header: 'Sep 2018', accessor: '9/2018' },
            { Header: 'Oct 2018', accessor: '10/2018' },
            { Header: 'Nov 2018', accessor: '11/2018' },
            { Header: 'Dec 2018', accessor: '12/2018' },
            { Header: 'Jan 2019', accessor: '1/2019' },
            { Header: 'Feb 2019', accessor: '2/2019' },
            { Header: 'Mar 2019', accessor: '3/2019' },
            { Header: 'Apr 2019', accessor: '4/2019' },
            { Header: 'May 2019', accessor: '5/2019' },
            { Header: 'Jun 2019', accessor: '6/2019' },
            { Header: 'Jul 2019', accessor: '7/2019' },
            { Header: 'Aug 2019', accessor: '8/2019' },
            { Header: 'Sep 2019', accessor: '9/2019' },
            { Header: 'Oct 2019', accessor: '10/2019' },
            { Header: 'Nov 2019', accessor: '11/2019' },
            { Header: 'Dec 2019', accessor: '12/2019' },
            { Header: 'Jan 2020', accessor: '1/2020' },
            { Header: 'Feb 2020', accessor: '2/2020' },
            { Header: 'Mar 2020', accessor: '3/2020' },
            { Header: 'Apr 2020', accessor: '4/2020' },
            { Header: 'May 2020', accessor: '5/2020' },
            { Header: 'Jun 2020', accessor: '6/2020' },
            { Header: 'Jul 2020', accessor: '7/2020' },
            { Header: 'Aug 2020', accessor: '8/2020' },
            { Header: 'Sep 2020', accessor: '9/2020' },
            { Header: 'Oct 2020', accessor: '10/2020' },
            { Header: 'Nov 2020', accessor: '11/2020' },
            { Header: 'Dec 2020', accessor: '12/2020' }
          ]}
        />
      )}

      {global.USGSWUC && (
        <USGSPWU
          id="USGSWUC_layer"
          global={global}
          opacity={global.USGSWUCOpacity}
          // @ts-ignore
          url={settings.tileSetURLs.USGSWUC}
          action={global.action}
          sourceLayer={`water_usage_cities`}
          reportRoute={"/USGSWUCReport"}
          sourceLabel={'USGS Monthly Public Water Usage (MGD) by City 2000-2020'}
          sourceLink={"https://www.usgs.gov/data/public-supply-water-use-reanalysis-2000-2020-period-huc12-month-and-year-conterminous-united"}
          tableColumns={[
            { Header: 'Jan 2016', accessor: '1/2016' },
            { Header: 'Feb 2016', accessor: '2/2016' },
            { Header: 'Mar 2016', accessor: '3/2016' },
            { Header: 'Apr 2016', accessor: '4/2016' },
            { Header: 'May 2016', accessor: '5/2016' },
            { Header: 'Jun 2016', accessor: '6/2016' },
            { Header: 'Jul 2016', accessor: '7/2016' },
            { Header: 'Aug 2016', accessor: '8/2016' },
            { Header: 'Sep 2016', accessor: '9/2016' },
            { Header: 'Oct 2016', accessor: '10/2016' },
            { Header: 'Nov 2016', accessor: '11/2016' },
            { Header: 'Dec 2016', accessor: '12/2016' },
            { Header: 'May 2017', accessor: '5/2017' },
            { Header: 'Jun 2017', accessor: '6/2017' },
            { Header: 'Jul 2017', accessor: '7/2017' },
            { Header: 'Aug 2017', accessor: '8/2017' },
            { Header: 'Sep 2017', accessor: '9/2017' },
            { Header: 'Oct 2017', accessor: '10/2017' },
            { Header: 'Nov 2017', accessor: '11/2017' },
            { Header: 'Dec 2017', accessor: '12/2017' },
            { Header: 'Jan 2018', accessor: '1/2018' },
            { Header: 'Feb 2018', accessor: '2/2018' },
            { Header: 'Mar 2018', accessor: '3/2018' },
            { Header: 'Apr 2018', accessor: '4/2018' },
            { Header: 'May 2018', accessor: '5/2018' },
            { Header: 'Jun 2018', accessor: '6/2018' },
            { Header: 'Jul 2018', accessor: '7/2018' },
            { Header: 'Aug 2018', accessor: '8/2018' },
            { Header: 'Sep 2018', accessor: '9/2018' },
            { Header: 'Oct 2018', accessor: '10/2018' },
            { Header: 'Nov 2018', accessor: '11/2018' },
            { Header: 'Dec 2018', accessor: '12/2018' },
            { Header: 'Jan 2019', accessor: '1/2019' },
            { Header: 'Feb 2019', accessor: '2/2019' },
            { Header: 'Mar 2019', accessor: '3/2019' },
            { Header: 'Apr 2019', accessor: '4/2019' },
            { Header: 'May 2019', accessor: '5/2019' },
            { Header: 'Jun 2019', accessor: '6/2019' },
            { Header: 'Jul 2019', accessor: '7/2019' },
            { Header: 'Aug 2019', accessor: '8/2019' },
            { Header: 'Sep 2019', accessor: '9/2019' },
            { Header: 'Oct 2019', accessor: '10/2019' },
            { Header: 'Nov 2019', accessor: '11/2019' },
            { Header: 'Dec 2019', accessor: '12/2019' },
            { Header: 'Jan 2020', accessor: '1/2020' },
            { Header: 'Feb 2020', accessor: '2/2020' },
            { Header: 'Mar 2020', accessor: '3/2020' },
            { Header: 'Apr 2020', accessor: '4/2020' },
            { Header: 'May 2020', accessor: '5/2020' },
            { Header: 'Jun 2020', accessor: '6/2020' },
            { Header: 'Jul 2020', accessor: '7/2020' },
            { Header: 'Aug 2020', accessor: '8/2020' },
            { Header: 'Sep 2020', accessor: '9/2020' },
            { Header: 'Oct 2020', accessor: '10/2020' },
            { Header: 'Nov 2020', accessor: '11/2020' },
            { Header: 'Dec 2020', accessor: '12/2020' }
          ]}
        />
      )}

      {global.OABSSG && (
        <LineLayer
          id="overtureSingaporeStreamSystems"
          opacity={global.OABSSGOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABSSG}
          sourceLayer={"stream_SG"}
          lineColor={`${adjustHexBrightness("#3eab1a", global.OABSSGBrightness)}`}
          width={2}
        />
      )}

      {global.OABSCA && (
        <LineLayer
          id="overtureCanadaStreamSystems"
          opacity={global.OABSCAOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABSCA}
          sourceLayer={"stream_MX"}
          lineColor={`${adjustHexBrightness("#3eab1a", global.OABSCABrightness)}`}
          width={2}
        />
      )}

      {global.OABSMX && (
        <LineLayer
        id="overtureMexicoStreamSystems"
        opacity={global.OABSMXOpacity}
        // @ts-ignore
        tileSet={settings.tileSetURLs.OABSMX}
        sourceLayer={"stream_MX"}
        lineColor={`${adjustHexBrightness("#3eab1a", global.OABSMXBrightness, 10)}`}
        width={2}
      />
      )}

      {global.OABSID && (
        <LineLayer
        id="overtureInonesiaStreamSystems"
        opacity={global.OABSIDOpacity}
        // @ts-ignore
        tileSet={settings.tileSetURLs.OABSID}
        sourceLayer={"stream_ID"}
        lineColor={`${adjustHexBrightness("#3eab1a", global.OABSIDaqueductAtlasCoastalFloodOpacityBrightness)}`}
        width={2}
      />
      )}

      {global.OABSPH && (
        <LineLayer
        id="overturePhilippineStreamSystems"
        opacity={global.OABSPHOpacity}
        // @ts-ignore
        tileSet={settings.tileSetURLs.OABSPH}
        sourceLayer={"stream_PH"}
        lineColor={`${adjustHexBrightness("#A45BCF", global.OABSPHBrightness)}`}
        width={2}
      />
      )}

      {global.OABRPK && (
        <LineLayer
          id="overturePakistanRiverSystems"
          opacity={global.OABRPKOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABRPK}
          sourceLayer={"rivers_PK"}
          lineColor={`${adjustHexBrightness("#1F51FF", global.OABRPKBrightness)}`}
          width={2}
        />
      )}

      {global.OABRSG && (
        <LineLayer
          id="overtureMexicoRiverSystems"
          opacity={global.OABRSGOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABRSG}
          sourceLayer={"rivers_SG"}
          lineColor={"#50add8"}
          width={2}
        />
      )}

      {global.OABRMX && (
        <LineLayer
          id="overtureMexicoRiverSystems"
          opacity={global.OABRMXOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABRMX}
          sourceLayer={"rivers_MX"}
          lineColor={`${adjustHexBrightness("#54C06D", global.OABRMXBrightness)}`}
          width={2}
        />
      )}

      {global.OABRCA && (
        <LineLayer
          id="overtureCanadaRiverSystems"
          opacity={global.OABRCAOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABRCA}
          sourceLayer={"rivers_CA"}
          lineColor={`${adjustHexBrightness("#54C06D", global.OABRCABrightness)}`}
          width={2}
        />
      )}

      {global.OABRID && (
        <LineLayer
        id="overtureIndonesiaRiverSystems"
        opacity={global.OABRIDOpacity}
        // @ts-ignore
        tileSet={settings.tileSetURLs.OABRID}
        sourceLayer={"rivers_ID"}
        lineColor={"#54C06D"}
        width={2}
      />
      )}
      {global.OABRPH && (
        <LineLayer
            id="overturePhilippinesRiverSystems"
            opacity={global.OABRPHOpacity}
            // @ts-ignore
            tileSet={settings.tileSetURLs.OABRPH}
            sourceLayer={"rivers_PH"}
            lineColor={`${adjustHexBrightness("#54C06D", global.OABRPHBrightness)}`}
            width={2}
          />
      )}
      {global.OGWTD && (
        <OGWTDLayer
          id="OGWTD"
          global={global}
          opacity={global.OGWTDOpacity}
          tileSet={settings.tileSetURLs.OGWTD}
          sourceLayer={"water_treatment_equipment"}
          circleColor={`${adjustHexBrightness("#CCDAFF", global.OGWTDBrightness)}`}
          action={global.action}
          zoom={zoom}
          radius={2}
        />
      )}

      {global.OABUS && (
        <OABLayer
          id="OABUS"
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABUS}
          sourceLayer={"admins_US"}
          lineColor={`${adjustHexBrightness("#2505B9", global.OABUSBrightness)}`}
          opacity={global.OABUSOpacity}
          ref={currentRef}
          width={2}
          global={global}
          action={global.action}
        />
      )}

      {global.OABPH && (
        <OABLayer
          id="OABPH"
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABPH}
          sourceLayer={"admins_PH"}
          lineColor={`${adjustHexBrightness("#2505B9", global.OABPHBrightness)}`}
          opacity={global.OABPHOpacity}
          ref={currentRef}
          width={2}
          global={global}
          action={global.action}
        />
      )}

      {global.OABMX && (
        <OABLayer
          id="OABMX"
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABMX}
          sourceLayer={"admins_MX"}
          lineColor={`${adjustHexBrightness("#2505B9", global.OABMXBrightness)}`}
          opacity={global.OABMXOpacity}
          ref={currentRef}
          width={2}
          global={global}
          action={global.action}
        />
      )}

      {global.OABID && (
        <OABLayer
          id="OABID"
          // @ts-ignore
          tileSet={settings.tileSetURLs.OABID}
          sourceLayer={"admins_ID"}
          lineColor={`${adjustHexBrightness("#2505B9", global.OABIDBrightness)}`}
          opacity={global.OABIDOpacity}
          ref={currentRef}
          width={2}
          global={global}
          action={global.action}
        />
      )}

      {global.NationalWaterImpairements && (
        <>
          <WaterImpairments
            idPoly="water_impairments_poly"
            idLines="water_impairments_lines"
            idPoints="water_impairments_points"
            global={global}
            brightness={global.NationalWaterImpairementsBrightness}
            action={global.action}
            opacity={global.NationalWaterImpairementsOpacity}
            tileSetPoly={settings.tileSetURLs.NationalImpairedWaterPolygons}
            tileSetLines={settings.tileSetURLs.NationalImpairedWaterLines}
            tileSetPoints={settings.tileSetURLs.NationalImpairedWaterPoints}
            sourceLayerPoly={"303d_polygons"}
            sourceLayerLines={"303d_lines"}
            sourceLayerPoints={"303d_points"}
            reportRoute={"/WaterImpairmentsReport"}
          />
        </>
      )}

      {global.WaterConflict && (
        <WaterConflict
          id="water_conflict"
          global={global}
          opacity={global.WaterConflictOpacity}
          tileSet={settings.tileSetURLs.WaterConflict}
          sourceLayer={"water_conflict"}
          circleColor={`${adjustHexBrightness("#5BA37F", global.WaterConflictBrightness)}`}
          radius={7}
          reportRoute={"/WaterConflict"}
          sourceLabel={
            "Water Conflict Chronology - Pacific Institute (pacinst.org)"
          }
          sourceLink={"https://pacinst.org/water-conflict-chronology/"}
          tableColumns={[
            {
              Header: "Country",
              accessor: "country",
            },
            {
              Header: "Conflict Type",
              accessor: "conflict_type",
            },
            {
              Header: "Date",
              accessor: "date",
            },
            {
              Header: "Label",
              accessor: "label",
            },
            {
              Header: "Description",
              accessor: "text",
            },
            {
              Header: "lat",
              accessor: "lat",
            },
            {
              Header: "lng",
              accessor: "lng",
            },
          ]}
        />
      )}

      {global.GBIF && (
        <GlobalMapWithReport
          id="GBIF"
          opacity={global.GBIFOpacity}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.GBIF}
          sourceLayer={"GBIF"}
          action={global.action}
          reportRoute={"/GBIF"}
          sourceLabel={"Global Biodiversity Information Facility"}
          sourceLink={"https://planetarycomputer.microsoft.com/dataset/gbif"}
          tableColumns={[
            {
              Header: "Country",
              accessor: "COUNTRY",
            },
            {
              Header: "Country AFF",
              accessor: "COUNTRYAFF",
            },
            {
              Header: "Species",
              accessor: "species",
            },
          ]}
          fillColor={[
            "interpolate",
            ["linear"],
            ["get", "species"],
            1,
            `${adjustHexBrightness("#440154", global.GBIFBrightness)}`,
            341,
            `${adjustHexBrightness("#3B528B", global.GBIFBrightness)}`,
            1167,
            `${adjustHexBrightness("#22918C", global.GBIFBrightness)}`,
            4986,
            `${adjustHexBrightness("#5EC962", global.GBIFBrightness)}`,
            10292,
            `${adjustHexBrightness("#FCE725", global.GBIFBrightness)}`,
            12248,
            `${adjustHexBrightness("#FCE725", global.GBIFBrightness)}`,
          ]}
        />
      )}

          

      {global.SkyFiAustin && (
        <>
          <RasterLayer
            id="SkyFiAustin"
            url={settings.tileSetURLs.SkyFiAustin}
            opacity={global.SkyFiAustinOpacity}
          />
          <LabelMarker
            lat={30.272232875807347}
            lng={-97.72650843122246}
            zoom={zoom}
            zoomDisplay={13}
            text={'Image Taken: 14 July 2023'}
            style={{
              color: 'white',
              backgroundColor: '#002044',
              border: '2px solid #ffffff',
              padding: '4px',
              borderRadius: '4px',
              fontSize: '14px'
            }}
          />
        </>
      )}

      {global.SkyFiWhiting && (
        <>
          <RasterLayer
            id="SkyFiWhiting"
            url={settings.tileSetURLs.SkyFiWhiting}
            opacity={global.SkyFiWhitingOpacity}
          />
          <LabelMarker
              lat={41.64060790993801}
              lng={-87.45914438126117}
              zoom={zoom}
              zoomDisplay={12}
              text={'Image Taken: 2 Febuary 2024'}
              style={{
                color: 'white',
                backgroundColor: '#002044',
                border: '2px solid #ffffff',
                padding: '4px',
                borderRadius: '4px',
                fontSize: '14px'
              }}
            />
        </>
  
      )}

      {global.MoBiSRA && (
        <RasterLayer
          id="MoBiSRA"
          url={settings.tileSetURLs.MoBiSRA}
          opacity={global.MoBiSRAOpacity}
        />
      )}

      {global.MoBiPSR && (
        <RasterLayer
          id="MoBiPSR"
          url={settings.tileSetURLs.MoBiPSR}
          opacity={global.MoBiPSROpacity}
        />
      )}
      {global.MoBiSRAll && (
        <RasterLayer
          id="MoBiSRAll"
          url={settings.tileSetURLs.MoBiSRAll}
          opacity={global.MoBiSRAllOpacity}
        />
      )}
      {global.MoBiPI && (
        <RasterLayer
          id="MoBiPI"
          url={settings.tileSetURLs.MoBiPI}
          opacity={global.MoBiPIOpacity}
        />
      )}
      {global.MoBiV && (
        <RasterLayer
          id="MoBiV"
          url={settings.tileSetURLs.MoBiV}
          opacity={global.MoBiVOpacity}
        />
      )}
      {global.indoHucs && (
        <>
          <GlobalMapWithReport
            id="indoHucs"
            opacity={global.indoHucsOpacity * 0.15}
            beforeID={beforeID}
            outlineColor={"transparent"}
            fillColor={`${adjustHexBrightness("#ffffff", global.indoHucsBrightness)}`}
            report={false}
            // @ts-ignore
            tileSet={settings.tileSetURLs.IndoHUCS}
            action={global.action}
            sourceLayer={"indonesia_hucs"}
            reportRoute={"/IndoHucs"}
            sourceLabel={"Indonesian HUCs"}
            sourceLink={""}
            tableColumns={[
              {
                Header: "Shape_Area",
                accessor: "Shape_Area",
              },
              {
                Header: "Shape_Leng",
                accessor: "Shape_Leng",
              },
              {
                Header: "Shape_Length",
                accessor: "Shape_Length",
              },
              {
                Header: "aq30_id",
                accessor: "aq30_id",
              },
              {
                Header: "aq30_id_1",
                accessor: "aq30_id_1",
              },
              {
                Header: "aqid",
                accessor: "aqid",
              },
              {
                Header: "bws_cat",
                accessor: "bws_cat",
              },
              {
                Header: "bws_label",
                accessor: "bws_label",
              },
              {
                Header: "bws_raw",
                accessor: "bws_raw",
              },
              {
                Header: "bws_score",
                accessor: "bws_score",
              },
              {
                Header: "bwd_cat",
                accessor: "bwd_cat",
              },
              {
                Header: "bwd_label",
                accessor: "bwd_label",
              },
              {
                Header: "bwd_raw",
                accessor: "bwd_raw",
              },
              {
                Header: "bwd_score",
                accessor: "bwd_score",
              },
              {
                Header: "area_km2",
                accessor: "area_km2",
              },
              {
                Header: "cep_cat",
                accessor: "cep_cat",
              },
              {
                Header: "cep_label",
                accessor: "cep_label",
              },
              {
                Header: "cep_raw",
                accessor: "cep_raw",
              },
              {
                Header: "cep_score",
                accessor: "cep_score",
              },
              {
                Header: "cfr_cat",
                accessor: "cfr_cat",
              },
              {
                Header: "cfr_label",
                accessor: "cfr_label",
              },
              {
                Header: "cfr_raw",
                accessor: "cfr_raw",
              },
              {
                Header: "cfr_score",
                accessor: "cfr_score",
              },
              {
                Header: "drr_cat",
                accessor: "drr_cat",
              },
              {
                Header: "drr_label",
                accessor: "drr_label",
              },
              {
                Header: "drr_raw",
                accessor: "drr_raw",
              },
              {
                Header: "drr_score",
                accessor: "drr_score",
              },
              {
                Header: "gid_0",
                accessor: "gid_0",
              },
              {
                Header: "gid_1",
                accessor: "gid_1",
              },
              {
                Header: "gtd_cat",
                accessor: "gtd_cat",
              },
              {
                Header: "gtd_label",
                accessor: "gtd_label",
              },
              {
                Header: "gtd_raw",
                accessor: "gtd_raw",
              },
              {
                Header: "gtd_score",
                accessor: "gtd_score",
              },
              {
                Header: "iav_cat",
                accessor: "iav_cat",
              },
              {
                Header: "iav_label",
                accessor: "iav_label",
              },
              {
                Header: "iav_raw",
                accessor: "iav_raw",
              },
              {
                Header: "iav_score",
                accessor: "iav_score",
              },
              {
                Header: "name_0",
                accessor: "name_0",
              },
              {
                Header: "name_1",
                accessor: "name_1",
              },
              {
                Header: "pfaf_id",
                accessor: "pfaf_id",
              },
              {
                Header: "rfr_cat",
                accessor: "rfr_cat",
              },
              {
                Header: "rfr_label",
                accessor: "rfr_label",
              },
              {
                Header: "rfr_raw",
                accessor: "rfr_raw",
              },
              {
                Header: "rfr_score",
                accessor: "rfr_score",
              },
              {
                Header: "rri_cat",
                accessor: "rri_cat",
              },
              {
                Header: "rri_label",
                accessor: "rri_label",
              },
              {
                Header: "rri_score",
                accessor: "rri_score",
              },
              {
                Header: "sev_cat",
                accessor: "sev_cat",
              },
              {
                Header: "sev_label",
                accessor: "sev_label",
              },
              {
                Header: "sev_raw",
                accessor: "sev_raw",
              },
              {
                Header: "sev_score",
                accessor: "sev_score",
              },
              {
                Header: "string_id",
                accessor: "string_id",
              },
              {
                Header: "string_id_1",
                accessor: "string_id_1",
              },
              {
                Header: "ucw_cat",
                accessor: "ucw_cat",
              },
              {
                Header: "ucw_label",
                accessor: "ucw_label",
              },
              {
                Header: "ucw_raw",
                accessor: "ucw_raw",
              },
              {
                Header: "ucw_score",
                accessor: "ucw_score",
              },
              {
                Header: "udw_cat",
                accessor: "udw_cat",
              },
              {
                Header: "udw_label",
                accessor: "udw_label",
              },
              {
                Header: "udw_raw",
                accessor: "udw_raw",
              },
              {
                Header: "udw_score",
                accessor: "udw_score",
              },
              {
                Header: "usa_cat",
                accessor: "usa_cat",
              },
              {
                Header: "usa_label",
                accessor: "usa_label",
              },
              {
                Header: "usa_raw",
                accessor: "usa_raw",
              },
              {
                Header: "usa_score",
                accessor: "usa_score",
              },
              {
                Header: "w_awr_agr_qal_cat",
                accessor: "w_awr_agr_qal_cat",
              },
              {
                Header: "w_awr_agr_qal_label",
                accessor: "w_awr_agr_qal_label",
              },
              {
                Header: "w_awr_agr_qal_raw",
                accessor: "w_awr_agr_qal_raw",
              },
              {
                Header: "w_awr_agr_qal_score",
                accessor: "w_awr_agr_qal_score",
              },
              {
                Header: "w_awr_agr_qal_weight_fraction",
                accessor: "w_awr_agr_qal_weight_fraction",
              },
              {
                Header: "w_awr_agr_qan_cat",
                accessor: "w_awr_agr_qan_cat",
              },
              {
                Header: "w_awr_agr_qan_label",
                accessor: "w_awr_agr_qan_label",
              },
              {
                Header: "w_awr_agr_qan_raw",
                accessor: "w_awr_agr_qan_raw",
              },
              {
                Header: "w_awr_agr_qan_score",
                accessor: "w_awr_agr_qan_score",
              },
              {
                Header: "w_awr_agr_qan_weight_fraction",
                accessor: "w_awr_agr_qan_weight_fraction",
              },
              {
                Header: "w_awr_agr_rrr_cat",
                accessor: "w_awr_agr_rrr_cat",
              },
              {
                Header: "w_awr_agr_rrr_label",
                accessor: "w_awr_agr_rrr_label",
              },
              {
                Header: "w_awr_agr_rrr_raw",
                accessor: "w_awr_agr_rrr_raw",
              },
              {
                Header: "w_awr_agr_rrr_score",
                accessor: "w_awr_agr_rrr_score",
              },
              {
                Header: "w_awr_agr_rrr_weight_fraction",
                accessor: "w_awr_agr_rrr_weight_fraction",
              },
              {
                Header: "w_awr_agr_tot_cat",
                accessor: "w_awr_agr_tot_cat",
              },
              {
                Header: "w_awr_agr_tot_label",
                accessor: "w_awr_agr_tot_label",
              },
              {
                Header: "w_awr_agr_tot_raw",
                accessor: "w_awr_agr_tot_raw",
              },
              {
                Header: "w_awr_agr_tot_score",
                accessor: "w_awr_agr_tot_score",
              },
              {
                Header: "w_awr_agr_tot_weight_fraction",
                accessor: "w_awr_agr_tot_weight_fraction",
              },
              {
                Header: "w_awr_che_qal_cat",
                accessor: "w_awr_che_qal_cat",
              },
              {
                Header: "w_awr_che_qal_label",
                accessor: "w_awr_che_qal_label",
              },
              {
                Header: "w_awr_che_qal_raw",
                accessor: "w_awr_che_qal_raw",
              },
              {
                Header: "w_awr_che_qal_score",
                accessor: "w_awr_che_qal_score",
              },
              {
                Header: "w_awr_che_qal_weight_fraction",
                accessor: "w_awr_che_qal_weight_fraction",
              },
              {
                Header: "w_awr_che_qan_cat",
                accessor: "w_awr_che_qan_cat",
              },
              {
                Header: "w_awr_che_qan_label",
                accessor: "w_awr_che_qan_label",
              },
              {
                Header: "w_awr_che_qan_raw",
                accessor: "w_awr_che_qan_raw",
              },
              {
                Header: "w_awr_che_qan_score",
                accessor: "w_awr_che_qan_score",
              },
              {
                Header: "w_awr_che_qan_weight_fraction",
                accessor: "w_awr_che_qan_weight_fraction",
              },
              {
                Header: "w_awr_che_rrr_cat",
                accessor: "w_awr_che_rrr_cat",
              },
              {
                Header: "w_awr_che_rrr_label",
                accessor: "w_awr_che_rrr_label",
              },
              {
                Header: "w_awr_che_rrr_raw",
                accessor: "w_awr_che_rrr_raw",
              },
              {
                Header: "w_awr_che_rrr_score",
                accessor: "w_awr_che_rrr_score",
              },
              {
                Header: "w_awr_che_rrr_weight_fraction",
                accessor: "w_awr_che_rrr_weight_fraction",
              },
              {
                Header: "w_awr_che_tot_cat",
                accessor: "w_awr_che_tot_cat",
              },
              {
                Header: "w_awr_che_tot_label",
                accessor: "w_awr_che_tot_label",
              },
              {
                Header: "w_awr_che_tot_raw",
                accessor: "w_awr_che_tot_raw",
              },
              {
                Header: "w_awr_che_tot_score",
                accessor: "w_awr_che_tot_score",
              },
              {
                Header: "w_awr_che_tot_weight_fraction",
                accessor: "w_awr_che_tot_weight_fraction",
              },
              {
                Header: "w_awr_con_qal_cat",
                accessor: "w_awr_con_qal_cat",
              },
              {
                Header: "w_awr_con_qal_label",
                accessor: "w_awr_con_qal_label",
              },
              {
                Header: "w_awr_con_qal_score",
                accessor: "w_awr_con_qal_score",
              },
              {
                Header: "w_awr_con_qal_weight_fraction",
                accessor: "w_awr_con_qal_weight_fraction",
              },
              {
                Header: "w_awr_con_qan_cat",
                accessor: "w_awr_con_qan_cat",
              },
              {
                Header: "w_awr_con_qan_label",
                accessor: "w_awr_con_qan_label",
              },
              {
                Header: "w_awr_con_qan_raw",
                accessor: "w_awr_con_qan_raw",
              },
              {
                Header: "w_awr_con_qan_weight_fraction",
                accessor: "w_awr_con_qan_weight_fraction",
              },
              {
                Header: "w_awr_con_qan_score",
                accessor: "w_awr_con_qan_score",
              },
              {
                Header: "w_awr_con_rrr_cat",
                accessor: "w_awr_con_rrr_cat",
              },
              {
                Header: "w_awr_con_rrr_label",
                accessor: "w_awr_con_rrr_label",
              },
              {
                Header: "w_awr_con_rrr_raw",
                accessor: "w_awr_con_rrr_raw",
              },
              {
                Header: "w_awr_con_rrr_score",
                accessor: "w_awr_con_rrr_score",
              },
              {
                Header: "w_awr_con_rrr_weight_fraction",
                accessor: "w_awr_con_rrr_weight_fraction",
              },
              {
                Header: "w_awr_con_tot_cat",
                accessor: "w_awr_con_tot_cat",
              },
              {
                Header: "w_awr_con_tot_label",
                accessor: "w_awr_con_tot_label",
              },
              {
                Header: "w_awr_con_tot_raw",
                accessor: "w_awr_con_tot_raw",
              },
              {
                Header: "w_awr_con_tot_score",
                accessor: "w_awr_con_tot_score",
              },
              {
                Header: "w_awr_con_tot_weight_fraction",
                accessor: "w_awr_con_tot_weight_fraction",
              },
              {
                Header: "w_awr_def_qal_cat",
                accessor: "w_awr_def_qal_cat",
              },
              {
                Header: "w_awr_def_qal_label",
                accessor: "w_awr_def_qal_label",
              },
              {
                Header: "w_awr_def_qal_raw",
                accessor: "w_awr_def_qal_raw",
              },
              {
                Header: "w_awr_def_qal_score",
                accessor: "w_awr_def_qal_score",
              },
              {
                Header: "w_awr_def_qal_weight_fraction",
                accessor: "w_awr_def_qal_weight_fraction",
              },
              {
                Header: "w_awr_def_qan_cat",
                accessor: "w_awr_def_qan_cat",
              },
              {
                Header: "w_awr_def_qan_label",
                accessor: "w_awr_def_qan_label",
              },
              {
                Header: "w_awr_def_qan_raw",
                accessor: "w_awr_def_qan_raw",
              },
              {
                Header: "w_awr_def_qan_score",
                accessor: "w_awr_def_qan_score",
              },
              {
                Header: "w_awr_def_qan_weight_fraction",
                accessor: "w_awr_def_qan_weight_fraction",
              },
              {
                Header: "w_awr_def_rrr_cat",
                accessor: "w_awr_def_rrr_cat",
              },
              {
                Header: "w_awr_def_rrr_label",
                accessor: "w_awr_def_rrr_label",
              },
              {
                Header: "w_awr_def_rrr_raw",
                accessor: "w_awr_def_rrr_raw",
              },
              {
                Header: "w_awr_def_rrr_score",
                accessor: "w_awr_def_rrr_score",
              },
              {
                Header: "w_awr_def_rrr_weight_fraction",
                accessor: "w_awr_def_rrr_weight_fraction",
              },
              {
                Header: "w_awr_def_tot_cat",
                accessor: "w_awr_def_tot_cat",
              },
              {
                Header: "w_awr_def_tot_label",
                accessor: "w_awr_def_tot_label",
              },
              {
                Header: "w_awr_def_tot_raw",
                accessor: "w_awr_def_tot_raw",
              },
              {
                Header: "w_awr_def_tot_score",
                accessor: "w_awr_def_tot_score",
              },
              {
                Header: "w_awr_def_tot_weight_fraction",
                accessor: "w_awr_def_tot_weight_fraction",
              },
              {
                Header: "w_awr_elp_qal_cat",
                accessor: "w_awr_elp_qal_cat",
              },
              {
                Header: "w_awr_elp_qal_label",
                accessor: "w_awr_elp_qal_label",
              },
              {
                Header: "w_awr_elp_qal_raw",
                accessor: "w_awr_elp_qal_raw",
              },
              {
                Header: "w_awr_elp_qal_score",
                accessor: "w_awr_elp_qal_score",
              },
              {
                Header: "w_awr_elp_qal_weight_fraction",
                accessor: "w_awr_elp_qal_weight_fraction",
              },
              {
                Header: "w_awr_elp_qan_cat",
                accessor: "w_awr_elp_qan_cat",
              },
              {
                Header: "w_awr_elp_qan_label",
                accessor: "w_awr_elp_qan_label",
              },
              {
                Header: "w_awr_elp_qan_raw",
                accessor: "w_awr_elp_qan_raw",
              },
              {
                Header: "w_awr_elp_qan_score",
                accessor: "w_awr_elp_qan_score",
              },
              {
                Header: "w_awr_elp_qan_weight_fraction",
                accessor: "w_awr_elp_qan_weight_fraction",
              },
              {
                Header: "w_awr_elp_rrr_cat",
                accessor: "w_awr_elp_rrr_cat",
              },
              {
                Header: "w_awr_elp_rrr_label",
                accessor: "w_awr_elp_rrr_label",
              },
              {
                Header: "w_awr_elp_rrr_raw",
                accessor: "w_awr_elp_rrr_raw",
              },
              {
                Header: "w_awr_elp_rrr_score",
                accessor: "w_awr_elp_rrr_score",
              },
              {
                Header: "w_awr_elp_rrr_weight_fraction",
                accessor: "w_awr_elp_rrr_weight_fraction",
              },
              {
                Header: "w_awr_elp_tot_cat",
                accessor: "w_awr_elp_tot_cat",
              },
              {
                Header: "w_awr_elp_tot_label",
                accessor: "w_awr_elp_tot_label",
              },
              {
                Header: "w_awr_elp_tot_raw",
                accessor: "w_awr_elp_tot_raw",
              },
              {
                Header: "w_awr_elp_tot_score",
                accessor: "w_awr_elp_tot_score",
              },
              {
                Header: "w_awr_elp_tot_weight_fraction",
                accessor: "w_awr_elp_tot_weight_fraction",
              },
              {
                Header: "w_awr_fnb_qal_cat",
                accessor: "w_awr_fnb_qal_cat",
              },
              {
                Header: "w_awr_fnb_qal_label",
                accessor: "w_awr_fnb_qal_label",
              },
              {
                Header: "w_awr_fnb_qal_raw",
                accessor: "w_awr_fnb_qal_raw",
              },
              {
                Header: "w_awr_fnb_qal_score",
                accessor: "w_awr_fnb_qal_score",
              },
              {
                Header: "w_awr_fnb_qal_weight_fraction",
                accessor: "w_awr_fnb_qal_weight_fraction",
              },
              {
                Header: "w_awr_fnb_qan_cat",
                accessor: "w_awr_fnb_qan_cat",
              },
              {
                Header: "w_awr_fnb_qan_label",
                accessor: "w_awr_fnb_qan_label",
              },
              {
                Header: "w_awr_fnb_qan_raw",
                accessor: "w_awr_fnb_qan_raw",
              },
              {
                Header: "w_awr_fnb_qan_score",
                accessor: "w_awr_fnb_qan_score",
              },
              {
                Header: "w_awr_fnb_qan_weight_fraction",
                accessor: "w_awr_fnb_qan_weight_fraction",
              },
              {
                Header: "w_awr_fnb_rrr_cat",
                accessor: "w_awr_fnb_rrr_cat",
              },
              {
                Header: "w_awr_fnb_rrr_label",
                accessor: "w_awr_fnb_rrr_label",
              },
              {
                Header: "w_awr_fnb_rrr_raw",
                accessor: "w_awr_fnb_rrr_raw",
              },
              {
                Header: "w_awr_fnb_rrr_score",
                accessor: "w_awr_fnb_rrr_score",
              },
              {
                Header: "w_awr_fnb_rrr_weight_fraction",
                accessor: "w_awr_fnb_rrr_weight_fraction",
              },
              {
                Header: "w_awr_fnb_tot_cat",
                accessor: "w_awr_fnb_tot_cat",
              },
              {
                Header: "w_awr_fnb_tot_label",
                accessor: "w_awr_fnb_tot_label",
              },
              {
                Header: "w_awr_fnb_tot_raw",
                accessor: "w_awr_fnb_tot_raw",
              },
              {
                Header: "w_awr_fnb_tot_score",
                accessor: "w_awr_fnb_tot_score",
              },
              {
                Header: "w_awr_fnb_tot_weight_fraction",
                accessor: "w_awr_fnb_tot_weight_fraction",
              },
              {
                Header: "w_awr_min_qal_cat",
                accessor: "w_awr_min_qal_cat",
              },
              {
                Header: "w_awr_min_qal_label",
                accessor: "w_awr_min_qal_label",
              },
              {
                Header: "w_awr_min_qal_raw",
                accessor: "w_awr_min_qal_raw",
              },
              {
                Header: "w_awr_min_qal_score",
                accessor: "w_awr_min_qal_score",
              },
              {
                Header: "w_awr_min_qal_weight_fraction",
                accessor: "w_awr_min_qal_weight_fraction",
              },
              {
                Header: "w_awr_min_qan_cat",
                accessor: "w_awr_min_qan_cat",
              },
              {
                Header: "w_awr_min_qan_label",
                accessor: "w_awr_min_qan_label",
              },
              {
                Header: "w_awr_min_qan_raw",
                accessor: "w_awr_min_qan_raw",
              },
              {
                Header: "w_awr_min_qan_score",
                accessor: "w_awr_min_qan_score",
              },
              {
                Header: "w_awr_min_qan_weight_fraction",
                accessor: "w_awr_min_qan_weight_fraction",
              },
              {
                Header: "w_awr_min_rrr_cat",
                accessor: "w_awr_min_rrr_cat",
              },
              {
                Header: "w_awr_min_rrr_label",
                accessor: "w_awr_min_rrr_label",
              },
              {
                Header: "w_awr_min_rrr_raw",
                accessor: "w_awr_min_rrr_raw",
              },
              {
                Header: "w_awr_min_rrr_score",
                accessor: "w_awr_min_rrr_score",
              },
              {
                Header: "w_awr_min_rrr_weight_fraction",
                accessor: "w_awr_min_rrr_weight_fraction",
              },
              {
                Header: "w_awr_min_tot_cat",
                accessor: "w_awr_min_tot_cat",
              },
              {
                Header: "w_awr_min_tot_label",
                accessor: "w_awr_min_tot_label",
              },
              {
                Header: "w_awr_min_tot_raw",
                accessor: "w_awr_min_tot_raw",
              },
              {
                Header: "w_awr_min_tot_score",
                accessor: "w_awr_min_tot_score",
              },
              {
                Header: "w_awr_min_tot_weight_fraction",
                accessor: "w_awr_min_tot_weight_fraction",
              },
              {
                Header: "w_awr_ong_qal_cat",
                accessor: "w_awr_ong_qal_cat",
              },
              {
                Header: "w_awr_ong_qal_label",
                accessor: "w_awr_ong_qal_label",
              },
              {
                Header: "w_awr_ong_qal_raw",
                accessor: "w_awr_ong_qal_raw",
              },
              {
                Header: "w_awr_ong_qal_score",
                accessor: "w_awr_ong_qal_score",
              },
              {
                Header: "w_awr_ong_qan_cat",
                accessor: "w_awr_ong_qan_cat",
              },
              {
                Header: "w_awr_ong_qan_label",
                accessor: "w_awr_ong_qan_label",
              },
              {
                Header: "w_awr_ong_qan_raw",
                accessor: "w_awr_ong_qan_raw",
              },
              {
                Header: "w_awr_ong_qan_score",
                accessor: "w_awr_ong_qan_score",
              },
              {
                Header: "w_awr_ong_qan_weight_fraction",
                accessor: "w_awr_ong_qan_weight_fraction",
              },
              {
                Header: "w_awr_ong_rrr_cat",
                accessor: "w_awr_ong_rrr_cat",
              },
              {
                Header: "w_awr_ong_rrr_label",
                accessor: "w_awr_ong_rrr_label",
              },
              {
                Header: "w_awr_ong_rrr_raw",
                accessor: "w_awr_ong_rrr_raw",
              },
              {
                Header: "w_awr_ong_rrr_score",
                accessor: "w_awr_ong_rrr_score",
              },
              {
                Header: "w_awr_ong_rrr_weight_fraction",
                accessor: "w_awr_ong_rrr_weight_fraction",
              },
              {
                Header: "w_awr_ong_tot_cat",
                accessor: "w_awr_ong_tot_cat",
              },
              {
                Header: "w_awr_ong_tot_label",
                accessor: "w_awr_ong_tot_label",
              },
              {
                Header: "w_awr_ong_tot_raw",
                accessor: "w_awr_ong_tot_raw",
              },
              {
                Header: "w_awr_ong_tot_score",
                accessor: "w_awr_ong_tot_score",
              },
              {
                Header: "w_awr_ong_tot_weight_fraction",
                accessor: "w_awr_ong_tot_weight_fraction",
              },
              {
                Header: "w_awr_smc_qal_cat",
                accessor: "w_awr_smc_qal_cat",
              },
              {
                Header: "w_awr_smc_qal_label",
                accessor: "w_awr_smc_qal_label",
              },
              {
                Header: "w_awr_smc_qal_raw",
                accessor: "w_awr_smc_qal_raw",
              },
              {
                Header: "w_awr_smc_qal_score",
                accessor: "w_awr_smc_qal_score",
              },
              {
                Header: "w_awr_smc_qal_weight_fraction",
                accessor: "w_awr_smc_qal_weight_fraction",
              },
              {
                Header: "w_awr_smc_qan_cat",
                accessor: "w_awr_smc_qan_cat",
              },
              {
                Header: "w_awr_smc_qan_label",
                accessor: "w_awr_smc_qan_label",
              },
              {
                Header: "w_awr_smc_qan_raw",
                accessor: "w_awr_smc_qan_raw",
              },
              {
                Header: "w_awr_smc_qan_score",
                accessor: "w_awr_smc_qan_score",
              },
              {
                Header: "w_awr_smc_qan_weight_fraction",
                accessor: "w_awr_smc_qan_weight_fraction",
              },
              {
                Header: "w_awr_smc_rrr_cat",
                accessor: "w_awr_smc_rrr_cat",
              },
              {
                Header: "w_awr_smc_rrr_label",
                accessor: "w_awr_smc_rrr_label",
              },
              {
                Header: "w_awr_smc_rrr_raw",
                accessor: "w_awr_smc_rrr_raw",
              },
              {
                Header: "w_awr_smc_rrr_score",
                accessor: "w_awr_smc_rrr_score",
              },
              {
                Header: "w_awr_smc_rrr_weight_fraction",
                accessor: "w_awr_smc_rrr_weight_fraction",
              },
              {
                Header: "w_awr_smc_tot_cat",
                accessor: "w_awr_smc_tot_cat",
              },
              {
                Header: "w_awr_smc_tot_label",
                accessor: "w_awr_smc_tot_label",
              },
              {
                Header: "w_awr_smc_tot_raw",
                accessor: "w_awr_smc_tot_raw",
              },
              {
                Header: "w_awr_smc_tot_score",
                accessor: "w_awr_smc_tot_score",
              },
              {
                Header: "w_awr_smc_tot_weight_fraction",
                accessor: "w_awr_smc_tot_weight_fraction",
              },
              {
                Header: "w_awr_tex_qal_cat",
                accessor: "w_awr_tex_qal_cat",
              },
              {
                Header: "w_awr_tex_qal_label",
                accessor: "w_awr_tex_qal_label",
              },
              {
                Header: "w_awr_tex_qal_raw",
                accessor: "w_awr_tex_qal_raw",
              },
              {
                Header: "w_awr_tex_qal_score",
                accessor: "w_awr_tex_qal_score",
              },
              {
                Header: "w_awr_tex_qal_weight_fraction",
                accessor: "w_awr_tex_qal_weight_fraction",
              },
              {
                Header: "w_awr_tex_qan_cat",
                accessor: "w_awr_tex_qan_cat",
              },
              {
                Header: "w_awr_tex_qan_label",
                accessor: "w_awr_tex_qan_label",
              },
              {
                Header: "w_awr_tex_qan_raw",
                accessor: "w_awr_tex_qan_raw",
              },
              {
                Header: "w_awr_tex_qan_weight_fraction",
                accessor: "w_awr_tex_qan_weight_fraction",
              },
              {
                Header: "w_awr_tex_rrr_cat",
                accessor: "w_awr_tex_rrr_cat",
              },
              {
                Header: "w_awr_tex_rrr_label",
                accessor: "w_awr_tex_rrr_label",
              },
              {
                Header: "w_awr_tex_rrr_raw",
                accessor: "w_awr_tex_rrr_raw",
              },
              {
                Header: "w_awr_tex_rrr_score",
                accessor: "w_awr_tex_rrr_score",
              },
              {
                Header: "w_awr_tex_rrr_weight_fraction",
                accessor: "w_awr_tex_rrr_weight_fraction",
              },
              {
                Header: "w_awr_tex_tot_cat",
                accessor: "w_awr_tex_tot_cat",
              },
              {
                Header: "w_awr_tex_tot_label",
                accessor: "w_awr_tex_tot_label",
              },
              {
                Header: "w_awr_tex_tot_raw",
                accessor: "w_awr_tex_tot_raw",
              },
              {
                Header: "w_awr_tex_tot_score",
                accessor: "w_awr_tex_tot_score",
              },
              {
                Header: "w_awr_tex_tot_weight_fraction",
                accessor: "w_awr_tex_tot_weight_fraction",
              },
            ]}
          />
          <LineLayer
            id="indoHucs-outlines"
            opacity={global.indoHucsOpacity}
            // @ts-ignore
            tileSet={settings.tileSetURLs.IndoHUCS}
            sourceLayer={"indonesia_hucs"}
            lineColor={`${adjustHexBrightness("#ffffff", global.indoHucsBrightness)}`}
            width={2}
          />
        </>
      )}
      {global.indoWatersheds && (
        <>
          <GlobalMapWithReport
            id="indoBasins"
            opacity={global.indoWatershedsOpacity * 0.25}
            beforeID={beforeID}
            outlineColor={"transparent"}
            fillColor={`${adjustHexBrightness("#770737", global.indoWatershedsBrightness)}`}
            report={false}
            // @ts-ignore
            tileSet={settings.tileSetURLs.IndoWatersheds}
            action={global.action}
            sourceLayer={"indonesia"}
            reportRoute={"/IndoWatersheds"}
            sourceLabel={"Indonesian Watersheds"}
            sourceLink={"https://ws-indonesia.org/"}
            tableColumns={[
              {
                Header: "Watershed Code",
                accessor: "KODE_DAS",
              },
              {
                Header: "Area",
                accessor: "LUAS_DAS",
              },
              {
                Header: "Metadata",
                accessor: "METADATA",
              },
              {
                Header: "Shape Area",
                accessor: "Shape_Area",
              },
              {
                Header: "Shape Length",
                accessor: "Shape_Leng",
              },
              {
                Header: "Global ID",
                accessor: "globalid",
              },
              {
                Header: "Kd Watershed",
                accessor: "kd_das",
              },
              {
                Header: "Kd Region",
                accessor: "kd_region",
              },
              {
                Header: "KD Thematic",
                accessor: "kd_tematik",
              },
              {
                Header: "kd_urutdas",
                accessor: "kd_urutdas",
              },
              {
                Header: "kel_m",
                accessor: "kel_m",
              },
              {
                Header: "Provence Code",
                accessor: "kode_prov",
              },
              {
                Header: "Area ha",
                accessor: "luas_ha",
              },
              {
                Header: "Area Repeat",
                accessor: "luas_ulang",
              },
              {
                Header: "Watershed Name",
                accessor: "nama_das",
              },
              {
                Header: "No Sort",
                accessor: "no_urut",
              },
              {
                Header: "Object Id",
                accessor: "objectid",
              },
              {
                Header: "Object Id 2",
                accessor: "objectid_2",
              },
              {
                Header: "Priority",
                accessor: "prioritas_",
              },
              {
                Header: "St Area Sh",
                accessor: "st_area_sh",
              },
              {
                Header: "St Length1",
                accessor: "st_length1",
              },
              {
                Header: "St length",
                accessor: "st_length_",
              },
              {
                Header: "Wil Work",
                accessor: "wil_kerja",
              },
            ]}
          />
          <LineLayer
            id="indoBasins-outlines"
            opacity={global.indoWatershedsOpacity}
            // @ts-ignore
            tileSet={settings.tileSetURLs.IndoWatersheds}
            sourceLayer={"indonesia"}
            lineColor={`${adjustHexBrightness("#770737", global.indoWatershedsBrightness)}`}
            width={2}
          />
        </>
      )}
      {global.AAV4RiverineFloodRisk && (
        <GlobalMapWithReport
          id="aAAV4RiverineFloodRisk"
          opacity={global.AAV4RiverineFloodRiskOpacity}
          beforeID={beforeID}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.AAV4}
          action={global.action}
          sourceLayer={"aqueduct4"}
          reportRoute={"/AqueductWaterRiskAtlasV4"}
          sourceLabel={"Aqueduct Water Risk Atlas v4.0"}
          sourceLink={"https://www.wri.org/publication/aqueduct-30"}
          tableColumns={[
            {
              Header: "aq30_id",
              accessor: "aq30_id",
            },
            {
              Header: "aqid",
              accessor: "aqid",
            },
            {
              Header: "area_km2",
              accessor: "area_km2",
            },
            {
              Header: "bwd_cat",
              accessor: "bwd_cat",
            },
            {
              Header: "bwd_label",
              accessor: "bwd_label",
            },
            {
              Header: "bwd_raw",
              accessor: "bwd_raw",
            },
            {
              Header: "bwd_score",
              accessor: "bwd_score",
            },
            {
              Header: "bws_cat",
              accessor: "bws_cat",
            },
            {
              Header: "bws_label",
              accessor: "bws_label",
            },
            {
              Header: "bws_raw",
              accessor: "bws_raw",
            },
            {
              Header: "bws_score",
              accessor: "bws_score",
            },
            {
              Header: "cep_cat",
              accessor: "cep_cat",
            },
            {
              Header: "cep_label",
              accessor: "cep_label",
            },
            {
              Header: "cep_raw",
              accessor: "cep_raw",
            },
            {
              Header: "cep_score",
              accessor: "cep_score",
            },
            {
              Header: "cfr_cat",
              accessor: "cfr_cat",
            },
            {
              Header: "cfr_label",
              accessor: "cfr_label",
            },
            {
              Header: "cfr_raw",
              accessor: "cfr_raw",
            },
            {
              Header: "cfr_score",
              accessor: "cfr_score",
            },
            {
              Header: "drr_cat",
              accessor: "drr_cat",
            },
            {
              Header: "drr_label",
              accessor: "drr_label",
            },
            {
              Header: "drr_raw",
              accessor: "drr_raw",
            },
            {
              Header: "drr_score",
              accessor: "drr_score",
            },
            {
              Header: "gid_0",
              accessor: "gid_0",
            },
            {
              Header: "gid_1",
              accessor: "gid_1",
            },
            {
              Header: "gtd_cat",
              accessor: "gtd_cat",
            },
            {
              Header: "gtd_label",
              accessor: "gtd_label",
            },
            {
              Header: "gtd_raw",
              accessor: "gtd_raw",
            },
            {
              Header: "gtd_score",
              accessor: "gtd_score",
            },
            {
              Header: "iav_cat",
              accessor: "iav_cat",
            },
            {
              Header: "iav_label",
              accessor: "iav_label",
            },
            {
              Header: "iav_raw",
              accessor: "iav_raw",
            },
            {
              Header: "iav_score",
              accessor: "iav_score",
            },
            {
              Header: "name_0",
              accessor: "name_0",
            },
            {
              Header: "name_1",
              accessor: "name_1",
            },
            {
              Header: "pfaf_id",
              accessor: "pfaf_id",
            },
            {
              Header: "rfr_cat",
              accessor: "rfr_cat",
            },
            {
              Header: "rfr_label",
              accessor: "rfr_label",
            },
            {
              Header: "rfr_raw",
              accessor: "rfr_raw",
            },
            {
              Header: "rfr_score",
              accessor: "rfr_score",
            },
            {
              Header: "rri_cat",
              accessor: "rri_cat",
            },
            {
              Header: "rri_label",
              accessor: "rri_label",
            },
            {
              Header: "rri_score",
              accessor: "rri_score",
            },
            {
              Header: "sev_cat",
              accessor: "sev_cat",
            },
            {
              Header: "sev_label",
              accessor: "sev_label",
            },
            {
              Header: "sev_raw",
              accessor: "sev_raw",
            },
            {
              Header: "sev_score",
              accessor: "sev_score",
            },
            {
              Header: "string_id",
              accessor: "string_id",
            },
            {
              Header: "ucw_cat",
              accessor: "ucw_cat",
            },
            {
              Header: "ucw_label",
              accessor: "ucw_label",
            },
            {
              Header: "ucw_raw",
              accessor: "ucw_raw",
            },
            {
              Header: "ucw_score",
              accessor: "ucw_score",
            },
            {
              Header: "udw_cat",
              accessor: "udw_cat",
            },
            {
              Header: "udw_label",
              accessor: "udw_label",
            },
            {
              Header: "udw_raw",
              accessor: "udw_raw",
            },
            {
              Header: "udw_score",
              accessor: "udw_score",
            },
            {
              Header: "usa_cat",
              accessor: "usa_cat",
            },
            {
              Header: "usa_label",
              accessor: "usa_label",
            },
            {
              Header: "usa_raw",
              accessor: "usa_raw",
            },
            {
              Header: "usa_score",
              accessor: "usa_score",
            },
            {
              Header: "w_awr_agr_qal_cat",
              accessor: "w_awr_agr_qal_cat",
            },
            {
              Header: "w_awr_agr_qal_label",
              accessor: "w_awr_agr_qal_label",
            },
            {
              Header: "w_awr_agr_qal_raw",
              accessor: "w_awr_agr_qal_raw",
            },
            {
              Header: "w_awr_agr_qal_score",
              accessor: "w_awr_agr_qal_score",
            },
            {
              Header: "w_awr_agr_qal_weight_fraction",
              accessor: "w_awr_agr_qal_weight_fraction",
            },
            {
              Header: "w_awr_agr_qan_cat",
              accessor: "w_awr_agr_qan_cat",
            },
            {
              Header: "w_awr_agr_qan_label",
              accessor: "w_awr_agr_qan_label",
            },
            {
              Header: "w_awr_agr_qan_raw",
              accessor: "w_awr_agr_qan_raw",
            },
            {
              Header: "w_awr_agr_qan_score",
              accessor: "w_awr_agr_qan_score",
            },
            {
              Header: "w_awr_agr_qan_weight_fraction",
              accessor: "w_awr_agr_qan_weight_fraction",
            },
            {
              Header: "w_awr_agr_rrr_cat",
              accessor: "w_awr_agr_rrr_cat",
            },
            {
              Header: "w_awr_agr_rrr_label",
              accessor: "w_awr_agr_rrr_label",
            },
            {
              Header: "w_awr_agr_rrr_raw",
              accessor: "w_awr_agr_rrr_raw",
            },
            {
              Header: "w_awr_agr_rrr_score",
              accessor: "w_awr_agr_rrr_score",
            },
            {
              Header: "w_awr_agr_rrr_weight_fraction",
              accessor: "w_awr_agr_rrr_weight_fraction",
            },
            {
              Header: "w_awr_agr_tot_cat",
              accessor: "w_awr_agr_tot_cat",
            },
            {
              Header: "w_awr_agr_tot_label",
              accessor: "w_awr_agr_tot_label",
            },
            {
              Header: "w_awr_agr_tot_raw",
              accessor: "w_awr_agr_tot_raw",
            },
            {
              Header: "w_awr_agr_tot_score",
              accessor: "w_awr_agr_tot_score",
            },
            {
              Header: "w_awr_agr_tot_weight_fraction",
              accessor: "w_awr_agr_tot_weight_fraction",
            },
            {
              Header: "w_awr_che_qal_cat",
              accessor: "w_awr_che_qal_cat",
            },
            {
              Header: "w_awr_che_qal_label",
              accessor: "w_awr_che_qal_label",
            },
            {
              Header: "w_awr_che_qal_raw",
              accessor: "w_awr_che_qal_raw",
            },
            {
              Header: "w_awr_che_qal_score",
              accessor: "w_awr_che_qal_score",
            },
            {
              Header: "w_awr_che_qal_weight_fraction",
              accessor: "w_awr_che_qal_weight_fraction",
            },
            {
              Header: "w_awr_che_qan_cat",
              accessor: "w_awr_che_qan_cat",
            },
            {
              Header: "w_awr_che_qan_label",
              accessor: "w_awr_che_qan_label",
            },
            {
              Header: "w_awr_che_qan_raw",
              accessor: "w_awr_che_qan_raw",
            },
            {
              Header: "w_awr_che_qan_score",
              accessor: "w_awr_che_qan_score",
            },
            {
              Header: "w_awr_che_qan_weight_fraction",
              accessor: "w_awr_che_qan_weight_fraction",
            },
            {
              Header: "w_awr_che_rrr_cat",
              accessor: "w_awr_che_rrr_cat",
            },
            {
              Header: "w_awr_che_rrr_label",
              accessor: "w_awr_che_rrr_label",
            },
            {
              Header: "w_awr_che_rrr_raw",
              accessor: "w_awr_che_rrr_raw",
            },
            {
              Header: "w_awr_che_rrr_score",
              accessor: "w_awr_che_rrr_score",
            },
            {
              Header: "w_awr_che_rrr_weight_fraction",
              accessor: "w_awr_che_rrr_weight_fraction",
            },
            {
              Header: "w_awr_che_tot_cat",
              accessor: "w_awr_che_tot_cat",
            },
            {
              Header: "w_awr_che_tot_label",
              accessor: "w_awr_che_tot_label",
            },
            {
              Header: "w_awr_che_tot_raw",
              accessor: "w_awr_che_tot_raw",
            },
            {
              Header: "w_awr_che_tot_score",
              accessor: "w_awr_che_tot_score",
            },
            {
              Header: "w_awr_che_tot_weight_fraction",
              accessor: "w_awr_che_tot_weight_fraction",
            },
            {
              Header: "w_awr_con_qal_cat",
              accessor: "w_awr_con_qal_cat",
            },
            {
              Header: "w_awr_con_qal_label",
              accessor: "w_awr_con_qal_label",
            },
            {
              Header: "w_awr_con_qal_score",
              accessor: "w_awr_con_qal_score",
            },
            {
              Header: "w_awr_con_qal_weight_fraction",
              accessor: "w_awr_con_qal_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_cat",
              accessor: "w_awr_con_qan_cat",
            },
            {
              Header: "w_awr_con_qan_label",
              accessor: "w_awr_con_qan_label",
            },
            {
              Header: "w_awr_con_qan_raw",
              accessor: "w_awr_con_qan_raw",
            },
            {
              Header: "w_awr_con_qan_weight_fraction",
              accessor: "w_awr_con_qan_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_score",
              accessor: "w_awr_con_qan_score",
            },
            {
              Header: "w_awr_con_rrr_cat",
              accessor: "w_awr_con_rrr_cat",
            },
            {
              Header: "w_awr_con_rrr_label",
              accessor: "w_awr_con_rrr_label",
            },
            {
              Header: "w_awr_con_rrr_raw",
              accessor: "w_awr_con_rrr_raw",
            },
            {
              Header: "w_awr_con_rrr_score",
              accessor: "w_awr_con_rrr_score",
            },
            {
              Header: "w_awr_con_rrr_weight_fraction",
              accessor: "w_awr_con_rrr_weight_fraction",
            },
            {
              Header: "w_awr_con_tot_cat",
              accessor: "w_awr_con_tot_cat",
            },
            {
              Header: "w_awr_con_tot_label",
              accessor: "w_awr_con_tot_label",
            },
            {
              Header: "w_awr_con_tot_raw",
              accessor: "w_awr_con_tot_raw",
            },
            {
              Header: "w_awr_con_tot_score",
              accessor: "w_awr_con_tot_score",
            },
            {
              Header: "w_awr_con_tot_weight_fraction",
              accessor: "w_awr_con_tot_weight_fraction",
            },
            {
              Header: "w_awr_def_qal_cat",
              accessor: "w_awr_def_qal_cat",
            },
            {
              Header: "w_awr_def_qal_label",
              accessor: "w_awr_def_qal_label",
            },
            {
              Header: "w_awr_def_qal_raw",
              accessor: "w_awr_def_qal_raw",
            },
            {
              Header: "w_awr_def_qal_score",
              accessor: "w_awr_def_qal_score",
            },
            {
              Header: "w_awr_def_qal_weight_fraction",
              accessor: "w_awr_def_qal_weight_fraction",
            },
            {
              Header: "w_awr_def_qan_cat",
              accessor: "w_awr_def_qan_cat",
            },
            {
              Header: "w_awr_def_qan_label",
              accessor: "w_awr_def_qan_label",
            },
            {
              Header: "w_awr_def_qan_raw",
              accessor: "w_awr_def_qan_raw",
            },
            {
              Header: "w_awr_def_qan_score",
              accessor: "w_awr_def_qan_score",
            },
            {
              Header: "w_awr_def_qan_weight_fraction",
              accessor: "w_awr_def_qan_weight_fraction",
            },
            {
              Header: "w_awr_def_rrr_cat",
              accessor: "w_awr_def_rrr_cat",
            },
            {
              Header: "w_awr_def_rrr_label",
              accessor: "w_awr_def_rrr_label",
            },
            {
              Header: "w_awr_def_rrr_raw",
              accessor: "w_awr_def_rrr_raw",
            },
            {
              Header: "w_awr_def_rrr_score",
              accessor: "w_awr_def_rrr_score",
            },
            {
              Header: "w_awr_def_rrr_weight_fraction",
              accessor: "w_awr_def_rrr_weight_fraction",
            },
            {
              Header: "w_awr_def_tot_cat",
              accessor: "w_awr_def_tot_cat",
            },
            {
              Header: "w_awr_def_tot_label",
              accessor: "w_awr_def_tot_label",
            },
            {
              Header: "w_awr_def_tot_raw",
              accessor: "w_awr_def_tot_raw",
            },
            {
              Header: "w_awr_def_tot_score",
              accessor: "w_awr_def_tot_score",
            },
            {
              Header: "w_awr_def_tot_weight_fraction",
              accessor: "w_awr_def_tot_weight_fraction",
            },
            {
              Header: "w_awr_elp_qal_cat",
              accessor: "w_awr_elp_qal_cat",
            },
            {
              Header: "w_awr_elp_qal_label",
              accessor: "w_awr_elp_qal_label",
            },
            {
              Header: "w_awr_elp_qal_raw",
              accessor: "w_awr_elp_qal_raw",
            },
            {
              Header: "w_awr_elp_qal_score",
              accessor: "w_awr_elp_qal_score",
            },
            {
              Header: "w_awr_elp_qal_weight_fraction",
              accessor: "w_awr_elp_qal_weight_fraction",
            },
            {
              Header: "w_awr_elp_qan_cat",
              accessor: "w_awr_elp_qan_cat",
            },
            {
              Header: "w_awr_elp_qan_label",
              accessor: "w_awr_elp_qan_label",
            },
            {
              Header: "w_awr_elp_qan_raw",
              accessor: "w_awr_elp_qan_raw",
            },
            {
              Header: "w_awr_elp_qan_score",
              accessor: "w_awr_elp_qan_score",
            },
            {
              Header: "w_awr_elp_qan_weight_fraction",
              accessor: "w_awr_elp_qan_weight_fraction",
            },
            {
              Header: "w_awr_elp_rrr_cat",
              accessor: "w_awr_elp_rrr_cat",
            },
            {
              Header: "w_awr_elp_rrr_label",
              accessor: "w_awr_elp_rrr_label",
            },
            {
              Header: "w_awr_elp_rrr_raw",
              accessor: "w_awr_elp_rrr_raw",
            },
            {
              Header: "w_awr_elp_rrr_score",
              accessor: "w_awr_elp_rrr_score",
            },
            {
              Header: "w_awr_elp_rrr_weight_fraction",
              accessor: "w_awr_elp_rrr_weight_fraction",
            },
            {
              Header: "w_awr_elp_tot_cat",
              accessor: "w_awr_elp_tot_cat",
            },
            {
              Header: "w_awr_elp_tot_label",
              accessor: "w_awr_elp_tot_label",
            },
            {
              Header: "w_awr_elp_tot_raw",
              accessor: "w_awr_elp_tot_raw",
            },
            {
              Header: "w_awr_elp_tot_score",
              accessor: "w_awr_elp_tot_score",
            },
            {
              Header: "w_awr_elp_tot_weight_fraction",
              accessor: "w_awr_elp_tot_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qal_cat",
              accessor: "w_awr_fnb_qal_cat",
            },
            {
              Header: "w_awr_fnb_qal_label",
              accessor: "w_awr_fnb_qal_label",
            },
            {
              Header: "w_awr_fnb_qal_raw",
              accessor: "w_awr_fnb_qal_raw",
            },
            {
              Header: "w_awr_fnb_qal_score",
              accessor: "w_awr_fnb_qal_score",
            },
            {
              Header: "w_awr_fnb_qal_weight_fraction",
              accessor: "w_awr_fnb_qal_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qan_cat",
              accessor: "w_awr_fnb_qan_cat",
            },
            {
              Header: "w_awr_fnb_qan_label",
              accessor: "w_awr_fnb_qan_label",
            },
            {
              Header: "w_awr_fnb_qan_raw",
              accessor: "w_awr_fnb_qan_raw",
            },
            {
              Header: "w_awr_fnb_qan_score",
              accessor: "w_awr_fnb_qan_score",
            },
            {
              Header: "w_awr_fnb_qan_weight_fraction",
              accessor: "w_awr_fnb_qan_weight_fraction",
            },
            {
              Header: "w_awr_fnb_rrr_cat",
              accessor: "w_awr_fnb_rrr_cat",
            },
            {
              Header: "w_awr_fnb_rrr_label",
              accessor: "w_awr_fnb_rrr_label",
            },
            {
              Header: "w_awr_fnb_rrr_raw",
              accessor: "w_awr_fnb_rrr_raw",
            },
            {
              Header: "w_awr_fnb_rrr_score",
              accessor: "w_awr_fnb_rrr_score",
            },
            {
              Header: "w_awr_fnb_rrr_weight_fraction",
              accessor: "w_awr_fnb_rrr_weight_fraction",
            },
            {
              Header: "w_awr_fnb_tot_cat",
              accessor: "w_awr_fnb_tot_cat",
            },
            {
              Header: "w_awr_fnb_tot_label",
              accessor: "w_awr_fnb_tot_label",
            },
            {
              Header: "w_awr_fnb_tot_raw",
              accessor: "w_awr_fnb_tot_raw",
            },
            {
              Header: "w_awr_fnb_tot_score",
              accessor: "w_awr_fnb_tot_score",
            },
            {
              Header: "w_awr_fnb_tot_weight_fraction",
              accessor: "w_awr_fnb_tot_weight_fraction",
            },
            {
              Header: "w_awr_min_qal_cat",
              accessor: "w_awr_min_qal_cat",
            },
            {
              Header: "w_awr_min_qal_label",
              accessor: "w_awr_min_qal_label",
            },
            {
              Header: "w_awr_min_qal_raw",
              accessor: "w_awr_min_qal_raw",
            },
            {
              Header: "w_awr_min_qal_score",
              accessor: "w_awr_min_qal_score",
            },
            {
              Header: "w_awr_min_qal_weight_fraction",
              accessor: "w_awr_min_qal_weight_fraction",
            },
            {
              Header: "w_awr_min_qan_cat",
              accessor: "w_awr_min_qan_cat",
            },
            {
              Header: "w_awr_min_qan_label",
              accessor: "w_awr_min_qan_label",
            },
            {
              Header: "w_awr_min_qan_raw",
              accessor: "w_awr_min_qan_raw",
            },
            {
              Header: "w_awr_min_qan_score",
              accessor: "w_awr_min_qan_score",
            },
            {
              Header: "w_awr_min_qan_weight_fraction",
              accessor: "w_awr_min_qan_weight_fraction",
            },
            {
              Header: "w_awr_min_rrr_cat",
              accessor: "w_awr_min_rrr_cat",
            },
            {
              Header: "w_awr_min_rrr_label",
              accessor: "w_awr_min_rrr_label",
            },
            {
              Header: "w_awr_min_rrr_raw",
              accessor: "w_awr_min_rrr_raw",
            },
            {
              Header: "w_awr_min_rrr_score",
              accessor: "w_awr_min_rrr_score",
            },
            {
              Header: "w_awr_min_rrr_weight_fraction",
              accessor: "w_awr_min_rrr_weight_fraction",
            },
            {
              Header: "w_awr_min_tot_cat",
              accessor: "w_awr_min_tot_cat",
            },
            {
              Header: "w_awr_min_tot_label",
              accessor: "w_awr_min_tot_label",
            },
            {
              Header: "w_awr_min_tot_raw",
              accessor: "w_awr_min_tot_raw",
            },
            {
              Header: "w_awr_min_tot_score",
              accessor: "w_awr_min_tot_score",
            },
            {
              Header: "w_awr_min_tot_weight_fraction",
              accessor: "w_awr_min_tot_weight_fraction",
            },
            {
              Header: "w_awr_ong_qal_cat",
              accessor: "w_awr_ong_qal_cat",
            },
            {
              Header: "w_awr_ong_qal_label",
              accessor: "w_awr_ong_qal_label",
            },
            {
              Header: "w_awr_ong_qal_raw",
              accessor: "w_awr_ong_qal_raw",
            },
            {
              Header: "w_awr_ong_qal_score",
              accessor: "w_awr_ong_qal_score",
            },
            {
              Header: "w_awr_ong_qan_cat",
              accessor: "w_awr_ong_qan_cat",
            },
            {
              Header: "w_awr_ong_qan_label",
              accessor: "w_awr_ong_qan_label",
            },
            {
              Header: "w_awr_ong_qan_raw",
              accessor: "w_awr_ong_qan_raw",
            },
            {
              Header: "w_awr_ong_qan_score",
              accessor: "w_awr_ong_qan_score",
            },
            {
              Header: "w_awr_ong_qan_weight_fraction",
              accessor: "w_awr_ong_qan_weight_fraction",
            },
            {
              Header: "w_awr_ong_rrr_cat",
              accessor: "w_awr_ong_rrr_cat",
            },
            {
              Header: "w_awr_ong_rrr_label",
              accessor: "w_awr_ong_rrr_label",
            },
            {
              Header: "w_awr_ong_rrr_raw",
              accessor: "w_awr_ong_rrr_raw",
            },
            {
              Header: "w_awr_ong_rrr_score",
              accessor: "w_awr_ong_rrr_score",
            },
            {
              Header: "w_awr_ong_rrr_weight_fraction",
              accessor: "w_awr_ong_rrr_weight_fraction",
            },
            {
              Header: "w_awr_ong_tot_cat",
              accessor: "w_awr_ong_tot_cat",
            },
            {
              Header: "w_awr_ong_tot_label",
              accessor: "w_awr_ong_tot_label",
            },
            {
              Header: "w_awr_ong_tot_raw",
              accessor: "w_awr_ong_tot_raw",
            },
            {
              Header: "w_awr_ong_tot_score",
              accessor: "w_awr_ong_tot_score",
            },
            {
              Header: "w_awr_ong_tot_weight_fraction",
              accessor: "w_awr_ong_tot_weight_fraction",
            },
            {
              Header: "w_awr_smc_qal_cat",
              accessor: "w_awr_smc_qal_cat",
            },
            {
              Header: "w_awr_smc_qal_label",
              accessor: "w_awr_smc_qal_label",
            },
            {
              Header: "w_awr_smc_qal_raw",
              accessor: "w_awr_smc_qal_raw",
            },
            {
              Header: "w_awr_smc_qal_score",
              accessor: "w_awr_smc_qal_score",
            },
            {
              Header: "w_awr_smc_qal_weight_fraction",
              accessor: "w_awr_smc_qal_weight_fraction",
            },
            {
              Header: "w_awr_smc_qan_cat",
              accessor: "w_awr_smc_qan_cat",
            },
            {
              Header: "w_awr_smc_qan_label",
              accessor: "w_awr_smc_qan_label",
            },
            {
              Header: "w_awr_smc_qan_raw",
              accessor: "w_awr_smc_qan_raw",
            },
            {
              Header: "w_awr_smc_qan_score",
              accessor: "w_awr_smc_qan_score",
            },
            {
              Header: "w_awr_smc_qan_weight_fraction",
              accessor: "w_awr_smc_qan_weight_fraction",
            },
            {
              Header: "w_awr_smc_rrr_cat",
              accessor: "w_awr_smc_rrr_cat",
            },
            {
              Header: "w_awr_smc_rrr_label",
              accessor: "w_awr_smc_rrr_label",
            },
            {
              Header: "w_awr_smc_rrr_raw",
              accessor: "w_awr_smc_rrr_raw",
            },
            {
              Header: "w_awr_smc_rrr_score",
              accessor: "w_awr_smc_rrr_score",
            },
            {
              Header: "w_awr_smc_rrr_weight_fraction",
              accessor: "w_awr_smc_rrr_weight_fraction",
            },
            {
              Header: "w_awr_smc_tot_cat",
              accessor: "w_awr_smc_tot_cat",
            },
            {
              Header: "w_awr_smc_tot_label",
              accessor: "w_awr_smc_tot_label",
            },
            {
              Header: "w_awr_smc_tot_raw",
              accessor: "w_awr_smc_tot_raw",
            },
            {
              Header: "w_awr_smc_tot_score",
              accessor: "w_awr_smc_tot_score",
            },
            {
              Header: "w_awr_smc_tot_weight_fraction",
              accessor: "w_awr_smc_tot_weight_fraction",
            },
            {
              Header: "w_awr_tex_qal_cat",
              accessor: "w_awr_tex_qal_cat",
            },
            {
              Header: "w_awr_tex_qal_label",
              accessor: "w_awr_tex_qal_label",
            },
            {
              Header: "w_awr_tex_qal_raw",
              accessor: "w_awr_tex_qal_raw",
            },
            {
              Header: "w_awr_tex_qal_score",
              accessor: "w_awr_tex_qal_score",
            },
            {
              Header: "w_awr_tex_qal_weight_fraction",
              accessor: "w_awr_tex_qal_weight_fraction",
            },
            {
              Header: "w_awr_tex_qan_cat",
              accessor: "w_awr_tex_qan_cat",
            },
            {
              Header: "w_awr_tex_qan_label",
              accessor: "w_awr_tex_qan_label",
            },
            {
              Header: "w_awr_tex_qan_raw",
              accessor: "w_awr_tex_qan_raw",
            },
            {
              Header: "w_awr_tex_qan_weight_fraction",
              accessor: "w_awr_tex_qan_weight_fraction",
            },
            {
              Header: "w_awr_tex_rrr_cat",
              accessor: "w_awr_tex_rrr_cat",
            },
            {
              Header: "w_awr_tex_rrr_label",
              accessor: "w_awr_tex_rrr_label",
            },
            {
              Header: "w_awr_tex_rrr_raw",
              accessor: "w_awr_tex_rrr_raw",
            },
            {
              Header: "w_awr_tex_rrr_score",
              accessor: "w_awr_tex_rrr_score",
            },
            {
              Header: "w_awr_tex_rrr_weight_fraction",
              accessor: "w_awr_tex_rrr_weight_fraction",
            },
            {
              Header: "w_awr_tex_tot_cat",
              accessor: "w_awr_tex_tot_cat",
            },
            {
              Header: "w_awr_tex_tot_label",
              accessor: "w_awr_tex_tot_label",
            },
            {
              Header: "w_awr_tex_tot_raw",
              accessor: "w_awr_tex_tot_raw",
            },
            {
              Header: "w_awr_tex_tot_score",
              accessor: "w_awr_tex_tot_score",
            },
            {
              Header: "w_awr_tex_tot_weight_fraction",
              accessor: "w_awr_tex_tot_weight_fraction",
            },
          ]}
          fillColor={[
            "match",
            ["get", "rfr_label"],
            ["Extremely High (more than 1 in 100)"],
            `${adjustHexBrightness("#D61F1F", global.AAV4RiverineFloodRiskBrightness)}`,
            ["High (6 in 1,000 to 1 in 100)"],
            `${adjustHexBrightness("#D46D0C", global.AAV4RiverineFloodRiskBrightness)}`,
            ["Medium - High (2 in 1,000 to 6 in 1,000)"],
            `${adjustHexBrightness("#FFD301", global.AAV4RiverineFloodRiskBrightness)}`,
            ["Low - Medium (1 in 1,000 to 2 in 1,000)"],
            `${adjustHexBrightness("#7BB662", global.AAV4RiverineFloodRiskBrightness)}`,
            ["Low (0 to 1 in 1,000)"],
            `${adjustHexBrightness("#006B3D", global.AAV4RiverineFloodRiskBrightness)}`,
            "hsla(0, 0%, 0%, 0)",
          ]}
        />
      )}

      {global.AAV4CoastalFloodRisk && (
        <GlobalMapWithReport
          id="AAV4CoastalFloodRisk"
          opacity={global.AAV4CoastalFloodRiskOpacity}
          beforeID={beforeID}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.AAV4}
          sourceLayer={"aqueduct4"}
          action={global.action}
          reportRoute={"/AqueductWaterRiskAtlasV4"}
          sourceLabel={"Aqueduct Water Risk Atlas v4.0"}
          sourceLink={"https://www.wri.org/publication/aqueduct-30"}
          tableColumns={[
            {
              Header: "aq30_id",
              accessor: "aq30_id",
            },
            {
              Header: "aqid",
              accessor: "aqid",
            },
            {
              Header: "area_km2",
              accessor: "area_km2",
            },
            {
              Header: "bwd_cat",
              accessor: "bwd_cat",
            },
            {
              Header: "bwd_label",
              accessor: "bwd_label",
            },
            {
              Header: "bwd_raw",
              accessor: "bwd_raw",
            },
            {
              Header: "bwd_score",
              accessor: "bwd_score",
            },
            {
              Header: "bws_cat",
              accessor: "bws_cat",
            },
            {
              Header: "bws_label",
              accessor: "bws_label",
            },
            {
              Header: "bws_raw",
              accessor: "bws_raw",
            },
            {
              Header: "bws_score",
              accessor: "bws_score",
            },
            {
              Header: "cep_cat",
              accessor: "cep_cat",
            },
            {
              Header: "cep_label",
              accessor: "cep_label",
            },
            {
              Header: "cep_raw",
              accessor: "cep_raw",
            },
            {
              Header: "cep_score",
              accessor: "cep_score",
            },
            {
              Header: "cfr_cat",
              accessor: "cfr_cat",
            },
            {
              Header: "cfr_label",
              accessor: "cfr_label",
            },
            {
              Header: "cfr_raw",
              accessor: "cfr_raw",
            },
            {
              Header: "cfr_score",
              accessor: "cfr_score",
            },
            {
              Header: "drr_cat",
              accessor: "drr_cat",
            },
            {
              Header: "drr_label",
              accessor: "drr_label",
            },
            {
              Header: "drr_raw",
              accessor: "drr_raw",
            },
            {
              Header: "drr_score",
              accessor: "drr_score",
            },
            {
              Header: "gid_0",
              accessor: "gid_0",
            },
            {
              Header: "gid_1",
              accessor: "gid_1",
            },
            {
              Header: "gtd_cat",
              accessor: "gtd_cat",
            },
            {
              Header: "gtd_label",
              accessor: "gtd_label",
            },
            {
              Header: "gtd_raw",
              accessor: "gtd_raw",
            },
            {
              Header: "gtd_score",
              accessor: "gtd_score",
            },
            {
              Header: "iav_cat",
              accessor: "iav_cat",
            },
            {
              Header: "iav_label",
              accessor: "iav_label",
            },
            {
              Header: "iav_raw",
              accessor: "iav_raw",
            },
            {
              Header: "iav_score",
              accessor: "iav_score",
            },
            {
              Header: "name_0",
              accessor: "name_0",
            },
            {
              Header: "name_1",
              accessor: "name_1",
            },
            {
              Header: "pfaf_id",
              accessor: "pfaf_id",
            },
            {
              Header: "rfr_cat",
              accessor: "rfr_cat",
            },
            {
              Header: "rfr_label",
              accessor: "rfr_label",
            },
            {
              Header: "rfr_raw",
              accessor: "rfr_raw",
            },
            {
              Header: "rfr_score",
              accessor: "rfr_score",
            },
            {
              Header: "rri_cat",
              accessor: "rri_cat",
            },
            {
              Header: "rri_label",
              accessor: "rri_label",
            },
            {
              Header: "rri_score",
              accessor: "rri_score",
            },
            {
              Header: "sev_cat",
              accessor: "sev_cat",
            },
            {
              Header: "sev_label",
              accessor: "sev_label",
            },
            {
              Header: "sev_raw",
              accessor: "sev_raw",
            },
            {
              Header: "sev_score",
              accessor: "sev_score",
            },
            {
              Header: "string_id",
              accessor: "string_id",
            },
            {
              Header: "ucw_cat",
              accessor: "ucw_cat",
            },
            {
              Header: "ucw_label",
              accessor: "ucw_label",
            },
            {
              Header: "ucw_raw",
              accessor: "ucw_raw",
            },
            {
              Header: "ucw_score",
              accessor: "ucw_score",
            },
            {
              Header: "udw_cat",
              accessor: "udw_cat",
            },
            {
              Header: "udw_label",
              accessor: "udw_label",
            },
            {
              Header: "udw_raw",
              accessor: "udw_raw",
            },
            {
              Header: "udw_score",
              accessor: "udw_score",
            },
            {
              Header: "usa_cat",
              accessor: "usa_cat",
            },
            {
              Header: "usa_label",
              accessor: "usa_label",
            },
            {
              Header: "usa_raw",
              accessor: "usa_raw",
            },
            {
              Header: "usa_score",
              accessor: "usa_score",
            },
            {
              Header: "w_awr_agr_qal_cat",
              accessor: "w_awr_agr_qal_cat",
            },
            {
              Header: "w_awr_agr_qal_label",
              accessor: "w_awr_agr_qal_label",
            },
            {
              Header: "w_awr_agr_qal_raw",
              accessor: "w_awr_agr_qal_raw",
            },
            {
              Header: "w_awr_agr_qal_score",
              accessor: "w_awr_agr_qal_score",
            },
            {
              Header: "w_awr_agr_qal_weight_fraction",
              accessor: "w_awr_agr_qal_weight_fraction",
            },
            {
              Header: "w_awr_agr_qan_cat",
              accessor: "w_awr_agr_qan_cat",
            },
            {
              Header: "w_awr_agr_qan_label",
              accessor: "w_awr_agr_qan_label",
            },
            {
              Header: "w_awr_agr_qan_raw",
              accessor: "w_awr_agr_qan_raw",
            },
            {
              Header: "w_awr_agr_qan_score",
              accessor: "w_awr_agr_qan_score",
            },
            {
              Header: "w_awr_agr_qan_weight_fraction",
              accessor: "w_awr_agr_qan_weight_fraction",
            },
            {
              Header: "w_awr_agr_rrr_cat",
              accessor: "w_awr_agr_rrr_cat",
            },
            {
              Header: "w_awr_agr_rrr_label",
              accessor: "w_awr_agr_rrr_label",
            },
            {
              Header: "w_awr_agr_rrr_raw",
              accessor: "w_awr_agr_rrr_raw",
            },
            {
              Header: "w_awr_agr_rrr_score",
              accessor: "w_awr_agr_rrr_score",
            },
            {
              Header: "w_awr_agr_rrr_weight_fraction",
              accessor: "w_awr_agr_rrr_weight_fraction",
            },
            {
              Header: "w_awr_agr_tot_cat",
              accessor: "w_awr_agr_tot_cat",
            },
            {
              Header: "w_awr_agr_tot_label",
              accessor: "w_awr_agr_tot_label",
            },
            {
              Header: "w_awr_agr_tot_raw",
              accessor: "w_awr_agr_tot_raw",
            },
            {
              Header: "w_awr_agr_tot_score",
              accessor: "w_awr_agr_tot_score",
            },
            {
              Header: "w_awr_agr_tot_weight_fraction",
              accessor: "w_awr_agr_tot_weight_fraction",
            },
            {
              Header: "w_awr_che_qal_cat",
              accessor: "w_awr_che_qal_cat",
            },
            {
              Header: "w_awr_che_qal_label",
              accessor: "w_awr_che_qal_label",
            },
            {
              Header: "w_awr_che_qal_raw",
              accessor: "w_awr_che_qal_raw",
            },
            {
              Header: "w_awr_che_qal_score",
              accessor: "w_awr_che_qal_score",
            },
            {
              Header: "w_awr_che_qal_weight_fraction",
              accessor: "w_awr_che_qal_weight_fraction",
            },
            {
              Header: "w_awr_che_qan_cat",
              accessor: "w_awr_che_qan_cat",
            },
            {
              Header: "w_awr_che_qan_label",
              accessor: "w_awr_che_qan_label",
            },
            {
              Header: "w_awr_che_qan_raw",
              accessor: "w_awr_che_qan_raw",
            },
            {
              Header: "w_awr_che_qan_score",
              accessor: "w_awr_che_qan_score",
            },
            {
              Header: "w_awr_che_qan_weight_fraction",
              accessor: "w_awr_che_qan_weight_fraction",
            },
            {
              Header: "w_awr_che_rrr_cat",
              accessor: "w_awr_che_rrr_cat",
            },
            {
              Header: "w_awr_che_rrr_label",
              accessor: "w_awr_che_rrr_label",
            },
            {
              Header: "w_awr_che_rrr_raw",
              accessor: "w_awr_che_rrr_raw",
            },
            {
              Header: "w_awr_che_rrr_score",
              accessor: "w_awr_che_rrr_score",
            },
            {
              Header: "w_awr_che_rrr_weight_fraction",
              accessor: "w_awr_che_rrr_weight_fraction",
            },
            {
              Header: "w_awr_che_tot_cat",
              accessor: "w_awr_che_tot_cat",
            },
            {
              Header: "w_awr_che_tot_label",
              accessor: "w_awr_che_tot_label",
            },
            {
              Header: "w_awr_che_tot_raw",
              accessor: "w_awr_che_tot_raw",
            },
            {
              Header: "w_awr_che_tot_score",
              accessor: "w_awr_che_tot_score",
            },
            {
              Header: "w_awr_che_tot_weight_fraction",
              accessor: "w_awr_che_tot_weight_fraction",
            },
            {
              Header: "w_awr_con_qal_cat",
              accessor: "w_awr_con_qal_cat",
            },
            {
              Header: "w_awr_con_qal_label",
              accessor: "w_awr_con_qal_label",
            },
            {
              Header: "w_awr_con_qal_score",
              accessor: "w_awr_con_qal_score",
            },
            {
              Header: "w_awr_con_qal_weight_fraction",
              accessor: "w_awr_con_qal_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_cat",
              accessor: "w_awr_con_qan_cat",
            },
            {
              Header: "w_awr_con_qan_label",
              accessor: "w_awr_con_qan_label",
            },
            {
              Header: "w_awr_con_qan_raw",
              accessor: "w_awr_con_qan_raw",
            },
            {
              Header: "w_awr_con_qan_weight_fraction",
              accessor: "w_awr_con_qan_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_score",
              accessor: "w_awr_con_qan_score",
            },
            {
              Header: "w_awr_con_rrr_cat",
              accessor: "w_awr_con_rrr_cat",
            },
            {
              Header: "w_awr_con_rrr_label",
              accessor: "w_awr_con_rrr_label",
            },
            {
              Header: "w_awr_con_rrr_raw",
              accessor: "w_awr_con_rrr_raw",
            },
            {
              Header: "w_awr_con_rrr_score",
              accessor: "w_awr_con_rrr_score",
            },
            {
              Header: "w_awr_con_rrr_weight_fraction",
              accessor: "w_awr_con_rrr_weight_fraction",
            },
            {
              Header: "w_awr_con_tot_cat",
              accessor: "w_awr_con_tot_cat",
            },
            {
              Header: "w_awr_con_tot_label",
              accessor: "w_awr_con_tot_label",
            },
            {
              Header: "w_awr_con_tot_raw",
              accessor: "w_awr_con_tot_raw",
            },
            {
              Header: "w_awr_con_tot_score",
              accessor: "w_awr_con_tot_score",
            },
            {
              Header: "w_awr_con_tot_weight_fraction",
              accessor: "w_awr_con_tot_weight_fraction",
            },
            {
              Header: "w_awr_def_qal_cat",
              accessor: "w_awr_def_qal_cat",
            },
            {
              Header: "w_awr_def_qal_label",
              accessor: "w_awr_def_qal_label",
            },
            {
              Header: "w_awr_def_qal_raw",
              accessor: "w_awr_def_qal_raw",
            },
            {
              Header: "w_awr_def_qal_score",
              accessor: "w_awr_def_qal_score",
            },
            {
              Header: "w_awr_def_qal_weight_fraction",
              accessor: "w_awr_def_qal_weight_fraction",
            },
            {
              Header: "w_awr_def_qan_cat",
              accessor: "w_awr_def_qan_cat",
            },
            {
              Header: "w_awr_def_qan_label",
              accessor: "w_awr_def_qan_label",
            },
            {
              Header: "w_awr_def_qan_raw",
              accessor: "w_awr_def_qan_raw",
            },
            {
              Header: "w_awr_def_qan_score",
              accessor: "w_awr_def_qan_score",
            },
            {
              Header: "w_awr_def_qan_weight_fraction",
              accessor: "w_awr_def_qan_weight_fraction",
            },
            {
              Header: "w_awr_def_rrr_cat",
              accessor: "w_awr_def_rrr_cat",
            },
            {
              Header: "w_awr_def_rrr_label",
              accessor: "w_awr_def_rrr_label",
            },
            {
              Header: "w_awr_def_rrr_raw",
              accessor: "w_awr_def_rrr_raw",
            },
            {
              Header: "w_awr_def_rrr_score",
              accessor: "w_awr_def_rrr_score",
            },
            {
              Header: "w_awr_def_rrr_weight_fraction",
              accessor: "w_awr_def_rrr_weight_fraction",
            },
            {
              Header: "w_awr_def_tot_cat",
              accessor: "w_awr_def_tot_cat",
            },
            {
              Header: "w_awr_def_tot_label",
              accessor: "w_awr_def_tot_label",
            },
            {
              Header: "w_awr_def_tot_raw",
              accessor: "w_awr_def_tot_raw",
            },
            {
              Header: "w_awr_def_tot_score",
              accessor: "w_awr_def_tot_score",
            },
            {
              Header: "w_awr_def_tot_weight_fraction",
              accessor: "w_awr_def_tot_weight_fraction",
            },
            {
              Header: "w_awr_elp_qal_cat",
              accessor: "w_awr_elp_qal_cat",
            },
            {
              Header: "w_awr_elp_qal_label",
              accessor: "w_awr_elp_qal_label",
            },
            {
              Header: "w_awr_elp_qal_raw",
              accessor: "w_awr_elp_qal_raw",
            },
            {
              Header: "w_awr_elp_qal_score",
              accessor: "w_awr_elp_qal_score",
            },
            {
              Header: "w_awr_elp_qal_weight_fraction",
              accessor: "w_awr_elp_qal_weight_fraction",
            },
            {
              Header: "w_awr_elp_qan_cat",
              accessor: "w_awr_elp_qan_cat",
            },
            {
              Header: "w_awr_elp_qan_label",
              accessor: "w_awr_elp_qan_label",
            },
            {
              Header: "w_awr_elp_qan_raw",
              accessor: "w_awr_elp_qan_raw",
            },
            {
              Header: "w_awr_elp_qan_score",
              accessor: "w_awr_elp_qan_score",
            },
            {
              Header: "w_awr_elp_qan_weight_fraction",
              accessor: "w_awr_elp_qan_weight_fraction",
            },
            {
              Header: "w_awr_elp_rrr_cat",
              accessor: "w_awr_elp_rrr_cat",
            },
            {
              Header: "w_awr_elp_rrr_label",
              accessor: "w_awr_elp_rrr_label",
            },
            {
              Header: "w_awr_elp_rrr_raw",
              accessor: "w_awr_elp_rrr_raw",
            },
            {
              Header: "w_awr_elp_rrr_score",
              accessor: "w_awr_elp_rrr_score",
            },
            {
              Header: "w_awr_elp_rrr_weight_fraction",
              accessor: "w_awr_elp_rrr_weight_fraction",
            },
            {
              Header: "w_awr_elp_tot_cat",
              accessor: "w_awr_elp_tot_cat",
            },
            {
              Header: "w_awr_elp_tot_label",
              accessor: "w_awr_elp_tot_label",
            },
            {
              Header: "w_awr_elp_tot_raw",
              accessor: "w_awr_elp_tot_raw",
            },
            {
              Header: "w_awr_elp_tot_score",
              accessor: "w_awr_elp_tot_score",
            },
            {
              Header: "w_awr_elp_tot_weight_fraction",
              accessor: "w_awr_elp_tot_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qal_cat",
              accessor: "w_awr_fnb_qal_cat",
            },
            {
              Header: "w_awr_fnb_qal_label",
              accessor: "w_awr_fnb_qal_label",
            },
            {
              Header: "w_awr_fnb_qal_raw",
              accessor: "w_awr_fnb_qal_raw",
            },
            {
              Header: "w_awr_fnb_qal_score",
              accessor: "w_awr_fnb_qal_score",
            },
            {
              Header: "w_awr_fnb_qal_weight_fraction",
              accessor: "w_awr_fnb_qal_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qan_cat",
              accessor: "w_awr_fnb_qan_cat",
            },
            {
              Header: "w_awr_fnb_qan_label",
              accessor: "w_awr_fnb_qan_label",
            },
            {
              Header: "w_awr_fnb_qan_raw",
              accessor: "w_awr_fnb_qan_raw",
            },
            {
              Header: "w_awr_fnb_qan_score",
              accessor: "w_awr_fnb_qan_score",
            },
            {
              Header: "w_awr_fnb_qan_weight_fraction",
              accessor: "w_awr_fnb_qan_weight_fraction",
            },
            {
              Header: "w_awr_fnb_rrr_cat",
              accessor: "w_awr_fnb_rrr_cat",
            },
            {
              Header: "w_awr_fnb_rrr_label",
              accessor: "w_awr_fnb_rrr_label",
            },
            {
              Header: "w_awr_fnb_rrr_raw",
              accessor: "w_awr_fnb_rrr_raw",
            },
            {
              Header: "w_awr_fnb_rrr_score",
              accessor: "w_awr_fnb_rrr_score",
            },
            {
              Header: "w_awr_fnb_rrr_weight_fraction",
              accessor: "w_awr_fnb_rrr_weight_fraction",
            },
            {
              Header: "w_awr_fnb_tot_cat",
              accessor: "w_awr_fnb_tot_cat",
            },
            {
              Header: "w_awr_fnb_tot_label",
              accessor: "w_awr_fnb_tot_label",
            },
            {
              Header: "w_awr_fnb_tot_raw",
              accessor: "w_awr_fnb_tot_raw",
            },
            {
              Header: "w_awr_fnb_tot_score",
              accessor: "w_awr_fnb_tot_score",
            },
            {
              Header: "w_awr_fnb_tot_weight_fraction",
              accessor: "w_awr_fnb_tot_weight_fraction",
            },
            {
              Header: "w_awr_min_qal_cat",
              accessor: "w_awr_min_qal_cat",
            },
            {
              Header: "w_awr_min_qal_label",
              accessor: "w_awr_min_qal_label",
            },
            {
              Header: "w_awr_min_qal_raw",
              accessor: "w_awr_min_qal_raw",
            },
            {
              Header: "w_awr_min_qal_score",
              accessor: "w_awr_min_qal_score",
            },
            {
              Header: "w_awr_min_qal_weight_fraction",
              accessor: "w_awr_min_qal_weight_fraction",
            },
            {
              Header: "w_awr_min_qan_cat",
              accessor: "w_awr_min_qan_cat",
            },
            {
              Header: "w_awr_min_qan_label",
              accessor: "w_awr_min_qan_label",
            },
            {
              Header: "w_awr_min_qan_raw",
              accessor: "w_awr_min_qan_raw",
            },
            {
              Header: "w_awr_min_qan_score",
              accessor: "w_awr_min_qan_score",
            },
            {
              Header: "w_awr_min_qan_weight_fraction",
              accessor: "w_awr_min_qan_weight_fraction",
            },
            {
              Header: "w_awr_min_rrr_cat",
              accessor: "w_awr_min_rrr_cat",
            },
            {
              Header: "w_awr_min_rrr_label",
              accessor: "w_awr_min_rrr_label",
            },
            {
              Header: "w_awr_min_rrr_raw",
              accessor: "w_awr_min_rrr_raw",
            },
            {
              Header: "w_awr_min_rrr_score",
              accessor: "w_awr_min_rrr_score",
            },
            {
              Header: "w_awr_min_rrr_weight_fraction",
              accessor: "w_awr_min_rrr_weight_fraction",
            },
            {
              Header: "w_awr_min_tot_cat",
              accessor: "w_awr_min_tot_cat",
            },
            {
              Header: "w_awr_min_tot_label",
              accessor: "w_awr_min_tot_label",
            },
            {
              Header: "w_awr_min_tot_raw",
              accessor: "w_awr_min_tot_raw",
            },
            {
              Header: "w_awr_min_tot_score",
              accessor: "w_awr_min_tot_score",
            },
            {
              Header: "w_awr_min_tot_weight_fraction",
              accessor: "w_awr_min_tot_weight_fraction",
            },
            {
              Header: "w_awr_ong_qal_cat",
              accessor: "w_awr_ong_qal_cat",
            },
            {
              Header: "w_awr_ong_qal_label",
              accessor: "w_awr_ong_qal_label",
            },
            {
              Header: "w_awr_ong_qal_raw",
              accessor: "w_awr_ong_qal_raw",
            },
            {
              Header: "w_awr_ong_qal_score",
              accessor: "w_awr_ong_qal_score",
            },
            {
              Header: "w_awr_ong_qan_cat",
              accessor: "w_awr_ong_qan_cat",
            },
            {
              Header: "w_awr_ong_qan_label",
              accessor: "w_awr_ong_qan_label",
            },
            {
              Header: "w_awr_ong_qan_raw",
              accessor: "w_awr_ong_qan_raw",
            },
            {
              Header: "w_awr_ong_qan_score",
              accessor: "w_awr_ong_qan_score",
            },
            {
              Header: "w_awr_ong_qan_weight_fraction",
              accessor: "w_awr_ong_qan_weight_fraction",
            },
            {
              Header: "w_awr_ong_rrr_cat",
              accessor: "w_awr_ong_rrr_cat",
            },
            {
              Header: "w_awr_ong_rrr_label",
              accessor: "w_awr_ong_rrr_label",
            },
            {
              Header: "w_awr_ong_rrr_raw",
              accessor: "w_awr_ong_rrr_raw",
            },
            {
              Header: "w_awr_ong_rrr_score",
              accessor: "w_awr_ong_rrr_score",
            },
            {
              Header: "w_awr_ong_rrr_weight_fraction",
              accessor: "w_awr_ong_rrr_weight_fraction",
            },
            {
              Header: "w_awr_ong_tot_cat",
              accessor: "w_awr_ong_tot_cat",
            },
            {
              Header: "w_awr_ong_tot_label",
              accessor: "w_awr_ong_tot_label",
            },
            {
              Header: "w_awr_ong_tot_raw",
              accessor: "w_awr_ong_tot_raw",
            },
            {
              Header: "w_awr_ong_tot_score",
              accessor: "w_awr_ong_tot_score",
            },
            {
              Header: "w_awr_ong_tot_weight_fraction",
              accessor: "w_awr_ong_tot_weight_fraction",
            },
            {
              Header: "w_awr_smc_qal_cat",
              accessor: "w_awr_smc_qal_cat",
            },
            {
              Header: "w_awr_smc_qal_label",
              accessor: "w_awr_smc_qal_label",
            },
            {
              Header: "w_awr_smc_qal_raw",
              accessor: "w_awr_smc_qal_raw",
            },
            {
              Header: "w_awr_smc_qal_score",
              accessor: "w_awr_smc_qal_score",
            },
            {
              Header: "w_awr_smc_qal_weight_fraction",
              accessor: "w_awr_smc_qal_weight_fraction",
            },
            {
              Header: "w_awr_smc_qan_cat",
              accessor: "w_awr_smc_qan_cat",
            },
            {
              Header: "w_awr_smc_qan_label",
              accessor: "w_awr_smc_qan_label",
            },
            {
              Header: "w_awr_smc_qan_raw",
              accessor: "w_awr_smc_qan_raw",
            },
            {
              Header: "w_awr_smc_qan_score",
              accessor: "w_awr_smc_qan_score",
            },
            {
              Header: "w_awr_smc_qan_weight_fraction",
              accessor: "w_awr_smc_qan_weight_fraction",
            },
            {
              Header: "w_awr_smc_rrr_cat",
              accessor: "w_awr_smc_rrr_cat",
            },
            {
              Header: "w_awr_smc_rrr_label",
              accessor: "w_awr_smc_rrr_label",
            },
            {
              Header: "w_awr_smc_rrr_raw",
              accessor: "w_awr_smc_rrr_raw",
            },
            {
              Header: "w_awr_smc_rrr_score",
              accessor: "w_awr_smc_rrr_score",
            },
            {
              Header: "w_awr_smc_rrr_weight_fraction",
              accessor: "w_awr_smc_rrr_weight_fraction",
            },
            {
              Header: "w_awr_smc_tot_cat",
              accessor: "w_awr_smc_tot_cat",
            },
            {
              Header: "w_awr_smc_tot_label",
              accessor: "w_awr_smc_tot_label",
            },
            {
              Header: "w_awr_smc_tot_raw",
              accessor: "w_awr_smc_tot_raw",
            },
            {
              Header: "w_awr_smc_tot_score",
              accessor: "w_awr_smc_tot_score",
            },
            {
              Header: "w_awr_smc_tot_weight_fraction",
              accessor: "w_awr_smc_tot_weight_fraction",
            },
            {
              Header: "w_awr_tex_qal_cat",
              accessor: "w_awr_tex_qal_cat",
            },
            {
              Header: "w_awr_tex_qal_label",
              accessor: "w_awr_tex_qal_label",
            },
            {
              Header: "w_awr_tex_qal_raw",
              accessor: "w_awr_tex_qal_raw",
            },
            {
              Header: "w_awr_tex_qal_score",
              accessor: "w_awr_tex_qal_score",
            },
            {
              Header: "w_awr_tex_qal_weight_fraction",
              accessor: "w_awr_tex_qal_weight_fraction",
            },
            {
              Header: "w_awr_tex_qan_cat",
              accessor: "w_awr_tex_qan_cat",
            },
            {
              Header: "w_awr_tex_qan_label",
              accessor: "w_awr_tex_qan_label",
            },
            {
              Header: "w_awr_tex_qan_raw",
              accessor: "w_awr_tex_qan_raw",
            },
            {
              Header: "w_awr_tex_qan_weight_fraction",
              accessor: "w_awr_tex_qan_weight_fraction",
            },
            {
              Header: "w_awr_tex_rrr_cat",
              accessor: "w_awr_tex_rrr_cat",
            },
            {
              Header: "w_awr_tex_rrr_label",
              accessor: "w_awr_tex_rrr_label",
            },
            {
              Header: "w_awr_tex_rrr_raw",
              accessor: "w_awr_tex_rrr_raw",
            },
            {
              Header: "w_awr_tex_rrr_score",
              accessor: "w_awr_tex_rrr_score",
            },
            {
              Header: "w_awr_tex_rrr_weight_fraction",
              accessor: "w_awr_tex_rrr_weight_fraction",
            },
            {
              Header: "w_awr_tex_tot_cat",
              accessor: "w_awr_tex_tot_cat",
            },
            {
              Header: "w_awr_tex_tot_label",
              accessor: "w_awr_tex_tot_label",
            },
            {
              Header: "w_awr_tex_tot_raw",
              accessor: "w_awr_tex_tot_raw",
            },
            {
              Header: "w_awr_tex_tot_score",
              accessor: "w_awr_tex_tot_score",
            },
            {
              Header: "w_awr_tex_tot_weight_fraction",
              accessor: "w_awr_tex_tot_weight_fraction",
            },
          ]}
          fillColor={[
            "case",
            [
              "match",
              ["get", "cfr_label"],
              ["Extremely High (more than 2 in 1,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#4C1C24", global.AAV4CoastalFloodRiskBrightness)}`,
            [
              "match",
              ["get", "cfr_label"],
              ["High (3 in 10,000 to 2 in 1,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#782121", global.AAV4CoastalFloodRiskBrightness)}`,
            [
              "match",
              ["get", "bws_label"],
              ["Medium - High (7 in 100,000 to 3 in 10,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#807720", global.AAV4CoastalFloodRiskBrightness)}`,
            [
              "match",
              ["get", "cfr_label"],
              ["Low - Medium (9 in 1,000,000 to 7 in 100,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#69852A", global.AAV4CoastalFloodRiskBrightness)}`,
            [
              "match",
              ["get", "cfr_label"],
              ["Low (0 to 9 in 1,000,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#2C4235", global.AAV4CoastalFloodRiskBrightness)}`,
            "hsla(0, 0%, 0%, 0)",
          ]}
        />
      )}
      {global.AAV4WaterStress && (
        <GlobalMapWithReport
          id="AAV4WaterStress"
          opacity={global.AAV4WaterStressOpacity}
          beforeID={beforeID}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.AAV4}
          sourceLayer={"aqueduct4"}
          action={global.action}
          reportRoute={"/AqueductWaterRiskAtlasV4"}
          sourceLabel={"Aqueduct Water Risk Atlas v4.0"}
          sourceLink={"https://www.wri.org/publication/aqueduct-30"}
          tableColumns={[
            {
              Header: "aq30_id",
              accessor: "aq30_id",
            },
            {
              Header: "aqid",
              accessor: "aqid",
            },
            {
              Header: "area_km2",
              accessor: "area_km2",
            },
            {
              Header: "bwd_cat",
              accessor: "bwd_cat",
            },
            {
              Header: "bwd_label",
              accessor: "bwd_label",
            },
            {
              Header: "bwd_raw",
              accessor: "bwd_raw",
            },
            {
              Header: "bwd_score",
              accessor: "bwd_score",
            },
            {
              Header: "bws_cat",
              accessor: "bws_cat",
            },
            {
              Header: "bws_label",
              accessor: "bws_label",
            },
            {
              Header: "bws_raw",
              accessor: "bws_raw",
            },
            {
              Header: "bws_score",
              accessor: "bws_score",
            },
            {
              Header: "cep_cat",
              accessor: "cep_cat",
            },
            {
              Header: "cep_label",
              accessor: "cep_label",
            },
            {
              Header: "cep_raw",
              accessor: "cep_raw",
            },
            {
              Header: "cep_score",
              accessor: "cep_score",
            },
            {
              Header: "cfr_cat",
              accessor: "cfr_cat",
            },
            {
              Header: "cfr_label",
              accessor: "cfr_label",
            },
            {
              Header: "cfr_raw",
              accessor: "cfr_raw",
            },
            {
              Header: "cfr_score",
              accessor: "cfr_score",
            },
            {
              Header: "drr_cat",
              accessor: "drr_cat",
            },
            {
              Header: "drr_label",
              accessor: "drr_label",
            },
            {
              Header: "drr_raw",
              accessor: "drr_raw",
            },
            {
              Header: "drr_score",
              accessor: "drr_score",
            },
            {
              Header: "gid_0",
              accessor: "gid_0",
            },
            {
              Header: "gid_1",
              accessor: "gid_1",
            },
            {
              Header: "gtd_cat",
              accessor: "gtd_cat",
            },
            {
              Header: "gtd_label",
              accessor: "gtd_label",
            },
            {
              Header: "gtd_raw",
              accessor: "gtd_raw",
            },
            {
              Header: "gtd_score",
              accessor: "gtd_score",
            },
            {
              Header: "iav_cat",
              accessor: "iav_cat",
            },
            {
              Header: "iav_label",
              accessor: "iav_label",
            },
            {
              Header: "iav_raw",
              accessor: "iav_raw",
            },
            {
              Header: "iav_score",
              accessor: "iav_score",
            },
            {
              Header: "name_0",
              accessor: "name_0",
            },
            {
              Header: "name_1",
              accessor: "name_1",
            },
            {
              Header: "pfaf_id",
              accessor: "pfaf_id",
            },
            {
              Header: "rfr_cat",
              accessor: "rfr_cat",
            },
            {
              Header: "rfr_label",
              accessor: "rfr_label",
            },
            {
              Header: "rfr_raw",
              accessor: "rfr_raw",
            },
            {
              Header: "rfr_score",
              accessor: "rfr_score",
            },
            {
              Header: "rri_cat",
              accessor: "rri_cat",
            },
            {
              Header: "rri_label",
              accessor: "rri_label",
            },
            {
              Header: "rri_score",
              accessor: "rri_score",
            },
            {
              Header: "sev_cat",
              accessor: "sev_cat",
            },
            {
              Header: "sev_label",
              accessor: "sev_label",
            },
            {
              Header: "sev_raw",
              accessor: "sev_raw",
            },
            {
              Header: "sev_score",
              accessor: "sev_score",
            },
            {
              Header: "string_id",
              accessor: "string_id",
            },
            {
              Header: "ucw_cat",
              accessor: "ucw_cat",
            },
            {
              Header: "ucw_label",
              accessor: "ucw_label",
            },
            {
              Header: "ucw_raw",
              accessor: "ucw_raw",
            },
            {
              Header: "ucw_score",
              accessor: "ucw_score",
            },
            {
              Header: "udw_cat",
              accessor: "udw_cat",
            },
            {
              Header: "udw_label",
              accessor: "udw_label",
            },
            {
              Header: "udw_raw",
              accessor: "udw_raw",
            },
            {
              Header: "udw_score",
              accessor: "udw_score",
            },
            {
              Header: "usa_cat",
              accessor: "usa_cat",
            },
            {
              Header: "usa_label",
              accessor: "usa_label",
            },
            {
              Header: "usa_raw",
              accessor: "usa_raw",
            },
            {
              Header: "usa_score",
              accessor: "usa_score",
            },
            {
              Header: "w_awr_agr_qal_cat",
              accessor: "w_awr_agr_qal_cat",
            },
            {
              Header: "w_awr_agr_qal_label",
              accessor: "w_awr_agr_qal_label",
            },
            {
              Header: "w_awr_agr_qal_raw",
              accessor: "w_awr_agr_qal_raw",
            },
            {
              Header: "w_awr_agr_qal_score",
              accessor: "w_awr_agr_qal_score",
            },
            {
              Header: "w_awr_agr_qal_weight_fraction",
              accessor: "w_awr_agr_qal_weight_fraction",
            },
            {
              Header: "w_awr_agr_qan_cat",
              accessor: "w_awr_agr_qan_cat",
            },
            {
              Header: "w_awr_agr_qan_label",
              accessor: "w_awr_agr_qan_label",
            },
            {
              Header: "w_awr_agr_qan_raw",
              accessor: "w_awr_agr_qan_raw",
            },
            {
              Header: "w_awr_agr_qan_score",
              accessor: "w_awr_agr_qan_score",
            },
            {
              Header: "w_awr_agr_qan_weight_fraction",
              accessor: "w_awr_agr_qan_weight_fraction",
            },
            {
              Header: "w_awr_agr_rrr_cat",
              accessor: "w_awr_agr_rrr_cat",
            },
            {
              Header: "w_awr_agr_rrr_label",
              accessor: "w_awr_agr_rrr_label",
            },
            {
              Header: "w_awr_agr_rrr_raw",
              accessor: "w_awr_agr_rrr_raw",
            },
            {
              Header: "w_awr_agr_rrr_score",
              accessor: "w_awr_agr_rrr_score",
            },
            {
              Header: "w_awr_agr_rrr_weight_fraction",
              accessor: "w_awr_agr_rrr_weight_fraction",
            },
            {
              Header: "w_awr_agr_tot_cat",
              accessor: "w_awr_agr_tot_cat",
            },
            {
              Header: "w_awr_agr_tot_label",
              accessor: "w_awr_agr_tot_label",
            },
            {
              Header: "w_awr_agr_tot_raw",
              accessor: "w_awr_agr_tot_raw",
            },
            {
              Header: "w_awr_agr_tot_score",
              accessor: "w_awr_agr_tot_score",
            },
            {
              Header: "w_awr_agr_tot_weight_fraction",
              accessor: "w_awr_agr_tot_weight_fraction",
            },
            {
              Header: "w_awr_che_qal_cat",
              accessor: "w_awr_che_qal_cat",
            },
            {
              Header: "w_awr_che_qal_label",
              accessor: "w_awr_che_qal_label",
            },
            {
              Header: "w_awr_che_qal_raw",
              accessor: "w_awr_che_qal_raw",
            },
            {
              Header: "w_awr_che_qal_score",
              accessor: "w_awr_che_qal_score",
            },
            {
              Header: "w_awr_che_qal_weight_fraction",
              accessor: "w_awr_che_qal_weight_fraction",
            },
            {
              Header: "w_awr_che_qan_cat",
              accessor: "w_awr_che_qan_cat",
            },
            {
              Header: "w_awr_che_qan_label",
              accessor: "w_awr_che_qan_label",
            },
            {
              Header: "w_awr_che_qan_raw",
              accessor: "w_awr_che_qan_raw",
            },
            {
              Header: "w_awr_che_qan_score",
              accessor: "w_awr_che_qan_score",
            },
            {
              Header: "w_awr_che_qan_weight_fraction",
              accessor: "w_awr_che_qan_weight_fraction",
            },
            {
              Header: "w_awr_che_rrr_cat",
              accessor: "w_awr_che_rrr_cat",
            },
            {
              Header: "w_awr_che_rrr_label",
              accessor: "w_awr_che_rrr_label",
            },
            {
              Header: "w_awr_che_rrr_raw",
              accessor: "w_awr_che_rrr_raw",
            },
            {
              Header: "w_awr_che_rrr_score",
              accessor: "w_awr_che_rrr_score",
            },
            {
              Header: "w_awr_che_rrr_weight_fraction",
              accessor: "w_awr_che_rrr_weight_fraction",
            },
            {
              Header: "w_awr_che_tot_cat",
              accessor: "w_awr_che_tot_cat",
            },
            {
              Header: "w_awr_che_tot_label",
              accessor: "w_awr_che_tot_label",
            },
            {
              Header: "w_awr_che_tot_raw",
              accessor: "w_awr_che_tot_raw",
            },
            {
              Header: "w_awr_che_tot_score",
              accessor: "w_awr_che_tot_score",
            },
            {
              Header: "w_awr_che_tot_weight_fraction",
              accessor: "w_awr_che_tot_weight_fraction",
            },
            {
              Header: "w_awr_con_qal_cat",
              accessor: "w_awr_con_qal_cat",
            },
            {
              Header: "w_awr_con_qal_label",
              accessor: "w_awr_con_qal_label",
            },
            {
              Header: "w_awr_con_qal_score",
              accessor: "w_awr_con_qal_score",
            },
            {
              Header: "w_awr_con_qal_weight_fraction",
              accessor: "w_awr_con_qal_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_cat",
              accessor: "w_awr_con_qan_cat",
            },
            {
              Header: "w_awr_con_qan_label",
              accessor: "w_awr_con_qan_label",
            },
            {
              Header: "w_awr_con_qan_raw",
              accessor: "w_awr_con_qan_raw",
            },
            {
              Header: "w_awr_con_qan_weight_fraction",
              accessor: "w_awr_con_qan_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_score",
              accessor: "w_awr_con_qan_score",
            },
            {
              Header: "w_awr_con_rrr_cat",
              accessor: "w_awr_con_rrr_cat",
            },
            {
              Header: "w_awr_con_rrr_label",
              accessor: "w_awr_con_rrr_label",
            },
            {
              Header: "w_awr_con_rrr_raw",
              accessor: "w_awr_con_rrr_raw",
            },
            {
              Header: "w_awr_con_rrr_score",
              accessor: "w_awr_con_rrr_score",
            },
            {
              Header: "w_awr_con_rrr_weight_fraction",
              accessor: "w_awr_con_rrr_weight_fraction",
            },
            {
              Header: "w_awr_con_tot_cat",
              accessor: "w_awr_con_tot_cat",
            },
            {
              Header: "w_awr_con_tot_label",
              accessor: "w_awr_con_tot_label",
            },
            {
              Header: "w_awr_con_tot_raw",
              accessor: "w_awr_con_tot_raw",
            },
            {
              Header: "w_awr_con_tot_score",
              accessor: "w_awr_con_tot_score",
            },
            {
              Header: "w_awr_con_tot_weight_fraction",
              accessor: "w_awr_con_tot_weight_fraction",
            },
            {
              Header: "w_awr_def_qal_cat",
              accessor: "w_awr_def_qal_cat",
            },
            {
              Header: "w_awr_def_qal_label",
              accessor: "w_awr_def_qal_label",
            },
            {
              Header: "w_awr_def_qal_raw",
              accessor: "w_awr_def_qal_raw",
            },
            {
              Header: "w_awr_def_qal_score",
              accessor: "w_awr_def_qal_score",
            },
            {
              Header: "w_awr_def_qal_weight_fraction",
              accessor: "w_awr_def_qal_weight_fraction",
            },
            {
              Header: "w_awr_def_qan_cat",
              accessor: "w_awr_def_qan_cat",
            },
            {
              Header: "w_awr_def_qan_label",
              accessor: "w_awr_def_qan_label",
            },
            {
              Header: "w_awr_def_qan_raw",
              accessor: "w_awr_def_qan_raw",
            },
            {
              Header: "w_awr_def_qan_score",
              accessor: "w_awr_def_qan_score",
            },
            {
              Header: "w_awr_def_qan_weight_fraction",
              accessor: "w_awr_def_qan_weight_fraction",
            },
            {
              Header: "w_awr_def_rrr_cat",
              accessor: "w_awr_def_rrr_cat",
            },
            {
              Header: "w_awr_def_rrr_label",
              accessor: "w_awr_def_rrr_label",
            },
            {
              Header: "w_awr_def_rrr_raw",
              accessor: "w_awr_def_rrr_raw",
            },
            {
              Header: "w_awr_def_rrr_score",
              accessor: "w_awr_def_rrr_score",
            },
            {
              Header: "w_awr_def_rrr_weight_fraction",
              accessor: "w_awr_def_rrr_weight_fraction",
            },
            {
              Header: "w_awr_def_tot_cat",
              accessor: "w_awr_def_tot_cat",
            },
            {
              Header: "w_awr_def_tot_label",
              accessor: "w_awr_def_tot_label",
            },
            {
              Header: "w_awr_def_tot_raw",
              accessor: "w_awr_def_tot_raw",
            },
            {
              Header: "w_awr_def_tot_score",
              accessor: "w_awr_def_tot_score",
            },
            {
              Header: "w_awr_def_tot_weight_fraction",
              accessor: "w_awr_def_tot_weight_fraction",
            },
            {
              Header: "w_awr_elp_qal_cat",
              accessor: "w_awr_elp_qal_cat",
            },
            {
              Header: "w_awr_elp_qal_label",
              accessor: "w_awr_elp_qal_label",
            },
            {
              Header: "w_awr_elp_qal_raw",
              accessor: "w_awr_elp_qal_raw",
            },
            {
              Header: "w_awr_elp_qal_score",
              accessor: "w_awr_elp_qal_score",
            },
            {
              Header: "w_awr_elp_qal_weight_fraction",
              accessor: "w_awr_elp_qal_weight_fraction",
            },
            {
              Header: "w_awr_elp_qan_cat",
              accessor: "w_awr_elp_qan_cat",
            },
            {
              Header: "w_awr_elp_qan_label",
              accessor: "w_awr_elp_qan_label",
            },
            {
              Header: "w_awr_elp_qan_raw",
              accessor: "w_awr_elp_qan_raw",
            },
            {
              Header: "w_awr_elp_qan_score",
              accessor: "w_awr_elp_qan_score",
            },
            {
              Header: "w_awr_elp_qan_weight_fraction",
              accessor: "w_awr_elp_qan_weight_fraction",
            },
            {
              Header: "w_awr_elp_rrr_cat",
              accessor: "w_awr_elp_rrr_cat",
            },
            {
              Header: "w_awr_elp_rrr_label",
              accessor: "w_awr_elp_rrr_label",
            },
            {
              Header: "w_awr_elp_rrr_raw",
              accessor: "w_awr_elp_rrr_raw",
            },
            {
              Header: "w_awr_elp_rrr_score",
              accessor: "w_awr_elp_rrr_score",
            },
            {
              Header: "w_awr_elp_rrr_weight_fraction",
              accessor: "w_awr_elp_rrr_weight_fraction",
            },
            {
              Header: "w_awr_elp_tot_cat",
              accessor: "w_awr_elp_tot_cat",
            },
            {
              Header: "w_awr_elp_tot_label",
              accessor: "w_awr_elp_tot_label",
            },
            {
              Header: "w_awr_elp_tot_raw",
              accessor: "w_awr_elp_tot_raw",
            },
            {
              Header: "w_awr_elp_tot_score",
              accessor: "w_awr_elp_tot_score",
            },
            {
              Header: "w_awr_elp_tot_weight_fraction",
              accessor: "w_awr_elp_tot_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qal_cat",
              accessor: "w_awr_fnb_qal_cat",
            },
            {
              Header: "w_awr_fnb_qal_label",
              accessor: "w_awr_fnb_qal_label",
            },
            {
              Header: "w_awr_fnb_qal_raw",
              accessor: "w_awr_fnb_qal_raw",
            },
            {
              Header: "w_awr_fnb_qal_score",
              accessor: "w_awr_fnb_qal_score",
            },
            {
              Header: "w_awr_fnb_qal_weight_fraction",
              accessor: "w_awr_fnb_qal_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qan_cat",
              accessor: "w_awr_fnb_qan_cat",
            },
            {
              Header: "w_awr_fnb_qan_label",
              accessor: "w_awr_fnb_qan_label",
            },
            {
              Header: "w_awr_fnb_qan_raw",
              accessor: "w_awr_fnb_qan_raw",
            },
            {
              Header: "w_awr_fnb_qan_score",
              accessor: "w_awr_fnb_qan_score",
            },
            {
              Header: "w_awr_fnb_qan_weight_fraction",
              accessor: "w_awr_fnb_qan_weight_fraction",
            },
            {
              Header: "w_awr_fnb_rrr_cat",
              accessor: "w_awr_fnb_rrr_cat",
            },
            {
              Header: "w_awr_fnb_rrr_label",
              accessor: "w_awr_fnb_rrr_label",
            },
            {
              Header: "w_awr_fnb_rrr_raw",
              accessor: "w_awr_fnb_rrr_raw",
            },
            {
              Header: "w_awr_fnb_rrr_score",
              accessor: "w_awr_fnb_rrr_score",
            },
            {
              Header: "w_awr_fnb_rrr_weight_fraction",
              accessor: "w_awr_fnb_rrr_weight_fraction",
            },
            {
              Header: "w_awr_fnb_tot_cat",
              accessor: "w_awr_fnb_tot_cat",
            },
            {
              Header: "w_awr_fnb_tot_label",
              accessor: "w_awr_fnb_tot_label",
            },
            {
              Header: "w_awr_fnb_tot_raw",
              accessor: "w_awr_fnb_tot_raw",
            },
            {
              Header: "w_awr_fnb_tot_score",
              accessor: "w_awr_fnb_tot_score",
            },
            {
              Header: "w_awr_fnb_tot_weight_fraction",
              accessor: "w_awr_fnb_tot_weight_fraction",
            },
            {
              Header: "w_awr_min_qal_cat",
              accessor: "w_awr_min_qal_cat",
            },
            {
              Header: "w_awr_min_qal_label",
              accessor: "w_awr_min_qal_label",
            },
            {
              Header: "w_awr_min_qal_raw",
              accessor: "w_awr_min_qal_raw",
            },
            {
              Header: "w_awr_min_qal_score",
              accessor: "w_awr_min_qal_score",
            },
            {
              Header: "w_awr_min_qal_weight_fraction",
              accessor: "w_awr_min_qal_weight_fraction",
            },
            {
              Header: "w_awr_min_qan_cat",
              accessor: "w_awr_min_qan_cat",
            },
            {
              Header: "w_awr_min_qan_label",
              accessor: "w_awr_min_qan_label",
            },
            {
              Header: "w_awr_min_qan_raw",
              accessor: "w_awr_min_qan_raw",
            },
            {
              Header: "w_awr_min_qan_score",
              accessor: "w_awr_min_qan_score",
            },
            {
              Header: "w_awr_min_qan_weight_fraction",
              accessor: "w_awr_min_qan_weight_fraction",
            },
            {
              Header: "w_awr_min_rrr_cat",
              accessor: "w_awr_min_rrr_cat",
            },
            {
              Header: "w_awr_min_rrr_label",
              accessor: "w_awr_min_rrr_label",
            },
            {
              Header: "w_awr_min_rrr_raw",
              accessor: "w_awr_min_rrr_raw",
            },
            {
              Header: "w_awr_min_rrr_score",
              accessor: "w_awr_min_rrr_score",
            },
            {
              Header: "w_awr_min_rrr_weight_fraction",
              accessor: "w_awr_min_rrr_weight_fraction",
            },
            {
              Header: "w_awr_min_tot_cat",
              accessor: "w_awr_min_tot_cat",
            },
            {
              Header: "w_awr_min_tot_label",
              accessor: "w_awr_min_tot_label",
            },
            {
              Header: "w_awr_min_tot_raw",
              accessor: "w_awr_min_tot_raw",
            },
            {
              Header: "w_awr_min_tot_score",
              accessor: "w_awr_min_tot_score",
            },
            {
              Header: "w_awr_min_tot_weight_fraction",
              accessor: "w_awr_min_tot_weight_fraction",
            },
            {
              Header: "w_awr_ong_qal_cat",
              accessor: "w_awr_ong_qal_cat",
            },
            {
              Header: "w_awr_ong_qal_label",
              accessor: "w_awr_ong_qal_label",
            },
            {
              Header: "w_awr_ong_qal_raw",
              accessor: "w_awr_ong_qal_raw",
            },
            {
              Header: "w_awr_ong_qal_score",
              accessor: "w_awr_ong_qal_score",
            },
            {
              Header: "w_awr_ong_qan_cat",
              accessor: "w_awr_ong_qan_cat",
            },
            {
              Header: "w_awr_ong_qan_label",
              accessor: "w_awr_ong_qan_label",
            },
            {
              Header: "w_awr_ong_qan_raw",
              accessor: "w_awr_ong_qan_raw",
            },
            {
              Header: "w_awr_ong_qan_score",
              accessor: "w_awr_ong_qan_score",
            },
            {
              Header: "w_awr_ong_qan_weight_fraction",
              accessor: "w_awr_ong_qan_weight_fraction",
            },
            {
              Header: "w_awr_ong_rrr_cat",
              accessor: "w_awr_ong_rrr_cat",
            },
            {
              Header: "w_awr_ong_rrr_label",
              accessor: "w_awr_ong_rrr_label",
            },
            {
              Header: "w_awr_ong_rrr_raw",
              accessor: "w_awr_ong_rrr_raw",
            },
            {
              Header: "w_awr_ong_rrr_score",
              accessor: "w_awr_ong_rrr_score",
            },
            {
              Header: "w_awr_ong_rrr_weight_fraction",
              accessor: "w_awr_ong_rrr_weight_fraction",
            },
            {
              Header: "w_awr_ong_tot_cat",
              accessor: "w_awr_ong_tot_cat",
            },
            {
              Header: "w_awr_ong_tot_label",
              accessor: "w_awr_ong_tot_label",
            },
            {
              Header: "w_awr_ong_tot_raw",
              accessor: "w_awr_ong_tot_raw",
            },
            {
              Header: "w_awr_ong_tot_score",
              accessor: "w_awr_ong_tot_score",
            },
            {
              Header: "w_awr_ong_tot_weight_fraction",
              accessor: "w_awr_ong_tot_weight_fraction",
            },
            {
              Header: "w_awr_smc_qal_cat",
              accessor: "w_awr_smc_qal_cat",
            },
            {
              Header: "w_awr_smc_qal_label",
              accessor: "w_awr_smc_qal_label",
            },
            {
              Header: "w_awr_smc_qal_raw",
              accessor: "w_awr_smc_qal_raw",
            },
            {
              Header: "w_awr_smc_qal_score",
              accessor: "w_awr_smc_qal_score",
            },
            {
              Header: "w_awr_smc_qal_weight_fraction",
              accessor: "w_awr_smc_qal_weight_fraction",
            },
            {
              Header: "w_awr_smc_qan_cat",
              accessor: "w_awr_smc_qan_cat",
            },
            {
              Header: "w_awr_smc_qan_label",
              accessor: "w_awr_smc_qan_label",
            },
            {
              Header: "w_awr_smc_qan_raw",
              accessor: "w_awr_smc_qan_raw",
            },
            {
              Header: "w_awr_smc_qan_score",
              accessor: "w_awr_smc_qan_score",
            },
            {
              Header: "w_awr_smc_qan_weight_fraction",
              accessor: "w_awr_smc_qan_weight_fraction",
            },
            {
              Header: "w_awr_smc_rrr_cat",
              accessor: "w_awr_smc_rrr_cat",
            },
            {
              Header: "w_awr_smc_rrr_label",
              accessor: "w_awr_smc_rrr_label",
            },
            {
              Header: "w_awr_smc_rrr_raw",
              accessor: "w_awr_smc_rrr_raw",
            },
            {
              Header: "w_awr_smc_rrr_score",
              accessor: "w_awr_smc_rrr_score",
            },
            {
              Header: "w_awr_smc_rrr_weight_fraction",
              accessor: "w_awr_smc_rrr_weight_fraction",
            },
            {
              Header: "w_awr_smc_tot_cat",
              accessor: "w_awr_smc_tot_cat",
            },
            {
              Header: "w_awr_smc_tot_label",
              accessor: "w_awr_smc_tot_label",
            },
            {
              Header: "w_awr_smc_tot_raw",
              accessor: "w_awr_smc_tot_raw",
            },
            {
              Header: "w_awr_smc_tot_score",
              accessor: "w_awr_smc_tot_score",
            },
            {
              Header: "w_awr_smc_tot_weight_fraction",
              accessor: "w_awr_smc_tot_weight_fraction",
            },
            {
              Header: "w_awr_tex_qal_cat",
              accessor: "w_awr_tex_qal_cat",
            },
            {
              Header: "w_awr_tex_qal_label",
              accessor: "w_awr_tex_qal_label",
            },
            {
              Header: "w_awr_tex_qal_raw",
              accessor: "w_awr_tex_qal_raw",
            },
            {
              Header: "w_awr_tex_qal_score",
              accessor: "w_awr_tex_qal_score",
            },
            {
              Header: "w_awr_tex_qal_weight_fraction",
              accessor: "w_awr_tex_qal_weight_fraction",
            },
            {
              Header: "w_awr_tex_qan_cat",
              accessor: "w_awr_tex_qan_cat",
            },
            {
              Header: "w_awr_tex_qan_label",
              accessor: "w_awr_tex_qan_label",
            },
            {
              Header: "w_awr_tex_qan_raw",
              accessor: "w_awr_tex_qan_raw",
            },
            {
              Header: "w_awr_tex_qan_weight_fraction",
              accessor: "w_awr_tex_qan_weight_fraction",
            },
            {
              Header: "w_awr_tex_rrr_cat",
              accessor: "w_awr_tex_rrr_cat",
            },
            {
              Header: "w_awr_tex_rrr_label",
              accessor: "w_awr_tex_rrr_label",
            },
            {
              Header: "w_awr_tex_rrr_raw",
              accessor: "w_awr_tex_rrr_raw",
            },
            {
              Header: "w_awr_tex_rrr_score",
              accessor: "w_awr_tex_rrr_score",
            },
            {
              Header: "w_awr_tex_rrr_weight_fraction",
              accessor: "w_awr_tex_rrr_weight_fraction",
            },
            {
              Header: "w_awr_tex_tot_cat",
              accessor: "w_awr_tex_tot_cat",
            },
            {
              Header: "w_awr_tex_tot_label",
              accessor: "w_awr_tex_tot_label",
            },
            {
              Header: "w_awr_tex_tot_raw",
              accessor: "w_awr_tex_tot_raw",
            },
            {
              Header: "w_awr_tex_tot_score",
              accessor: "w_awr_tex_tot_score",
            },
            {
              Header: "w_awr_tex_tot_weight_fraction",
              accessor: "w_awr_tex_tot_weight_fraction",
            },
          ]}
          fillColor={[
            "match",
            ["get", "bws_label"],
            ["Extremely High (>80%)"],
            `${adjustHexBrightness("#623023", global.AAV4WaterStressBrightness)}`,
            ["High (40-80%)"],
            `${adjustHexBrightness("#943A24", global.AAV4WaterStressBrightness)}`,
            ["Medium - High (20-40%)"],
            `${adjustHexBrightness("#9C8F20", global.AAV4WaterStressBrightness)}`,
            ["Low - Medium (10-20%)"],
            `${adjustHexBrightness("#809C2D", global.AAV4WaterStressBrightness)}`,
            ["Low (<10%)"],
            `${adjustHexBrightness("#597A3A", global.AAV4WaterStressBrightness)}`,
            ["Arid and Low Water Use"],
            `${adjustHexBrightness("#60675B", global.AAV4WaterStressBrightness)}`,
            "hsla(0, 0%, 0%, 0)",
          ]}
        />
      )}
      {global.cvi && (
        <>
          <LineLayer
            id="cvi-boundaries"
            opacity={0.5}
            // @ts-ignore
            tileSet={settings.tileSetURLs.CVI}
            sourceLayer={"cvi"}
            lineColor={"#FFFFFF"}
            minzoom={5}
            width={1}
          />
          <CVILayer
            id="cvi"
            global={global}
            opacity={global.cviOpacity}
            beforeId={"cvi-boundaries"}
            // @ts-ignore
            tileSet={settings.tileSetURLs.CVI}
            action={global.action}
            sourceLayer={"cvi"}
            reportRoute={"/ClimateVulnerabilityIndex"}
            sourceLabel={"Climate Vulnerability Index"}
            sourceLink={"https://climatevulnerabilityindex.org/methodology/"}
            tableColumns={[
              {
                Header: "GEO ID 10",
                accessor: "GEOID10",
              },
              {
                Header: "CVI Percentile Overall",
                accessor: "percentile_cvi_overall",
              },
              {
                Header: "Raw CVI Overall",
                accessor: "raw_cvi_overall",
              },
            ]}
          />
        </>
      )}
      {global.rainfallForecast && (
        <ExcessiveRainfallForecast
          global={global}
          action={global.action}
          sourceLayer={"rainfall_forecast"}
          reportRoute={"/RainfallForecast"}
          sourceLabel={
            "Excessive Rainfall Outlook (ERO) Interactive Display (noaa.gov)"
          }
          sourceLink={
            "https://www.wpc.ncep.noaa.gov/qpf/excessive_rainfall_outlook_ero.php"
          }
          tableColumns={[
            {
              Header: "END TIME",
              accessor: "END_TIME",
            },
            {
              Header: "ISSUE TIME",
              accessor: "ISSUE_TIME",
            },
            {
              Header: "OUTLOOK",
              accessor: "OUTLOOK",
            },
            {
              Header: "FIRE",
              accessor: "FIRE_YEAR",
            },
            {
              Header: "PRODUCT",
              accessor: "PRODUCT",
            },
            {
              Header: "START TIME",
              accessor: "START_TIME",
            },
            {
              Header: "Snippet",
              accessor: "Snippet",
            },
            {
              Header: "VALID TIME",
              accessor: "VALID_TIME",
            },
          ]}
          opacity={global.rainfallForecastOpacity}
        />
      )}
      {global.allFireData && (
        <FireDataLayer
          id="all-fire-data"
          global={global}
          opacity={global.allFireDataOpacity}
          action={global.action}
          year={global.fireYear}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.HistoricalFireBoundaryData}
          sourceLayer={"AllFireData"}
          report={true}
          reportRoute={"/HistoricalFireData"}
          sourceLabel={"NIFC Maps"}
          sourceLink={"https://www.nifc.gov/fire-information/maps"}
          initialCoordinates={[-117.65992832253397, 45.992885019482]}
          zoom={6}
          tableColumns={[
            {
              Header: "AGENCY",
              accessor: "AGENCY",
            },
            {
              Header: "COMMENTS",
              accessor: "COMMENTS",
            },
            {
              Header: "DATE",
              accessor: "DATE_CUR",
              Cell: (props: any) => formatDate(parseInt(props.value)),
            },
            {
              Header: "FEATURE",
              accessor: "FEATURE_CA",
            },
            {
              Header: "FIRE",
              accessor: "FIRE_YEAR",
            },
            {
              Header: "FIRE YEAR INT",
              accessor: "FIRE_YEAR_INT",
            },
            {
              Header: "GEO ID",
              accessor: "GEO_ID",
            },
            {
              Header: "GIS ACRES",
              accessor: "GIS_ACRES",
            },
            {
              Header: "INCIDENT",
              accessor: "INCIDENT",
            },
            {
              Header: "IRWINID",
              accessor: "IRWINID",
            },
            {
              Header: "LOCAL NUM",
              accessor: "LOCAL_NUM",
            },
            {
              Header: "MAP METHOD",
              accessor: "MAP_METHOD",
            },
            {
              Header: "OBJECTID",
              accessor: "OBJECTID",
            },
            {
              Header: "RESP I",
              accessor: "POO_RESP_I",
            },
            {
              Header: "SOURCE",
              accessor: "SOURCE",
            },
            {
              Header: "Shape Area",
              accessor: "Shape__Area",
            },
            {
              Header: "Shape Length",
              accessor: "Shape__Length",
            },
            {
              Header: "UNIT ID",
              accessor: "UNIT_ID",
            },
            {
              Header: "UNQE FIRE ID",
              accessor: "UNQE_FIRE_ID",
            },
            {
              Header: "USER NAME",
              accessor: "USER_NAME",
            },
          ]}
        />
      )}
      {global.wildFireBoundaries && (
        <>
          <LineLayer
            id="wildfire-boundaries"
            opacity={global.wildFireBoundariesOpacity}
            // @ts-ignore
            tileSet={settings.tileSetURLs.WildFireBoundaries}
            sourceLayer={"2023_wildfires"}
            lineColor={"#F98000"}
            width={3}
          />
          <BIALayer
            id="wildfire-boundaries-data"
            opacity={global.wildFireBoundariesOpacity}
            action={global.action}
            wrfc={true}
            outlineColor={"transparent"}
            tileSet={settings.tileSetURLs.WildFireBoundaries}
            sourceLayer={"2023_wildfires"}
            fillColor={`${adjustHexBrightness("#F98000", global.wildFireBoundariesBrightness)}`}
            report={true}
            sourceLabel={"NIFC Maps"}
            reportRoute={"/WildfireBoundaries"}
            sourceLink={"https://www.nifc.gov/fire-information/maps"}
            tableColumns={[
              {
                Header: "ADS Permission State",
                accessor: "attr_ADSPermissionState",
              },
              {
                Header: "Calculated Acres",
                accessor: "attr_CalculatedAcres",
              },
              {
                Header: "Cpx ID",
                accessor: "attr_CpxID",
              },
              {
                Header: "Cpx Name",
                accessor: "attr_CpxName",
              },
              {
                Header: "Created By System",
                accessor: "attr_CreatedBySystem",
              },
              {
                Header: "Created On Date Time",
                accessor: "attr_CreatedOnDateTime_dt",
              },
              {
                Header: "Discovery Acres",
                accessor: "attr_DiscoveryAcres",
              },
              {
                Header: "Dispatch Center ID",
                accessor: "attr_DispatchCenterID",
              },
              {
                Header: "Estimated Cost To Date",
                accessor: "attr_EstimatedCostToDate",
              },
              {
                Header: "Estimated Final Cost",
                accessor: "attr_EstimatedFinalCost",
              },
              {
                Header: "FS Job Code",
                accessor: "attr_FSJobCode",
              },
              {
                Header: "FS Override Code",
                accessor: "attr_FSOverrideCode",
              },
              {
                Header: "Final Acres",
                accessor: "attr_FinalAcres",
              },
              {
                Header: "Fire Behavior General",
                accessor: "attr_FireBehaviorGeneral",
              },
              {
                Header: "Fire Behavior General 1",
                accessor: "attr_FireBehaviorGeneral1",
              },
              {
                Header: "Fire Behavior General 2",
                accessor: "attr_FireBehaviorGeneral2",
              },
              {
                Header: "Fire Behavior General 3",
                accessor: "attr_FireBehaviorGeneral3",
              },
              {
                Header: "Fire Cause",
                accessor: "attr_FireCause",
              },
              {
                Header: "Fire Code",
                accessor: "attr_FireCode",
              },
              {
                Header: "Fire Discovery Date Time",
                accessor: "attr_FireDiscoveryDateTime",
              },
              {
                Header: "Fire Mgmt Complexity",
                accessor: "attr_FireMgmtComplexity",
              },
              {
                Header: "Fire Strategy Confine Percent",
                accessor: "attr_FireStrategyConfinePercent",
              },
              {
                Header: "Fire Strategy Full Supp Prcnt",
                accessor: "attr_FireStrategyFullSuppPrcnt",
              },
              {
                Header: "Fire Strategy Monitor Percent",
                accessor: "attr_FireStrategyMonitorPercent",
              },
              {
                Header: "Fire Strategy Point Zone Prcnt",
                accessor: "attr_FireStrategyPointZonePrcnt",
              },
              {
                Header: "GACC",
                accessor: "attr_GACC",
              },
              {
                Header: "ICS 209 Report Date Time",
                accessor: "attr_ICS209ReportDateTime",
              },
              {
                Header: "ICS 209 Report Status",
                accessor: "attr_ICS209ReportStatus",
              },
              {
                Header: "ICS 209 Rpt For Time Period From",
                accessor: "attr_ICS209RptForTimePeriodFrom",
              },
              {
                Header: "ICS 209 Rpt For Time Period To",
                accessor: "attr_ICS209RptForTimePeriodTo",
              },
              {
                Header: "Incident Management Org",
                accessor: "attr_IncidentManagementOrg",
              },
              {
                Header: "Incident Name",
                accessor: "attr_IncidentName",
              },
              {
                Header: "Incident Short Description",
                accessor: "attr_IncidentShortDescription",
              },
              {
                Header: "WATER TYPE SIZE AMT",
                accessor: "WATER_TYPE_SIZE_AMT",
              },
              {
                Header: "Incident Size",
                accessor: "attr_IncidentSize",
              },
              {
                Header: "Incident Type Category",
                accessor: "attr_IncidentTypeCategory",
              },
              {
                Header: "Incident Type Kind",
                accessor: "attr_IncidentTypeKind",
              },
              {
                Header: "Initial Latitude",
                accessor: "attr_InitialLatitude",
              },
              {
                Header: "Initial Longitude",
                accessor: "attr_InitialLongitude",
              },
              {
                Header: "Initial Response Acres",
                accessor: "attr_InitialResponseAcres",
              },
              {
                Header: "WETLAND CURRENT USE",
                accessor: "WETLAND_CURRENT_USE",
              },
              {
                Header: "Initial Response Date Time",
                accessor: "attr_InitialResponseDateTime",
              },
              {
                Header: "Irwin ID",
                accessor: "attr_IrwinID",
              },
              {
                Header: "Is Cpx Child",
                accessor: "attr_IsCpxChild",
              },
              {
                Header: "Is FS Assisted",
                accessor: "attr_IsFSAssisted",
              },
              {
                Header: "Is Fire Code Requested",
                accessor: "attr_IsFireCodeRequested",
              },
              {
                Header: "Is Multi Jurisdictional",
                accessor: "attr_IsMultiJurisdictional",
              },
              {
                Header: "Is Reimbursable",
                accessor: "attr_IsReimbursable",
              },
              {
                Header: "Is Trespass",
                accessor: "attr_IsTrespass",
              },
              {
                Header: "Is Unified Command",
                accessor: "attr_IsUnifiedCommand",
              },
              {
                Header: "Local Incident Identifier",
                accessor: "attr_LocalIncidentIdentifier",
              },
              {
                Header: "Modified By System",
                accessor: "attr_ModifiedBySystem",
              },
              {
                Header: "Modified On Date Time",
                accessor: "attr_ModifiedOnDateTime_dt",
              },
              {
                Header: "Organizational Assessment",
                accessor: "attr_OrganizationalAssessment",
              },
              {
                Header: "City",
                accessor: "attr_POOCity",
              },
              {
                Header: "POO County",
                accessor: "attr_POOCounty",
              },
              {
                Header: "Dispatch Center ID",
                accessor: "attr_POODispatchCenterID",
              },
              {
                Header: "Fips",
                accessor: "attr_POOFips",
              },
              {
                Header: "Jurisdictional Agency",
                accessor: "attr_POOJurisdictionalAgency",
              },
              {
                Header: "Jurisdictional Unit",
                accessor: "attr_POOJurisdictionalUnit",
              },
              {
                Header: "Landowner Category",
                accessor: "attr_POOLandownerCategory",
              },
              {
                Header: "Landowner Kind",
                accessor: "attr_POOLandownerKind",
              },
              {
                Header: "Legal Desc Principal Merid",
                accessor: "attr_POOLegalDescPrincipalMerid",
              },
              {
                Header: "Legal Desc Qtr",
                accessor: "attr_POOLegalDescQtr",
              },
              {
                Header: "POO Legal Desc Range",
                accessor: "attr_POOLegalDescRange",
              },
              {
                Header: "Legal Desc Section",
                accessor: "attr_POOLegalDescSection",
              },
              {
                Header: "attr_POOLegalDescTownship",
                accessor: "Legal Desc Township",
              },
              {
                Header: "Predictive Service Area ID",
                accessor: "attr_POOPredictiveServiceAreaID",
              },
              {
                Header: "Protecting Agency",
                accessor: "attr_POOProtectingAgency",
              },
              {
                Header: "Protecting Unit",
                accessor: "attr_POOProtectingUnit",
              },
              {
                Header: "State",
                accessor: "attr_POOState",
              },
              {
                Header: "Percent Contained",
                accessor: "attr_PercentContained",
              },
              {
                Header: "Percent Perim To Be Contained",
                accessor: "attr_PercentPerimToBeContained",
              },
              {
                Header: "Primary Fuel Model",
                accessor: "attr_PrimaryFuelModel",
              },
              {
                Header: "Secondary Fuel Model",
                accessor: "attr_SecondaryFuelModel",
              },
              {
                Header: "Source",
                accessor: "attr_Source",
              },
              {
                Header: "Source Global ID",
                accessor: "attr_SourceGlobalID",
              },
              {
                Header: "Source OID",
                accessor: "attr_SourceOID",
              },
              {
                Header: "Strat Decision Publish Date",
                accessor: "attr_StratDecisionPublishDate",
              },
              {
                Header: "Unique Fire Identifier",
                accessor: "attr_UniqueFireIdentifier",
              },
              {
                Header: "WFDSS Decision Status",
                accessor: "attr_WFDSSDecisionStatus",
              },
              {
                Header: "Poly Acres Auto Calc",
                accessor: "poly_Acres_AutoCalc",
              },
              {
                Header: "Create Date",
                accessor: "poly_CreateDate",
              },
              {
                Header: "Date Current",
                accessor: "poly_DateCurrent",
              },
              {
                Header: "Feature Category",
                accessor: "poly_FeatureCategory",
              },
              {
                Header: "GIS Acres",
                accessor: "poly_GISAcres",
              },
              {
                Header: "IRWINID",
                accessor: "poly_IRWINID",
              },
              {
                Header: "Incident Name",
                accessor: "poly_IncidentName",
              },
              {
                Header: "Map Method",
                accessor: "poly_MapMethod",
              },
              {
                Header: "Polygon Date Time",
                accessor: "poly_PolygonDateTime",
              },
              {
                Header: "Source OID",
                accessor: "poly_SourceOID",
              },
            ]}
          />
        </>
      )}
      {global.waterWatchSites && (
        <CircleVectorLayer
          global={global}
          id="waterWatchSites"
          opacity={global.waterWatchOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.USGSWaterWatch}
          sourceLayer={"water_watch_sites"}
          circleColor={`${adjustHexBrightness("#767ED6", global.waterWatchBrightness)}`}
          action={global.action}
          radius={4}
          zoom={zoom}
          wws={true}
        />
      )}
      {global.EPOLayer &&
        global.contaminate === "moderately_persistent_contaminants" && (
          <EastPalestine
            global={global}
            id="epo"
            opacity={global.EPOLayerOpacity}
            outlineColor={"white"}
            tileSet={settings.tileSetURLs.eastPalestineModel}
            sourceLayer={"east_palestine_model"}
            data={epmpc}
          />
        )}

      {global.EPOLayer &&
        global.contaminate ===
          "moderately_persistent_contaminants_with_air_deposition" && (
          <EastPalestine
            global={global}
            id="epo"
            opacity={global.EPOLayerOpacity}
            outlineColor={"white"}
            tileSet={settings.tileSetURLs.eastPalestineModel}
            sourceLayer={"east_palestine_model"}
            data={epmpcad}
          />
        )}

      {global.EPOLayer && global.contaminate === "persistent_contaminants" && (
        <EastPalestine
          global={global}
          id="epo"
          opacity={global.EPOLayerOpacity}
          outlineColor={"white"}
          tileSet={settings.tileSetURLs.eastPalestineModel}
          sourceLayer={"east_palestine_model"}
          data={eppc}
        />
      )}

      {global.EPOLayer &&
        global.contaminate ===
          "persistent_contaminants_with_air_deposition" && (
          <EastPalestine
            global={global}
            id="epo"
            opacity={global.EPOLayerOpacity}
            outlineColor={"white"}
            tileSet={settings.tileSetURLs.eastPalestineModel}
            sourceLayer={"east_palestine_model"}
            data={eppcad}
          />
        )}

      {global.EPOLayer && global.contaminate === "volatile_contaminants" && (
        <EastPalestine
          global={global}
          id="epo"
          opacity={global.EPOLayerOpacity}
          outlineColor={"white"}
          tileSet={settings.tileSetURLs.eastPalestineModel}
          sourceLayer={"east_palestine_model"}
          data={epvc}
        />
      )}

      {global.EPOLayer &&
        global.contaminate === "volatile_contaminants_with_air_deposition" && (
          <EastPalestine
            global={global}
            id="epo"
            opacity={global.EPOLayerOpacity}
            outlineColor={"white"}
            tileSet={settings.tileSetURLs.eastPalestineModel}
            sourceLayer={"east_palestine_model"}
            data={epvcad}
          />
        )}

      {global.eddi && (
        <EDDIDrought
          id={"EDDIDrought"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.eddiOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}

      {global.NOAAMix && (
        <NOAAMix
          id={"NOAAMix"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.NOAAMixOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}

      {global.FemaFirm && (
        <FemaFirm
          id={"FemaFirm"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.FemaFirmOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}


      {global.FHO && (
        <FHO
          id={"FHO"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.FHOOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}

      {global.StreamAnomaly && (
        <StreamAnomaly
          id={"StreamAnomaly"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.StreamAnomalyOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}

      {global.RiverGauge && (
        <RiverGauge
          id={"RiverGauge"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.RiverGaugeOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}

      {global.FloodInd && (
        <FloodInd
          id={"FloodInd"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.FloodIndOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}


      {global.nwshazard && (
        <NWSHazard
          id={"NWSHazard"}
          global={global}
          tileSet={settings_map.tileSetURLs.new_huc8s}
          sourceLayer={settings_map.tileSetURLs.new_huc8s_sourcelayer}
          opacity={global.NOAAMixOpacity}
          outlineColor={"teal"}
          fillColor={["match", ["get", "huc12"], "teal", "teal", "transparent"]}
        />
      )}

      {global.waterQualityPortal && (
        <WaterQualityPortal
          id={"WaterQualityPortal"}
          global={global}
          data={global.waterQualityPortalLocations}
          opacity={global.waterQualityPortalOpacity}
        />
      )}

      {global.yellowstoneLayer && global.volPersistentContaminants && (
        <YellowstoneHuc12
          global={global}
          id="yellowstone-vp"
          opacity={global.yellowstoneLayerOpacity}
          outlineColor={"#FF0000"}
          tileSet={settings.tileSetURLs.huc12_volatile_contam}
          sourceLayer={"ys_huc12_volatile_contam"}
          data={volatile}
        />
      )}
      {global.yellowstoneLayer && global.modPersistentContaminants && (
        <YellowstoneHuc12
          global={global}
          id="yellowstone-mp"
          opacity={global.yellowstoneLayerOpacity}
          outlineColor={"green"}
          tileSet={settings.tileSetURLs.huc12_moderate_persistent}
          sourceLayer={"ys_huc12_moderate_persistent"}
          data={moderate}
        />
      )}
      {global.yellowstoneLayer && global.persistentContaminants && (
        <YellowstoneHuc12
          global={global}
          id="yellowstone-p"
          opacity={global.yellowstoneLayerOpacity}
          outlineColor={"white"}
          tileSet={settings.tileSetURLs.persistentContaminants}
          sourceLayer={"ys_persistent_contaminants"}
          data={persistent}
        />
      )}
      {global.wrfcRisk && (
        <WRFCLayer
          id="wrfc"
          opacity={global.wrfcRiskOpacity}
          outlineColor={"teal"}
          global={global}
          // @ts-ignore
          tileSet={settings.tileSetURLs.wrfcRisk}
          action={global.action}
          sourceLayer={"WRF_Country_Risk"}
          ausAq={false}
          fillColor={"teal"}
          phfh={false}
          wrfc={true}
        />
      )}
      {global.ausGemstat && (
        <GemstatLayer
          id="gemstat-aus"
          opacity={global.ausGemstatOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.gemstatAus}
          sourceLayer={"gemstat_stations_australia"}
          circleColor={`${adjustHexBrightness("#FFA500", global.ausGemstatBrightness)}`}
          zoom={zoom}
        />
      )}
      {global.gemstatPH && (
        <GemstatLayer
          id="gemstat-ph"
          opacity={global.gemstatPHOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.gemstatPH}
          sourceLayer={"gemstat_stations_philippines"}
          circleColor={`${adjustHexBrightness("#ffa500", global.gemstatPHBrightness)}`}
          zoom={zoom}
        />
      )}

      {global.gemstat && (
        <GemstatLayer
          id="gemstat"
          opacity={global.gemstatOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.gemstatLayer}
          sourceLayer={"gemstat_stations_usa"}
          circleColor={`${adjustHexBrightness("#FFA500", global.gemstatBrightness)}`}
          zoom={zoom}
        />
      )}
      {global.phFloodHazard && (
        <BIALayer
          id="philippine-flood-hazard"
          opacity={global.phFloodHazardOpacity}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.PHFloodHazard}
          action={global.action}
          sourceLayer={"flood_hazard_1_50000"}
          ausAq={true}
          fillColor={[
            "match",
            ["get", "rating"],
            "Low Susceptibility",
            `${adjustHexBrightness("#FFFF00", global.phFloodHazardBrightness)}`,
            "Low to Moderate Susceptibility",
            `${adjustHexBrightness("#C500FF", global.phFloodHazardBrightness)}`,
            "Moderate Susceptibility",
            `${adjustHexBrightness("#A900E6", global.phFloodHazardBrightness)}`,
            "Moderate Susceptibility to High Susceptibility",
            `${adjustHexBrightness("#FF0000", global.phFloodHazardBrightness)}`,
            "High Susceptibility",
            `${adjustHexBrightness("#EB0000", global.phFloodHazardBrightness)}`,
            "Flood Prone",
            `${adjustHexBrightness("#D20000", global.phFloodHazardBrightness)}`,
            "transparent",
          ]}
          phfh={true}
        />
      )}
      {global.philRegions && (
        <BIALayer
          id="philippine-regions"
          opacity={global.philRegionsOpacity}
          outlineColor={`${adjustHexBrightness("#ffa500", global.philRegionsBrightness)}`}
          // @ts-ignore
          tileSet={settings.tileSetURLs.PhilRegions}
          action={global.action}
          sourceLayer={"philippines_with_regions"}
          ausAq={true}
          fillColor={"transparent"}
          phil={true}
        />
      )}
      {global.ABWDLayer && (
        <CircleVectorLayer
          zoom={zoom}
          global={global}
          id="ABWDLayer"
          opacity={global.ABWDLayerOpacity}
          tileSet={settings.tileSetURLs.ABWDLayer}
          sourceLayer={"Australia_Bores"}
          circleColor={`${adjustHexBrightness("#FFFF00", global.ABWDLayerBrightness, 7)}`}
          action={global.action}
          radius={3}
          ausBore={true}
        />
      )}
      {global.ANGISUpper && (
        <BIALayer
          id="ANGIS-upper"
          opacity={global.ANGISUpperOpacity}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.ANGISLayer}
          action={global.action}
          sourceLayer={"NGIS_Aquifer"}
          ausAq={true}
          fillColor={[
            "match",
            ["get", "AquiferTyp"],
            "Upper",
            `${adjustHexBrightness("#CB6D3C", global.ANGISUpperBrightness)}`,
            "transparent",
          ]}
          filter={["==", "AquiferTyp", "Upper"]}
        />
      )}
      {global.ANGISMid && (
        <BIALayer
          id="ANGIS-mid"
          opacity={global.ANGISMidOpacity}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.ANGISLayer}
          action={global.action}
          sourceLayer={"NGIS_Aquifer"}
          ausAq={true}
          fillColor={[
            "match",
            ["get", "AquiferTyp"],
            "Middle",
            `${adjustHexBrightness("#659532", global.ANGISMidBrightness)}`,
            "transparent",
          ]}
          filter={["==", "AquiferTyp", "Middle"]}
        />
      )}
      {global.ANGISLower && (
        <BIALayer
          id="ANGIS-lower"
          opacity={global.ANGISLowerOpacity}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.ANGISLayer}
          action={global.action}
          sourceLayer={"NGIS_Aquifer"}
          ausAq={true}
          filter={["==", "AquiferTyp", "Lower"]}
          fillColor={[
            "match",
            ["get", "AquiferTyp"],
            "Lower",
            `${adjustHexBrightness("#82B5E3", global.ANGISLowerBrightness)}`,
            "transparent",
          ]}
        />
      )}
      {global.ausPrinHydro && (
        <BIALayer
          id="aus-prin-hydro"
          opacity={global.ausPrinHydroOpacity}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.APHLayer}
          action={global.action}
          sourceLayer={"Principle_Hydrogeology"}
          ausHydro={true}
          fillColor={[
            "match",
            ["get", "TYPE"],
            "FRACTURED",
            [
              "match",
              ["get", "PRODTY"],
              "LOW-MOD",
              `${adjustHexBrightness("#CCFF99", global.ausPrinHydroBrightness)}`,
              "HIGH",
              `${adjustHexBrightness("#669900", global.ausPrinHydroBrightness)}`,
              "transparent",
            ],
            "POROUS",
            [
              "match",
              ["get", "PRODTY"],
              "LOW-MOD",
              `${adjustHexBrightness("#C6DBEF", global.ausPrinHydroBrightness)}`,
              "HIGH",
              `${adjustHexBrightness("#6699CC", global.ausPrinHydroBrightness)}`,
              "transparent",
            ],
            " ",
            `${adjustHexBrightness("#CD9A66", global.ausPrinHydroBrightness)}`,
            "transparent",
          ]}
        />
      )}
      {global.biaLayer && (
        <BIALayer
          id="bia"
          opacity={global.biaLayerOpacity}
          outlineColor={"transparent"}
          // @ts-ignore
          tileSet={settings.tileSetURLs.BIALayer}
          action={global.action}
          sourceLayer={"BIA_National_LAR"}
          fillColor={[
            "match",
            ["get", "name"],
            ["Navajo LAR"],
            `${adjustHexBrightness("#A05238", global.biaLayerBrightness)}`,
            ["Crow LAR"],
            `${adjustHexBrightness("#20476F", global.biaLayerBrightness)}`,
            ["Hopi LAR"],
            `${adjustHexBrightness("#937A57", global.biaLayerBrightness)}`,
            ["Blackfeet LAR"],
            `${adjustHexBrightness("#594B34", global.biaLayerBrightness)}`,
            ["Tohono O'odham LAR"],
            `${adjustHexBrightness("#882d17", global.biaLayerBrightness)}`,
            ["Northern Cheyenne LAR"],
            `${adjustHexBrightness("#00a98f", global.biaLayerBrightness)}`,
            ["Standing Rock LAR"],
            `${adjustHexBrightness("#0abf53", global.biaLayerBrightness)}`,
            ["Cheyenne River LAR"],
            `${adjustHexBrightness("#00a98f", global.biaLayerBrightness)}`,
            ["Pine Ridge LAR"],
            `${adjustHexBrightness("#003300", global.biaLayerBrightness)}`,
            ["Rosebud LAR"],
            `${adjustHexBrightness("#34aa79", global.biaLayerBrightness)}`,
            ["Uintah and Ouray LAR"],
            `${adjustHexBrightness("#FFAA00", global.biaLayerBrightness)}`,
            ["Fort Peck LAR"],
            `${adjustHexBrightness("#2E473B", global.biaLayerBrightness)}`,
            ["Wind River LAR"],
            `${adjustHexBrightness("#739AC5", global.biaLayerBrightness)}`,
            ["Flathead LAR"],
            `${adjustHexBrightness("#6C7B8B", global.biaLayerBrightness)}`,
            ["Fort Apache LAR"],
            `${adjustHexBrightness("#6F4242", global.biaLayerBrightness)}`,
            ["San Carlos LAR"],
            `${adjustHexBrightness("#615E3F", global.biaLayerBrightness)}`,
            ["Ute Mountain Ute LAR"],
            `${adjustHexBrightness("#551011", global.biaLayerBrightness)}`,
            ["Southern Ute LAR"],
            `${adjustHexBrightness("#551011", global.biaLayerBrightness)}`,
            ["Jicarilla Apache LAR"],
            `${adjustHexBrightness("#6F4242", global.biaLayerBrightness)}`,
            ["Taos LAR"],
            `${adjustHexBrightness("#5F9EA0", global.biaLayerBrightness)}`,
            ["Hualapai LAR"],
            `${adjustHexBrightness("#63AB62", global.biaLayerBrightness)}`,
            ["Fort Hall LAR"],
            `${adjustHexBrightness("#660000", global.biaLayerBrightness)}`,
            ["Duck Valley LAR"],
            `${adjustHexBrightness("#B8860B", global.biaLayerBrightness)}`,
            ["Walker River LAR"],
            `${adjustHexBrightness("#AAAAFF", global.biaLayerBrightness)}`,
            ["Pyramid Lake LAR"],
            `${adjustHexBrightness("#96CDCD", global.biaLayerBrightness)}`,
            ["Warm Springs LAR"],
            `${adjustHexBrightness("#F2473F", global.biaLayerBrightness)}`,
            ["Yakama LAR"],
            `${adjustHexBrightness("#FCD59C", global.biaLayerBrightness)}`,
            ["Umatilla LAR"],
            `${adjustHexBrightness("#FF6600", global.biaLayerBrightness)}`,
            ["Nez Perce LAR"],
            `${adjustHexBrightness("#FCB514", global.biaLayerBrightness)}`,
            ["Coeur D'Alene LAR"],
            `${adjustHexBrightness("#D6C537", global.biaLayerBrightness)}`,
            ["Colville LAR"],
            `${adjustHexBrightness("#D1E231", global.biaLayerBrightness)}`,
            ["Spokane LAR"],
            `${adjustHexBrightness("#ADD8E6", global.biaLayerBrightness)}`,
            ["Quinault LAR"],
            `${adjustHexBrightness("#00C5CD", global.biaLayerBrightness)}`,
            ["Fort Belknap LAR"],
            `${adjustHexBrightness("#008B45", global.biaLayerBrightness)}`,
            ["Fort Berthold LAR"],
            `${adjustHexBrightness("#218868", global.biaLayerBrightness)}`,
            ["Yankton LAR"],
            `${adjustHexBrightness("#191970", global.biaLayerBrightness)}`,
            ["Santee LAR"],
            `${adjustHexBrightness("#0AC92B", global.biaLayerBrightness)}`,
            ["Winnebago LAR"],
            `${adjustHexBrightness("#068481", global.biaLayerBrightness)}`,
            ["Omaha LAR"],
            `${adjustHexBrightness("#0BB5FF", global.biaLayerBrightness)}`,
            ["White Earth LAR"],
            `${adjustHexBrightness("#008000", global.biaLayerBrightness)}`,
            ["Red Lake LAR"],
            `${adjustHexBrightness("#8B1A1A", global.biaLayerBrightness)}`,
            ["Leech Lake LAR"],
            `${adjustHexBrightness("#96CDCD", global.biaLayerBrightness)}`,
            ["Oneida (New York) LAR"],
            `${adjustHexBrightness("#7D9EC0", global.biaLayerBrightness)}`,
            ["Lower Brule LAR"],
            `${adjustHexBrightness("#4db848", global.biaLayerBrightness)}`,
            ["Crow Creek LAR"],
            `${adjustHexBrightness("#4e88c7", global.biaLayerBrightness)}`,
            ["Zuni LAR"],
            `${adjustHexBrightness("#A05238", global.biaLayerBrightness)}`,
            ["Navajo (Ramah) LAR"],
            `${adjustHexBrightness("#20476F", global.biaLayerBrightness)}`,
            ["Acoma LAR"],
            `${adjustHexBrightness("#937A57", global.biaLayerBrightness)}`,
            ["Laguna LAR"],
            `${adjustHexBrightness("#594B34", global.biaLayerBrightness)}`,
            ["Acoma (Red Lake) LAR"],
            `${adjustHexBrightness("#882d17", global.biaLayerBrightness)}`,
            ["Navajo (Alamo) LAR"],
            `${adjustHexBrightness("#00a98f", global.biaLayerBrightness)}`,
            ["Isleta LAR"],
            `${adjustHexBrightness("#0abf53", global.biaLayerBrightness)}`,
            ["Santa Ana LAR"],
            `${adjustHexBrightness("#00a98f", global.biaLayerBrightness)}`,
            ["San Felipe LAR"],
            `${adjustHexBrightness("#003300", global.biaLayerBrightness)}`,
            ["Sandia LAR"],
            `${adjustHexBrightness("#69dead", global.biaLayerBrightness)}`,
            ["Santo Domingo LAR"],
            `${adjustHexBrightness("#FFAA00", global.biaLayerBrightness)}`,
            ["Cochiti LAR"],
            `${adjustHexBrightness("#2E473B", global.biaLayerBrightness)}`,
            ["Jemez LAR"],
            `${adjustHexBrightness("#739AC5", global.biaLayerBrightness)}`,
            ["Mescalero Apache LAR"],
            `${adjustHexBrightness("#bb89ca", global.biaLayerBrightness)}`,
            ["Bois Fort (Nett Lake) LAR"],
            `${adjustHexBrightness("#fcb315", global.biaLayerBrightness)}`,
            ["Fond du Lac LAR"],
            `${adjustHexBrightness("#946b2d", global.biaLayerBrightness)}`,
            ["Bad River LAR"],
            `${adjustHexBrightness("#222f5b", global.biaLayerBrightness)}`,
            ["Lac Courte Oreilles LAR"],
            `${adjustHexBrightness("#4f5bd5", global.biaLayerBrightness)}`,
            ["Lac du Flambeau LAR"],
            `${adjustHexBrightness("#fa7e1e", global.biaLayerBrightness)}`,
            ["Menominee LAR"],
            `${adjustHexBrightness("#00b159", global.biaLayerBrightness)}`,
            ["Oneida LAR"],
            `${adjustHexBrightness("#00aedb", global.biaLayerBrightness)}`,
            ["Isabella LAR"],
            `${adjustHexBrightness("#fb2e01", global.biaLayerBrightness)}`,
            `${adjustHexBrightness("#ffffff", global.biaLayerBrightness)}`,
          ]}
        />
      )}
      {global.philippinesRivers && (
        <LineLayer
          id="philippine-rivers"
          opacity={global.philippinesRiversOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.PhilippinesRivers}
          sourceLayer={"philippine_rivers"}
          lineColor={`${adjustHexBrightness("#00BCD4", global.philippinesRiversBrightness)}`}
          width={2}
        />
      )}
      {global.philippinesRiverBasins && (
        <LineLayer
          id="philippines-basins"
          opacity={global.philippinesRiverBasinsOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.PhilippinesRiverBasins}
          sourceLayer={"philippine_basins"}
          lineColor={`${adjustHexBrightness('#ffffff', global.philippinesRiverBasinsBrightness)}`}
          width={2}
        />
      )}
      {global.usCounties && (
        <CountiesLayer
          id="counties"
          opacity={global.usCountiesOpacity}
          lineColor={`${adjustHexBrightness("#965cc1", global.usCountiesBrightness)}`}
          tileSet={settings.tileSetURLs.usCounties}
          sourceLayer={"counties-2agbeg"}
        />
      )}
      {global.resMask && (
        <VectorTileLayer
          id="RES-MASK"
          opacity={0.5}
          outlineColor={"black"}
          tileSet={settings.tileSetURLs.resMask}
          sourceLayer={"res_mask"}
          fillColor={"black"}
        />
      )}
      {global.userOrgString === "RES" &&
        pathname !== "/NationalStreamQualityAccountingNetwork" &&
        pathname !== "/NationalStormWaterQualityDatabase2020" &&
        pathname !== "/ClimatePrecipitationCalculator" &&
        pathname !== "/RiskScreening" && (
          <VectorTileLayer
            id="RES-MASK"
            opacity={0.5}
            outlineColor={"black"}
            tileSet={settings.tileSetURLs.resMask}
            sourceLayer={"res_mask"}
            fillColor={"black"}
          />
        )}
      {global.I10Huc12s && (
        <VectorTileLayer
          id="I10-huc-12"
          opacity={global.I10Huc12sOpacity}
          outlineColor={"wheat"}
          tileSet={settings.tileSetURLs.I10Huc12s}
          sourceLayer={"I10_huc12s"}
          fillColor={`${adjustHexBrightness("#b3a500", global.I10Huc12sBrightness)}`}
        />
      )}
      {global.outflowsSelected && (
        <OutflowLayer brightness={global.outflowsSelectedBrightness} opacity={global.outflowsSelectedOpacity} />
      )}
      {global.gwNitrateAssessmentSelected && (
        <NitrateAssessmentLayer
          global={global}
          opacity={global.gwNitrateAssessmentSelectedOpacity}
        />
      )}
      {global.nhdSelected && (
        <>
          <VectorTileLayer
            id="nhd_one"
            opacity={global.nhdSelectedOpacity}
            fillColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightness)}`}
            outlineColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightness)}`}
            tileSet={settings.tileSetURLs.nhd_flowline_one}
            sourceLayer={"nhd_hr_nhdflowline_one"}
            beforeID={settings.defaultBeforeId}
          />
          <VectorTileLayer
            id="nhd_two"
            opacity={global.nhdSelectedOpacity}
            fillColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightness)}`}
            outlineColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightness)}`}
            tileSet={settings.tileSetURLs.nhd_flowline_two}
            sourceLayer={"nhd_hr_nhdflowline_two"}
            beforeID={settings.defaultBeforeId}
          />
          <VectorTileLayer
            id="nhd_waterbody"
            opacity={global.nhdSelectedOpacity}
            fillColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightness)}`}
            outlineColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightness)}`}
            tileSet={settings.tileSetURLs.nhd_waterbody_tiles}
            sourceLayer={"nhd_hr_nhdwaterbody"}
            beforeID={settings.defaultBeforeId}
          />
          <VectorTileLayer
            id="nhd_area_tiles"
            opacity={global.nhdSelectedOpacity}
            fillColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightess)}`}
            outlineColor={`${adjustHexBrightness("#00BCD4", global.nhdSelectedBrightess)}`}
            tileSet={settings.tileSetURLs.nhd_area_tiles}
            sourceLayer={"nhd_hr_nhdarea"}
            beforeID={settings.defaultBeforeId}
          />
        </>
      )}
      {global.NOAALayer && (
        <>
          <VectorTileLayer
            id="fl_layer_area"
            opacity={1}
            outlineColor={"#D49C2C"}
            tileSet={"FL_TBW_slr_final_dist"}
            sourceLayer={settings.tileSetURLs.FL_TBW}
            fillColor={"#D49C2C"}
          />
        </>
      )}
      {global.FemaGulf && (
        <FemaFloodZones
          id="fema_gulf"
          opacity={global.FemaGulfOpacity * 0.5}
          tileSet={settings.tileSetURLs.FemaGulf}
          sourceLayer={"FEMA_Fld_Haz_Ar_12021C"}
          fillColor={[
            "match",
            ["get", "FLD_ZONE"],
            ["A"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,        
            ["AE"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,       
            ["AO"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,       
            ["V"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,        
            ["VE"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,       
            ["AH"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,       
            ["D"], `${adjustHexBrightness("#F2E673", global.FemaGulfBrightness)}`,        
            ["X"], `${adjustHexBrightness("#FF8000", global.FemaGulfBrightness)}`,        
            ["A99"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,      
            ["AR"], `${adjustHexBrightness("#00E6FF", global.FemaGulfBrightness)}`,       
            ["AE;FLOODWAY"], `${adjustHexBrightness("#FF0000", global.FemaGulfBrightness)}`, 
            "transparent"
          ]}
        />
      )}


      {global.femaZones && (
        <>
          <FemaLayers
            id="AppleFEMA"
            opacity={global.femaZonesOpacity}
            outlineColor={"transparent"}
            beforeID={beforeID}
            // @ts-ignore
            tileSet={settings.tileSetURLs.femaApple}
            action={global.action}
            sourceLayer={"AppleFEMA"}
            fillColor={[
              "match",
              ["get", "FLD_ZONE"],
              ["X"],
             `${adjustHSLABrightness("hsla(123, 96%, 77%, 0.75)", global.femaZonesBrightness)}`,
              `${adjustHSLABrightness("hsla(0, 4%, 90%, 0)", global.femaZonesBrightness)}`,
            ]}
          />
          <FemaLayers
            id="deloitte_fema_zones"
            opacity={global.femaZonesOpacity}
            outlineColor={"transparent"}
            beforeID={beforeID}
            // @ts-ignore
            tileSet={settings.tileSetURLs.deloitte_fema_zones}
            action={global.action}
            sourceLayer={"deloitte_fema_zones"}
            fillColor={[
              "match",
              ["get", "FLD_ZONE"],
              ["A"],
              `${adjustHSLABrightness("hsla(0, 90%, 41%, 0.7)", global.femaZonesBrightness)}`,
              ["AO"],
              `${adjustHSLABrightness("hsla(0, 93%, 39%, 0.7)", global.femaZonesBrightness)}`,
              ["AH"],
              `${adjustHSLABrightness("hsla(0, 94%, 41%, 0.7)", global.femaZonesBrightness)}`,
              ["A1-A30"],
              `${adjustHSLABrightness("hsla(0, 89%, 36%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/A1-A30"],
              `${adjustHSLABrightness("hsla(1, 85%, 29%, 0.7)", global.femaZonesBrightness)}`,
              ["AE"],
              `${adjustHSLABrightness("hsla(5, 74%, 59%, 0.7)", global.femaZonesBrightness)}`,
              ["A99"],
              `${adjustHSLABrightness("hsla(8, 91%, 46%, 0.7)", global.femaZonesBrightness)}`,
              ["AR"],
              `${adjustHSLABrightness("hsla(0, 88%, 52%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/AE"],
              `${adjustHSLABrightness("hsla(0, 78%, 46%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/AO"],
              `${adjustHSLABrightness("hsla(0, 89%, 37%, 0.7)", global.femaZonesBrightness)}`,
              ["X"],
              `${adjustHSLABrightness("hsla(123, 96%, 77%, 0.5)", global.femaZonesBrightness)}`,
              ["B"],
              `${adjustHSLABrightness("hsl(63, 100%, 60%)", global.femaZonesBrightness)}`,
              ["AR/A"],
              `${adjustHSLABrightness("hsla(1, 91%, 44%, 0.7)", global.femaZonesBrightness)}`,
              ["V"],
              `${adjustHSLABrightness("hsla(0, 88%, 37%, 0.7)", global.femaZonesBrightness)}`,
              ["VE"],
              `${adjustHSLABrightness("hsla(0, 94%, 50%, 0.7)", global.femaZonesBrightness)}`,
              ["V1-V30"],
              `${adjustHSLABrightness("hsla(0, 88%, 41%, 0.7)", global.femaZonesBrightness)}`,
              `${adjustHSLABrightness("hsla(0, 4%, 90%, 0)", global.femaZonesBrightness)}`,
            ]}
          />
          <FemaLayers
              id="deloitte_zones"
              opacity={global.femaZonesOpacity}
              outlineColor={"transparent"}
              beforeID={beforeID}
              // @ts-ignore
              tileSet={settings.tileSetURLs.deloitte_fema}
              action={global.action}
              sourceLayer={"deloitte_fema"}
              fillColor={[
                "match",
                ["get", "FLD_ZONE"],
                ["A"],
                `${adjustHSLABrightness("hsla(0, 90%, 41%, 0.7)", global.femaZonesBrightness)}`,
                ["AO"],
                `${adjustHSLABrightness("hsla(0, 93%, 39%, 0.7)", global.femaZonesBrightness)}`,
                ["AH"],
                `${adjustHSLABrightness("hsla(0, 94%, 41%, 0.7)", global.femaZonesBrightness)}`,
                ["A1-A30"],
                `${adjustHSLABrightness("hsla(0, 89%, 36%, 0.7)", global.femaZonesBrightness)}`,
                ["AR/A1-A30"],
                `${adjustHSLABrightness("hsla(1, 85%, 29%, 0.7)", global.femaZonesBrightness)}`,
                ["AE"],
                `${adjustHSLABrightness("hsla(5, 74%, 59%, 0.7)", global.femaZonesBrightness)}`,
                ["A99"],
                `${adjustHSLABrightness("hsla(8, 91%, 46%, 0.7)", global.femaZonesBrightness)}`,
                ["AR"],
                `${adjustHSLABrightness("hsla(0, 88%, 52%, 0.7)", global.femaZonesBrightness)}`,
                ["AR/AE"],
                `${adjustHSLABrightness("hsla(0, 78%, 46%, 0.7)", global.femaZonesBrightness)}`,
                ["AR/AO"],
                `${adjustHSLABrightness("hsla(0, 89%, 37%, 0.7)", global.femaZonesBrightness)}`,
                ["X"],
                `${adjustHSLABrightness("hsla(123, 96%, 77%, 0.5)", global.femaZonesBrightness)}`,
                ["B"],
                `${adjustHSLABrightness("hsl(63, 100%, 60%)", global.femaZonesBrightness)}`,
                ["AR/A"],
                `${adjustHSLABrightness("hsla(1, 91%, 44%, 0.7)", global.femaZonesBrightness)}`,
                ["V"],
                `${adjustHSLABrightness("hsla(0, 88%, 37%, 0.7)", global.femaZonesBrightness)}`,
                ["VE"],
                `${adjustHSLABrightness("hsla(0, 94%, 50%, 0.7)", global.femaZonesBrightness)}`,
                ["V1-V30"],
                `${adjustHSLABrightness("hsla(0, 88%, 41%, 0.7)", global.femaZonesBrightness)}`,
                `${adjustHSLABrightness("hsla(0, 4%, 90%, 0)", global.femaZonesBrightness)}`,
              ]}
            />
          <FemaLayers
            id="RES-FEMA1"
            opacity={global.femaZonesOpacity}
            beforeID={beforeID}
            outlineColor={"transparent"}
            tileSet={settings.tileSetURLs.resFEMA}
            sourceLayer={"RES_FEMA_LAYER"}
            fillColor={[
              "match",
              ["get", "FLD_ZONE"],
              ["A"],
              `${adjustHSLABrightness("hsla(0, 90%, 41%, 0.7)", global.femaZonesBrightness)}`,
              ["AO"],
              `${adjustHSLABrightness("hsla(0, 93%, 39%, 0.7)", global.femaZonesBrightness)}`,
              ["AH"],
              `${adjustHSLABrightness("hsla(0, 94%, 41%, 0.7)", global.femaZonesBrightness)}`,
              ["A1-A30"],
              `${adjustHSLABrightness("hsla(0, 89%, 36%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/A1-A30"],
              `${adjustHSLABrightness("hsla(1, 85%, 29%, 0.7)", global.femaZonesBrightness)}`,
              ["AE"],
              `${adjustHSLABrightness("hsla(5, 74%, 59%, 0.7)", global.femaZonesBrightness)}`,
              ["A99"],
              `${adjustHSLABrightness("hsla(8, 91%, 46%, 0.7)", global.femaZonesBrightness)}`,
              ["AR"],
              `${adjustHSLABrightness("hsla(0, 88%, 52%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/AE"],
              `${adjustHSLABrightness("hsla(0, 78%, 46%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/AO"],
              `${adjustHSLABrightness("hsla(0, 89%, 37%, 0.7)", global.femaZonesBrightness)}`,
              ["X"],
              `${adjustHSLABrightness("hsla(123, 96%, 77%, 0.5)", global.femaZonesBrightness)}`,
              ["B"],
              `${adjustHSLABrightness("hsl(63, 100%, 60%)", global.femaZonesBrightness)}`,
              ["AR/A"],
              `${adjustHSLABrightness("hsla(1, 91%, 44%, 0.7)", global.femaZonesBrightness)}`,
              ["V"],
              `${adjustHSLABrightness("hsla(0, 88%, 37%, 0.7)", global.femaZonesBrightness)}`,
              ["VE"],
              `${adjustHSLABrightness("hsla(0, 94%, 50%, 0.7)", global.femaZonesBrightness)}`,
              ["V1-V30"],
              `${adjustHSLABrightness("hsla(0, 88%, 41%, 0.7)", global.femaZonesBrightness)}`,
              `${adjustHSLABrightness("hsla(0, 4%, 90%, 0)", global.femaZonesBrightness)}`,
            ]}
          />
          <FemaLayers
            id="fema_zones"
            opacity={global.femaZonesOpacity}
            beforeID={beforeID}
            outlineColor={"transparent"}
            tileSet={settings.tileSetURLs.FEMA_Flood_Hazard_Zones}
            sourceLayer={"FEMA_Flood_Hazard_Zones"}
            fillColor={[
              "match",
              ["get", "FLD_ZONE"],
              ["A"],
              `${adjustHSLABrightness("hsla(0, 90%, 41%, 0.7)", global.femaZonesBrightness)}`,
              ["AO"],
              `${adjustHSLABrightness("hsla(0, 93%, 39%, 0.7)", global.femaZonesBrightness)}`,
              ["AH"],
              `${adjustHSLABrightness("hsla(0, 94%, 41%, 0.7)", global.femaZonesBrightness)}`,
              ["A1-A30"],
              `${adjustHSLABrightness("hsla(0, 89%, 36%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/A1-A30"],
              `${adjustHSLABrightness("hsla(1, 85%, 29%, 0.7)", global.femaZonesBrightness)}`,
              ["AE"],
              `${adjustHSLABrightness("hsla(5, 74%, 59%, 0.7)", global.femaZonesBrightness)}`,
              ["A99"],
              `${adjustHSLABrightness("hsla(8, 91%, 46%, 0.7)", global.femaZonesBrightness)}`,
              ["AR"],
              `${adjustHSLABrightness("hsla(0, 88%, 52%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/AE"],
              `${adjustHSLABrightness("hsla(0, 78%, 46%, 0.7)", global.femaZonesBrightness)}`,
              ["AR/AO"],
              `${adjustHSLABrightness("hsla(0, 89%, 37%, 0.7)", global.femaZonesBrightness)}`,
              ["X"],
              `${adjustHSLABrightness("hsla(123, 96%, 77%, 0.5)", global.femaZonesBrightness)}`,
              ["B"],
              `${adjustHSLABrightness("hsl(63, 100%, 60%)", global.femaZonesBrightness)}`,
              ["AR/A"],
              `${adjustHSLABrightness("hsla(1, 91%, 44%, 0.7)", global.femaZonesBrightness)}`,
              ["V"],
              `${adjustHSLABrightness("hsla(0, 88%, 37%, 0.7)", global.femaZonesBrightness)}`,
              ["VE"],
              `${adjustHSLABrightness("hsla(0, 94%, 50%, 0.7)", global.femaZonesBrightness)}`,
              ["V1-V30"],
              `${adjustHSLABrightness("hsla(0, 88%, 41%, 0.7)", global.femaZonesBrightness)}`,
              `${adjustHSLABrightness("hsla(0, 4%, 90%, 0)", global.femaZonesBrightness)}`,
            ]}
          />
          <FemaLayers
            id="fema_zones_2"
            opacity={global.femaZonesOpacity}
            beforeID={beforeID}
            outlineColor={"transparent"}
            tileSet={settings.tileSetURLs.FEMA_Flood_Hazard_Zones}
            sourceLayer={"FEMA_Flood_Hazard_Zones"}
            fillColor={[
              "match",
              ["get", "ZONE_SUBTY"],
              ["0.2 PCT ANNUAL CHANCE FLOOD HAZARD"],
              `${adjustHSLABrightness("hsla(51, 61%, 48%, 0.7)", global.femaZonesBrightness)}`,
              `${adjustHSLABrightness("hsla(0, 0%, 0%, 0)", global.femaZonesBrightness)}`,
            ]}
          />
        </>
      )}

      {global.aqueductAtlasCoastalFlood && (
        <GlobalMapWithReport
          id="aqueduct_atlas_coastal_flood"
          opacity={global.aqueductAtlasCoastalFloodOpacity}
          beforeID={beforeID}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.aqueductAtlas}
          sourceLayer={"Aqueduct_Water_Risk_Atlas_Annual"}
          action={global.action}
          reportRoute={"/AqueductWaterRiskAtlas"}
          sourceLabel={"Aqueduct Water Risk Atlas"}
          sourceLink={"https://www.wri.org/publication/aqueduct-30"}
          tableColumns={[
            {
              Header: "aq30_id",
              accessor: "aq30_id",
            },
            {
              Header: "aqid",
              accessor: "aqid",
            },
            {
              Header: "area_km2",
              accessor: "area_km2",
            },
            {
              Header: "bwd_cat",
              accessor: "bwd_cat",
            },
            {
              Header: "bwd_label",
              accessor: "bwd_label",
            },
            {
              Header: "bwd_raw",
              accessor: "bwd_raw",
            },
            {
              Header: "bwd_score",
              accessor: "bwd_score",
            },
            {
              Header: "bws_cat",
              accessor: "bws_cat",
            },
            {
              Header: "bws_label",
              accessor: "bws_label",
            },
            {
              Header: "bws_raw",
              accessor: "bws_raw",
            },
            {
              Header: "bws_score",
              accessor: "bws_score",
            },
            {
              Header: "cep_cat",
              accessor: "cep_cat",
            },
            {
              Header: "cep_label",
              accessor: "cep_label",
            },
            {
              Header: "cep_raw",
              accessor: "cep_raw",
            },
            {
              Header: "cep_score",
              accessor: "cep_score",
            },
            {
              Header: "cfr_cat",
              accessor: "cfr_cat",
            },
            {
              Header: "cfr_label",
              accessor: "cfr_label",
            },
            {
              Header: "cfr_raw",
              accessor: "cfr_raw",
            },
            {
              Header: "cfr_score",
              accessor: "cfr_score",
            },
            {
              Header: "drr_cat",
              accessor: "drr_cat",
            },
            {
              Header: "drr_label",
              accessor: "drr_label",
            },
            {
              Header: "drr_raw",
              accessor: "drr_raw",
            },
            {
              Header: "drr_score",
              accessor: "drr_score",
            },
            {
              Header: "gid_0",
              accessor: "gid_0",
            },
            {
              Header: "gid_1",
              accessor: "gid_1",
            },
            {
              Header: "gtd_cat",
              accessor: "gtd_cat",
            },
            {
              Header: "gtd_label",
              accessor: "gtd_label",
            },
            {
              Header: "gtd_raw",
              accessor: "gtd_raw",
            },
            {
              Header: "gtd_score",
              accessor: "gtd_score",
            },
            {
              Header: "iav_cat",
              accessor: "iav_cat",
            },
            {
              Header: "iav_label",
              accessor: "iav_label",
            },
            {
              Header: "iav_raw",
              accessor: "iav_raw",
            },
            {
              Header: "iav_score",
              accessor: "iav_score",
            },
            {
              Header: "name_0",
              accessor: "name_0",
            },
            {
              Header: "name_1",
              accessor: "name_1",
            },
            {
              Header: "pfaf_id",
              accessor: "pfaf_id",
            },
            {
              Header: "rfr_cat",
              accessor: "rfr_cat",
            },
            {
              Header: "rfr_label",
              accessor: "rfr_label",
            },
            {
              Header: "rfr_raw",
              accessor: "rfr_raw",
            },
            {
              Header: "rfr_score",
              accessor: "rfr_score",
            },
            {
              Header: "rri_cat",
              accessor: "rri_cat",
            },
            {
              Header: "rri_label",
              accessor: "rri_label",
            },
            {
              Header: "rri_score",
              accessor: "rri_score",
            },
            {
              Header: "sev_cat",
              accessor: "sev_cat",
            },
            {
              Header: "sev_label",
              accessor: "sev_label",
            },
            {
              Header: "sev_raw",
              accessor: "sev_raw",
            },
            {
              Header: "sev_score",
              accessor: "sev_score",
            },
            {
              Header: "string_id",
              accessor: "string_id",
            },
            {
              Header: "ucw_cat",
              accessor: "ucw_cat",
            },
            {
              Header: "ucw_label",
              accessor: "ucw_label",
            },
            {
              Header: "ucw_raw",
              accessor: "ucw_raw",
            },
            {
              Header: "ucw_score",
              accessor: "ucw_score",
            },
            {
              Header: "udw_cat",
              accessor: "udw_cat",
            },
            {
              Header: "udw_label",
              accessor: "udw_label",
            },
            {
              Header: "udw_raw",
              accessor: "udw_raw",
            },
            {
              Header: "udw_score",
              accessor: "udw_score",
            },
            {
              Header: "usa_cat",
              accessor: "usa_cat",
            },
            {
              Header: "usa_label",
              accessor: "usa_label",
            },
            {
              Header: "usa_raw",
              accessor: "usa_raw",
            },
            {
              Header: "usa_score",
              accessor: "usa_score",
            },
            {
              Header: "w_awr_agr_qal_cat",
              accessor: "w_awr_agr_qal_cat",
            },
            {
              Header: "w_awr_agr_qal_label",
              accessor: "w_awr_agr_qal_label",
            },
            {
              Header: "w_awr_agr_qal_raw",
              accessor: "w_awr_agr_qal_raw",
            },
            {
              Header: "w_awr_agr_qal_score",
              accessor: "w_awr_agr_qal_score",
            },
            {
              Header: "w_awr_agr_qal_weight_fraction",
              accessor: "w_awr_agr_qal_weight_fraction",
            },
            {
              Header: "w_awr_agr_qan_cat",
              accessor: "w_awr_agr_qan_cat",
            },
            {
              Header: "w_awr_agr_qan_label",
              accessor: "w_awr_agr_qan_label",
            },
            {
              Header: "w_awr_agr_qan_raw",
              accessor: "w_awr_agr_qan_raw",
            },
            {
              Header: "w_awr_agr_qan_score",
              accessor: "w_awr_agr_qan_score",
            },
            {
              Header: "w_awr_agr_qan_weight_fraction",
              accessor: "w_awr_agr_qan_weight_fraction",
            },
            {
              Header: "w_awr_agr_rrr_cat",
              accessor: "w_awr_agr_rrr_cat",
            },
            {
              Header: "w_awr_agr_rrr_label",
              accessor: "w_awr_agr_rrr_label",
            },
            {
              Header: "w_awr_agr_rrr_raw",
              accessor: "w_awr_agr_rrr_raw",
            },
            {
              Header: "w_awr_agr_rrr_score",
              accessor: "w_awr_agr_rrr_score",
            },
            {
              Header: "w_awr_agr_rrr_weight_fraction",
              accessor: "w_awr_agr_rrr_weight_fraction",
            },
            {
              Header: "w_awr_agr_tot_cat",
              accessor: "w_awr_agr_tot_cat",
            },
            {
              Header: "w_awr_agr_tot_label",
              accessor: "w_awr_agr_tot_label",
            },
            {
              Header: "w_awr_agr_tot_raw",
              accessor: "w_awr_agr_tot_raw",
            },
            {
              Header: "w_awr_agr_tot_score",
              accessor: "w_awr_agr_tot_score",
            },
            {
              Header: "w_awr_agr_tot_weight_fraction",
              accessor: "w_awr_agr_tot_weight_fraction",
            },
            {
              Header: "w_awr_che_qal_cat",
              accessor: "w_awr_che_qal_cat",
            },
            {
              Header: "w_awr_che_qal_label",
              accessor: "w_awr_che_qal_label",
            },
            {
              Header: "w_awr_che_qal_raw",
              accessor: "w_awr_che_qal_raw",
            },
            {
              Header: "w_awr_che_qal_score",
              accessor: "w_awr_che_qal_score",
            },
            {
              Header: "w_awr_che_qal_weight_fraction",
              accessor: "w_awr_che_qal_weight_fraction",
            },
            {
              Header: "w_awr_che_qan_cat",
              accessor: "w_awr_che_qan_cat",
            },
            {
              Header: "w_awr_che_qan_label",
              accessor: "w_awr_che_qan_label",
            },
            {
              Header: "w_awr_che_qan_raw",
              accessor: "w_awr_che_qan_raw",
            },
            {
              Header: "w_awr_che_qan_score",
              accessor: "w_awr_che_qan_score",
            },
            {
              Header: "w_awr_che_qan_weight_fraction",
              accessor: "w_awr_che_qan_weight_fraction",
            },
            {
              Header: "w_awr_che_rrr_cat",
              accessor: "w_awr_che_rrr_cat",
            },
            {
              Header: "w_awr_che_rrr_label",
              accessor: "w_awr_che_rrr_label",
            },
            {
              Header: "w_awr_che_rrr_raw",
              accessor: "w_awr_che_rrr_raw",
            },
            {
              Header: "w_awr_che_rrr_score",
              accessor: "w_awr_che_rrr_score",
            },
            {
              Header: "w_awr_che_rrr_weight_fraction",
              accessor: "w_awr_che_rrr_weight_fraction",
            },
            {
              Header: "w_awr_che_tot_cat",
              accessor: "w_awr_che_tot_cat",
            },
            {
              Header: "w_awr_che_tot_label",
              accessor: "w_awr_che_tot_label",
            },
            {
              Header: "w_awr_che_tot_raw",
              accessor: "w_awr_che_tot_raw",
            },
            {
              Header: "w_awr_che_tot_score",
              accessor: "w_awr_che_tot_score",
            },
            {
              Header: "w_awr_che_tot_weight_fraction",
              accessor: "w_awr_che_tot_weight_fraction",
            },
            {
              Header: "w_awr_con_qal_cat",
              accessor: "w_awr_con_qal_cat",
            },
            {
              Header: "w_awr_con_qal_label",
              accessor: "w_awr_con_qal_label",
            },
            {
              Header: "w_awr_con_qal_score",
              accessor: "w_awr_con_qal_score",
            },
            {
              Header: "w_awr_con_qal_weight_fraction",
              accessor: "w_awr_con_qal_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_cat",
              accessor: "w_awr_con_qan_cat",
            },
            {
              Header: "w_awr_con_qan_label",
              accessor: "w_awr_con_qan_label",
            },
            {
              Header: "w_awr_con_qan_raw",
              accessor: "w_awr_con_qan_raw",
            },
            {
              Header: "w_awr_con_qan_weight_fraction",
              accessor: "w_awr_con_qan_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_score",
              accessor: "w_awr_con_qan_score",
            },
            {
              Header: "w_awr_con_rrr_cat",
              accessor: "w_awr_con_rrr_cat",
            },
            {
              Header: "w_awr_con_rrr_label",
              accessor: "w_awr_con_rrr_label",
            },
            {
              Header: "w_awr_con_rrr_raw",
              accessor: "w_awr_con_rrr_raw",
            },
            {
              Header: "w_awr_con_rrr_score",
              accessor: "w_awr_con_rrr_score",
            },
            {
              Header: "w_awr_con_rrr_weight_fraction",
              accessor: "w_awr_con_rrr_weight_fraction",
            },
            {
              Header: "w_awr_con_tot_cat",
              accessor: "w_awr_con_tot_cat",
            },
            {
              Header: "w_awr_con_tot_label",
              accessor: "w_awr_con_tot_label",
            },
            {
              Header: "w_awr_con_tot_raw",
              accessor: "w_awr_con_tot_raw",
            },
            {
              Header: "w_awr_con_tot_score",
              accessor: "w_awr_con_tot_score",
            },
            {
              Header: "w_awr_con_tot_weight_fraction",
              accessor: "w_awr_con_tot_weight_fraction",
            },
            {
              Header: "w_awr_def_qal_cat",
              accessor: "w_awr_def_qal_cat",
            },
            {
              Header: "w_awr_def_qal_label",
              accessor: "w_awr_def_qal_label",
            },
            {
              Header: "w_awr_def_qal_raw",
              accessor: "w_awr_def_qal_raw",
            },
            {
              Header: "w_awr_def_qal_score",
              accessor: "w_awr_def_qal_score",
            },
            {
              Header: "w_awr_def_qal_weight_fraction",
              accessor: "w_awr_def_qal_weight_fraction",
            },
            {
              Header: "w_awr_def_qan_cat",
              accessor: "w_awr_def_qan_cat",
            },
            {
              Header: "w_awr_def_qan_label",
              accessor: "w_awr_def_qan_label",
            },
            {
              Header: "w_awr_def_qan_raw",
              accessor: "w_awr_def_qan_raw",
            },
            {
              Header: "w_awr_def_qan_score",
              accessor: "w_awr_def_qan_score",
            },
            {
              Header: "w_awr_def_qan_weight_fraction",
              accessor: "w_awr_def_qan_weight_fraction",
            },
            {
              Header: "w_awr_def_rrr_cat",
              accessor: "w_awr_def_rrr_cat",
            },
            {
              Header: "w_awr_def_rrr_label",
              accessor: "w_awr_def_rrr_label",
            },
            {
              Header: "w_awr_def_rrr_raw",
              accessor: "w_awr_def_rrr_raw",
            },
            {
              Header: "w_awr_def_rrr_score",
              accessor: "w_awr_def_rrr_score",
            },
            {
              Header: "w_awr_def_rrr_weight_fraction",
              accessor: "w_awr_def_rrr_weight_fraction",
            },
            {
              Header: "w_awr_def_tot_cat",
              accessor: "w_awr_def_tot_cat",
            },
            {
              Header: "w_awr_def_tot_label",
              accessor: "w_awr_def_tot_label",
            },
            {
              Header: "w_awr_def_tot_raw",
              accessor: "w_awr_def_tot_raw",
            },
            {
              Header: "w_awr_def_tot_score",
              accessor: "w_awr_def_tot_score",
            },
            {
              Header: "w_awr_def_tot_weight_fraction",
              accessor: "w_awr_def_tot_weight_fraction",
            },
            {
              Header: "w_awr_elp_qal_cat",
              accessor: "w_awr_elp_qal_cat",
            },
            {
              Header: "w_awr_elp_qal_label",
              accessor: "w_awr_elp_qal_label",
            },
            {
              Header: "w_awr_elp_qal_raw",
              accessor: "w_awr_elp_qal_raw",
            },
            {
              Header: "w_awr_elp_qal_score",
              accessor: "w_awr_elp_qal_score",
            },
            {
              Header: "w_awr_elp_qal_weight_fraction",
              accessor: "w_awr_elp_qal_weight_fraction",
            },
            {
              Header: "w_awr_elp_qan_cat",
              accessor: "w_awr_elp_qan_cat",
            },
            {
              Header: "w_awr_elp_qan_label",
              accessor: "w_awr_elp_qan_label",
            },
            {
              Header: "w_awr_elp_qan_raw",
              accessor: "w_awr_elp_qan_raw",
            },
            {
              Header: "w_awr_elp_qan_score",
              accessor: "w_awr_elp_qan_score",
            },
            {
              Header: "w_awr_elp_qan_weight_fraction",
              accessor: "w_awr_elp_qan_weight_fraction",
            },
            {
              Header: "w_awr_elp_rrr_cat",
              accessor: "w_awr_elp_rrr_cat",
            },
            {
              Header: "w_awr_elp_rrr_label",
              accessor: "w_awr_elp_rrr_label",
            },
            {
              Header: "w_awr_elp_rrr_raw",
              accessor: "w_awr_elp_rrr_raw",
            },
            {
              Header: "w_awr_elp_rrr_score",
              accessor: "w_awr_elp_rrr_score",
            },
            {
              Header: "w_awr_elp_rrr_weight_fraction",
              accessor: "w_awr_elp_rrr_weight_fraction",
            },
            {
              Header: "w_awr_elp_tot_cat",
              accessor: "w_awr_elp_tot_cat",
            },
            {
              Header: "w_awr_elp_tot_label",
              accessor: "w_awr_elp_tot_label",
            },
            {
              Header: "w_awr_elp_tot_raw",
              accessor: "w_awr_elp_tot_raw",
            },
            {
              Header: "w_awr_elp_tot_score",
              accessor: "w_awr_elp_tot_score",
            },
            {
              Header: "w_awr_elp_tot_weight_fraction",
              accessor: "w_awr_elp_tot_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qal_cat",
              accessor: "w_awr_fnb_qal_cat",
            },
            {
              Header: "w_awr_fnb_qal_label",
              accessor: "w_awr_fnb_qal_label",
            },
            {
              Header: "w_awr_fnb_qal_raw",
              accessor: "w_awr_fnb_qal_raw",
            },
            {
              Header: "w_awr_fnb_qal_score",
              accessor: "w_awr_fnb_qal_score",
            },
            {
              Header: "w_awr_fnb_qal_weight_fraction",
              accessor: "w_awr_fnb_qal_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qan_cat",
              accessor: "w_awr_fnb_qan_cat",
            },
            {
              Header: "w_awr_fnb_qan_label",
              accessor: "w_awr_fnb_qan_label",
            },
            {
              Header: "w_awr_fnb_qan_raw",
              accessor: "w_awr_fnb_qan_raw",
            },
            {
              Header: "w_awr_fnb_qan_score",
              accessor: "w_awr_fnb_qan_score",
            },
            {
              Header: "w_awr_fnb_qan_weight_fraction",
              accessor: "w_awr_fnb_qan_weight_fraction",
            },
            {
              Header: "w_awr_fnb_rrr_cat",
              accessor: "w_awr_fnb_rrr_cat",
            },
            {
              Header: "w_awr_fnb_rrr_label",
              accessor: "w_awr_fnb_rrr_label",
            },
            {
              Header: "w_awr_fnb_rrr_raw",
              accessor: "w_awr_fnb_rrr_raw",
            },
            {
              Header: "w_awr_fnb_rrr_score",
              accessor: "w_awr_fnb_rrr_score",
            },
            {
              Header: "w_awr_fnb_rrr_weight_fraction",
              accessor: "w_awr_fnb_rrr_weight_fraction",
            },
            {
              Header: "w_awr_fnb_tot_cat",
              accessor: "w_awr_fnb_tot_cat",
            },
            {
              Header: "w_awr_fnb_tot_label",
              accessor: "w_awr_fnb_tot_label",
            },
            {
              Header: "w_awr_fnb_tot_raw",
              accessor: "w_awr_fnb_tot_raw",
            },
            {
              Header: "w_awr_fnb_tot_score",
              accessor: "w_awr_fnb_tot_score",
            },
            {
              Header: "w_awr_fnb_tot_weight_fraction",
              accessor: "w_awr_fnb_tot_weight_fraction",
            },
            {
              Header: "w_awr_min_qal_cat",
              accessor: "w_awr_min_qal_cat",
            },
            {
              Header: "w_awr_min_qal_label",
              accessor: "w_awr_min_qal_label",
            },
            {
              Header: "w_awr_min_qal_raw",
              accessor: "w_awr_min_qal_raw",
            },
            {
              Header: "w_awr_min_qal_score",
              accessor: "w_awr_min_qal_score",
            },
            {
              Header: "w_awr_min_qal_weight_fraction",
              accessor: "w_awr_min_qal_weight_fraction",
            },
            {
              Header: "w_awr_min_qan_cat",
              accessor: "w_awr_min_qan_cat",
            },
            {
              Header: "w_awr_min_qan_label",
              accessor: "w_awr_min_qan_label",
            },
            {
              Header: "w_awr_min_qan_raw",
              accessor: "w_awr_min_qan_raw",
            },
            {
              Header: "w_awr_min_qan_score",
              accessor: "w_awr_min_qan_score",
            },
            {
              Header: "w_awr_min_qan_weight_fraction",
              accessor: "w_awr_min_qan_weight_fraction",
            },
            {
              Header: "w_awr_min_rrr_cat",
              accessor: "w_awr_min_rrr_cat",
            },
            {
              Header: "w_awr_min_rrr_label",
              accessor: "w_awr_min_rrr_label",
            },
            {
              Header: "w_awr_min_rrr_raw",
              accessor: "w_awr_min_rrr_raw",
            },
            {
              Header: "w_awr_min_rrr_score",
              accessor: "w_awr_min_rrr_score",
            },
            {
              Header: "w_awr_min_rrr_weight_fraction",
              accessor: "w_awr_min_rrr_weight_fraction",
            },
            {
              Header: "w_awr_min_tot_cat",
              accessor: "w_awr_min_tot_cat",
            },
            {
              Header: "w_awr_min_tot_label",
              accessor: "w_awr_min_tot_label",
            },
            {
              Header: "w_awr_min_tot_raw",
              accessor: "w_awr_min_tot_raw",
            },
            {
              Header: "w_awr_min_tot_score",
              accessor: "w_awr_min_tot_score",
            },
            {
              Header: "w_awr_min_tot_weight_fraction",
              accessor: "w_awr_min_tot_weight_fraction",
            },
            {
              Header: "w_awr_ong_qal_cat",
              accessor: "w_awr_ong_qal_cat",
            },
            {
              Header: "w_awr_ong_qal_label",
              accessor: "w_awr_ong_qal_label",
            },
            {
              Header: "w_awr_ong_qal_raw",
              accessor: "w_awr_ong_qal_raw",
            },
            {
              Header: "w_awr_ong_qal_score",
              accessor: "w_awr_ong_qal_score",
            },
            {
              Header: "w_awr_ong_qan_cat",
              accessor: "w_awr_ong_qan_cat",
            },
            {
              Header: "w_awr_ong_qan_label",
              accessor: "w_awr_ong_qan_label",
            },
            {
              Header: "w_awr_ong_qan_raw",
              accessor: "w_awr_ong_qan_raw",
            },
            {
              Header: "w_awr_ong_qan_score",
              accessor: "w_awr_ong_qan_score",
            },
            {
              Header: "w_awr_ong_qan_weight_fraction",
              accessor: "w_awr_ong_qan_weight_fraction",
            },
            {
              Header: "w_awr_ong_rrr_cat",
              accessor: "w_awr_ong_rrr_cat",
            },
            {
              Header: "w_awr_ong_rrr_label",
              accessor: "w_awr_ong_rrr_label",
            },
            {
              Header: "w_awr_ong_rrr_raw",
              accessor: "w_awr_ong_rrr_raw",
            },
            {
              Header: "w_awr_ong_rrr_score",
              accessor: "w_awr_ong_rrr_score",
            },
            {
              Header: "w_awr_ong_rrr_weight_fraction",
              accessor: "w_awr_ong_rrr_weight_fraction",
            },
            {
              Header: "w_awr_ong_tot_cat",
              accessor: "w_awr_ong_tot_cat",
            },
            {
              Header: "w_awr_ong_tot_label",
              accessor: "w_awr_ong_tot_label",
            },
            {
              Header: "w_awr_ong_tot_raw",
              accessor: "w_awr_ong_tot_raw",
            },
            {
              Header: "w_awr_ong_tot_score",
              accessor: "w_awr_ong_tot_score",
            },
            {
              Header: "w_awr_ong_tot_weight_fraction",
              accessor: "w_awr_ong_tot_weight_fraction",
            },
            {
              Header: "w_awr_smc_qal_cat",
              accessor: "w_awr_smc_qal_cat",
            },
            {
              Header: "w_awr_smc_qal_label",
              accessor: "w_awr_smc_qal_label",
            },
            {
              Header: "w_awr_smc_qal_raw",
              accessor: "w_awr_smc_qal_raw",
            },
            {
              Header: "w_awr_smc_qal_score",
              accessor: "w_awr_smc_qal_score",
            },
            {
              Header: "w_awr_smc_qal_weight_fraction",
              accessor: "w_awr_smc_qal_weight_fraction",
            },
            {
              Header: "w_awr_smc_qan_cat",
              accessor: "w_awr_smc_qan_cat",
            },
            {
              Header: "w_awr_smc_qan_label",
              accessor: "w_awr_smc_qan_label",
            },
            {
              Header: "w_awr_smc_qan_raw",
              accessor: "w_awr_smc_qan_raw",
            },
            {
              Header: "w_awr_smc_qan_score",
              accessor: "w_awr_smc_qan_score",
            },
            {
              Header: "w_awr_smc_qan_weight_fraction",
              accessor: "w_awr_smc_qan_weight_fraction",
            },
            {
              Header: "w_awr_smc_rrr_cat",
              accessor: "w_awr_smc_rrr_cat",
            },
            {
              Header: "w_awr_smc_rrr_label",
              accessor: "w_awr_smc_rrr_label",
            },
            {
              Header: "w_awr_smc_rrr_raw",
              accessor: "w_awr_smc_rrr_raw",
            },
            {
              Header: "w_awr_smc_rrr_score",
              accessor: "w_awr_smc_rrr_score",
            },
            {
              Header: "w_awr_smc_rrr_weight_fraction",
              accessor: "w_awr_smc_rrr_weight_fraction",
            },
            {
              Header: "w_awr_smc_tot_cat",
              accessor: "w_awr_smc_tot_cat",
            },
            {
              Header: "w_awr_smc_tot_label",
              accessor: "w_awr_smc_tot_label",
            },
            {
              Header: "w_awr_smc_tot_raw",
              accessor: "w_awr_smc_tot_raw",
            },
            {
              Header: "w_awr_smc_tot_score",
              accessor: "w_awr_smc_tot_score",
            },
            {
              Header: "w_awr_smc_tot_weight_fraction",
              accessor: "w_awr_smc_tot_weight_fraction",
            },
            {
              Header: "w_awr_tex_qal_cat",
              accessor: "w_awr_tex_qal_cat",
            },
            {
              Header: "w_awr_tex_qal_label",
              accessor: "w_awr_tex_qal_label",
            },
            {
              Header: "w_awr_tex_qal_raw",
              accessor: "w_awr_tex_qal_raw",
            },
            {
              Header: "w_awr_tex_qal_score",
              accessor: "w_awr_tex_qal_score",
            },
            {
              Header: "w_awr_tex_qal_weight_fraction",
              accessor: "w_awr_tex_qal_weight_fraction",
            },
            {
              Header: "w_awr_tex_qan_cat",
              accessor: "w_awr_tex_qan_cat",
            },
            {
              Header: "w_awr_tex_qan_label",
              accessor: "w_awr_tex_qan_label",
            },
            {
              Header: "w_awr_tex_qan_raw",
              accessor: "w_awr_tex_qan_raw",
            },
            {
              Header: "w_awr_tex_qan_weight_fraction",
              accessor: "w_awr_tex_qan_weight_fraction",
            },
            {
              Header: "w_awr_tex_rrr_cat",
              accessor: "w_awr_tex_rrr_cat",
            },
            {
              Header: "w_awr_tex_rrr_label",
              accessor: "w_awr_tex_rrr_label",
            },
            {
              Header: "w_awr_tex_rrr_raw",
              accessor: "w_awr_tex_rrr_raw",
            },
            {
              Header: "w_awr_tex_rrr_score",
              accessor: "w_awr_tex_rrr_score",
            },
            {
              Header: "w_awr_tex_rrr_weight_fraction",
              accessor: "w_awr_tex_rrr_weight_fraction",
            },
            {
              Header: "w_awr_tex_tot_cat",
              accessor: "w_awr_tex_tot_cat",
            },
            {
              Header: "w_awr_tex_tot_label",
              accessor: "w_awr_tex_tot_label",
            },
            {
              Header: "w_awr_tex_tot_raw",
              accessor: "w_awr_tex_tot_raw",
            },
            {
              Header: "w_awr_tex_tot_score",
              accessor: "w_awr_tex_tot_score",
            },
            {
              Header: "w_awr_tex_tot_weight_fraction",
              accessor: "w_awr_tex_tot_weight_fraction",
            },
          ]}
          fillColor={[
            "case",
            [
              "match",
              ["get", "cfr_label"],
              ["Extremely High (more than 2 in 1,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#4C1523", global.aqueductAtlasCoastalFloodBrightness)}`,
            [
              "match",
              ["get", "cfr_label"],
              ["High (3 in 10,000 to 2 in 1,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#7F1D24", global.aqueductAtlasCoastalFloodBrightness)}`,
            [
              "match",
              ["get", "bws_label"],
              ["Medium - High (7 in 100,000 to 3 in 10,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#867A20", global.aqueductAtlasCoastalFloodBrightness)}`,
            [
              "match",
              ["get", "cfr_label"],
              ["Low - Medium (9 in 1,000,000 to 7 in 100,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#6E8C2B", global.aqueductAtlasCoastalFloodBrightness)}`,
            [
              "match",
              ["get", "cfr_label"],
              ["Low (0 to 9 in 1,000,000)"],
              true,
              false,
            ],
            `${adjustHexBrightness("#283F37", global.aqueductAtlasCoastalFloodBrightness)}`,
            "hsla(0, 0%, 0%, 0)",
          ]}
        />
      )}

      {global.aqueductAtlasWaterStress && (
        <GlobalMapWithReport
          id="aqueduct_atlas_water_stress"
          beforeID={beforeID}
          opacity={global.aqueductAtlasWaterStressOpacity}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.aqueductAtlas}
          action={global.action}
          reportRoute={"/AqueductWaterRiskAtlas"}
          sourceLayer={"Aqueduct_Water_Risk_Atlas_Annual"}
          sourceLabel={"Aqueduct Water Risk Atlas"}
          sourceLink={"https://www.wri.org/publication/aqueduct-30"}
          tableColumns={[
            {
              Header: "aq30_id",
              accessor: "aq30_id",
            },
            {
              Header: "aqid",
              accessor: "aqid",
            },
            {
              Header: "area_km2",
              accessor: "area_km2",
            },
            {
              Header: "bwd_cat",
              accessor: "bwd_cat",
            },
            {
              Header: "bwd_label",
              accessor: "bwd_label",
            },
            {
              Header: "bwd_raw",
              accessor: "bwd_raw",
            },
            {
              Header: "bwd_score",
              accessor: "bwd_score",
            },
            {
              Header: "bws_cat",
              accessor: "bws_cat",
            },
            {
              Header: "bws_label",
              accessor: "bws_label",
            },
            {
              Header: "bws_raw",
              accessor: "bws_raw",
            },
            {
              Header: "bws_score",
              accessor: "bws_score",
            },
            {
              Header: "cep_cat",
              accessor: "cep_cat",
            },
            {
              Header: "cep_label",
              accessor: "cep_label",
            },
            {
              Header: "cep_raw",
              accessor: "cep_raw",
            },
            {
              Header: "cep_score",
              accessor: "cep_score",
            },
            {
              Header: "cfr_cat",
              accessor: "cfr_cat",
            },
            {
              Header: "cfr_label",
              accessor: "cfr_label",
            },
            {
              Header: "cfr_raw",
              accessor: "cfr_raw",
            },
            {
              Header: "cfr_score",
              accessor: "cfr_score",
            },
            {
              Header: "drr_cat",
              accessor: "drr_cat",
            },
            {
              Header: "drr_label",
              accessor: "drr_label",
            },
            {
              Header: "drr_raw",
              accessor: "drr_raw",
            },
            {
              Header: "drr_score",
              accessor: "drr_score",
            },
            {
              Header: "gid_0",
              accessor: "gid_0",
            },
            {
              Header: "gid_1",
              accessor: "gid_1",
            },
            {
              Header: "gtd_cat",
              accessor: "gtd_cat",
            },
            {
              Header: "gtd_label",
              accessor: "gtd_label",
            },
            {
              Header: "gtd_raw",
              accessor: "gtd_raw",
            },
            {
              Header: "gtd_score",
              accessor: "gtd_score",
            },
            {
              Header: "iav_cat",
              accessor: "iav_cat",
            },
            {
              Header: "iav_label",
              accessor: "iav_label",
            },
            {
              Header: "iav_raw",
              accessor: "iav_raw",
            },
            {
              Header: "iav_score",
              accessor: "iav_score",
            },
            {
              Header: "name_0",
              accessor: "name_0",
            },
            {
              Header: "name_1",
              accessor: "name_1",
            },
            {
              Header: "pfaf_id",
              accessor: "pfaf_id",
            },
            {
              Header: "rfr_cat",
              accessor: "rfr_cat",
            },
            {
              Header: "rfr_label",
              accessor: "rfr_label",
            },
            {
              Header: "rfr_raw",
              accessor: "rfr_raw",
            },
            {
              Header: "rfr_score",
              accessor: "rfr_score",
            },
            {
              Header: "rri_cat",
              accessor: "rri_cat",
            },
            {
              Header: "rri_label",
              accessor: "rri_label",
            },
            {
              Header: "rri_score",
              accessor: "rri_score",
            },
            {
              Header: "sev_cat",
              accessor: "sev_cat",
            },
            {
              Header: "sev_label",
              accessor: "sev_label",
            },
            {
              Header: "sev_raw",
              accessor: "sev_raw",
            },
            {
              Header: "sev_score",
              accessor: "sev_score",
            },
            {
              Header: "string_id",
              accessor: "string_id",
            },
            {
              Header: "ucw_cat",
              accessor: "ucw_cat",
            },
            {
              Header: "ucw_label",
              accessor: "ucw_label",
            },
            {
              Header: "ucw_raw",
              accessor: "ucw_raw",
            },
            {
              Header: "ucw_score",
              accessor: "ucw_score",
            },
            {
              Header: "udw_cat",
              accessor: "udw_cat",
            },
            {
              Header: "udw_label",
              accessor: "udw_label",
            },
            {
              Header: "udw_raw",
              accessor: "udw_raw",
            },
            {
              Header: "udw_score",
              accessor: "udw_score",
            },
            {
              Header: "usa_cat",
              accessor: "usa_cat",
            },
            {
              Header: "usa_label",
              accessor: "usa_label",
            },
            {
              Header: "usa_raw",
              accessor: "usa_raw",
            },
            {
              Header: "usa_score",
              accessor: "usa_score",
            },
            {
              Header: "w_awr_agr_qal_cat",
              accessor: "w_awr_agr_qal_cat",
            },
            {
              Header: "w_awr_agr_qal_label",
              accessor: "w_awr_agr_qal_label",
            },
            {
              Header: "w_awr_agr_qal_raw",
              accessor: "w_awr_agr_qal_raw",
            },
            {
              Header: "w_awr_agr_qal_score",
              accessor: "w_awr_agr_qal_score",
            },
            {
              Header: "w_awr_agr_qal_weight_fraction",
              accessor: "w_awr_agr_qal_weight_fraction",
            },
            {
              Header: "w_awr_agr_qan_cat",
              accessor: "w_awr_agr_qan_cat",
            },
            {
              Header: "w_awr_agr_qan_label",
              accessor: "w_awr_agr_qan_label",
            },
            {
              Header: "w_awr_agr_qan_raw",
              accessor: "w_awr_agr_qan_raw",
            },
            {
              Header: "w_awr_agr_qan_score",
              accessor: "w_awr_agr_qan_score",
            },
            {
              Header: "w_awr_agr_qan_weight_fraction",
              accessor: "w_awr_agr_qan_weight_fraction",
            },
            {
              Header: "w_awr_agr_rrr_cat",
              accessor: "w_awr_agr_rrr_cat",
            },
            {
              Header: "w_awr_agr_rrr_label",
              accessor: "w_awr_agr_rrr_label",
            },
            {
              Header: "w_awr_agr_rrr_raw",
              accessor: "w_awr_agr_rrr_raw",
            },
            {
              Header: "w_awr_agr_rrr_score",
              accessor: "w_awr_agr_rrr_score",
            },
            {
              Header: "w_awr_agr_rrr_weight_fraction",
              accessor: "w_awr_agr_rrr_weight_fraction",
            },
            {
              Header: "w_awr_agr_tot_cat",
              accessor: "w_awr_agr_tot_cat",
            },
            {
              Header: "w_awr_agr_tot_label",
              accessor: "w_awr_agr_tot_label",
            },
            {
              Header: "w_awr_agr_tot_raw",
              accessor: "w_awr_agr_tot_raw",
            },
            {
              Header: "w_awr_agr_tot_score",
              accessor: "w_awr_agr_tot_score",
            },
            {
              Header: "w_awr_agr_tot_weight_fraction",
              accessor: "w_awr_agr_tot_weight_fraction",
            },
            {
              Header: "w_awr_che_qal_cat",
              accessor: "w_awr_che_qal_cat",
            },
            {
              Header: "w_awr_che_qal_label",
              accessor: "w_awr_che_qal_label",
            },
            {
              Header: "w_awr_che_qal_raw",
              accessor: "w_awr_che_qal_raw",
            },
            {
              Header: "w_awr_che_qal_score",
              accessor: "w_awr_che_qal_score",
            },
            {
              Header: "w_awr_che_qal_weight_fraction",
              accessor: "w_awr_che_qal_weight_fraction",
            },
            {
              Header: "w_awr_che_qan_cat",
              accessor: "w_awr_che_qan_cat",
            },
            {
              Header: "w_awr_che_qan_label",
              accessor: "w_awr_che_qan_label",
            },
            {
              Header: "w_awr_che_qan_raw",
              accessor: "w_awr_che_qan_raw",
            },
            {
              Header: "w_awr_che_qan_score",
              accessor: "w_awr_che_qan_score",
            },
            {
              Header: "w_awr_che_qan_weight_fraction",
              accessor: "w_awr_che_qan_weight_fraction",
            },
            {
              Header: "w_awr_che_rrr_cat",
              accessor: "w_awr_che_rrr_cat",
            },
            {
              Header: "w_awr_che_rrr_label",
              accessor: "w_awr_che_rrr_label",
            },
            {
              Header: "w_awr_che_rrr_raw",
              accessor: "w_awr_che_rrr_raw",
            },
            {
              Header: "w_awr_che_rrr_score",
              accessor: "w_awr_che_rrr_score",
            },
            {
              Header: "w_awr_che_rrr_weight_fraction",
              accessor: "w_awr_che_rrr_weight_fraction",
            },
            {
              Header: "w_awr_che_tot_cat",
              accessor: "w_awr_che_tot_cat",
            },
            {
              Header: "w_awr_che_tot_label",
              accessor: "w_awr_che_tot_label",
            },
            {
              Header: "w_awr_che_tot_raw",
              accessor: "w_awr_che_tot_raw",
            },
            {
              Header: "w_awr_che_tot_score",
              accessor: "w_awr_che_tot_score",
            },
            {
              Header: "w_awr_che_tot_weight_fraction",
              accessor: "w_awr_che_tot_weight_fraction",
            },
            {
              Header: "w_awr_con_qal_cat",
              accessor: "w_awr_con_qal_cat",
            },
            {
              Header: "w_awr_con_qal_label",
              accessor: "w_awr_con_qal_label",
            },
            {
              Header: "w_awr_con_qal_score",
              accessor: "w_awr_con_qal_score",
            },
            {
              Header: "w_awr_con_qal_weight_fraction",
              accessor: "w_awr_con_qal_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_cat",
              accessor: "w_awr_con_qan_cat",
            },
            {
              Header: "w_awr_con_qan_label",
              accessor: "w_awr_con_qan_label",
            },
            {
              Header: "w_awr_con_qan_raw",
              accessor: "w_awr_con_qan_raw",
            },
            {
              Header: "w_awr_con_qan_weight_fraction",
              accessor: "w_awr_con_qan_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_score",
              accessor: "w_awr_con_qan_score",
            },
            {
              Header: "w_awr_con_rrr_cat",
              accessor: "w_awr_con_rrr_cat",
            },
            {
              Header: "w_awr_con_rrr_label",
              accessor: "w_awr_con_rrr_label",
            },
            {
              Header: "w_awr_con_rrr_raw",
              accessor: "w_awr_con_rrr_raw",
            },
            {
              Header: "w_awr_con_rrr_score",
              accessor: "w_awr_con_rrr_score",
            },
            {
              Header: "w_awr_con_rrr_weight_fraction",
              accessor: "w_awr_con_rrr_weight_fraction",
            },
            {
              Header: "w_awr_con_tot_cat",
              accessor: "w_awr_con_tot_cat",
            },
            {
              Header: "w_awr_con_tot_label",
              accessor: "w_awr_con_tot_label",
            },
            {
              Header: "w_awr_con_tot_raw",
              accessor: "w_awr_con_tot_raw",
            },
            {
              Header: "w_awr_con_tot_score",
              accessor: "w_awr_con_tot_score",
            },
            {
              Header: "w_awr_con_tot_weight_fraction",
              accessor: "w_awr_con_tot_weight_fraction",
            },
            {
              Header: "w_awr_def_qal_cat",
              accessor: "w_awr_def_qal_cat",
            },
            {
              Header: "w_awr_def_qal_label",
              accessor: "w_awr_def_qal_label",
            },
            {
              Header: "w_awr_def_qal_raw",
              accessor: "w_awr_def_qal_raw",
            },
            {
              Header: "w_awr_def_qal_score",
              accessor: "w_awr_def_qal_score",
            },
            {
              Header: "w_awr_def_qal_weight_fraction",
              accessor: "w_awr_def_qal_weight_fraction",
            },
            {
              Header: "w_awr_def_qan_cat",
              accessor: "w_awr_def_qan_cat",
            },
            {
              Header: "w_awr_def_qan_label",
              accessor: "w_awr_def_qan_label",
            },
            {
              Header: "w_awr_def_qan_raw",
              accessor: "w_awr_def_qan_raw",
            },
            {
              Header: "w_awr_def_qan_score",
              accessor: "w_awr_def_qan_score",
            },
            {
              Header: "w_awr_def_qan_weight_fraction",
              accessor: "w_awr_def_qan_weight_fraction",
            },
            {
              Header: "w_awr_def_rrr_cat",
              accessor: "w_awr_def_rrr_cat",
            },
            {
              Header: "w_awr_def_rrr_label",
              accessor: "w_awr_def_rrr_label",
            },
            {
              Header: "w_awr_def_rrr_raw",
              accessor: "w_awr_def_rrr_raw",
            },
            {
              Header: "w_awr_def_rrr_score",
              accessor: "w_awr_def_rrr_score",
            },
            {
              Header: "w_awr_def_rrr_weight_fraction",
              accessor: "w_awr_def_rrr_weight_fraction",
            },
            {
              Header: "w_awr_def_tot_cat",
              accessor: "w_awr_def_tot_cat",
            },
            {
              Header: "w_awr_def_tot_label",
              accessor: "w_awr_def_tot_label",
            },
            {
              Header: "w_awr_def_tot_raw",
              accessor: "w_awr_def_tot_raw",
            },
            {
              Header: "w_awr_def_tot_score",
              accessor: "w_awr_def_tot_score",
            },
            {
              Header: "w_awr_def_tot_weight_fraction",
              accessor: "w_awr_def_tot_weight_fraction",
            },
            {
              Header: "w_awr_elp_qal_cat",
              accessor: "w_awr_elp_qal_cat",
            },
            {
              Header: "w_awr_elp_qal_label",
              accessor: "w_awr_elp_qal_label",
            },
            {
              Header: "w_awr_elp_qal_raw",
              accessor: "w_awr_elp_qal_raw",
            },
            {
              Header: "w_awr_elp_qal_score",
              accessor: "w_awr_elp_qal_score",
            },
            {
              Header: "w_awr_elp_qal_weight_fraction",
              accessor: "w_awr_elp_qal_weight_fraction",
            },
            {
              Header: "w_awr_elp_qan_cat",
              accessor: "w_awr_elp_qan_cat",
            },
            {
              Header: "w_awr_elp_qan_label",
              accessor: "w_awr_elp_qan_label",
            },
            {
              Header: "w_awr_elp_qan_raw",
              accessor: "w_awr_elp_qan_raw",
            },
            {
              Header: "w_awr_elp_qan_score",
              accessor: "w_awr_elp_qan_score",
            },
            {
              Header: "w_awr_elp_qan_weight_fraction",
              accessor: "w_awr_elp_qan_weight_fraction",
            },
            {
              Header: "w_awr_elp_rrr_cat",
              accessor: "w_awr_elp_rrr_cat",
            },
            {
              Header: "w_awr_elp_rrr_label",
              accessor: "w_awr_elp_rrr_label",
            },
            {
              Header: "w_awr_elp_rrr_raw",
              accessor: "w_awr_elp_rrr_raw",
            },
            {
              Header: "w_awr_elp_rrr_score",
              accessor: "w_awr_elp_rrr_score",
            },
            {
              Header: "w_awr_elp_rrr_weight_fraction",
              accessor: "w_awr_elp_rrr_weight_fraction",
            },
            {
              Header: "w_awr_elp_tot_cat",
              accessor: "w_awr_elp_tot_cat",
            },
            {
              Header: "w_awr_elp_tot_label",
              accessor: "w_awr_elp_tot_label",
            },
            {
              Header: "w_awr_elp_tot_raw",
              accessor: "w_awr_elp_tot_raw",
            },
            {
              Header: "w_awr_elp_tot_score",
              accessor: "w_awr_elp_tot_score",
            },
            {
              Header: "w_awr_elp_tot_weight_fraction",
              accessor: "w_awr_elp_tot_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qal_cat",
              accessor: "w_awr_fnb_qal_cat",
            },
            {
              Header: "w_awr_fnb_qal_label",
              accessor: "w_awr_fnb_qal_label",
            },
            {
              Header: "w_awr_fnb_qal_raw",
              accessor: "w_awr_fnb_qal_raw",
            },
            {
              Header: "w_awr_fnb_qal_score",
              accessor: "w_awr_fnb_qal_score",
            },
            {
              Header: "w_awr_fnb_qal_weight_fraction",
              accessor: "w_awr_fnb_qal_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qan_cat",
              accessor: "w_awr_fnb_qan_cat",
            },
            {
              Header: "w_awr_fnb_qan_label",
              accessor: "w_awr_fnb_qan_label",
            },
            {
              Header: "w_awr_fnb_qan_raw",
              accessor: "w_awr_fnb_qan_raw",
            },
            {
              Header: "w_awr_fnb_qan_score",
              accessor: "w_awr_fnb_qan_score",
            },
            {
              Header: "w_awr_fnb_qan_weight_fraction",
              accessor: "w_awr_fnb_qan_weight_fraction",
            },
            {
              Header: "w_awr_fnb_rrr_cat",
              accessor: "w_awr_fnb_rrr_cat",
            },
            {
              Header: "w_awr_fnb_rrr_label",
              accessor: "w_awr_fnb_rrr_label",
            },
            {
              Header: "w_awr_fnb_rrr_raw",
              accessor: "w_awr_fnb_rrr_raw",
            },
            {
              Header: "w_awr_fnb_rrr_score",
              accessor: "w_awr_fnb_rrr_score",
            },
            {
              Header: "w_awr_fnb_rrr_weight_fraction",
              accessor: "w_awr_fnb_rrr_weight_fraction",
            },
            {
              Header: "w_awr_fnb_tot_cat",
              accessor: "w_awr_fnb_tot_cat",
            },
            {
              Header: "w_awr_fnb_tot_label",
              accessor: "w_awr_fnb_tot_label",
            },
            {
              Header: "w_awr_fnb_tot_raw",
              accessor: "w_awr_fnb_tot_raw",
            },
            {
              Header: "w_awr_fnb_tot_score",
              accessor: "w_awr_fnb_tot_score",
            },
            {
              Header: "w_awr_fnb_tot_weight_fraction",
              accessor: "w_awr_fnb_tot_weight_fraction",
            },
            {
              Header: "w_awr_min_qal_cat",
              accessor: "w_awr_min_qal_cat",
            },
            {
              Header: "w_awr_min_qal_label",
              accessor: "w_awr_min_qal_label",
            },
            {
              Header: "w_awr_min_qal_raw",
              accessor: "w_awr_min_qal_raw",
            },
            {
              Header: "w_awr_min_qal_score",
              accessor: "w_awr_min_qal_score",
            },
            {
              Header: "w_awr_min_qal_weight_fraction",
              accessor: "w_awr_min_qal_weight_fraction",
            },
            {
              Header: "w_awr_min_qan_cat",
              accessor: "w_awr_min_qan_cat",
            },
            {
              Header: "w_awr_min_qan_label",
              accessor: "w_awr_min_qan_label",
            },
            {
              Header: "w_awr_min_qan_raw",
              accessor: "w_awr_min_qan_raw",
            },
            {
              Header: "w_awr_min_qan_score",
              accessor: "w_awr_min_qan_score",
            },
            {
              Header: "w_awr_min_qan_weight_fraction",
              accessor: "w_awr_min_qan_weight_fraction",
            },
            {
              Header: "w_awr_min_rrr_cat",
              accessor: "w_awr_min_rrr_cat",
            },
            {
              Header: "w_awr_min_rrr_label",
              accessor: "w_awr_min_rrr_label",
            },
            {
              Header: "w_awr_min_rrr_raw",
              accessor: "w_awr_min_rrr_raw",
            },
            {
              Header: "w_awr_min_rrr_score",
              accessor: "w_awr_min_rrr_score",
            },
            {
              Header: "w_awr_min_rrr_weight_fraction",
              accessor: "w_awr_min_rrr_weight_fraction",
            },
            {
              Header: "w_awr_min_tot_cat",
              accessor: "w_awr_min_tot_cat",
            },
            {
              Header: "w_awr_min_tot_label",
              accessor: "w_awr_min_tot_label",
            },
            {
              Header: "w_awr_min_tot_raw",
              accessor: "w_awr_min_tot_raw",
            },
            {
              Header: "w_awr_min_tot_score",
              accessor: "w_awr_min_tot_score",
            },
            {
              Header: "w_awr_min_tot_weight_fraction",
              accessor: "w_awr_min_tot_weight_fraction",
            },
            {
              Header: "w_awr_ong_qal_cat",
              accessor: "w_awr_ong_qal_cat",
            },
            {
              Header: "w_awr_ong_qal_label",
              accessor: "w_awr_ong_qal_label",
            },
            {
              Header: "w_awr_ong_qal_raw",
              accessor: "w_awr_ong_qal_raw",
            },
            {
              Header: "w_awr_ong_qal_score",
              accessor: "w_awr_ong_qal_score",
            },
            {
              Header: "w_awr_ong_qan_cat",
              accessor: "w_awr_ong_qan_cat",
            },
            {
              Header: "w_awr_ong_qan_label",
              accessor: "w_awr_ong_qan_label",
            },
            {
              Header: "w_awr_ong_qan_raw",
              accessor: "w_awr_ong_qan_raw",
            },
            {
              Header: "w_awr_ong_qan_score",
              accessor: "w_awr_ong_qan_score",
            },
            {
              Header: "w_awr_ong_qan_weight_fraction",
              accessor: "w_awr_ong_qan_weight_fraction",
            },
            {
              Header: "w_awr_ong_rrr_cat",
              accessor: "w_awr_ong_rrr_cat",
            },
            {
              Header: "w_awr_ong_rrr_label",
              accessor: "w_awr_ong_rrr_label",
            },
            {
              Header: "w_awr_ong_rrr_raw",
              accessor: "w_awr_ong_rrr_raw",
            },
            {
              Header: "w_awr_ong_rrr_score",
              accessor: "w_awr_ong_rrr_score",
            },
            {
              Header: "w_awr_ong_rrr_weight_fraction",
              accessor: "w_awr_ong_rrr_weight_fraction",
            },
            {
              Header: "w_awr_ong_tot_cat",
              accessor: "w_awr_ong_tot_cat",
            },
            {
              Header: "w_awr_ong_tot_label",
              accessor: "w_awr_ong_tot_label",
            },
            {
              Header: "w_awr_ong_tot_raw",
              accessor: "w_awr_ong_tot_raw",
            },
            {
              Header: "w_awr_ong_tot_score",
              accessor: "w_awr_ong_tot_score",
            },
            {
              Header: "w_awr_ong_tot_weight_fraction",
              accessor: "w_awr_ong_tot_weight_fraction",
            },
            {
              Header: "w_awr_smc_qal_cat",
              accessor: "w_awr_smc_qal_cat",
            },
            {
              Header: "w_awr_smc_qal_label",
              accessor: "w_awr_smc_qal_label",
            },
            {
              Header: "w_awr_smc_qal_raw",
              accessor: "w_awr_smc_qal_raw",
            },
            {
              Header: "w_awr_smc_qal_score",
              accessor: "w_awr_smc_qal_score",
            },
            {
              Header: "w_awr_smc_qal_weight_fraction",
              accessor: "w_awr_smc_qal_weight_fraction",
            },
            {
              Header: "w_awr_smc_qan_cat",
              accessor: "w_awr_smc_qan_cat",
            },
            {
              Header: "w_awr_smc_qan_label",
              accessor: "w_awr_smc_qan_label",
            },
            {
              Header: "w_awr_smc_qan_raw",
              accessor: "w_awr_smc_qan_raw",
            },
            {
              Header: "w_awr_smc_qan_score",
              accessor: "w_awr_smc_qan_score",
            },
            {
              Header: "w_awr_smc_qan_weight_fraction",
              accessor: "w_awr_smc_qan_weight_fraction",
            },
            {
              Header: "w_awr_smc_rrr_cat",
              accessor: "w_awr_smc_rrr_cat",
            },
            {
              Header: "w_awr_smc_rrr_label",
              accessor: "w_awr_smc_rrr_label",
            },
            {
              Header: "w_awr_smc_rrr_raw",
              accessor: "w_awr_smc_rrr_raw",
            },
            {
              Header: "w_awr_smc_rrr_score",
              accessor: "w_awr_smc_rrr_score",
            },
            {
              Header: "w_awr_smc_rrr_weight_fraction",
              accessor: "w_awr_smc_rrr_weight_fraction",
            },
            {
              Header: "w_awr_smc_tot_cat",
              accessor: "w_awr_smc_tot_cat",
            },
            {
              Header: "w_awr_smc_tot_label",
              accessor: "w_awr_smc_tot_label",
            },
            {
              Header: "w_awr_smc_tot_raw",
              accessor: "w_awr_smc_tot_raw",
            },
            {
              Header: "w_awr_smc_tot_score",
              accessor: "w_awr_smc_tot_score",
            },
            {
              Header: "w_awr_smc_tot_weight_fraction",
              accessor: "w_awr_smc_tot_weight_fraction",
            },
            {
              Header: "w_awr_tex_qal_cat",
              accessor: "w_awr_tex_qal_cat",
            },
            {
              Header: "w_awr_tex_qal_label",
              accessor: "w_awr_tex_qal_label",
            },
            {
              Header: "w_awr_tex_qal_raw",
              accessor: "w_awr_tex_qal_raw",
            },
            {
              Header: "w_awr_tex_qal_score",
              accessor: "w_awr_tex_qal_score",
            },
            {
              Header: "w_awr_tex_qal_weight_fraction",
              accessor: "w_awr_tex_qal_weight_fraction",
            },
            {
              Header: "w_awr_tex_qan_cat",
              accessor: "w_awr_tex_qan_cat",
            },
            {
              Header: "w_awr_tex_qan_label",
              accessor: "w_awr_tex_qan_label",
            },
            {
              Header: "w_awr_tex_qan_raw",
              accessor: "w_awr_tex_qan_raw",
            },
            {
              Header: "w_awr_tex_qan_weight_fraction",
              accessor: "w_awr_tex_qan_weight_fraction",
            },
            {
              Header: "w_awr_tex_rrr_cat",
              accessor: "w_awr_tex_rrr_cat",
            },
            {
              Header: "w_awr_tex_rrr_label",
              accessor: "w_awr_tex_rrr_label",
            },
            {
              Header: "w_awr_tex_rrr_raw",
              accessor: "w_awr_tex_rrr_raw",
            },
            {
              Header: "w_awr_tex_rrr_score",
              accessor: "w_awr_tex_rrr_score",
            },
            {
              Header: "w_awr_tex_rrr_weight_fraction",
              accessor: "w_awr_tex_rrr_weight_fraction",
            },
            {
              Header: "w_awr_tex_tot_cat",
              accessor: "w_awr_tex_tot_cat",
            },
            {
              Header: "w_awr_tex_tot_label",
              accessor: "w_awr_tex_tot_label",
            },
            {
              Header: "w_awr_tex_tot_raw",
              accessor: "w_awr_tex_tot_raw",
            },
            {
              Header: "w_awr_tex_tot_score",
              accessor: "w_awr_tex_tot_score",
            },
            {
              Header: "w_awr_tex_tot_weight_fraction",
              accessor: "w_awr_tex_tot_weight_fraction",
            },
          ]}
          fillColor={[
            "match",
            ["get", "bws_label"],
            ["Extremely High (>80%)"],
             `${adjustHexBrightness("#632721", global.aqueductAtlasWaterStressBrightness)}`,
            ["High (40-80%)"],
             `${adjustHexBrightness("#973024", global.aqueductAtlasWaterStressBrightness)}`,
            ["Medium - High (20-40%)"],
             `${adjustHexBrightness("#9E8D20", global.aqueductAtlasWaterStressBrightness)}`,
            ["Low - Medium (10-20%)"],
             `${adjustHexBrightness("#7D992C", global.aqueductAtlasWaterStressBrightness)}`,
            ["Low (<10%)"],
             `${adjustHexBrightness("#54763C", global.aqueductAtlasWaterStressBrightness)}`,
            ["Arid and Low Water Use"],
             `${adjustHexBrightness("#5B5F61", global.aqueductAtlasWaterStressBrightness)}`,
            "hsla(0, 0%, 0%, 0)",
          ]}
        />
      )}

      {global.aqueductAtlasRiverine && (
        <GlobalMapWithReport
          id="aqueduct_atlas_riverine"
          opacity={global.aqueductAtlasRiverineOpacity}
          beforeID={beforeID}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.aqueductAtlas}
          sourceLayer={"Aqueduct_Water_Risk_Atlas_Annual"}
          reportRoute={"/AqueductWaterRiskAtlas"}
          action={global.action}
          sourceLabel={"Aqueduct Water Risk Atlas"}
          sourceLink={"https://www.wri.org/publication/aqueduct-30"}
          tableColumns={[
            {
              Header: "aq30_id",
              accessor: "aq30_id",
            },
            {
              Header: "aqid",
              accessor: "aqid",
            },
            {
              Header: "area_km2",
              accessor: "area_km2",
            },
            {
              Header: "bwd_cat",
              accessor: "bwd_cat",
            },
            {
              Header: "bwd_label",
              accessor: "bwd_label",
            },
            {
              Header: "bwd_raw",
              accessor: "bwd_raw",
            },
            {
              Header: "bwd_score",
              accessor: "bwd_score",
            },
            {
              Header: "bws_cat",
              accessor: "bws_cat",
            },
            {
              Header: "bws_label",
              accessor: "bws_label",
            },
            {
              Header: "bws_raw",
              accessor: "bws_raw",
            },
            {
              Header: "bws_score",
              accessor: "bws_score",
            },
            {
              Header: "cep_cat",
              accessor: "cep_cat",
            },
            {
              Header: "cep_label",
              accessor: "cep_label",
            },
            {
              Header: "cep_raw",
              accessor: "cep_raw",
            },
            {
              Header: "cep_score",
              accessor: "cep_score",
            },
            {
              Header: "cfr_cat",
              accessor: "cfr_cat",
            },
            {
              Header: "cfr_label",
              accessor: "cfr_label",
            },
            {
              Header: "cfr_raw",
              accessor: "cfr_raw",
            },
            {
              Header: "cfr_score",
              accessor: "cfr_score",
            },
            {
              Header: "drr_cat",
              accessor: "drr_cat",
            },
            {
              Header: "drr_label",
              accessor: "drr_label",
            },
            {
              Header: "drr_raw",
              accessor: "drr_raw",
            },
            {
              Header: "drr_score",
              accessor: "drr_score",
            },
            {
              Header: "gid_0",
              accessor: "gid_0",
            },
            {
              Header: "gid_1",
              accessor: "gid_1",
            },
            {
              Header: "gtd_cat",
              accessor: "gtd_cat",
            },
            {
              Header: "gtd_label",
              accessor: "gtd_label",
            },
            {
              Header: "gtd_raw",
              accessor: "gtd_raw",
            },
            {
              Header: "gtd_score",
              accessor: "gtd_score",
            },
            {
              Header: "iav_cat",
              accessor: "iav_cat",
            },
            {
              Header: "iav_label",
              accessor: "iav_label",
            },
            {
              Header: "iav_raw",
              accessor: "iav_raw",
            },
            {
              Header: "iav_score",
              accessor: "iav_score",
            },
            {
              Header: "name_0",
              accessor: "name_0",
            },
            {
              Header: "name_1",
              accessor: "name_1",
            },
            {
              Header: "pfaf_id",
              accessor: "pfaf_id",
            },
            {
              Header: "rfr_cat",
              accessor: "rfr_cat",
            },
            {
              Header: "rfr_label",
              accessor: "rfr_label",
            },
            {
              Header: "rfr_raw",
              accessor: "rfr_raw",
            },
            {
              Header: "rfr_score",
              accessor: "rfr_score",
            },
            {
              Header: "rri_cat",
              accessor: "rri_cat",
            },
            {
              Header: "rri_label",
              accessor: "rri_label",
            },
            {
              Header: "rri_score",
              accessor: "rri_score",
            },
            {
              Header: "sev_cat",
              accessor: "sev_cat",
            },
            {
              Header: "sev_label",
              accessor: "sev_label",
            },
            {
              Header: "sev_raw",
              accessor: "sev_raw",
            },
            {
              Header: "sev_score",
              accessor: "sev_score",
            },
            {
              Header: "string_id",
              accessor: "string_id",
            },
            {
              Header: "ucw_cat",
              accessor: "ucw_cat",
            },
            {
              Header: "ucw_label",
              accessor: "ucw_label",
            },
            {
              Header: "ucw_raw",
              accessor: "ucw_raw",
            },
            {
              Header: "ucw_score",
              accessor: "ucw_score",
            },
            {
              Header: "udw_cat",
              accessor: "udw_cat",
            },
            {
              Header: "udw_label",
              accessor: "udw_label",
            },
            {
              Header: "udw_raw",
              accessor: "udw_raw",
            },
            {
              Header: "udw_score",
              accessor: "udw_score",
            },
            {
              Header: "usa_cat",
              accessor: "usa_cat",
            },
            {
              Header: "usa_label",
              accessor: "usa_label",
            },
            {
              Header: "usa_raw",
              accessor: "usa_raw",
            },
            {
              Header: "usa_score",
              accessor: "usa_score",
            },
            {
              Header: "w_awr_agr_qal_cat",
              accessor: "w_awr_agr_qal_cat",
            },
            {
              Header: "w_awr_agr_qal_label",
              accessor: "w_awr_agr_qal_label",
            },
            {
              Header: "w_awr_agr_qal_raw",
              accessor: "w_awr_agr_qal_raw",
            },
            {
              Header: "w_awr_agr_qal_score",
              accessor: "w_awr_agr_qal_score",
            },
            {
              Header: "w_awr_agr_qal_weight_fraction",
              accessor: "w_awr_agr_qal_weight_fraction",
            },
            {
              Header: "w_awr_agr_qan_cat",
              accessor: "w_awr_agr_qan_cat",
            },
            {
              Header: "w_awr_agr_qan_label",
              accessor: "w_awr_agr_qan_label",
            },
            {
              Header: "w_awr_agr_qan_raw",
              accessor: "w_awr_agr_qan_raw",
            },
            {
              Header: "w_awr_agr_qan_score",
              accessor: "w_awr_agr_qan_score",
            },
            {
              Header: "w_awr_agr_qan_weight_fraction",
              accessor: "w_awr_agr_qan_weight_fraction",
            },
            {
              Header: "w_awr_agr_rrr_cat",
              accessor: "w_awr_agr_rrr_cat",
            },
            {
              Header: "w_awr_agr_rrr_label",
              accessor: "w_awr_agr_rrr_label",
            },
            {
              Header: "w_awr_agr_rrr_raw",
              accessor: "w_awr_agr_rrr_raw",
            },
            {
              Header: "w_awr_agr_rrr_score",
              accessor: "w_awr_agr_rrr_score",
            },
            {
              Header: "w_awr_agr_rrr_weight_fraction",
              accessor: "w_awr_agr_rrr_weight_fraction",
            },
            {
              Header: "w_awr_agr_tot_cat",
              accessor: "w_awr_agr_tot_cat",
            },
            {
              Header: "w_awr_agr_tot_label",
              accessor: "w_awr_agr_tot_label",
            },
            {
              Header: "w_awr_agr_tot_raw",
              accessor: "w_awr_agr_tot_raw",
            },
            {
              Header: "w_awr_agr_tot_score",
              accessor: "w_awr_agr_tot_score",
            },
            {
              Header: "w_awr_agr_tot_weight_fraction",
              accessor: "w_awr_agr_tot_weight_fraction",
            },
            {
              Header: "w_awr_che_qal_cat",
              accessor: "w_awr_che_qal_cat",
            },
            {
              Header: "w_awr_che_qal_label",
              accessor: "w_awr_che_qal_label",
            },
            {
              Header: "w_awr_che_qal_raw",
              accessor: "w_awr_che_qal_raw",
            },
            {
              Header: "w_awr_che_qal_score",
              accessor: "w_awr_che_qal_score",
            },
            {
              Header: "w_awr_che_qal_weight_fraction",
              accessor: "w_awr_che_qal_weight_fraction",
            },
            {
              Header: "w_awr_che_qan_cat",
              accessor: "w_awr_che_qan_cat",
            },
            {
              Header: "w_awr_che_qan_label",
              accessor: "w_awr_che_qan_label",
            },
            {
              Header: "w_awr_che_qan_raw",
              accessor: "w_awr_che_qan_raw",
            },
            {
              Header: "w_awr_che_qan_score",
              accessor: "w_awr_che_qan_score",
            },
            {
              Header: "w_awr_che_qan_weight_fraction",
              accessor: "w_awr_che_qan_weight_fraction",
            },
            {
              Header: "w_awr_che_rrr_cat",
              accessor: "w_awr_che_rrr_cat",
            },
            {
              Header: "w_awr_che_rrr_label",
              accessor: "w_awr_che_rrr_label",
            },
            {
              Header: "w_awr_che_rrr_raw",
              accessor: "w_awr_che_rrr_raw",
            },
            {
              Header: "w_awr_che_rrr_score",
              accessor: "w_awr_che_rrr_score",
            },
            {
              Header: "w_awr_che_rrr_weight_fraction",
              accessor: "w_awr_che_rrr_weight_fraction",
            },
            {
              Header: "w_awr_che_tot_cat",
              accessor: "w_awr_che_tot_cat",
            },
            {
              Header: "w_awr_che_tot_label",
              accessor: "w_awr_che_tot_label",
            },
            {
              Header: "w_awr_che_tot_raw",
              accessor: "w_awr_che_tot_raw",
            },
            {
              Header: "w_awr_che_tot_score",
              accessor: "w_awr_che_tot_score",
            },
            {
              Header: "w_awr_che_tot_weight_fraction",
              accessor: "w_awr_che_tot_weight_fraction",
            },
            {
              Header: "w_awr_con_qal_cat",
              accessor: "w_awr_con_qal_cat",
            },
            {
              Header: "w_awr_con_qal_label",
              accessor: "w_awr_con_qal_label",
            },
            {
              Header: "w_awr_con_qal_score",
              accessor: "w_awr_con_qal_score",
            },
            {
              Header: "w_awr_con_qal_weight_fraction",
              accessor: "w_awr_con_qal_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_cat",
              accessor: "w_awr_con_qan_cat",
            },
            {
              Header: "w_awr_con_qan_label",
              accessor: "w_awr_con_qan_label",
            },
            {
              Header: "w_awr_con_qan_raw",
              accessor: "w_awr_con_qan_raw",
            },
            {
              Header: "w_awr_con_qan_weight_fraction",
              accessor: "w_awr_con_qan_weight_fraction",
            },
            {
              Header: "w_awr_con_qan_score",
              accessor: "w_awr_con_qan_score",
            },
            {
              Header: "w_awr_con_rrr_cat",
              accessor: "w_awr_con_rrr_cat",
            },
            {
              Header: "w_awr_con_rrr_label",
              accessor: "w_awr_con_rrr_label",
            },
            {
              Header: "w_awr_con_rrr_raw",
              accessor: "w_awr_con_rrr_raw",
            },
            {
              Header: "w_awr_con_rrr_score",
              accessor: "w_awr_con_rrr_score",
            },
            {
              Header: "w_awr_con_rrr_weight_fraction",
              accessor: "w_awr_con_rrr_weight_fraction",
            },
            {
              Header: "w_awr_con_tot_cat",
              accessor: "w_awr_con_tot_cat",
            },
            {
              Header: "w_awr_con_tot_label",
              accessor: "w_awr_con_tot_label",
            },
            {
              Header: "w_awr_con_tot_raw",
              accessor: "w_awr_con_tot_raw",
            },
            {
              Header: "w_awr_con_tot_score",
              accessor: "w_awr_con_tot_score",
            },
            {
              Header: "w_awr_con_tot_weight_fraction",
              accessor: "w_awr_con_tot_weight_fraction",
            },
            {
              Header: "w_awr_def_qal_cat",
              accessor: "w_awr_def_qal_cat",
            },
            {
              Header: "w_awr_def_qal_label",
              accessor: "w_awr_def_qal_label",
            },
            {
              Header: "w_awr_def_qal_raw",
              accessor: "w_awr_def_qal_raw",
            },
            {
              Header: "w_awr_def_qal_score",
              accessor: "w_awr_def_qal_score",
            },
            {
              Header: "w_awr_def_qal_weight_fraction",
              accessor: "w_awr_def_qal_weight_fraction",
            },
            {
              Header: "w_awr_def_qan_cat",
              accessor: "w_awr_def_qan_cat",
            },
            {
              Header: "w_awr_def_qan_label",
              accessor: "w_awr_def_qan_label",
            },
            {
              Header: "w_awr_def_qan_raw",
              accessor: "w_awr_def_qan_raw",
            },
            {
              Header: "w_awr_def_qan_score",
              accessor: "w_awr_def_qan_score",
            },
            {
              Header: "w_awr_def_qan_weight_fraction",
              accessor: "w_awr_def_qan_weight_fraction",
            },
            {
              Header: "w_awr_def_rrr_cat",
              accessor: "w_awr_def_rrr_cat",
            },
            {
              Header: "w_awr_def_rrr_label",
              accessor: "w_awr_def_rrr_label",
            },
            {
              Header: "w_awr_def_rrr_raw",
              accessor: "w_awr_def_rrr_raw",
            },
            {
              Header: "w_awr_def_rrr_score",
              accessor: "w_awr_def_rrr_score",
            },
            {
              Header: "w_awr_def_rrr_weight_fraction",
              accessor: "w_awr_def_rrr_weight_fraction",
            },
            {
              Header: "w_awr_def_tot_cat",
              accessor: "w_awr_def_tot_cat",
            },
            {
              Header: "w_awr_def_tot_label",
              accessor: "w_awr_def_tot_label",
            },
            {
              Header: "w_awr_def_tot_raw",
              accessor: "w_awr_def_tot_raw",
            },
            {
              Header: "w_awr_def_tot_score",
              accessor: "w_awr_def_tot_score",
            },
            {
              Header: "w_awr_def_tot_weight_fraction",
              accessor: "w_awr_def_tot_weight_fraction",
            },
            {
              Header: "w_awr_elp_qal_cat",
              accessor: "w_awr_elp_qal_cat",
            },
            {
              Header: "w_awr_elp_qal_label",
              accessor: "w_awr_elp_qal_label",
            },
            {
              Header: "w_awr_elp_qal_raw",
              accessor: "w_awr_elp_qal_raw",
            },
            {
              Header: "w_awr_elp_qal_score",
              accessor: "w_awr_elp_qal_score",
            },
            {
              Header: "w_awr_elp_qal_weight_fraction",
              accessor: "w_awr_elp_qal_weight_fraction",
            },
            {
              Header: "w_awr_elp_qan_cat",
              accessor: "w_awr_elp_qan_cat",
            },
            {
              Header: "w_awr_elp_qan_label",
              accessor: "w_awr_elp_qan_label",
            },
            {
              Header: "w_awr_elp_qan_raw",
              accessor: "w_awr_elp_qan_raw",
            },
            {
              Header: "w_awr_elp_qan_score",
              accessor: "w_awr_elp_qan_score",
            },
            {
              Header: "w_awr_elp_qan_weight_fraction",
              accessor: "w_awr_elp_qan_weight_fraction",
            },
            {
              Header: "w_awr_elp_rrr_cat",
              accessor: "w_awr_elp_rrr_cat",
            },
            {
              Header: "w_awr_elp_rrr_label",
              accessor: "w_awr_elp_rrr_label",
            },
            {
              Header: "w_awr_elp_rrr_raw",
              accessor: "w_awr_elp_rrr_raw",
            },
            {
              Header: "w_awr_elp_rrr_score",
              accessor: "w_awr_elp_rrr_score",
            },
            {
              Header: "w_awr_elp_rrr_weight_fraction",
              accessor: "w_awr_elp_rrr_weight_fraction",
            },
            {
              Header: "w_awr_elp_tot_cat",
              accessor: "w_awr_elp_tot_cat",
            },
            {
              Header: "w_awr_elp_tot_label",
              accessor: "w_awr_elp_tot_label",
            },
            {
              Header: "w_awr_elp_tot_raw",
              accessor: "w_awr_elp_tot_raw",
            },
            {
              Header: "w_awr_elp_tot_score",
              accessor: "w_awr_elp_tot_score",
            },
            {
              Header: "w_awr_elp_tot_weight_fraction",
              accessor: "w_awr_elp_tot_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qal_cat",
              accessor: "w_awr_fnb_qal_cat",
            },
            {
              Header: "w_awr_fnb_qal_label",
              accessor: "w_awr_fnb_qal_label",
            },
            {
              Header: "w_awr_fnb_qal_raw",
              accessor: "w_awr_fnb_qal_raw",
            },
            {
              Header: "w_awr_fnb_qal_score",
              accessor: "w_awr_fnb_qal_score",
            },
            {
              Header: "w_awr_fnb_qal_weight_fraction",
              accessor: "w_awr_fnb_qal_weight_fraction",
            },
            {
              Header: "w_awr_fnb_qan_cat",
              accessor: "w_awr_fnb_qan_cat",
            },
            {
              Header: "w_awr_fnb_qan_label",
              accessor: "w_awr_fnb_qan_label",
            },
            {
              Header: "w_awr_fnb_qan_raw",
              accessor: "w_awr_fnb_qan_raw",
            },
            {
              Header: "w_awr_fnb_qan_score",
              accessor: "w_awr_fnb_qan_score",
            },
            {
              Header: "w_awr_fnb_qan_weight_fraction",
              accessor: "w_awr_fnb_qan_weight_fraction",
            },
            {
              Header: "w_awr_fnb_rrr_cat",
              accessor: "w_awr_fnb_rrr_cat",
            },
            {
              Header: "w_awr_fnb_rrr_label",
              accessor: "w_awr_fnb_rrr_label",
            },
            {
              Header: "w_awr_fnb_rrr_raw",
              accessor: "w_awr_fnb_rrr_raw",
            },
            {
              Header: "w_awr_fnb_rrr_score",
              accessor: "w_awr_fnb_rrr_score",
            },
            {
              Header: "w_awr_fnb_rrr_weight_fraction",
              accessor: "w_awr_fnb_rrr_weight_fraction",
            },
            {
              Header: "w_awr_fnb_tot_cat",
              accessor: "w_awr_fnb_tot_cat",
            },
            {
              Header: "w_awr_fnb_tot_label",
              accessor: "w_awr_fnb_tot_label",
            },
            {
              Header: "w_awr_fnb_tot_raw",
              accessor: "w_awr_fnb_tot_raw",
            },
            {
              Header: "w_awr_fnb_tot_score",
              accessor: "w_awr_fnb_tot_score",
            },
            {
              Header: "w_awr_fnb_tot_weight_fraction",
              accessor: "w_awr_fnb_tot_weight_fraction",
            },
            {
              Header: "w_awr_min_qal_cat",
              accessor: "w_awr_min_qal_cat",
            },
            {
              Header: "w_awr_min_qal_label",
              accessor: "w_awr_min_qal_label",
            },
            {
              Header: "w_awr_min_qal_raw",
              accessor: "w_awr_min_qal_raw",
            },
            {
              Header: "w_awr_min_qal_score",
              accessor: "w_awr_min_qal_score",
            },
            {
              Header: "w_awr_min_qal_weight_fraction",
              accessor: "w_awr_min_qal_weight_fraction",
            },
            {
              Header: "w_awr_min_qan_cat",
              accessor: "w_awr_min_qan_cat",
            },
            {
              Header: "w_awr_min_qan_label",
              accessor: "w_awr_min_qan_label",
            },
            {
              Header: "w_awr_min_qan_raw",
              accessor: "w_awr_min_qan_raw",
            },
            {
              Header: "w_awr_min_qan_score",
              accessor: "w_awr_min_qan_score",
            },
            {
              Header: "w_awr_min_qan_weight_fraction",
              accessor: "w_awr_min_qan_weight_fraction",
            },
            {
              Header: "w_awr_min_rrr_cat",
              accessor: "w_awr_min_rrr_cat",
            },
            {
              Header: "w_awr_min_rrr_label",
              accessor: "w_awr_min_rrr_label",
            },
            {
              Header: "w_awr_min_rrr_raw",
              accessor: "w_awr_min_rrr_raw",
            },
            {
              Header: "w_awr_min_rrr_score",
              accessor: "w_awr_min_rrr_score",
            },
            {
              Header: "w_awr_min_rrr_weight_fraction",
              accessor: "w_awr_min_rrr_weight_fraction",
            },
            {
              Header: "w_awr_min_tot_cat",
              accessor: "w_awr_min_tot_cat",
            },
            {
              Header: "w_awr_min_tot_label",
              accessor: "w_awr_min_tot_label",
            },
            {
              Header: "w_awr_min_tot_raw",
              accessor: "w_awr_min_tot_raw",
            },
            {
              Header: "w_awr_min_tot_score",
              accessor: "w_awr_min_tot_score",
            },
            {
              Header: "w_awr_min_tot_weight_fraction",
              accessor: "w_awr_min_tot_weight_fraction",
            },
            {
              Header: "w_awr_ong_qal_cat",
              accessor: "w_awr_ong_qal_cat",
            },
            {
              Header: "w_awr_ong_qal_label",
              accessor: "w_awr_ong_qal_label",
            },
            {
              Header: "w_awr_ong_qal_raw",
              accessor: "w_awr_ong_qal_raw",
            },
            {
              Header: "w_awr_ong_qal_score",
              accessor: "w_awr_ong_qal_score",
            },
            {
              Header: "w_awr_ong_qan_cat",
              accessor: "w_awr_ong_qan_cat",
            },
            {
              Header: "w_awr_ong_qan_label",
              accessor: "w_awr_ong_qan_label",
            },
            {
              Header: "w_awr_ong_qan_raw",
              accessor: "w_awr_ong_qan_raw",
            },
            {
              Header: "w_awr_ong_qan_score",
              accessor: "w_awr_ong_qan_score",
            },
            {
              Header: "w_awr_ong_qan_weight_fraction",
              accessor: "w_awr_ong_qan_weight_fraction",
            },
            {
              Header: "w_awr_ong_rrr_cat",
              accessor: "w_awr_ong_rrr_cat",
            },
            {
              Header: "w_awr_ong_rrr_label",
              accessor: "w_awr_ong_rrr_label",
            },
            {
              Header: "w_awr_ong_rrr_raw",
              accessor: "w_awr_ong_rrr_raw",
            },
            {
              Header: "w_awr_ong_rrr_score",
              accessor: "w_awr_ong_rrr_score",
            },
            {
              Header: "w_awr_ong_rrr_weight_fraction",
              accessor: "w_awr_ong_rrr_weight_fraction",
            },
            {
              Header: "w_awr_ong_tot_cat",
              accessor: "w_awr_ong_tot_cat",
            },
            {
              Header: "w_awr_ong_tot_label",
              accessor: "w_awr_ong_tot_label",
            },
            {
              Header: "w_awr_ong_tot_raw",
              accessor: "w_awr_ong_tot_raw",
            },
            {
              Header: "w_awr_ong_tot_score",
              accessor: "w_awr_ong_tot_score",
            },
            {
              Header: "w_awr_ong_tot_weight_fraction",
              accessor: "w_awr_ong_tot_weight_fraction",
            },
            {
              Header: "w_awr_smc_qal_cat",
              accessor: "w_awr_smc_qal_cat",
            },
            {
              Header: "w_awr_smc_qal_label",
              accessor: "w_awr_smc_qal_label",
            },
            {
              Header: "w_awr_smc_qal_raw",
              accessor: "w_awr_smc_qal_raw",
            },
            {
              Header: "w_awr_smc_qal_score",
              accessor: "w_awr_smc_qal_score",
            },
            {
              Header: "w_awr_smc_qal_weight_fraction",
              accessor: "w_awr_smc_qal_weight_fraction",
            },
            {
              Header: "w_awr_smc_qan_cat",
              accessor: "w_awr_smc_qan_cat",
            },
            {
              Header: "w_awr_smc_qan_label",
              accessor: "w_awr_smc_qan_label",
            },
            {
              Header: "w_awr_smc_qan_raw",
              accessor: "w_awr_smc_qan_raw",
            },
            {
              Header: "w_awr_smc_qan_score",
              accessor: "w_awr_smc_qan_score",
            },
            {
              Header: "w_awr_smc_qan_weight_fraction",
              accessor: "w_awr_smc_qan_weight_fraction",
            },
            {
              Header: "w_awr_smc_rrr_cat",
              accessor: "w_awr_smc_rrr_cat",
            },
            {
              Header: "w_awr_smc_rrr_label",
              accessor: "w_awr_smc_rrr_label",
            },
            {
              Header: "w_awr_smc_rrr_raw",
              accessor: "w_awr_smc_rrr_raw",
            },
            {
              Header: "w_awr_smc_rrr_score",
              accessor: "w_awr_smc_rrr_score",
            },
            {
              Header: "w_awr_smc_rrr_weight_fraction",
              accessor: "w_awr_smc_rrr_weight_fraction",
            },
            {
              Header: "w_awr_smc_tot_cat",
              accessor: "w_awr_smc_tot_cat",
            },
            {
              Header: "w_awr_smc_tot_label",
              accessor: "w_awr_smc_tot_label",
            },
            {
              Header: "w_awr_smc_tot_raw",
              accessor: "w_awr_smc_tot_raw",
            },
            {
              Header: "w_awr_smc_tot_score",
              accessor: "w_awr_smc_tot_score",
            },
            {
              Header: "w_awr_smc_tot_weight_fraction",
              accessor: "w_awr_smc_tot_weight_fraction",
            },
            {
              Header: "w_awr_tex_qal_cat",
              accessor: "w_awr_tex_qal_cat",
            },
            {
              Header: "w_awr_tex_qal_label",
              accessor: "w_awr_tex_qal_label",
            },
            {
              Header: "w_awr_tex_qal_raw",
              accessor: "w_awr_tex_qal_raw",
            },
            {
              Header: "w_awr_tex_qal_score",
              accessor: "w_awr_tex_qal_score",
            },
            {
              Header: "w_awr_tex_qal_weight_fraction",
              accessor: "w_awr_tex_qal_weight_fraction",
            },
            {
              Header: "w_awr_tex_qan_cat",
              accessor: "w_awr_tex_qan_cat",
            },
            {
              Header: "w_awr_tex_qan_label",
              accessor: "w_awr_tex_qan_label",
            },
            {
              Header: "w_awr_tex_qan_raw",
              accessor: "w_awr_tex_qan_raw",
            },
            {
              Header: "w_awr_tex_qan_weight_fraction",
              accessor: "w_awr_tex_qan_weight_fraction",
            },
            {
              Header: "w_awr_tex_rrr_cat",
              accessor: "w_awr_tex_rrr_cat",
            },
            {
              Header: "w_awr_tex_rrr_label",
              accessor: "w_awr_tex_rrr_label",
            },
            {
              Header: "w_awr_tex_rrr_raw",
              accessor: "w_awr_tex_rrr_raw",
            },
            {
              Header: "w_awr_tex_rrr_score",
              accessor: "w_awr_tex_rrr_score",
            },
            {
              Header: "w_awr_tex_rrr_weight_fraction",
              accessor: "w_awr_tex_rrr_weight_fraction",
            },
            {
              Header: "w_awr_tex_tot_cat",
              accessor: "w_awr_tex_tot_cat",
            },
            {
              Header: "w_awr_tex_tot_label",
              accessor: "w_awr_tex_tot_label",
            },
            {
              Header: "w_awr_tex_tot_raw",
              accessor: "w_awr_tex_tot_raw",
            },
            {
              Header: "w_awr_tex_tot_score",
              accessor: "w_awr_tex_tot_score",
            },
            {
              Header: "w_awr_tex_tot_weight_fraction",
              accessor: "w_awr_tex_tot_weight_fraction",
            },
          ]}
          fillColor={[
            "match",
            ["get", "rfr_label"],
            ["Extremely High (more than 1 in 100)"],
            `${adjustHexBrightness("#CC2A23", global.aqueductAtlasRiverineBrightness)}`,
            ["High (6 in 1,000 to 1 in 100)"],
            `${adjustHexBrightness("#DE7A11", global.aqueductAtlasRiverineBrightness)}`,
            ["Medium - High (2 in 1,000 to 6 in 1,000)"],
            `${adjustHexBrightness("#f1cc08", global.aqueductAtlasRiverineBrightness)}`,
            ["Low - Medium (1 in 1,000 to 2 in 1,000)"],
            `${adjustHexBrightness("#0B6E3D", global.aqueductAtlasRiverineBrightness)}`,
            ["Low (0 to 1 in 1,000)"],
            `${adjustHexBrightness("#7ab15e", global.aqueductAtlasRiverineBrightness)}`,
            "hsla(0, 0%, 0%, 0)",
          ]}
        />
      )}

      {global.femaBoundaries && (
        <VectorTileLayer
          id="fema_boundaries"
          fillColor={"#ec9b00"}
          opacity={0.5}
          outlineColor={"#ec9b00"}
          tileSet={settings.tileSetURLs.FEMA_Flood_Hazard_Boundaries}
          sourceLayer={"FEMA_Flood_Hazard_Boundaries"}
        />
      )}
      {global.GFSHuc12s && (
        <>
          <HucListLine
            global={global}
            colorHUC8={"transparent"}
            outlineColorHUC8={"transparent"}
            colorHUC12={"black"}
            outlineColorHUC12={"black"}
            huc8Width={["interpolate", ["linear"], ["zoom"], 5, 0.3, 15, 3]}
            width={["interpolate", ["linear"], ["zoom"], 7, 2, 15, 5]}
            opacity={1}
            switch={false}
            mapRef={mapRef}
            huc8ID={"huc8s-line"}
            huc12ID={"huc12s-line"}
            huc12MinZoom={3}
            huc8sOn={false}
          />
          {<TileHover12 global={global} mapRef={mapRef} mode={"huc12"} brightness={global.hucsSelectedBrightness} opacity={global.hucsSelectedOpacity} />}
        </>
      )}
      {global.hucsSelected && global.userOrgString !== "RES" && (
        <>
          <HucLayer
            global={global}
            brightness={global.hucsSelectedBrightness}
            opacity={global.hucsSelectedOpacity}
            action={global.action}
          />
        </>
      )}
      {global.hucsSelected && global.userOrgString === "RES" && (
        <>
          <HucListLine
            colorHUC8={"white"}
            outlineColorHUC8={"white"}
            colorHUC12={"black"}
            outlineColorHUC12={"black"}
            huc8Width={2}
            width={2}
            opacity={global.hucsSelectedOpacity}
            switch={false}
            mapRef={mapRef}
            huc8ID={"huc8s-line"}
            huc12ID={"huc12s-line"}
          />
          {zoom > 8 && (
            <TileHover global={global} mapRef={mapRef} mode={"huc12"} />
          )}
          {zoom < 8 && (
            <TileHover global={global} mapRef={mapRef} mode={"huc8"} />
          )}
        </>
      )}
      {global.droughtMonitoringSelected && (
        <VectorTileLayer
          id="drought"
          fillColor={[
            "match",
            ["get", "DM"],
            0,
            `${adjustHexBrightness("#f4fb18", global.droughtMonitoringBrightness)}`,
            1,
            `${adjustHexBrightness("#f99010", global.droughtMonitoringBrightness)}`,
            2,
            `${adjustHexBrightness("#ea631f", global.droughtMonitoringBrightness)}`,
            3,
            `${adjustHexBrightness("#fa3f19", global.droughtMonitoringBrightness)}`,
            4,
            `${adjustHexBrightness("#ab0707", global.droughtMonitoringBrightness)}`,
            /* other */ "transparent",
          ]}
          opacity={global.droughtMonitoringOpacity}
          outlineColor={"#8B8000"}
          tileSet={settings.tileSetURLs.US_Daily_Drought_Monitoring}
          sourceLayer={settings.tileSetURLs.droughtSource}
          beforeID={settings.defaultBeforeId}
        />
      )}
      {global.principalAquifersSelected && (
        <VectorTileLayer
          id="aquifers"
          fillColor={[
            "match",
            ["get", "ROCK_NAME"],
            // 0, 'transparent',
            "Carbonate-rock aquifers",
            `${adjustHexBrightness("#8a658b", global.principalAquifersBrightness)}`,
            "Igneous and metamorphic-rock aquifers",
            `${adjustHexBrightness("#efa3a3", global.principalAquifersBrightness)}`,
            "Sandstone and carbonate-rock aquifers",
            `${adjustHexBrightness("#f1c065", global.principalAquifersBrightness)}`,
            "Sandstone aquifers",
            `${adjustHexBrightness("#633b2c", global.principalAquifersBrightness)}`,
            "Semiconsolidated sand aquifers",
            `${adjustHexBrightness("#2d2843", global.principalAquifersBrightness)}`,
            "Unconsolidated sand and gravel aquifers",
            `${adjustHexBrightness("#292154", global.principalAquifersBrightness)}`,
            /* other */ "transparent",
          ]}
          opacity={global.principalAquifersOpacity}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.Principal_Aquifer_Map}
          sourceLayer={settings.tileSetURLs.aquiferSource}
        />
      )}
      {global.domesticWellsSelected && (
        // thing1
        <VectorTileLayer
          id="domestic-wells"
          fillColor={[
            "match",
            ["get", "z"],
            // 0, 'transparent',
            6,
            `${adjustHexBrightness("#254153", global.domesticWellsbrightness)}`,
            5,
            `${adjustHexBrightness("#0b61bf", global.domesticWellsbrightness)}`,
            4,
            `${adjustHexBrightness("#98c3f0", global.domesticWellsbrightness)}`,
            3,
            `${adjustHexBrightness("#ddeded", global.domesticWellsbrightness)}`,
            2,
            `${adjustHexBrightness("#4fc32c", global.domesticWellsbrightness)}`,
            1,
            `${adjustHexBrightness("#2c8f29", global.domesticWellsbrightness)}`,
            /* other */ "transparent",
          ]}
          opacity={global.domesticWellsOpacity}
          outlineColor={"transparent"}
          tileSet={settings.tileSetURLs.Domestic_Well_by_Population}
          sourceLayer={settings.tileSetURLs.domesticWellsSource}
        />
      )}
      {global.zipSelected && (
        <LineLayer
          id="zip-codes"
          opacity={global.zipOpacity}
          lineColor={`${adjustHexBrightness("#FDA401", global.zipBrightness)}`}
          width={2}
          tileSet={settings.tileSetURLs.ZipCodeMap}
          sourceLayer={settings.tileSetURLs.ZipCodeSource}
        />
      )}
      {global.terraClimateLayerSelected && (
        <TerraclimateLayer
          opacity={global.terraClimateLayerSelectedOpacity}
          fillColor={[
            "match",
            ["get", "z"],
            "0",
            `${adjustHexBrightness("#440154", global.terraClimateLayerSelectedBrightness)}`,
            "1",
            `${adjustHexBrightness("#443983", global.terraClimateLayerSelectedBrightness)}`,
            "11",
            `${adjustHexBrightness("#31688e", global.terraClimateLayerSelectedBrightness)}`,
            "46",
            `${adjustHexBrightness("#21918c", global.terraClimateLayerSelectedBrightness)}`,
            "128",
            `${adjustHexBrightness("#35b779", global.terraClimateLayerSelectedBrightness)}`,
            "279",
            `${adjustHexBrightness("#90d743", global.terraClimateLayerSelectedBrightness)}`,
            /* other */ "transparent",
          ]}
          sourceUrl={"mapbox://trueelementsmapping.terraclimate_final_"}
          terraSource={"terraclimate_final_"}
          global={global}
        />
      )}
      {global.evapotranspirationSelected && (
        <TerraclimateLayer
          opacity={global.evapotranspirationSelectedOpacity}
          fillColor={[
            "match",
            ["get", "z"],
            "0",
            `${adjustHexBrightness("#1A8693", global.evapotranspirationSelectedBrightness)}`,
            "1",
            `${adjustHexBrightness("#4EA2A2", global.evapotranspirationSelectedBrightness)}`,
            "9",
            `${adjustHexBrightness("#D4B95E", global.evapotranspirationSelectedBrightness)}`,
            "35",
            `${adjustHexBrightness("#D28F34", global.evapotranspirationSelectedBrightness)}`,
            "90",
            `${adjustHexBrightness("#B34232", global.evapotranspirationSelectedBrightness)}`,
            "186",
            `${adjustHexBrightness("#741a33", global.evapotranspirationSelectedBrightness)}`,
            /* other */ "transparent",
          ]}
          sourceUrl={"mapbox://trueelementsmapping.terraclimate_pet_"}
          terraSource={"terraclimate_pet_"}
          global={global}
        />
      )}
      {global.EJDemoSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"Demographic-Index"}
          opacity={global.EJDemoSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_VULEOPCT"],
            1,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJDemoSelectedBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJDemoSelectedBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJDemoSelectedBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJDemoSelectedBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJDemoSelectedBrightness)}`,
            50,
            `${adjustHexBrightness("#e2e2e2", global.EJDemoSelectedBrightness)}`,
            60,
            `${adjustHexBrightness("#D0D0D0", global.EJDemoSelectedBrightness)}`,
            70,
            `${adjustHexBrightness("#ABABAB", global.EJDemoSelectedBrightness)}`,
            80,
            `${adjustHexBrightness("#F5F5AF", global.EJDemoSelectedBrightness)}`,
            90,
            `${adjustHexBrightness("#E8C276", global.EJDemoSelectedBrightness)}`,
            95,
            `${adjustHexBrightness("#CA7676", global.EJDemoSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJLowIncomeSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"Percentile-Low-Income"}
          opacity={global.EJLowIncomeSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_LWINCPCT"],
            0,
            "transparent",
            1,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJLowIncomeSelectedBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJLowIncomeSelectedBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJLowIncomeSelectedBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJLowIncomeSelectedBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJLowIncomeSelectedBrightness)}`,
            50,
            `${adjustHexBrightness("#B8D5CD", global.EJLowIncomeSelectedBrightness)}`,
            60,
            `${adjustHexBrightness("#8ABAAE", global.EJLowIncomeSelectedBrightness)}`,
            70,
            `${adjustHexBrightness("#5CA08E", global.EJLowIncomeSelectedBrightness)}`,
            80,
            `${adjustHexBrightness("#2E856E", global.EJLowIncomeSelectedBrightness)}`,
            90,
            `${adjustHexBrightness("#006A4E", global.EJLowIncomeSelectedBrightness)}`,
            95,
            `${adjustHexBrightness("#003728", global.EJLowIncomeSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJPOCSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"poc"}
          opacity={global.EJPOCSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_MINORPCT"],
            2,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJPOCSelectedBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJPOCSelectedBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJPOCSelectedBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJPOCSelectedBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJPOCSelectedBrightness)}`,
            50,
            `${adjustHexBrightness("#ffd700", global.EJPOCSelectedBrightness)}`,
            60,
            `${adjustHexBrightness("#ccac00", global.EJPOCSelectedBrightness)}`,
            70,
            `${adjustHexBrightness("#998100", global.EJPOCSelectedBrightness)}`,
            80,
            `${adjustHexBrightness("#665600", global.EJPOCSelectedBrightness)}`,
            90,
            `${adjustHexBrightness("#4d4100", global.EJPOCSelectedBrightness)}`,
            95,
            `${adjustHexBrightness("#332b00", global.EJPOCSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJUnemploymentSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"unemployment"}
          opacity={global.EJUnemploymentSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_UNEMPPCT"],
            12,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJUnemploymentSelectedBrightness)}`,
            15,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJUnemploymentSelectedBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJUnemploymentSelectedBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJUnemploymentSelectedBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJUnemploymentSelectedBrightness)}`,
            50,
            `${adjustHexBrightness("#ffffff", global.EJUnemploymentSelectedBrightness)}`,
            60,
            `${adjustHexBrightness("#FFF600", global.EJUnemploymentSelectedBrightness)}`,
            70,
            `${adjustHexBrightness("#FFCF07", global.EJUnemploymentSelectedBrightness)}`,
            80,
            `${adjustHexBrightness("#FFA80F", global.EJUnemploymentSelectedBrightness)}`,
            90,
            `${adjustHexBrightness("#FE8116", global.EJUnemploymentSelectedBrightness)}`,
            95,
            `${adjustHexBrightness("#FE5A1D", global.EJUnemploymentSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJLingIsoSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"ling-iso"}
          opacity={global.EJLingIsoSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "LINGISOPCT"],
            0,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJLingIsoSelectedBrightness)}`,
            0.1,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJLingIsoSelectedBrightness)}`,
            0.2,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJLingIsoSelectedBrightness)}`,
            0.3,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJLingIsoSelectedBrightness)}`,
            0.4,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJLingIsoSelectedBrightness)}`,
            0.5,
            `${adjustHexBrightness("#ffffff", global.EJLingIsoSelectedBrightness)}`,
            0.6,
            `${adjustHexBrightness("#00ffff", global.EJLingIsoSelectedBrightness)}`,
            0.7,
            `${adjustHexBrightness("#00dddd", global.EJLingIsoSelectedBrightness)}`,
            0.8,
            `${adjustHexBrightness("#00a2a2", global.EJLingIsoSelectedBrightness)}`,
            0.9,
            `${adjustHexBrightness("#006868", global.EJLingIsoSelectedBrightness)}`,
            0.95,
            `${adjustHexBrightness("#006868", global.EJLingIsoSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJLessHSSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"less-hs"}
          opacity={global.EJLessHSSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_LESHSPCT"],
            5,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJLessHSSelectedBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJLessHSSelectedBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJLessHSSelectedBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJLessHSSelectedBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJLessHSSelectedBrightness)}`,
            50,
            `${adjustHexBrightness("#577d6c", global.EJLessHSSelectedBrightness)}`,
            60,
            `${adjustHexBrightness("#4f7262", global.EJLessHSSelectedBrightness)}`,
            70,
            `${adjustHexBrightness("#476658", global.EJLessHSSelectedBrightness)}`,
            80,
            `${adjustHexBrightness("#3f5b4e", global.EJLessHSSelectedBrightness)}`,
            90,
            `${adjustHexBrightness("#374f44", global.EJLessHSSelectedBrightness)}`,
            95,
            `${adjustHexBrightness("#2f443a", global.EJLessHSSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJFiveAndUnderAgeSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"fiveandunder"}
          opacity={global.EJFiveAndUnderAgeSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "UNDER5PCT"],
            0,
            "transparent",
            0.05,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.1,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.15,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.2,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.25,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.3,
            `${adjustHexBrightness("#4542B9", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.35,
            `${adjustHexBrightness("#1FC3CD", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.4,
            `${adjustHexBrightness("#01AA31", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.45,
            `${adjustHexBrightness("#F5C603", global.EJFiveAndUnderAgeSelectedBrightness)}`,
            0.5,
            `${adjustHexBrightness("#D62226", global.EJFiveAndUnderAgeSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJSixtyFourAndOverSelected && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"oversixtyfour"}
          opacity={global.EJSixtyFourAndOverSelectedOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "OVER64PCT"],
            0,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.1,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.2,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.3,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.4,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.5,
            `${adjustHexBrightness("#ffffff", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.6,
            `${adjustHexBrightness("#7F6890", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.7,
            `${adjustHexBrightness("#C5D4AB", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.8,
            `${adjustHexBrightness("#FCE8B8", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.9,
            `${adjustHexBrightness("#F4BF90", global.EJSixtyFourAndOverSelectedBrightness)}`,
            0.95,
            `${adjustHexBrightness("#A17A74", global.EJSixtyFourAndOverSelectedBrightness)}`,
          ]}
        />
      )}
      {global.EJWasteWaterDischarge && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"wwdischarge"}
          opacity={global.EJWasteWaterDischargeOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_PWDIS"],
            1,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJWasteWaterDischargeBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJWasteWaterDischargeBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJWasteWaterDischargeBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJWasteWaterDischargeBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJWasteWaterDischargeBrightness)}`,
            50,
            `${adjustHexBrightness("#ffffff", global.EJWasteWaterDischargeBrightness)}`,
            60,
            `${adjustHexBrightness("#fad643", global.EJWasteWaterDischargeBrightness)}`,
            70,
            `${adjustHexBrightness("#dbb42c", global.EJWasteWaterDischargeBrightness)}`,
            80,
            `${adjustHexBrightness("#b69121", global.EJWasteWaterDischargeBrightness)}`,
            90,
            `${adjustHexBrightness("#926c15", global.EJWasteWaterDischargeBrightness)}`,
            100,
            `${adjustHexBrightness("#76520e", global.EJWasteWaterDischargeBrightness)}`,
          ]}
        />
      )}
      {global.EJUndergroundStorageTanks && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"underground-storage-tanks"}
          opacity={global.EJUndergroundStorageTanksOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_UST"],
            17,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJUndergroundStorageTanksBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJUndergroundStorageTanksBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJUndergroundStorageTanksBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJUndergroundStorageTanksBrightness)}`,
            50,
            `${adjustHexBrightness("#ffffff", global.EJUndergroundStorageTanksBrightness)}`,
            60,
            `${adjustHexBrightness("#3399cc", global.EJUndergroundStorageTanksBrightness)}`,
            70,
            `${adjustHexBrightness("#2d87b4", global.EJUndergroundStorageTanksBrightness)}`,
            80,
            `${adjustHexBrightness("#256e93", global.EJUndergroundStorageTanksBrightness)}`,
            90,
            `${adjustHexBrightness("#1d5672", global.EJUndergroundStorageTanksBrightness)}`,
            95,
            `${adjustHexBrightness("#143d52", global.EJUndergroundStorageTanksBrightness)}`,
          ]}
        />
      )}
      {global.EJHazardousWaste && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"HWP"}
          opacity={global.EJHazardousWasteOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_PTSDF"],
            1,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJHazardousWasteBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJHazardousWasteBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJHazardousWasteBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJHazardousWasteBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJHazardousWasteBrightness)}`,
            50,
            `${adjustHexBrightness("#ffffff", global.EJHazardousWasteBrightness)}`,
            60,
            `${adjustHexBrightness("#ff5b1b", global.EJHazardousWasteBrightness)}`,
            70,
            `${adjustHexBrightness("#ff4800", global.EJHazardousWasteBrightness)}`,
            80,
            `${adjustHexBrightness("#e44000", global.EJHazardousWasteBrightness)}`,
            90,
            `${adjustHexBrightness("#c93900", global.EJHazardousWasteBrightness)}`,
            95,
            `${adjustHexBrightness("#942a00", global.EJHazardousWasteBrightness)}`,
          ]}
        />
      )}
      {global.EJRMP && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"rmp"}
          opacity={global.EJRMPOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_PRMP"],
            1,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJRMPBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJRMPBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJRMPBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJRMPBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJRMPBrightness)}`,
            50,
            `${adjustHexBrightness("#ffffff", global.EJRMPBrightness)}`,
            60,
            `${adjustHexBrightness("#e0aaff", global.EJRMPBrightness)}`,
            70,
            `${adjustHexBrightness("#c77dff", global.EJRMPBrightness)}`,
            80,
            `${adjustHexBrightness("#7b2cbf", global.EJRMPBrightness)}`,
            90,
            `${adjustHexBrightness("#3c096c", global.EJRMPBrightness)}`,
            95,
            `${adjustHexBrightness("#10002b", global.EJRMPBrightness)}`,
          ]}
        />
      )}
      {global.EJSuperfund && (
        <EnviromentalJustice
          global={global}
          year={2021}
          id={"ejsp"}
          opacity={global.EJSuperfundOpacity}
          paint={[
            "interpolate",
            ["linear"],
            ["get", "P_PNPL"],
            1,
            `${adjustRGBABrightness("rgba(255,255,255,0.1)", global.EJSuperfundBrightness)}`,
            10,
            `${adjustRGBABrightness("rgba(255,255,255,0.2)", global.EJSuperfundBrightness)}`,
            20,
            `${adjustRGBABrightness("rgba(255,255,255,0.3)", global.EJSuperfundBrightness)}`,
            30,
            `${adjustRGBABrightness("rgba(255,255,255,0.4)", global.EJSuperfundBrightness)}`,
            40,
            `${adjustRGBABrightness("rgba(255,255,255,0.5)", global.EJSuperfundBrightness)}`,
            50,
            `${adjustHexBrightness("#ffffff", global.EJSuperfundBrightness)}`,
            60,
            `${adjustHexBrightness("#b8e6b8", global.EJSuperfundBrightness)}`,
            70,
            `${adjustHexBrightness("#7acc7a", global.EJSuperfundBrightness)}`,
            80,
            `${adjustHexBrightness("#47b347", global.EJSuperfundBrightness)}`,
            90,
            `${adjustHexBrightness("#1f991f", global.EJSuperfundBrightness)}`,
            95,
            `${adjustHexBrightness("#008000", global.EJSuperfundBrightness)}`,
          ]}
        />
      )}
      {global.HUCsMexico && <HUCsMexico opacity={global.HUCsMexicoOpacity} />}

      {global.waterSystemBoundaryTier1 && (
        <EpicLayer
          id="water_system_boundaries1"
          tileSet={settings.tileSetURLs.waterSystemBoundaries}
          sourceLayer={"water_systems_service_boundary1m"}
          opacity={global.waterSystemBoundaryTier1Opacity}
          outlineColor={"#000000"}
          fillColor={[
            "match",
            ["get", "tier"],
            "Tier 1",
            `${adjustHexBrightness("#60C492", global.waterSystemBoundaryTier1Brightness)}`,
            /* other */ "transparent",
          ]}
        />
      )}

      {global.waterSystemBoundaryTier2 && (
        <EpicLayer
          id="water_system_boundaries2"
          tileSet={settings.tileSetURLs.waterSystemBoundaries}
          sourceLayer={"water_systems_service_boundary1m"}
          opacity={global.waterSystemBoundaryTier2Opacity}
          outlineColor={"#000000"}
          fillColor={[
            "match",
            ["get", "tier"],
            "Tier 2a",
            `${adjustHexBrightness("#36A0C4", global.waterSystemBoundaryTier2Brightness)}`,
            /* other */ "transparent",
          ]}
        />
      )}
      {global.waterSystemBoundaryTier3 && (
        <EpicLayer
          id="water_system_boundaries3"
          tileSet={settings.tileSetURLs.waterSystemBoundaries}
          sourceLayer={"water_systems_service_boundary1m"}
          opacity={global.waterSystemBoundaryTier3Opacity}
          outlineColor={"#000000"}
          fillColor={[
            "match",
            ["get", "tier"],
            "Tier 3",
            `${adjustHexBrightness("#C73234", global.waterSystemBoundaryTier3Brightness)}`,
            /* other */ "transparent",
          ]}
        />
      )}
      {global.WDPA && (
        <GlobalMapWithReport
          id="WDPA"
          fillColor={[
            "case",
            [
              "match",
              ["get", "DESIG_ENG"],
              ["Marine Protected Area"],
              false,
              true,
            ],
            `${adjustHexBrightness("#9ACD32", global.WDPABrightness)}`,
            `${adjustHSLABrightness("hsla(237, 84%, 41%, 0.7)", global.WDPABrightness)}`,
          ]}
          opacity={global.WDPAOpacity}
          outlineColor={"black"}
          tileSet={settings.tileSetURLs.WorldProtectedAreas}
          sourceLayer={"WDPA_WDOECM_Feb2023_Public_USA"}
          beforeID={settings.defaultBeforeId}
          reportRoute={"/PAWD"}
          action={global.action}
          sourceLabel={"WDPA"}
          sourceLink={
            "https://www.protectedplanet.net/en/thematic-areas/wdpa?tab=WDPA"
          }
          tableColumns={[
            {
              Header: "CONS OBJ",
              accessor: "CONS_OBJ",
            },
            {
              Header: "DESIGNATION",
              accessor: "DESIG",
            },
            {
              Header: "DESIG ENG",
              accessor: "DESIG_ENG",
            },
            {
              Header: "DESIG TYPE",
              accessor: "DESIG_TYPE",
            },
            {
              Header: "GIS AREA",
              accessor: "GIS_AREA",
            },
            {
              Header: "GOVTYPE",
              accessor: "GOV_TYPE",
            },
            {
              Header: "ISO3",
              accessor: "ISO3",
            },
            {
              Header: "MANG AUTH",
              accessor: "MANG_AUTH",
            },
            {
              Header: "MANG PLAN",
              accessor: "MANG_PLAN",
            },
            {
              Header: "MARINE",
              accessor: "MARINE",
            },
            {
              Header: "NAME",
              accessor: "NAME",
            },
            {
              Header: "NO TAKE",
              accessor: "NO_TAKE",
            },
            {
              Header: "NO TK AREA",
              accessor: "NO_TK_AREA",
            },
            {
              Header: "ORIG NAME",
              accessor: "ORIG_NAME",
            },
            {
              Header: "OWN TYPE",
              accessor: "OWN_TYPE",
            },
            {
              Header: "PARENT ISO",
              accessor: "PARENT_ISO",
            },
            {
              Header: "PA DEF",
              accessor: "PA_DEF",
            },
            {
              Header: "REP AREA",
              accessor: "REP_AREA",
            },
            {
              Header: "REP M AREA",
              accessor: "REP_M_AREA",
            },
            {
              Header: "STATUS",
              accessor: "STATUS",
            },
            {
              Header: "STATUS YR",
              accessor: "STATUS_YR",
            },
            {
              Header: "SUB LOC",
              accessor: "SUB_LOC",
            },
            {
              Header: "SUPP INFO",
              accessor: "SUPP_INFO",
            },
            {
              Header: "VERIF",
              accessor: "VERIF",
            },
            {
              Header: "WDPAID",
              accessor: "WDPAID",
            },
            {
              Header: "WDPA PID",
              accessor: "WDPA_PID",
            },
          ]}
        />
      )}

      {global.NOAASeaLevelRise && (
        <>
          <RenderSeaLevelRise
            global={global}
            id={"FL_SLR"}
            tileSet={settings.tileSetURLs.FL_SLR}
            sourceLayer={"FL_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"LA"}
            tileSet={settings.tileSetURLs.LA_SLR}
            sourceLayer={"LA_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"MS"}
            tileSet={settings.tileSetURLs.MS_SLR}
            sourceLayer={"MS_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"MD"}
            tileSet={settings.tileSetURLs.MD_SLR}
            sourceLayer={"MD_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"MA"}
            tileSet={settings.tileSetURLs.MA_SLR}
            sourceLayer={"MA_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"NH"}
            tileSet={settings.tileSetURLs.NH_SLR}
            sourceLayer={"NH_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"NJ"}
            tileSet={settings.tileSetURLs.NJ_SLR}
            sourceLayer={"NJ_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"NY"}
            tileSet={settings.tileSetURLs.NY_SLR}
            sourceLayer={"NY_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"OR"}
            tileSet={settings.tileSetURLs.OR_SLR}
            sourceLayer={"OR_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"WA"}
            tileSet={settings.tileSetURLs.WA_SLR}
            sourceLayer={"WA_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"SC"}
            tileSet={settings.tileSetURLs.SC_SLR}
            sourceLayer={"SC_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"PR"}
            tileSet={settings.tileSetURLs.PR_SLR}
            sourceLayer={"PR_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"Guam"}
            tileSet={settings.tileSetURLs.Guam_SLR}
            sourceLayer={"Guam_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"USVI"}
            tileSet={settings.tileSetURLs.USVI_SLR}
            sourceLayer={"USVI_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"CNMI"}
            tileSet={settings.tileSetURLs.CNMI_SLR}
            sourceLayer={"CNMI_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"AS"}
            tileSet={settings.tileSetURLs.AS_SLR}
            sourceLayer={"AS_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"CA"}
            tileSet={settings.tileSetURLs.CA_SLR}
            sourceLayer={"CA_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"TX"}
            tileSet={settings.tileSetURLs.TX_SLR}
            sourceLayer={"TX_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"NC"}
            tileSet={settings.tileSetURLs.NC_SLR}
            sourceLayer={"NC_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"VA"}
            tileSet={settings.tileSetURLs.VA_SLR}
            sourceLayer={"VA_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"HI"}
            tileSet={settings.tileSetURLs.HI_SLR}
            sourceLayer={"HI_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"FL"}
            tileSet={settings.tileSetURLs.FL_TBW}
            sourceLayer={"FL_TBW_slr_final_dist"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"ME"}
            tileSet={settings.tileSetURLs.ME_SLR}
            sourceLayer={"ME_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"PA"}
            tileSet={settings.tileSetURLs.PA_SLR}
            sourceLayer={"PA_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"DE"}
            tileSet={settings.tileSetURLs.DE_SLR}
            sourceLayer={"DE_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"CT"}
            tileSet={settings.tileSetURLs.CT_SLR}
            sourceLayer={"CT_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"DC"}
            tileSet={settings.tileSetURLs.DC_SLR}
            sourceLayer={"DC_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"RI"}
            tileSet={settings.tileSetURLs.RI_SLR}
            sourceLayer={"RI_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"AL"}
            tileSet={settings.tileSetURLs.AL_SLR}
            sourceLayer={"AL_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"AL"}
            tileSet={settings.tileSetURLs.AL_SLR}
            sourceLayer={"AL_SLR"}
            index={global.feetIndex}
          />
          <RenderSeaLevelRise
            global={global}
            id={"GA"}
            tileSet={settings.tileSetURLs.GA_SLR}
            sourceLayer={"GA_SLR"}
            index={global.feetIndex}
          />
        </>
      )}
      {global.EPASuperfundSites && (
        <CircleVectorLayer
          zoom={zoom}
          global={global}
          id="epa-superfund"
          opacity={global.EPASuperfundSitesOpacity}
          tileSet={settings.tileSetURLs.EPA_Superfund_NPL}
          sourceLayer={"EPA_Superfund_NPL"}
          circleColor={[
            "case",
            ["<=", ["get", "Site_Score"], 87],
            `${adjustHexBrightness("#F6EE04", global.EPASuperfundSitesBrightness)}`,
            `${adjustHexBrightness("#000000", global.EPASuperfundSitesBrightness)}`,
          ]}
        />
      )}
      {global.eastPalestineOutflows && (
        <EpicLayer
          id="water_system_boundaries3"
          tileSet={settings.tileSetURLs.eastPalestineModel}
          sourceLayer={"east_palestine_model"}
          opacity={0}
          outlineColor={""}
          fillColor={""}
        />
      )}
      {/* <EpicLayer
                id="water_system_boundaries3"
                tileSet={settings.tileSetURLs.eastPalestineModel}
                sourceLayer={'east_palestine_model'}
                opacity={0}
                outlineColor={''}
                fillColor={''}
            /> */}

    </>
  );
};

export default RenderLayers;
