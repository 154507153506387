import { qualityReferences } from "../shared/qualityReferences";
import { Accordion } from "react-bootstrap";

export const RenderLegend = ({ global }: any) => {
  return (
    <>
      {global.nhdSelected |
        global.wrfcRisk |
        global.cvi |
        global.eddi |
        global.NOAAMix |
        global.FemaFirm |
        global.StreamAnomaly |
        global.FHO |
        global.RiverGauge |
        global.FloodInd |
        global.nwshazard |
        global.waterQualityPortal |
        global.phFloodHazard |
        global.ausGemstat |
        global.gemstatPH |
        global.philRegions |
        global.ausPrinHydro |
        global.gemstat |
        global.hucsSelected |
        global.biaLayer |
        global.outflowsSelected |
        global.philippinesRiverBasins |
        global.industrySelected |
        global.philippinesRivers |
        global.pollutionSelected |
        global.wastewaterSelected |
        global.domesticWellsSelected |
        global.principalAquifersSelected |
        global.droughtMonitoringSelected |
        global.gwNitrateAssessmentSelected |
        global.terraClimateLayerSelected |
        global.evapotranspirationSelected |
        global.EJDemoSelected |
        global.EJLowIncomeSelected |
        global.EJPOCSelected |
        global.EJUnemploymentSelected |
        global.EJLingIsoSelected |
        global.EJLessHSSelected |
        global.EJFiveAndUnderAgeSelected |
        global.EJSixtyFourAndOverSelected |
        global.HUCsMexico |
        global.EJHazardousWaste |
        global.EJWasteWaterDischarge |
        global.EJRMP |
        global.EJUndergroundStorageTanks |
        global.EJSuperfund |
        global.waterSystemBoundaryTier1 |
        global.waterSystemBoundaryTier2 |
        global.waterSystemBoundaryTier3 |
        global.femaBoundaries |
        global.femaZones |
        global.ANGISLower |
        global.ANGISMid |
        global.indoWatersheds |
        global.persistentContaminants |
        global.modPersistentContaminants |
        global.volPersistentContaminants |
        global.ANGISUpper |
        global.surfacewaterTrueQI |
        global.h303DTrueQI |
        global.indoHucs |
        global.NOAALayer |
        global.AAV4CoastalFloodRisk |
        global.AAV4RiverineFloodRisk |
        global.aqueductAtlasCoastalFlood |
        global.aqueductAtlasWaterStress |
        global.NOAASeaLevelRise |
        global.aqueductAtlasRiverine |
        global.impairedLakes |
        global.ABWDLayer |
        global.EPOLayer |
        global.WDPA |
        global.AAV4WaterStress |
        global.toggleHighwayStyle |
        global.allFireData |
        global.waterWatchSites |
        global.EPASuperfundSites |
        global.rainfallForecast |
        global.wildFireBoundaries |
        global.MoBiPSR |
        global.MoBiSRAll |
        global.MoBiPI |
        global.MoBiV |
        global.MoBiSRA |
        global.OABID |
        global.OABUS |
        global.OABPH |
        global.OABMX |
        global.OGWTD |
        global.OABRID |
        global.OABRPH |
        global.OABRPK |
        global.OABRMX |
        global.OABRCA |
        global.OABRSG |
        global.OABSPH |
        global.OABSMX |
        global.OABSID |
        global.OABSCA |
        global.OABSPK |
        global.OABSSG |
        global.CWALayer |
        global.AppleLocationData |
        global.USGSWUC |
        global.USGSHuc12 |
        global.NationalWaterImpairements |
        global.GBIF |
        global.WaterConflict |
        global.CEJST |
        global.FHBP |
        global.OWQPDemo |
        global.FBFIB |
        global.SkyFiWhiting |
        global.SkyFiAustin |
        global.MBFIBDT |
        global.NSG |
        global.MEC |
        global.Hydrobasins |
        global.GGDE |
        global.Wise |
        global.FemaGulf |
        global.OSUWells |
        global.drinkingWaterQualitySelected && (
        <div className="legend-container">
          <Accordion
            key={"te-map-legend-accordion"}
            className="te-map-legend-accordion te-map-legend-main-class"
            defaultActiveKey="0"
            flush
          >
            {/* global.FemaGulf */}
            <Accordion.Item className="te-map-legend-accordion" eventKey="0">
              <Accordion.Header className="te-map-legend-accordion-header">
                {"Global Live Water Layers"}
              </Accordion.Header>
              <Accordion.Body className="te-map-legend-accordion-body">
                {/* OWQPDemo */}
                {global.OSUWells ? qualityReferences("OSUWells", global) : ''}
                {global.Hydrobasins ? qualityReferences("Hydrobasins", global) : ''}
                {global.FemaGulf ? qualityReferences("FemaGulf", global) : ''}
                {global.Wise ? qualityReferences("Wise", global) : ''}
                {global.MEC ? qualityReferences("MEC", global) : ''}
                {global.GGDE ? qualityReferences("GGDE", global) : ''}
                {global.NSG ? qualityReferences("NSG", global) : ''}
                {global.MBFIBDT ? qualityReferences("MBFIBDT", global) : ''}
                {global.OWQPDemo ? qualityReferences("OWQPDemo", global) : ''}
                {global.FBFIB ? qualityReferences("FBFIB", global) : ''}
                {global.FHBP ? qualityReferences("FHBP", global) : ''}
                {global.CWALayer ? qualityReferences("CWALayer", global) : ''}
                {global.CEJST ? qualityReferences("CEJST", global) : ''}
                {global.USGSHuc12 ? qualityReferences("USGSHuc12", global) : ''}
                {global.AppleLocationData ? qualityReferences("apple", global) : ''}
                {global.SkyFiWhiting ? qualityReferences("SkyFiWhiting", global) : ''}
                {global.SkyFiAustin ? qualityReferences("SkyFiAustin", global) : ''}
                {global.USGSWUC ? qualityReferences("USGSWUC", global) : ''}
                {global.OABSID ? qualityReferences("OABSID", global) : ''}
                {global.OABSCA ? qualityReferences("OABSCA", global) : ''}
                {global.OABSSG ? qualityReferences("OABSSG", global) : ''}
                {global.OABSPK ? qualityReferences("OABSPK", global) : ''}
                {global.OABSMX ? qualityReferences("OABSMX", global) : ''}
                {global.OABSPH ? qualityReferences("OABSPH", global) : ''}
                {global.OABRSG ? qualityReferences("OABRSG", global) : ''}
                {global.OABRPK ? qualityReferences("OABRPK", global) : ''}
                {global.OABRMX ? qualityReferences("OABRMX", global) : ''}
                {global.OABRCA ? qualityReferences("OABRCA", global) : ''}
                {global.OABRID ? qualityReferences("OABRID", global) : ''}
                {global.OABRPH ? qualityReferences("OABRPH", global) : ''}
                {global.OGWTD ? qualityReferences("OGWTD", global) : ""}
                {global.OABID ? qualityReferences("OABID", global) : ""}
                {global.OABUS ? qualityReferences("OABUS", global) : ""}
                {global.OABPH ? qualityReferences("OABPH", global) : ""}
                {global.OABMX ? qualityReferences("OABMX", global) : ""}
                {global.cvi ? qualityReferences("cvi", global) : ""}
                {global.NationalWaterImpairements
                  ? qualityReferences("NationalWaterImpairements", global)
                  : ""}
                {global.WaterConflict ? qualityReferences("WaterConflict", global) : ""}
                {global.GBIF ? qualityReferences("GBIF", global) : ""}
                {global.indoHucs ? qualityReferences("indoHucs", global) : ""}
                {global.MoBiPSR ? qualityReferences("MoBiPSR", global) : ""}
                {global.MoBiV ? qualityReferences("MoBiV", global) : ""}
                {global.MoBiPI ? qualityReferences("MoBiPI", global) : ""}
                {global.MoBiSRAll ? qualityReferences("MoBiSRAll", global) : ""}
                {global.MoBiSRA ? qualityReferences("MoBiSRA", global) : ""}
                {global.indoWatersheds
                  ? qualityReferences("indoWatersheds", global)
                  : ""}
                {global.rainfallForecast
                  ? qualityReferences("rainfallForecast", global)
                  : ""}
                {global.toggleHighwayStyle
                  ? qualityReferences("toggleHighwayStyle", global)
                  : ""}
                {global.EPOLayer ? qualityReferences("EPOLayer", global) : ""}
                {global.allFireData ? qualityReferences("allFireData", global) : ""}
                {global.wildFireBoundaries
                  ? qualityReferences("wildFireBoundaries", global)
                  : ""}
                {global.waterWatchSites
                  ? qualityReferences("waterWatchSites", global)
                  : ""}
                {global.impairedLakes ? qualityReferences("impairedLakes", global) : ""}
                {global.persistentContaminants
                  ? qualityReferences("contam", global)
                  : ""}
                {global.modPersistentContaminants
                  ? qualityReferences("contam", global)
                  : ""}
                {global.volPersistentContaminants
                  ? qualityReferences("contam", global)
                  : ""}
                {global.ANGISLower | global.ANGISMid | global.ANGISUpper
                  ? qualityReferences("ANGIS", global)
                  : ""}
                {global.drinkingWaterQualitySelected |
                global.h303DTrueQI |
                global.surfacewaterTrueQI |
                global.wastewaterSelected |
                global.industrySelected
                  ? qualityReferences("water quality", global)
                  : ""}
                {global.evapotranspirationSelected
                  ? qualityReferences("evapotranspiration", global)
                  : ""}
                {global.wrfcRisk ? qualityReferences("wrfcRisk", global) : ""}
                {global.eddi ? qualityReferences("eddi", global) : ""}
                {global.NOAAMix ? qualityReferences("NOAAMix", global) : ""}
                {global.FemaFirm ? qualityReferences("FemaFirm", global) : ""}
                {global.StreamAnomaly ? qualityReferences("StreamAnomaly", global) : ""}
                {global.FHO ? qualityReferences("FHO", global) : ""}
                {global.RiverGauge ? qualityReferences("RiverGauge", global) : ""}
                {global.FloodInd ? qualityReferences("FloodInd", global) : ""}
                {global.nwshazard ? qualityReferences("nwshazard", global) : ""}
                {global.waterQualityPortal
                  ? qualityReferences("waterQualityPortal", global)
                  : ""}
                {global.phFloodHazard ? qualityReferences("phFloodHazard", global) : ""}
                {global.philRegions ? qualityReferences("philRegions", global) : ""}
                {global.gemstat | global.ausGemstat | global.gemstatPH
                  ? qualityReferences("gemstat", global)
                  : ""}
                {global.ABWDLayer ? qualityReferences("ABWDLayer", global) : ""}
                {global.biaLayer ? qualityReferences("biaLayer", global) : ""}
                {global.ausPrinHydro ? qualityReferences("ausPrinHydro", global) : ""}
                {global.philippinesRiverBasins
                  ? qualityReferences("PHydrobasinssins", global)
                  : ""}
                {global.philippinesRivers ? qualityReferences("PHRivers", global) : ""}
                {global.WDPA ? qualityReferences("WDPA", global) : ""}
                {global.EPASuperfundSites
                  ? qualityReferences("EPASuperfundSites", global)
                  : ""}
                {global.NOAASeaLevelRise ? qualityReferences("NOAA", global) : ""}
                {global.terraClimateLayerSelected
                  ? qualityReferences("terraclimate", global)
                  : ""}
                {global.gwNitrateAssessmentSelected
                  ? qualityReferences("nitrates", global)
                  : ""}
                {global.NOAALayer ? qualityReferences("NOAALayer", global) : ""}
                {global.principalAquifersSelected
                  ? qualityReferences("aquifers", global)
                  : ""}
                {global.droughtMonitoringSelected
                  ? qualityReferences("drought", global)
                  : ""}
                {global.domesticWellsSelected ? qualityReferences("wells", global) : ""}
                {global.pollutionSelected ? qualityReferences("pollution", global) : ""}
                {global.outflowsSelected ? qualityReferences("outflows", global) : ""}
                {global.hucsSelected ? qualityReferences("hucs", global) : ""}
                {global.nhdSelected ? qualityReferences("nhd", global) : ""}
                {global.AAV4CoastalFloodRisk ? qualityReferences("AAV4CoastalFloodRisk", global) : ''}
                {global.AAV4WaterStress ? qualityReferences("AAV4WaterStress", global) : ''}
                {global.AAV4RiverineFloodRisk ? qualityReferences("AAV4RiverineFloodRisk", global) : ''}
                {global.aqueductAtlasCoastalFlood
                  ? qualityReferences("aqueductAtlasCoastalFlood", global)
                  : ""}
                {global.aqueductAtlasWaterStress
                  ? qualityReferences("aqueductAtlasWaterStress", global)
                  : ""}
                {global.aqueductAtlasRiverine
                  ? qualityReferences("aqueductAtlasRiverine", global)
                  : ""}
                {global.EJDemoSelected
                  ? qualityReferences("EJDemoSelected", global)
                  : ""}
                {global.EJLowIncomeSelected
                  ? qualityReferences("EJLowIncomeSelected", global)
                  : ""}
                {global.EJPOCSelected ? qualityReferences("EJPOCSelected", global) : ""}
                {global.EJUnemploymentSelected
                  ? qualityReferences("EJUnemploymentSelected", global)
                  : ""}
                {global.EJLingIsoSelected
                  ? qualityReferences("EJLingIsoSelected", global)
                  : ""}
                {global.EJLessHSSelected
                  ? qualityReferences("EJLessHSSelected", global)
                  : ""}
                {global.EJFiveAndUnderAgeSelected
                  ? qualityReferences("EJFiveAndUnderAgeSelected", global)
                  : ""}
                {global.EJSixtyFourAndOverSelected
                  ? qualityReferences("EJSixtyFourAndOverSelected", global)
                  : ""}
                {global.HUCsMexico ? qualityReferences("HUCsMexico", global) : ""}
                {global.femaBoundaries
                  ? qualityReferences("femaBoundaries", global)
                  : ""}
                {global.EJWasteWaterDischarge
                  ? qualityReferences("EJWasteWaterDischarge", global)
                  : ""}
                {global.EJUndergroundStorageTanks
                  ? qualityReferences("EJUndergroundStorageTanks", global)
                  : ""}
                {global.EJHazardousWaste
                  ? qualityReferences("EJHazardousWaste", global)
                  : ""}
                {global.EJRMP ? qualityReferences("EJRMP", global) : ""}
                {global.EJSuperfund ? qualityReferences("EJSuperfund", global) : ""}
                {global.femaZones ? qualityReferences("femaZones", global) : ""}
                {global.waterSystemBoundaryTier1 |
                global.waterSystemBoundaryTier2 |
                global.waterSystemBoundaryTier3
                  ? qualityReferences("Water Service Boundary", global)
                  : ""}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default RenderLegend;
