import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LayerDataContext } from "../../../LayerDataContext";
import { PointContext } from "../../../PointContext";
import { AppContext } from "../../../AppContext";
import { RefContext } from "../../../RefContext";
import ReportTable from "../shared/ReportTable";
import settings from "../../../settings.json";
import MapAddition from  '../shared/MapAddition';
import ReportHeader from "../shared/ReportHeader";
import { Row, Col, Container } from "react-bootstrap";
import MapSlider from "../shared/MapSlider";
import ReportFooter from "../shared/ReportFooter";
import { putViewportIntoStorage } from '../../utils'
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  CircleLayer,
  Marker,
  Source,
  Layer
} from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLegend from "../shared/MapLegend";
import { faMaximize, faMinimize, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Column } from "jspdf-autotable";
import "./simple-table-report.scss";

const SimpleTableReport = ({ global, reportID }: any) => {

    const fullscreenhandle = useFullScreenHandle() as any;
    const mapRef = useRef<any>();

    const { currentPoint } = useContext<any>(PointContext);
    const { setCurrentRef } = useContext<any>(RefContext);

    const generateColumns = (dataObject: Record<string, any>): { Header: string; accessor: string }[] => {
        return Object.keys(dataObject).map(key => ({
          Header: key,
          accessor: key
        }));
    };

    useEffect(() => {
        global.setUserLogged(true);
        setCurrentRef(mapRef);
      }, []);

    const flyToPoint = (lng: any, lat: any) => {
        console.log(lng, lat)
    if (mapRef.current && lng && lat) {
            mapRef.current.flyTo({
            center: [lng, lat],
            essential: true,
            zoom: 10,
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if(currentPoint && mapRef) {
                console.log(currentPoint)
                const { lat, lng } = currentPoint
                flyToPoint(lng, lat)
            }
        }, 1500)
    }, [currentPoint])



  return (
    <Col className="global-hydrobasins-map-container">
        <FullScreen handle={fullscreenhandle}>
            <Row className="simple-map-container">
                <Row className="header">
                    <ReportHeader
                        global={global}
                        data={[currentPoint]}
                        mapRef={mapRef}
                        reportID={reportID}
                        fullScreenClickHandle={fullscreenhandle.enter}
                    />
                </Row>
                <Row className="map">
                    <Map
                        id={`TableReport-map ${reportID}`}
                        mapboxAccessToken={settings.maboxKey}
                        mapStyle={global.mapStyle}
                        preserveDrawingBuffer={true}
                        ref={mapRef}
                        projection={global.globeView ? "globe" : "mercator" as any}
                        onClick={(e) => global.onMapClick(e)}
                        onMove={(e) => {
                        putViewportIntoStorage({
                            longitude: e.viewState.longitude,
                            latitude: e.viewState.latitude,
                            zoom: e.viewState.zoom,
                        });
                        global.setViewport({
                            longitude: e.viewState.longitude,
                            latitude: e.viewState.latitude,
                            zoom: e.viewState.zoom,
                        });
                        }}
                    >
                        <MapAddition 
                            global={global} 
                            mapRef={mapRef}
                            position={'low'}
                            zipOff={true}
                            MapSliderAdd={true} 
                        />
                        <div className="map-legend-container">
                        <MapLegend legendWidth={300} global={global} />
                        </div>
                    </Map>
                </Row>
            </Row>
            <Row className="simple-table-container">
                <Row className="table">
                    {currentPoint && <ReportTable customWidth={"100%"} data={[currentPoint]} columns={generateColumns(currentPoint)} />}
                </Row>
                <Row className="footer">
                    <ReportFooter />
                </Row>
            </Row>
        </FullScreen>
    </Col>
  )

}

export default SimpleTableReport
