import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LayerDataContext } from "../../../LayerDataContext";
import { PointContext } from "../../../PointContext";
import { AppContext } from "../../../AppContext";
import { RefContext } from "../../../RefContext";
import ReportTable from "../shared/ReportTable";
import settings from "../../../settings.json";
import MapAddition from '../shared/MapAddition';
import ReportHeader from "../shared/ReportHeader";
import { Row, Col, Container, Form } from "react-bootstrap";
import MapSlider from "../shared/MapSlider";
import ReportFooter from "../shared/ReportFooter";
import { putViewportIntoStorage } from '../../utils'
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  CircleLayer,
  Marker,
  Source,
  Layer
} from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLegend from "../shared/MapLegend";
import { faMaximize, faMinimize, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { Column } from "jspdf-autotable";
import "./api-table-report.scss";

const APITableReport = ({ global, reportID, datasets, api }: any) => {

    const fullscreenhandle = useFullScreenHandle() as any;
    const mapRef = useRef<any>();

    const { currentPoint } = useContext<any>(PointContext);
    const { setCurrentRef } = useContext<any>(RefContext);

    const [dataset, setDataset] = useState(datasets[0])
    const [id, setId] = useState()
    const [reportData, setReportData] = useState()


    const generateColumns = (dataArray: Array<Record<string, any>>) => {
        if (!dataArray || dataArray.length === 0) return [];
    
        // Create a Set to hold unique keys across all objects
        const uniqueKeys = new Set<string>();
        
        // Gather all unique keys from the data array
        dataArray.forEach(dataObject => {
            Object.keys(dataObject).forEach(key => uniqueKeys.add(key));
        });
    
        // Create columns with Header and accessor
        return Array.from(uniqueKeys).map(key => ({
            Header: key,
            accessor: key
        }));
    };
    

    const getData = async (table: string, id: string) => {
        console.log(table, IDBOpenDBRequest)
        global.setLoading(true)
        await api(table, id)
        .then(({ body }: any) => setReportData(body))
        .catch((error:any) => console.log(error))
        .finally(() => global.setLoading(false))
    }

    useEffect(() => {
        if(dataset && id) getData(dataset, id)
    }, [
        dataset,
        id
    ])

    console.log(reportData)


    useEffect(() => {
        global.setUserLogged(true);
        setCurrentRef(mapRef);
      }, []);

    const flyToPoint = (lng: any, lat: any) => {
    if (mapRef.current && lng && lat) {
            mapRef.current.flyTo({
            center: [lng, lat],
            essential: true,
            zoom: 10,
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if(currentPoint && mapRef) {
                const { lat, lng, id } = currentPoint
                setId(id)
                flyToPoint(lng, lat)
            }
        }, 1500)
    }, [currentPoint])

  return (
    <Col className="api-container-map-container">
        <FullScreen handle={fullscreenhandle}>
            <Row className="api-map-container">
                <Row className="header">
                    <ReportHeader
                        global={global}
                        data={[currentPoint]}
                        mapRef={mapRef}
                        reportID={reportID}
                        fullScreenClickHandle={fullscreenhandle.enter}
                    />
                </Row>
                <Row className="map">
                    <Map
                        id={`TableReport-map ${reportID}`}
                        mapboxAccessToken={settings.maboxKey}
                        mapStyle={global.mapStyle}
                        preserveDrawingBuffer={true}
                        ref={mapRef}
                        projection={global.globeView ? "globe" : "mercator" as any}
                        onClick={(e) => global.onMapClick(e)}
                        onMove={(e) => {
                        putViewportIntoStorage({
                            longitude: e.viewState.longitude,
                            latitude: e.viewState.latitude,
                            zoom: e.viewState.zoom,
                        });
                        global.setViewport({
                            longitude: e.viewState.longitude,
                            latitude: e.viewState.latitude,
                            zoom: e.viewState.zoom,
                        });
                        }}
                    >
                        <MapAddition 
                            global={global} 
                            mapRef={mapRef}
                            position={'low'}
                            zipOff={true}
                            MapSliderAdd={true} 
                        />
                        <div className="map-legend-container">
                        <MapLegend legendWidth={300} global={global} />
                        </div>
                    </Map>
                </Row>
            </Row>
            <Form.Group
                    as={Col}
                    controlId="datasetSelect"
                    className="select-menu-container mt-3 mb-3"
                    style={{ width: "80%" }}
                >
                    <Form.Label className="dataset-label">Select Dataset</Form.Label>
                    <Form.Control onChange={({ target }: any) => setDataset(target.value)} className="dataset-select-menu" as="select">
                        {datasets.map((dataset: string, index: number) => (
                            <option key={index} value={dataset}>
                                {dataset}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            <Row className="api-table-container">
                <Row className="table">
                    {reportData && <ReportTable customWidth={"100%"} data={reportData} columns={generateColumns(reportData)} />}
                </Row>
                <Row className="footer">
                    <ReportFooter />
                </Row>
            </Row>
        </FullScreen>
    </Col>
  )

}

export default APITableReport
